import React from 'react';
import { Group, Modal, Stack, Text, Title } from '@mantine/core';
import { CollectionTableProps } from './CollectionTable';
import { DangerYesButton } from '../../../components/Buttons/DangerYesButton';
import { DangerNoButton } from '../../../components/Buttons/DangerNoButton';
import ModalTitle from '../../../components/Titles/ModalTitle';

interface Props {
	opened: boolean;
	close: () => void;
	collection: CollectionTableProps;
	// eslint-disable-next-line no-unused-vars
	deleteCollection: (collection: CollectionTableProps) => void;
}
export default function DeleteCollectionModal({
	opened,
	close,
	collection,
	deleteCollection,
}: Props) {
	return (
		<Modal
			size={'sm'}
			centered={true}
			title={<ModalTitle text={'Delete Collection'} />}
			opened={opened}
			onClose={close}
		>
			<Stack>
				<Text>
					Are you sure you want to delete{' '}
					<Text span fw={500} fs={'italic'}>
						{collection.name}
					</Text>
					?
				</Text>
				<Group position={'right'}>
					<DangerYesButton clickFunc={() => deleteCollection(collection)} />
					<DangerNoButton clickFunc={close} />
				</Group>
			</Stack>
		</Modal>
	);
}
