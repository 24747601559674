import React from 'react';
import {
  createStyles,
  Title,
  Text,
  Stack,
  TypographyStylesProvider,
  Collapse,
  Divider,
  Button,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

const useStyles = createStyles(() => ({
  summary: {
    '& p:last-child': {
      marginBottom: 0,
    },
  },
}));

interface Props {
  summary: string;
}

export default function MobileSummary({ summary }: Props) {
  const { classes } = useStyles();
  const [opened, { toggle }] = useDisclosure(false);
  const SIX_LINE_CLAMP_TEXT_LENGTH_MAXIMUM_ESTIMATE = 300;

  return (
    <>
      <Stack mx={'lg'} spacing={0}>
        <Text className={'sub-header'}>Summary</Text>
        {opened ? (
          <Collapse in={opened}>
            <TypographyStylesProvider>
              <Text
                className={classes.summary}
                styles={{
                  root: {
                    'p:last-child': {
                      marginBottom: 0,
                    },
                  },
                }}
                color={'secondary-text'}
                size={14}
                fw={400}
                dangerouslySetInnerHTML={{ __html: summary || 'None' }}
              />
            </TypographyStylesProvider>
          </Collapse>
        ) : (
          <TypographyStylesProvider>
            <Text
              color={'secondary-text'}
              size={14}
              fw={400}
              lineClamp={6}
              dangerouslySetInnerHTML={{ __html: summary || 'None' }}
            />
          </TypographyStylesProvider>
        )}
        {/*If summary length is > 300 chars then there will likely be more chars than 6 lines.*/}
        {summary?.length > SIX_LINE_CLAMP_TEXT_LENGTH_MAXIMUM_ESTIMATE && (
          <Divider
            size={1}
            color={'primary.0'}
            labelPosition={'center'}
            label={
              <Button variant={'subtle'} onClick={toggle}>
                {opened ? 'Read less' : 'Read more'}
              </Button>
            }
            mb={opened ? 'xl' : 'sm'}
          />
        )}
      </Stack>
    </>
  );
}
