import React, { useEffect } from 'react';
import { Box, Group, Text, createStyles, Stack } from '@mantine/core';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
	googleCalendarIcon,
	outlookCalendarIcon,
} from '../../../components/constants';
import {
	buildGoogleCalendarOAuthUrl,
	buildMicrosoftOutlookOAuthUrl,
	getOrganization,
} from '../../../api/api';
import { PreferencesData } from '../types';
import useMediaQueries from '../../../customHooks/useMediaQueries';
import { assign } from 'lodash';
import { assignSeat } from '../../../api/billing';
import { useRecoilValue } from 'recoil';
import { currentUser } from '../../../Atoms/userAtoms';

const useStyles = createStyles((theme) => ({
	container: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%', // Ensure it takes full height
	},
	cardContainer: {
		padding: theme.spacing.md,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		flexGrow: 1,
		[`@media (min-width: ${theme.breakpoints.xl})`]: {
			width: '100%',
			paddingRight: theme.spacing.xl,
			paddingLeft: theme.spacing.xl,
		},
		[`@media (max-width: ${theme.breakpoints.md})`]: {
			padding: 0,
		},
	},
	card: {
		backgroundColor: theme.white,
		height: '330px',
		width: '330px',
		borderRadius: theme.radius.md,
		boxShadow: theme.shadows.md,
		border: '1px solid #D9D9D9', // Added border
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		cursor: 'pointer',
		'&:hover': {
			boxShadow: theme.shadows.lg,
		},
		[`@media (max-width: ${theme.breakpoints.md})`]: {
			width: '250px',
			height: '250px',
		},
	},
	skipLink: {
		fontSize: 12,
		color: '#7F7F7F',
		fontWeight: 400,
		cursor: 'pointer',
		fontStyle: 'italic',
		marginRight: theme.spacing.md,
	},
	calendarLogo: {
		width: 150,
		height: 150,
		marginBottom: theme.spacing.xl,
		[`@media (max-width: ${theme.breakpoints.md})`]: {
			width: 100,
			height: 100,
		},
	},
	calendarText: {
		fontSize: 24,
		fontWeight: 500,
		textAlign: 'center',
		[`@media (max-width: ${theme.breakpoints.md})`]: {
			fontSize: 20,
		},
	},
	title: {
		fontSize: 32,
		fontWeight: 500,
		marginBottom: theme.spacing.xs,
		textAlign: 'left',
	},
}));

interface Props {
	organizationID: string;
	preferencesData: PreferencesData;
}

const ConnectCalendar: React.FC<Props> = ({
	organizationID,
	preferencesData,
}) => {
	const { classes } = useStyles();
	const user = useRecoilValue(currentUser);
	const navigate = useNavigate();
	const { maxMediumBreakpoints } = useMediaQueries();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const assignSeatToUser = async () => {
			// presence of session_id means the user has successfully subscribed to a plan in stripe.
			const sessionId = searchParams.get('session_id');

			if (sessionId) {
				// need to assign the user to this plan as the first seat.
				// use organizationID to get the user's organization
				// also the subscriptionId is somewhere.
				// Add your logic here to handle the subscription
				// also make sure the seat isnt already assigned.
				const organization = await getOrganization(organizationID);

				const { subscriptionId } = organization;
				const seat = await assignSeat({
					subscriptionId,
					userId: user.id,
				});
			}
		};

		assignSeatToUser();
	}, [searchParams]);

	const handleIntegration = async (platform: string) => {
		try {
			// Perform connection
			localStorage.setItem('congratulate_onboarding_calendar_sync', 'true');

			if (platform === 'google_calendar') {
				const res = await buildGoogleCalendarOAuthUrl(
					organizationID,
					true,
					+preferencesData.autoRecord
				);
				const connectionURL = res?.data?.data?.connectionURL;
				window.location.href = connectionURL;
			} else if (platform === 'microsoft_outlook') {
				const res = await buildMicrosoftOutlookOAuthUrl(
					organizationID,
					true,
					+preferencesData.autoRecord
				);
				const connectionURL = res?.data?.data?.connectionURL;
				window.location.href = connectionURL;
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleGoogleConnect = () => handleIntegration('google_calendar');
	const handleOutlookConnect = () => handleIntegration('microsoft_outlook');
	const handleSkip = () => navigate('/meetings?openTutorial=true');

	return (
		<Stack h={'100%'} p='xl' style={{ position: 'relative' }}>
			<Text className={classes.title}>Connect your calendar</Text>

			<Stack spacing={0} style={{ flexGrow: 1 }}>
				{/* Calendar Selection Cards */}
				<Group className={classes.cardContainer} style={{ flexGrow: 1 }} noWrap>
					<Box className={classes.card} onClick={handleGoogleConnect}>
						<img
							src={googleCalendarIcon}
							alt='Google Calendar'
							className={classes.calendarLogo}
						/>
						<Text className={classes.calendarText}>Google Calendar</Text>
					</Box>

					<Box className={classes.card} onClick={handleOutlookConnect}>
						<img
							src={outlookCalendarIcon}
							alt='Microsoft Outlook'
							className={classes.calendarLogo}
						/>
						<Text className={classes.calendarText}>Microsoft Outlook</Text>
					</Box>
				</Group>
				<Group w={'100%'} position='right' noWrap>
					<Text className={classes.skipLink} onClick={handleSkip}>
						Skip for now &gt;
					</Text>
				</Group>
			</Stack>
		</Stack>
	);
};

export default ConnectCalendar;
