import { useEffect, useState } from 'react';
import {
	Grid,
	Group,
	Paper,
	Stack,
	Text,
	TextInput,
	Title,
} from '@mantine/core';
import { styles } from '../../styles';
import { useRecoilState } from 'recoil';
import { user } from '../../../../../Atoms/userAtoms';
import { useForm } from '@mantine/form';
import InputMask from 'react-input-mask';
import { EditSaveCancelButton } from './EditSaveCancelButton';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../helpers/notifications';
import {
	updateCurrentUser,
	updateOrganizationUser,
} from '../../../../../api/api';
import useUserSettings from '../../../../../customHooks/useUserSettings';
import { logger } from '../../../../../helpers/logger';
import TimezoneSelect from '../../../../../components/Dropdowns/TimezoneSelect';
import { DateTime } from 'luxon';
import { timezones } from '../../../../../helpers/timezone';

export const PersonalInformationCard = () => {
	const { classes, theme } = styles();
	const [userInfo, setUserInfo] = useRecoilState(user);
	const { userSettings, updateUserSettings } = useUserSettings();
	const [editingPersonalInfo, setEditingPersonalInfo] = useState(false);
	const { firstName, lastName, email, phone, dob } = userInfo;
	const { job_title, bio, time_zone } = userSettings;
	const tz = timezones.find((t) => t.value === time_zone);

	const initialValues = {
		firstName,
		lastName,
		job_title,
		email,
		phone,
		time_zone: time_zone || tz?.value,
		dob: dob || '',
		bio,
	};

	const form = useForm({
		initialValues,
		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
			dob: (value) => {
				if (value.length) {
					// Parse the date using Luxon
					const date = DateTime.fromISO(value, { zone: 'utc' });

					// Check if the date is valid
					if (date.isValid) {
						return null; // Valid date
					} else {
						return 'Invalid date'; // Invalid date
					}
				}
				return null; // Return null if the value is empty
			},
		},
	});

	useEffect(() => {
		form.setValues(initialValues);
	}, [userInfo, userSettings]);

	const handleEdit = () => {
		form.setValues(initialValues);
		setEditingPersonalInfo(!editingPersonalInfo);
	};

	const updatePersonalInfo = async (values) => {
		try {
			const {
				firstName,
				lastName,
				email,
				phone,
				time_zone,
				dob,
				job_title,
				bio,
			} = values;

			// update user data dob only if there is one
			// dob (format yyyy-MM-dd)
			await updateCurrentUser(
				{
					dob: dob.length ? dob : null,
				},
				userInfo.id,
				userInfo.currentOrganizationID
			);

			// update organization user data
			const updatedUser = await updateOrganizationUser(
				{
					firstName,
					lastName,
					email,
					phone,
				},
				userInfo.id,
				userInfo.currentOrganizationID
			);
			// update user settings
			await updateUserSettings([
				{
					key: 'job_title',
					value: job_title || '',
				},
				{
					key: 'bio',
					value: bio || '',
				},
				{
					key: 'time_zone',
					value: time_zone,
				},
			]);
			// update state
			const newUserInfo = {
				...userInfo,
				...updatedUser?.data?.data,
			};
			setUserInfo(newUserInfo);
			form.setValues(newUserInfo);
			// success notification
			showSuccessNotification({
				message: 'Your personal information has been updated.',
			});
		} catch (error) {
			// failure notification
			showFailureNotification({
				message:
					"Sorry, we couldn't update the address. Error: " + error?.message,
			});
			logger('error', 'error updating personal information', { error });
		} finally {
			setEditingPersonalInfo(false);
		}
	};

	return (
		<form onSubmit={form.onSubmit(updatePersonalInfo)}>
			<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
				<Stack>
					<Group position={'apart'}>
						<Title order={4} color={'primary-text'}>
							Personal Information
						</Title>
						<EditSaveCancelButton
							isEditing={editingPersonalInfo}
							handleEdit={handleEdit}
						/>
					</Group>
					<Grid justify={'space-between'}>
						{/*1st row*/}
						<Grid.Col span={4}>
							<Text mb={4} size={'xs'}>
								First Name
							</Text>
							{editingPersonalInfo ? (
								<TextInput
									{...form.getInputProps('firstName')}
									radius='md'
									styles={{
										input: {
											backgroundColor: theme.colors.primary[0],
										},
									}}
								/>
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{firstName || 'N/A'}
								</Text>
							)}
						</Grid.Col>
						<Grid.Col span={4}>
							<Text mb={4} size={'xs'}>
								Last Name
							</Text>
							{editingPersonalInfo ? (
								<TextInput
									{...form.getInputProps('lastName')}
									radius='md'
									bg={'primary.0'}
									styles={{
										input: {
											backgroundColor: theme.colors.primary[0],
										},
									}}
								/>
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{lastName || 'N/A'}
								</Text>
							)}
						</Grid.Col>
						<Grid.Col span={4}>
							{' '}
							<Text mb={4} size={'xs'}>
								Date of Birth
							</Text>
							{editingPersonalInfo ? (
								<InputMask
									mask='9999-99-99'
									placeholder='YYYY-MM-DD'
									{...form.getInputProps('dob')}
								>
									{(inputProps) => (
										<TextInput
											{...inputProps}
											styles={{
												input: {
													backgroundColor: theme.colors.primary[0],
												},
											}}
										/>
									)}
								</InputMask>
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{dob || 'N/A'}
								</Text>
							)}
						</Grid.Col>
						{/*2nd row*/}
						<Grid.Col span={4}>
							<Text mb={4} size={'xs'}>
								Job Title
							</Text>
							{editingPersonalInfo ? (
								<TextInput
									{...form.getInputProps('job_title')}
									radius='md'
									styles={{
										input: {
											backgroundColor: theme.colors.primary[0],
										},
									}}
								/>
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{job_title || 'N/A'}
								</Text>
							)}
						</Grid.Col>
						<Grid.Col span={4}>
							<Text mb={4} size={'xs'}>
								Phone
							</Text>
							{editingPersonalInfo ? (
								<TextInput
									{...form.getInputProps('phone')}
									radius='md'
									styles={{
										input: {
											backgroundColor: theme.colors.primary[0],
										},
									}}
								/>
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{phone || 'N/A'}
								</Text>
							)}
						</Grid.Col>
						<Grid.Col span={4}>
							<Text mb={4} size={'xs'}>
								Time Zone
							</Text>
							{editingPersonalInfo ? (
								<TimezoneSelect form={form} showLabel={false} />
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{(tz?.label || time_zone || 'N/A').replace(/_/g, ' ')}
								</Text>
							)}
						</Grid.Col>
						{/*3rd row*/}
						<Grid.Col span={12}>
							<Text mb={4} size={'xs'}>
								Email address
							</Text>
							{editingPersonalInfo ? (
								<TextInput
									{...form.getInputProps('email')}
									radius='md'
									styles={{
										input: {
											backgroundColor: theme.colors.primary[0],
										},
									}}
									disabled
								/>
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{email || 'N/A'}
								</Text>
							)}
							{/* <Text mb={4} size={'xs'}>
								Bio
							</Text>
							{editingPersonalInfo ? (
								<Textarea
									{...form.getInputProps('bio')}
									radius='md'
									styles={{
										input: {
											backgroundColor: theme.colors.primary[0],
										},
									}}
								/>
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{bio || 'N/A'}
								</Text>
							)} */}
						</Grid.Col>
					</Grid>
				</Stack>
			</Paper>
		</form>
	);
};
