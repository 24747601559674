import React, { useEffect, useState } from 'react';
import {
	Chip,
	createStyles,
	Paper,
	Group,
	Stack,
	Text,
	Grid,
	useMantineTheme,
	Textarea,
	Checkbox,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { defaultFailureNotificationProps } from '../constants';
import { momentTypeChipSet } from '../../helpers/highlights';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useForm } from '@mantine/form';
import { CreateHighlightButton } from '../aside/components/CreateHighlightButton';
import CustomChip from '../aside/CustomChip';
import {
	currentMeetingHighlights as HighlightAtom,
	currentMeetingHighlights,
	mobileTimestamp,
	videoPlayerCurrentTime,
	videoPlayerElapsedTime,
} from '../../Atoms/meetingAtom';
import { IMeeting } from '../../interfaces/meeting';
import { createMeetingHighlight, updateMeetingHighlight } from '../../api/api';
import {
	editingHighlightAtom,
	editingHighlightID as editingHighlightIDAtom,
	editingHighlightInitialValuesAtom,
} from '../../Atoms/userAtoms';
import {
	convertSecondsToHHMMSS,
	convertTimeToSeconds,
	formatSecondsToMinutesAndSeconds,
} from '../../_utils/time';
import TimestampInput from '../TimestampInput';
import { logger } from '../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';

interface CreateHighlightCardProps {
	meeting: IMeeting;
	cardTitle?: string;
	buttonText?: string;
	isUpdating?: boolean;
}

const useStyles = createStyles((theme) => ({
	cardText: {
		fontSize: 12,
		color: theme.colors?.primary[5],
	},
	title: {
		color: theme.colors?.['primary-text'],
		fontWeight: 600,
		fontSize: 18,
	},
	toolTip: {
		fontSize: 12,
	},
	labels: {
		color: theme.colors?.['secondary-text'],
		fontSize: '10px',
		lineHeight: '14px',
		fontWeight: 600,
	},
	validationError: {
		color: '#fa5252',
		fontSize: '10px',
		lineHeight: 1.2,
		display: 'block',
	},
}));

export const CreateHighlightCard = ({
	meeting,
	buttonText,
	cardTitle = 'New Highlight',
	isUpdating = false,
}: CreateHighlightCardProps) => {
	const { classes } = useStyles();
	const [highlights, setHighlights] = useRecoilState(currentMeetingHighlights);
	const [isPrivate, setIsPrivate] = useState(false);
	const [buttonLoading, setButtonLoading] = useState(false);
	// const [highlightChipGroupVal, setHighlightChipGroupVal] = useState<string>('');
	const currentHighlights = useRecoilValue(HighlightAtom);
	// elapsedTime is what the user sees - string of 'mm:ss'
	const elapsedTime = useRecoilValue(videoPlayerElapsedTime);
	// currentTime is number type and what we use and pass to backend.
	// it is the current time of video player. so 0:01 means 1.
	const currentTime = useRecoilValue(videoPlayerCurrentTime);
	const mobileTimestampValue = useRecoilValue(mobileTimestamp);
	// const ref = useClickOutside(() => {
	// 	setEditingHighlight(false);
	// 	if (editingHighlight) form.reset();
	// });

	const [editingHighlight, setEditingHighlight] =
		useRecoilState(editingHighlightAtom);
	const [editingHighlightInitialValues, setEditingHighlightInitialValues] =
		useRecoilState(editingHighlightInitialValuesAtom);
	const editingHighlightID = useRecoilValue(editingHighlightIDAtom);
	const { content, type, visibility, timeStartMS, isTopQuestion } =
		editingHighlightInitialValues;
	const timeStartInSeconds = timeStartMS / 1000;
	const [highlightTimestamp, setHighlightTimestamp] = useState(
		formatSecondsToMinutesAndSeconds(timeStartInSeconds)
	);
	const [createHighlightTimestamp, setCreateHighlightTimestamp] = useState(
		formatSecondsToMinutesAndSeconds(currentTime)
	);
	const [timestampExceedsVideoDuration, setTimestampExceedsVideoDuration] =
		useState(false);

	const videoDuration = convertSecondsToHHMMSS(
		meeting?.videoMetadata.duration / 1000
	);
	const isAtleastOneHourLong = videoDuration?.substring(0, 2) !== '00';
	// if videoDuration is hh:mm:ss then it is at least 1 hour long. If its mm:ss then it's less than an hour;

	const form = useForm({
		initialValues: {
			content: content || '',
			type: type || '',
			isTopQuestion: isTopQuestion ?? null,
			// timestamp: highlightTimeStamp,
		},
		validate: {
			content: (value) => (value.length ? null : 'Content is required.'),
			type: (value) => (value.length ? null : 'Type is required.'),
			// timestamp: (value) => (value.length ? null : 'Time is required.'),
		},
	});

	useEffect(() => {
		return () => {
			form.reset();
			setEditingHighlightInitialValues({
				content: '',
				type: '',
				visibility: '',
				timeStartMS: 0,
				isTopQuestion: null,
			});
		};
	}, []);

	useEffect(() => {
		if (content.length) {
			form.setValues({
				content,
				type,
				isTopQuestion,
			});
			setIsPrivate(visibility === 'personal');
			setHighlightTimestamp(
				formatSecondsToMinutesAndSeconds(timeStartInSeconds)
			);
		}
	}, [editingHighlightInitialValues]);

	useEffect(() => {
		setCreateHighlightTimestamp(elapsedTime || '00:00');
	}, [elapsedTime]);

	useEffect(() => {
		if (form.values['type'] !== 'question') {
			form.setFieldValue('isTopQuestion', false);
		}
	}, [form.values['type']]);

	const createHighlight = async (values: {
		content: string;
		type: string;
		isTopQuestion: boolean | null;
	}) => {
		if (timestampExceedsVideoDuration) {
			showFailureNotification({
				message: 'Start time cannot be greater than video duration.',
			});
			return;
		}
		setButtonLoading(true);
		const timeInMS = convertTimeToSeconds(createHighlightTimestamp) * 1000;
		try {
			const payload = {
				timeStartMS: timeInMS || 0,
				timeEndMS: timeInMS || 0,
				...values,
				visibility: isPrivate ? 'personal' : 'group',
			};
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const highlight = await createMeetingHighlight(
				payload,
				meeting.id,
				meeting.organizationID
			);

			// highlight successful
			if ('timeStartMS' in highlight) {
				showSuccessNotification({
					message: 'Highlight was successfully created! Great work!',
				});
				setHighlights([highlight, ...highlights]);
				form.reset();
				setCreateHighlightTimestamp(
					formatSecondsToMinutesAndSeconds(currentTime)
				);
				setIsPrivate(false);
			} else {
				// error message sent back
				logger('error', 'Error creating highlight', highlight);
				showFailureNotification({
					...defaultFailureNotificationProps,
					title: 'Error',
					message: 'Sorry, failed to create a highlight. Please try again.',
				});
			}
		} catch (error: unknown) {
			logger('error', 'Error creating highlight', error);
			showFailureNotification({
				...defaultFailureNotificationProps,
				title: 'Error',
				message: 'Sorry, failed to create a highlight. Please try again.',
			});
		} finally {
			setButtonLoading(false);
			setEditingHighlight(false);
		}
	};

	const updateHighlight = async (values: { content: string; type: string }) => {
		if (timestampExceedsVideoDuration) {
			showSuccessNotification({
				message: 'Start time cannot be greater than video duration.',
			});
			return;
		}
		setButtonLoading(true);
		const highlightTime = convertTimeToSeconds(highlightTimestamp) * 1000;
		try {
			const payload = {
				timeStartMS: highlightTime || 0,
				timeEndMS: highlightTime || 0,
				...values,
				visibility: isPrivate ? 'personal' : 'group',
			};
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const highlight = await updateMeetingHighlight({
				data: {
					...payload,
				},
				meetingID: meeting.id,
				meetingHighlightID: editingHighlightID,
				organizationID: meeting.organizationID,
			});

			showSuccessNotification({
				message: 'Highlight was successfully updated! Great work!',
			});
			const updatedHighlights = currentHighlights.map((hl) =>
				hl.id === highlight.id ? highlight : hl
			);
			setHighlights(updatedHighlights);
			form.reset();
			setIsPrivate(false);
			setEditingHighlight(false);
		} catch (error: unknown) {
			logger('error', 'Error updating highlight', error);
			showFailureNotification({
				message: 'Sorry, failed to update a highlight. Please try again.',
			});
		} finally {
			setButtonLoading(false);
		}
	};

	return (
		<Paper
			//  ref={ref}
			p={'xs'}
			w={'100%'}
			maw={'100%'}
			// sx={{ zIndex: editingHighlight ? 500 : 0 }}
		>
			<form
				onSubmit={form.onSubmit((values) =>
					isUpdating ? updateHighlight(values) : createHighlight(values)
				)}
			>
				<Stack spacing={24} my={'sm'}>
					<Group position={'apart'} align={'center'}>
						<Text fw={700}>New Highlight</Text>
						<Group position={'right'} align={'center'} spacing={4}>
							{isPrivate ? (
								<FontAwesomeIcon icon={faLock} size={'xs'} />
							) : (
								<FontAwesomeIcon icon={faGlobe} size={'xs'} />
							)}
							<Text className={classes.cardText}>
								{isPrivate ? 'Personal' : 'Public'}
							</Text>
						</Group>
					</Group>
					<Grid>
						<Grid.Col span={3}>
							<Stack spacing={8}>
								<TimestampInput
									value={
										isUpdating ? highlightTimestamp : createHighlightTimestamp
									}
									setValue={
										isUpdating
											? setHighlightTimestamp
											: setCreateHighlightTimestamp
									}
									duration={meeting?.videoMetadata?.duration}
									error={timestampExceedsVideoDuration}
									setError={setTimestampExceedsVideoDuration}
								/>
							</Stack>
						</Grid.Col>
						<Grid.Col span={9}>
							<Textarea
								{...form.getInputProps('content')}
								placeholder='Your comment'
								radius='md'
								size='xs'
								autosize
								minRows={2}
								maxRows={6}
							/>
						</Grid.Col>
					</Grid>
					<Stack spacing={8}>
						<Group position={'apart'}>
							<Text className={classes.labels}>Moment Type</Text>
							{form.values['type'] === 'question' && (
								<Text className={classes.labels}>Top Question</Text>
							)}
						</Group>
						<Group position={'apart'} noWrap>
							<Chip.Group {...form.getInputProps('type')}>
								<Group w={'100%'} position={'left'}>
									<Group position='apart' noWrap spacing={'sm'}>
										{momentTypeChipSet.map(({ value, label, color }) => (
											<CustomChip
												key={value}
												color={color}
												size={'xs'}
												value={value}
												label={label}
											/>
										))}
									</Group>
								</Group>
							</Chip.Group>
							{form.values['type'] === 'question' && (
								<Checkbox
									{...form.getInputProps('isTopQuestion', {
										type: 'checkbox',
									})}
								/>
							)}
						</Group>
						{'type' in form.errors && form.values['type']?.length < 1 && (
							<Text className={classes.validationError}>Type is required.</Text>
						)}
					</Stack>
					<CreateHighlightButton
						loading={buttonLoading}
						buttonText={buttonText}
					/>
				</Stack>
			</form>
		</Paper>
	);
};

export default React.memo(CreateHighlightCard);
