import Lottie from 'lottie-react';
import * as animationData from './lottie-paper-plane-loader.json';
import { Loader, Stack, Text } from '@mantine/core';
import { useRecoilValue } from 'recoil';
import { organizationBrand } from '../../Atoms/settings';

export const ReelayLoader = ({
	height = 600,
	width = 600,
}: {
	height?: string | number;
	width?: string | number;
}) => {
	const brand = useRecoilValue(organizationBrand);
	if (brand?.logo_url) return <Loader size={200} />;

	return (
		<Stack>
			<Lottie
				loop={true}
				animationData={animationData}
				style={{
					width,
					height,
					marginLeft: 'auto',
					marginRight: 'auto',
				}}
			/>
			<Text
				style={{
					position: 'relative',
					bottom: '200px',
				}}
				align='center'
				fz={'1.5rem'}
				c={'secondary-text'}
				fw={500}
			>
				Loading your meeting details...
			</Text>
		</Stack>
	);
};
