export const uploadActionCards: ReelayActionCardData[] = [
	{
		imageURL:
			'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/upload_video.png',
		title: 'Past Meeting',
		content:
			'Upload a meeting that has already happened. File formats: .mp4, .avi, .ogg, .wav, .wma, .wmv, .mov',
		action: 'past',
	},
];

export const botActionCards: ReelayActionCardData[] = [
	{
		imageURL:
			'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/record_video.png',
		title: 'Live Meeting',
		content: 'Add Reelay to a live meeting now.',
		action: 'live',
	},
	{
		imageURL:
			'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/schedule_reelay.png',
		title: 'Future Meeting',
		content: 'Set up Reelay so it automatically joins a future meeting.',
		action: 'future',
	},
];

export interface ReelayActionCardData {
	imageURL: string;
	title: string;
	content: string;
	action: string;
}
