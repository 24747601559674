import React, { useState } from 'react';
import { ActionIcon, Group, Popover, Stack, Text } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-solid-svg-icons';
import { DangerYesButton } from '../../../Buttons/DangerYesButton';
import { DangerNoButton } from '../../../Buttons/DangerNoButton';
import { ShareableLink } from '../../../../helpers/links';

interface Props {
  deleteShareableLink: (link: ShareableLink) => void;
  link: ShareableLink;
  // opened: boolean;
  // setOpened: (o: any) => void;
}

export const DeleteIcon = ({
  deleteShareableLink,
  // opened,
  // setOpened,
  link,
}: Props) => {
  const [opened, setOpened] = useState(false);
  const handleDelete = () => {
    deleteShareableLink(link);
    setOpened(false);
  };

  return (
    <Popover opened={opened} onChange={setOpened}>
      <Popover.Target>
        <ActionIcon onClick={() => setOpened((o) => !o)}>
          <FontAwesomeIcon icon={faX} color={'red'} />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack>
          <Text>Are you sure you want to delete this link?</Text>
          <Group position={'right'}>
            <DangerYesButton clickFunc={handleDelete} />
            <DangerNoButton clickFunc={setOpened} />
          </Group>
        </Stack>
      </Popover.Dropdown>
    </Popover>
  );
};
