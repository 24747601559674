import { atom } from 'recoil';

type Testimonial = {
	avatarUrl: string;
	name: string;
	organization: string;
	production: boolean;
	role: string;
	stars: number;
	testimonial: string;
};

export const testimonialsAtom = atom<Testimonial[]>({
	key: 'testimonialsAtom',
	default: [],
});
