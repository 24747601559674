import { decode } from 'html-entities';

export const capitalizeFirstLetter = (str: string) => {
	return str[0].toUpperCase() + str.slice(1);
};

/**
 * replaces &apos; sent from backend with single quote "'"
 * @param str
 */
export const replaceApostropheHTMLEntityWithSingleQuote = (str: string) =>
	decode(str);

export function removeHtmlTags(inputString: string) {
	if (typeof inputString !== 'string') {
		return '';
	}
	const regex = /<[^>]+>/g;
	return replaceApostropheHTMLEntityWithSingleQuote(
		inputString.replace(regex, '')
	);
}

// convert string "1rem" to number 16 (base font size)
export const convertRemToPx = (remValue: string) => parseFloat(remValue) * 16;

export function swapBackgroundQueryParam(url, newHexColor) {
	try {
		const urlObject = new URL(url);

		// remove # from hex color
		const removeHashFromHexColor = newHexColor.replace('#', '');

		// Set the new background value
		urlObject.searchParams.set('background', removeHashFromHexColor);

		// Convert the updated URL object back to a string
		const updatedUrl = urlObject.toString();
		return updatedUrl;
	} catch (error) {
		console.error('Error swapping background query parameter:', error);
		return url; // Return the original URL in case of an error
	}
}

export const swapBackgroundColor = (url, newBackgroundColor) => {
	if (typeof url !== 'string') {
		return url;
	}

	const backgroundColor = newBackgroundColor.replace('#', '');
	// Regular expression to match the background parameter in the URL
	const regex = /background=([0-9A-Fa-f]+)/;

	// Check if the URL contains a background parameter
	const match = url.match(regex);

	if (match) {
		// Extract the existing background color
		const existingBackgroundColor = match[1];

		// Replace the existing background color with the new one
		const updatedUrl = url.replace(existingBackgroundColor, backgroundColor);

		// Return the updated URL
		return updatedUrl;
	} else {
		return url;
	}
};

export const getPageTitle = (pathname: string) => {
	switch (true) {
		case pathname.includes('meetings'):
			return 'Meetings';
		case pathname.includes('actions'):
			return 'Actions';
		case pathname.includes('mobile'):
			return 'Mobile';
		case pathname.includes('login'):
			return 'Login';
		case pathname.includes('admin'):
			return 'Admin';
		case pathname.includes('support'):
			return 'Support';
		case pathname.includes('integrations'):
			return 'Integrations';
		case pathname.includes('settings'):
			return 'Settings';
		case pathname.includes('account'):
			return 'Account';
		case pathname.includes('unauthorized'):
			return 'Unauthorized';
		case pathname.includes('share'):
			return 'Share';
		case pathname.includes('access-request'):
			return 'Access Request';
		case pathname.includes('signup'):
			return 'Signup';
		case pathname.includes('collections'):
			return 'Collections';
		default:
			return 'Page Not Found'; // Default title for unknown paths
	}
};

export const arraysHaveSameStrings = (arr1: string[], arr2: string[]) => {
	// Check if the arrays have the same length
	if (arr1.length !== arr2.length) {
		return false;
	}

	// Sort both arrays
	const sortedArr1 = arr1.slice().sort();
	const sortedArr2 = arr2.slice().sort();

	// Compare sorted arrays element by element
	for (let i = 0; i < sortedArr1.length; i++) {
		if (sortedArr1[i] !== sortedArr2[i]) {
			return false;
		}
	}

	// If all elements match, the arrays have the same items regardless of order
	return true;
};

export const safelyParseJSON = (input) => {
	try {
		// Attempt to parse the input as JSON
		const parsed = JSON.parse(input);

		// If it doesn't throw an error, it's valid JSON
		return parsed;
	} catch (e) {
		// If an error is thrown, it's not valid JSON, so return the original input
		return input;
	}
};

export function debounce(func, delay) {
	let timer;
	return function (...args) {
		clearTimeout(timer);
		timer = setTimeout(() => {
			func.apply(this, args);
		}, delay);
	};
}
