import { Skeleton } from '@mantine/core';

const TableRowSkeleton = ({
	colSpan,
	height = '36.69px',
}: {
	colSpan: number;
	height?: string | number;
}) => {
	return (
		<tr
			style={{
				height,
			}}
		>
			{Array.from({ length: colSpan }, (_, index) => index).map((idx) => (
				<td key={idx}>
					<Skeleton height={20} radius='xl' />
				</td>
			))}
		</tr>
	);
};

export default TableRowSkeleton;
