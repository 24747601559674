import React, { useEffect, useRef, useState } from 'react';
import { Button, Group, Modal, Stack, TextInput, Title } from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import { updateCollection } from '../../../api/collections';
import { logger } from '../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	collectionsAtom,
	currentCollection,
	editingCollectionAtom,
	renameModalOpenedAtom,
} from '../../../Atoms/collections';
import { user } from '../../../Atoms/userAtoms';
import ModalTitle from '../../../components/Titles/ModalTitle';

export default function RenameCollectionModal() {
	const focusTrapRef = useFocusTrap();
	const buttonRef = useRef(null);
	const setCollections = useSetRecoilState(collectionsAtom);
	const setCollection = useSetRecoilState(currentCollection);
	const [opened, setOpened] = useRecoilState(renameModalOpenedAtom);
	const editingCollection = useRecoilValue(editingCollectionAtom);
	const currentUser = useRecoilValue(user);
	const { currentOrganizationID } = currentUser;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [value, setValue] = useState(editingCollection.name);

	useEffect(() => {
		setValue(editingCollection.name);
	}, [editingCollection]);

	const handleUpdateCollection = async () => {
		try {
			if (!value.length) {
				setError('Name is required');
				return;
			}

			setLoading(true);
			const updatedCollection = await updateCollection(
				{ name: value },
				editingCollection.id,
				currentOrganizationID
			);
			setCollections((cur) =>
				cur.map((c) => (c.id === editingCollection.id ? updatedCollection : c))
			);
			setCollection((cur) => ({
				...cur,
				...updatedCollection,
			}));
			logger('info', 'Collection successfully made', updatedCollection);
			setOpened(false);
			setValue('');
			setError(null);
			showSuccessNotification({ message: 'Collection successfully made!' });
		} catch (error) {
			logger('error', 'Error creating collection', error);
			showFailureNotification({
				message: "Sorry, we couldn't update the collection. Please try again.",
			});
		} finally {
			setLoading(false);
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && buttonRef.current) {
			buttonRef.current.click();
		}
	};

	return (
		<Modal
			size={'sm'}
			centered={true}
			title={<ModalTitle text={'Rename'} />}
			opened={opened}
			onClose={() => setOpened(false)}
		>
			<Stack ref={focusTrapRef}>
				<TextInput
					data-autofocus
					placeholder={'Enter name'}
					value={value}
					onChange={(event) => setValue(event.currentTarget.value)}
					disabled={loading}
					error={error}
					onKeyDown={handleKeyPress}
				/>
				<Group noWrap position={'right'} spacing={'xs'}>
					<Button
						variant='subtle'
						radius='md'
						onClick={() => setOpened(false)}
						px={'sm'}
						disabled={loading}
					>
						Cancel
					</Button>
					<Button
						ref={buttonRef}
						onClick={handleUpdateCollection}
						variant='subtle'
						radius='md'
						px={'sm'}
						loading={loading}
					>
						Save
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
