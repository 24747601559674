export const reelay_logo =
	'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/reelay-logo-with-icon-high-res.png';
export const reelay_woodmark_logo =
	'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/reelay-wordmark-black.png';

export const googleCalendarIcon =
	'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/google_calendar_icon.png';
export const outlookCalendarIcon =
	'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/outlook_calendar_icon.png';
export const slackIcon =
	'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/slack_icon.png';
export const googleIcon =
	'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/google_icon.png';
export const microsoftIcon =
	'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/microsoft_icon.png';
export const zoomIcon =
	'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/zoom_icon.png';
export const googleMeetIcon =
	'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/meet_icon.png';
export const outlookTeamsIcon =
	'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/teams_icon.png';
export const reelayLogo =
	'https://reelay-ai-production.s3.us-west-1.amazonaws.com/assets/images/reelay-icon-high-res.png';

export const termsLink = 'https://www.reelay.com/terms-of-service';
export const privacyLink = 'https://www.reelay.com/privacy-policy';
export const helpLink = 'https://help.reelay.com/en';

export enum IntegrationType {
	Integration = 'INTEGRATION',
	App = 'APP',
}
export const calendarIntegrationList = [
	{
		id: 'j1j2n3-12n3n3-nmwmq-1231',
		title: 'Google Calendar',
		image: googleCalendarIcon,
		enabled: false,
		platform: 'google_calendar',
		description:
			'Connect with your Google Calendar to automatically schedule meetings.',
		active: true,
		vote_count: 22,
		type: IntegrationType.Integration,
	},
	{
		id: 'j1j2n3-12n3n3-nmwmq-1231',
		title: 'Outlook Calendar',
		image: outlookCalendarIcon,
		enabled: false,
		platform: 'microsoft_outlook',
		description:
			'Connect with your Outlook Calendar to automatically schedule meetings.',
		active: true,
		vote_count: 22,
		type: IntegrationType.Integration,
	},
];

export const communicationsIntegrationList = [
	{
		id: 'j1j2n3-12n3n3-nmwmq-1231',
		title: 'Slack',
		image: slackIcon,
		enabled: false,
		description: 'Connect with your Slack to automatically schedule meetings.',
		platform: 'slack',
		active: true,
		vote_count: 22,
		type: IntegrationType.Integration,
	},
	{
		id: 'j1j2n3-12n3n3-nmwmq-1231',
		title: 'Zoom',
		image: zoomIcon,
		enabled: false,
		description: 'Install Reelay for Zoom.',
		platform: 'zoom',
		active: true,
		vote_count: 22,
		type: IntegrationType.App,
	},
];

export const teams = [
	{
		value: ['kjannenga1@gmail.com', 'cjannenga312@gmail.com'],
		label: 'Team Jannenga',
		group: 'Teams',
	},
	{ value: ['a@b.com', 'c@d.com'], label: 'Team 2', group: 'Teams' },
	{
		value: 'kjannenga@reelay.ai',
		label: 'richard@piedpiper.com',
		group: 'Team Members',
	},
	{
		value: 'cjannenga@reelay.ai',
		label: 'mrbiggheadi@piedpiper.com',
		group: 'Team Members',
	},
];

export const priorityLevels = [
	{ title: 'P1', color: 'green' },
	{ title: 'P2', color: 'yellow' },
	{ title: 'P3', color: 'orange' },
	{ title: 'P4', color: 'red' },
];

import {
	faVideoArrowUpRight,
	faBuildingUser,
	faRobot,
	faFolders,
	faListCheck,
	faCog,
	faPlay,
	faCalendar,
	faUser,
	faShield,
	faUsers,
	faArchive,
	faShare,
	faBooks,
	faArrowUpFromBracket,
	faUserGroup,
	faCreditCard,
	faCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { UserRoles } from '../interfaces/user';

export const adminNavLinks = [
	{
		icon: faVideoArrowUpRight,
		label: 'Meetings',
		path: '/admin/meetings',
		nestedLinks: [],
	},
	{
		icon: faBuildingUser,
		label: 'Organizations',
		path: '/admin/organizations',
		nestedLinks: [],
	},
	{
		icon: faRobot,
		label: 'Live',
		path: '/admin/live',
		nestedLinks: [],
	},
];

export const navigationLinks = [
	{
		icon: faBooks,
		label: 'Library',
		path: '/meetings',
		nestedLinks: [
			{ label: 'Recordings', path: '/meetings/recordings', icon: faPlay },
			{ label: 'Minutes', path: '/meetings/minutes', icon: faCalendar },
			{ label: 'Schedule', path: '/meetings/schedule', icon: faCalendar },
		],
	},
	{
		icon: faFolders,
		label: 'Collections',
		path: '/collections',
		nestedLinks: [
			{ label: 'Archive', path: '/collections/archive', icon: faArchive },
			{
				label: 'My Collections',
				path: '/collections',
				icon: faFolders,
			},
			{
				label: 'Shared with me',
				path: '/collections/shared',
				icon: faArrowUpFromBracket,
			},
		],
	},
	{
		icon: faListCheck,
		label: 'Actions',
		path: '/actions',
		nestedLinks: [
			{
				label: 'To Do',
				path: '/actions/todo',
				icon: faListCheck,
			},
			{ label: 'Done', path: '/actions/done', icon: faCheck },
		],
	},
	{
		icon: faCog,
		label: 'Settings',
		path: '/settings',
		nestedLinks: [
			{ label: 'Account', path: '/settings/account', icon: faUser },
			{ label: 'Security', path: '/settings/security', icon: faShield },
			{ label: 'Teams', path: '/settings/teams', icon: faUserGroup },
			{ label: 'Members', path: '/settings/members', icon: faUsers },
			{ label: 'Billing', path: '/settings/billing', icon: faCreditCard },
			{
				label: 'Integrations',
				path: '/settings/integrations',
				icon: faCalendar,
			},
		],
	},
];

export const adminRoles = ['support', 'masteradmin'];
export const customerAdminRoles = ['admin', 'superadmin', 'masteradmin'];

export const defaultSuccessNotificationProps = {
	withCloseButton: true,
	autoClose: 5000,
	color: 'green',
};

export const defaultFailureNotificationProps = {
	withCloseButton: true,
	autoClose: 5000,
	color: 'red',
};

export const statusChips = [
	{
		value: 'created',
		label: 'Created',
		color: 'gray',
	},
	{
		value: 'scheduled',
		label: 'Scheduled',
		color: 'gray',
	},
	{
		value: 'pending',
		label: 'Pending',
		color: 'yellow',
	},
	{
		value: 'draft',
		label: 'Draft',
		color: 'blue',
	},
	{
		value: 'processing',
		label: 'Processing',
		color: 'green',
	},
	{
		value: 'audit',
		label: 'Audit',
		color: 'indigo',
	},
	{
		value: 'review',
		label: 'Review',
		color: 'red',
	},
	{
		value: 'published',
		label: 'Published',
		color: 'purple',
	},
	{
		value: 'planned',
		label: 'Planned',
		color: 'orange',
	},
];

export const roles = [
	{ value: UserRoles.Member, label: 'Member' },
	{ value: UserRoles.Admin, label: 'Admin' },
	{ value: UserRoles.SuperAdmin, label: 'Super Admin' },
	{ value: UserRoles.CreateMeeting, label: 'Create Meeting' },
	{ value: UserRoles.InviteToOrganization, label: 'Invite To Organization' },
	{ value: UserRoles.ProjectManager, label: 'Project Manager' },
];

export const MILLISECONDS_PER_SECOND = 1000;
export const SECONDS_PER_MINUTE = 60;
export const MINUTES_PER_HOUR = 60;
export const HOURS_PER_DAY = 24;
export const DAYS_PER_WEEK = 7;
export const TOTAL_PERCENTAGE = 100;

export const WATCH_DURATION = 10;

export const flagsmithFeatureFlags = {
	advancedSearch: 'advanced_search',
	agendaVisible: 'agenda_visible',
	highlightSharing: 'highlight_sharing',
	meetingCollections: 'meeting_collections',
	settingsSecurity: 'settings_security',
	transcriptSearch: 'transcript_search',
	actionItems: 'action_items',
	zoomIntegrationCard: 'zoom_integration_card',
	queueControls: 'queue_controls',
};

export const reelayAIModels = {
	'openai-4': 'gpt-4-turbo',
	'azure-4': 'reelay_turbo_preview',
	'openai-4o': 'gpt-4o',
	'azure-4o': 'reelay-4o',
};

export const reelayAIModelList = [
	{ value: reelayAIModels['openai-4'], label: 'GPT4 (openai)' },
	{ value: reelayAIModels['azure-4'], label: 'GPT4 (azure)' },
	{ value: reelayAIModels['openai-4o'], label: 'GPT4o (openai)' },
	{ value: reelayAIModels['azure-4o'], label: 'GPT4o (azure)' },
];
