import { Badge } from '@mantine/core';
import { UserRoles } from '../../../interfaces/user';

export const RoleBadge = ({ role }: { role: string }) => {
	const getRoleBadge = () => {
		switch (role) {
			case UserRoles.Member:
				return (
					<Badge key={role} size='xs' color={'green'}>
						Member
					</Badge>
				);
			case UserRoles.Admin:
				return (
					<Badge key={role} size='xs' color={'blue'}>
						Admin
					</Badge>
				);
			case UserRoles.SuperAdmin:
				return (
					<Badge key={role} size='xs' color={'grape'}>
						Super Admin
					</Badge>
				);
			case UserRoles.CreateMeeting:
				return (
					<Badge key={role} size='xs' color={'green'}>
						Create Meeting
					</Badge>
				);
			case UserRoles.InviteToOrganization:
				return (
					<Badge key={role} size='xs' color={'teal'}>
						Invite to Organization
					</Badge>
				);
			case UserRoles.Support:
				return (
					<Badge key={role} size='xs' color={'violet'}>
						Support
					</Badge>
				);
			case UserRoles.Guest:
				return (
					<Badge key={role} size='xs' color={'orange'}>
						Guest
					</Badge>
				);
			case UserRoles.ProjectManager:
				return (
					<Badge key={role} size='xs' color='indigo'>
						Project Manager
					</Badge>
				);
			default:
				return null;
		}
	};
	return getRoleBadge();
};
