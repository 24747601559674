import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextInput } from '@mantine/core';
import { Dispatch, SetStateAction } from 'react';

interface Props {
	value: string;
	setValue: Dispatch<SetStateAction<string>>;
	placeholder: string;
	width?: number | string;
	maxWidth?: number | string;
	flex?: number;
}

export default function GeneralSearchBar({
	value,
	setValue,
	placeholder,
	width = '100%',
	flex = 0,
}: Props) {
	return (
		<TextInput
			value={value}
			onChange={(event) => setValue(event.currentTarget.value)}
			placeholder={placeholder}
			icon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
			radius='md'
			size='sm'
			w={width}
			miw={'200px'}
			styles={{
				root: {
					flex,
				},
			}}
		/>
	);
}
