import React, { useState, useRef, useEffect, memo } from 'react';
import { useDocumentVisibility } from '@mantine/hooks';
import ReactPlayer from 'react-player';
import { AspectRatio, Container, createStyles, Image } from '@mantine/core';
import PlayerControls from '../reelay_video_player_controls';
import screenfull from 'screenfull';
import theme from '../../_utils/theme';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	componentLoadedAtom,
	currentMeeting,
	elapsedTimeAtom,
	playButtonClicked,
	showThumbnailAtom,
	timeDisplayFormatAtom,
	timestamp,
	totalDurationAtom,
	videoPlayerCurrentTime,
	videoPlayerElapsedTime,
	videoPlayerStateAtom,
	watchedEventSentAtom,
} from '../../Atoms/meetingAtom';
import { IMeeting, VideoMetadata } from '../../interfaces/meeting';
import { MILLISECONDS_PER_SECOND } from '../constants';
import { sendAnalyticEvent } from '../../api/api';
import { formatSecondsToMinutesAndSeconds } from '../../_utils/time';
import { logger } from '../../helpers/logger';
import { createVideoThumbnail } from '../../helpers/data';
import useMediaQueries from '../../customHooks/useMediaQueries';
import {useSearchParams} from "react-router-dom";

const useStyles = createStyles(() => ({
	reactPlayer: {
		borderTopRightRadius: 16,
		borderTopLeftRadius: 16,
		boxSizing: 'border-box',
		position: 'relative',
	},
	thumbnailOverlay: {
		position: 'absolute',
		top: '-20px',
		left: 0,
		width: '100%',
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		borderTopRightRadius: '16px',
		borderTopLeftRadius: '16px',
	},
	thumbnailImage: {
		width: '100%',
		height: '100%',
	},
	playerWrapper: {
		width: '100%',
		borderTopRightRadius: 16,
		borderTopLeftRadius: 16,
		position: 'relative',
		boxShadow: `1px 1.1px 3.1px rgba(0, 0, 0, 0.02),
  2.4px 2.7px 7.4px rgba(0, 0, 0, 0.028),
  4.5px 5px 13.9px rgba(0, 0, 0, 0.035),
  8px 8.9px 24.8px rgba(0, 0, 0, 0.042),
  15px 16.7px 46.4px rgba(0, 0, 0, 0.05),
  36px 40px 111px rgba(0, 0, 0, 0.07)`,
	},
	hidden: {
		display: 'none',
	},
	paper: {
		backgroundColor: theme.colors?.['background-gray'],
		borderColor: theme.colors?.['primary'][0],
		color: theme.colors?.['secondary-text'],
		minHeight: '150px',
		borderRadius: '8px',
		border: '1px',
	},
	playButton: {
		color: 'black',
		fontSize: 50,
		transform: 'scale(0.9)',
		'&:hover': {
			color: '#999',
			transform: 'scale(1)',
		},
	},
	playButtonOuterContainer: {
		borderRadius: '16px',
		position: 'absolute',
		top: 0,
		right: 0,
		left: 0,
		bottom: 0,
	},
	playButtonInnerContainer: {
		background: 'white',
		opacity: 0.7,
		borderRadius: '16px',
	},
	videoPlaying: {},
}));

let count = 0;

interface Props {
	videoMetadata: VideoMetadata;
	name: string;
	opened?: boolean;
	meeting: IMeeting | null;
	setMeeting?: React.Dispatch<React.SetStateAction<IMeeting>>;
	nameInputWidth?: number;
	audio_enabled?: boolean;
	playerRef: any;
	// isSupport?: boolean,
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const ReelayVideoPlayer = memo(
	({
		videoMetadata,
		name,
		meeting,
		setMeeting,
		playerRef,
		nameInputWidth = 350,
		audio_enabled = true,
	}: Props) => {
		const { duration = 0, playbackID } = videoMetadata;
		const { classes, theme } = useStyles();
		const { widthOrHeightLessThanLG } = useMediaQueries();
		const documentState = useDocumentVisibility();
		const [currentTime, setCurrentTime] = useRecoilState(
			videoPlayerCurrentTime
		);
		const [timestampValue, setTimestampValue] = useRecoilState(timestamp);
		const playButtonWasClicked = useRecoilValue(playButtonClicked);
		const setElapsedTimeAtom = useSetRecoilState(videoPlayerElapsedTime);
		const [totalDuration, setTotalDuration] = useRecoilState(totalDurationAtom);
		const [elapsedTime, setElapsedTime] = useRecoilState(elapsedTimeAtom);
		const [componentLoaded, setComponentLoaded] =
			useRecoilState(componentLoadedAtom);
		const [timeDisplayFormat, setTimeDisplayFormat] = useRecoilState(
			timeDisplayFormatAtom
		);
		const [watchedEventSent, setWatchedEventSent] =
			useRecoilState(watchedEventSentAtom);
		const [showThumbnail, setShowThumbnail] = useRecoilState(showThumbnailAtom);
		const [state, setState] = useRecoilState(videoPlayerStateAtom);
		const playerInterval = 2;
		const { playing, muted, volume, playbackRate, played } = state;
		const playerContainerRef = useRef<HTMLDivElement>(null);
		const controlsRef = useRef<HTMLDivElement>(null);
		const [searchParams] = useSearchParams();
		const sharingToken = searchParams.get('vt');

		useEffect(() => {
			if (componentLoaded) {
				playerRef?.current?.seekTo(timestampValue.time);
				const durationInSeconds = duration / MILLISECONDS_PER_SECOND;

				const totalDuration =
					formatSecondsToMinutesAndSeconds(durationInSeconds);
				setState((state: any) => ({
					...state,
					played: timestampValue.time,
					playedSeconds: timestampValue.time,
				}));
				setTotalDuration(totalDuration);
			} else {
				setComponentLoaded(true);
			}
		}, [componentLoaded, timestampValue, playButtonWasClicked]);

		useEffect(() => {
			return () => {
				setTimestampValue({ time: 0 });
				setCurrentTime(0);
				setElapsedTimeAtom('');
			};
		}, []);

		// not my idea, direct command from upper management.
		// if user switches browser tab while video player is paused,
		// add reelay logo overlay.
		useEffect(() => {
			if (documentState === 'hidden' && !playing) {
				setShowThumbnail(true);
			}
		}, [documentState]);

		const handlePlayPause = () => {
			const newPlayState = !state.playing;
			if (newPlayState) setShowThumbnail(false);
			if (meeting?.status === 'published') {
				sendAnalyticEvent(
					{
						name: 'reelay.video',
						sourceID: meeting?.id,
						targetID: videoMetadata.id,
						data: {
							type: newPlayState ? 'play' : 'pause',
							startTime:
								playerRef?.current?.getCurrentTime() * MILLISECONDS_PER_SECOND,
						},
					},
					meeting?.organizationID,
					sharingToken,
				);

				// create setTimeout to track if user watches video for 10 secs.
			}
			setState({ ...state, playing: newPlayState });
		};

		const handleRewind = () => {
			// eslint-disable-next-line no-magic-numbers,@typescript-eslint/ban-ts-comment
			// @ts-ignore
			playerRef.current.seekTo(
				playerRef.current.getCurrentTime() - playerInterval
			);
		};

		const handleForward = () => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			playerRef.current.seekTo(
				playerRef.current.getCurrentTime() + playerInterval
			);
		};

		const handleMute = () => {
			setState({ ...state, muted: !state.muted });
		};

		const handleVolumeChange = (value: number) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line no-magic-numbers
			setState({
				...state,
				volume: value / 100,
				muted: value === 0,
			});
		};

		const handleVolumeSeekUp = (value: number) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line no-magic-numbers
			setState({
				...state,
				seeking: false,
				volume: parseFloat(String(value / 100)),
				muted: value === 0,
			});
		};

		const handlePlaybackRateChange = (rate: number) => {
			setState({ ...state, playbackRate: rate });
		};

		const handleToggleFullScreen = () => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			screenfull
				.toggle(playerContainerRef.current)
				.then(() => logger('info', 'toggledFullScreen', {}));
		};

		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const handleProgress = (changeState) => {
			setCurrentTime(changeState.playedSeconds);
			if (count > 1) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				controlsRef.current.style.visibility = 'hidden';
				count = 0;
			}
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			if (
				controlsRef.current !== null &&
				controlsRef.current.style.visibility === 'visible'
			)
				count += 1;

			if (!state.seeking) setState({ ...state, ...changeState });

			const secondsAVideoIsConsideredWatched = 10;
			if (
				changeState.playedSeconds > secondsAVideoIsConsideredWatched &&
				!watchedEventSent
			) {
				sendAnalyticEvent(
					{
						name: 'reelay.video',
						sourceID: meeting?.id,
						targetID: meeting?.videoMetadata?.id,
						data: {
							type: 'watched',
							startTime: 0,
							endTime: changeState.playedSeconds * MILLISECONDS_PER_SECOND,
						},
					},
					meeting?.organizationID,
					sharingToken,
				);
				setWatchedEventSent(true);
			}
		};

		const handleSeekChange = (value: number) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line no-magic-numbers
			setState({ ...state, played: parseFloat(value / 100) });
		};

		const handleSeekMouseDown = () => {
			setState({ ...state, seeking: true });
		};

		const handleSeekMouseUp = (newValue: number) => {
			setState({ ...state, seeking: false });
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line no-magic-numbers
			setState({ ...state, played: parseFloat(newValue / 100) });
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			playerRef.current.seekTo(newValue / 100);
		};

		const handleDisplayFormat = () => {
			setTimeDisplayFormat(
				timeDisplayFormat === 'normal' ? 'remaining' : 'normal'
			);
		};

		const handleMouseMove = () => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			controlsRef.current.style.visibility = 'visible';
			count = 0;
		};
		// Simplified useEffect for updating times without getCurrentTime in dependencies
		useEffect(() => {
			const currentElapsedTime = formatSecondsToMinutesAndSeconds(currentTime);
			if (currentElapsedTime) setElapsedTime(currentElapsedTime);
			setElapsedTimeAtom(currentElapsedTime);
		}, [currentTime]); // Now depends on currentTime state

		return (
			// eslint-disable-next-line no-magic-numbers
			<Container
				size={'md'}
				style={{
					top: 0,
					bottom: 0,
					left: 0,
					right: 0,
					flexDirection: 'column',
					width: '100%',
					borderRadius: '16px',
					padding: 0,
					margin: 0,
				}}
				ref={playerContainerRef}
			>
				<AspectRatio
					style={{
						flexGrow: 1,
						flexShrink: 0,
						borderTopRightRadius: '16px',
						borderTopLeftRadius: '16px',
						boxSizing: 'border-box',
					}}
					ratio={16 / 9}
				>
					<ReactPlayer
						className={classes.reactPlayer}
						ref={playerRef}
						width={'100%'}
						height={'auto'}
						style={{
							borderTopRightRadius: '16px',
							borderTopLeftRadius: '16px',
						}}
						url={`https://stream.mux.com/${playbackID}.m3u8`}
						muted={muted}
						playing={playing}
						volume={volume}
						playbackRate={playbackRate}
						onProgress={handleProgress}
						onEnded={() => {
							setState((state: any) => ({
								...state,
								// played:  0,
								playing: false,
							}));
						}}
					/>
					{/* Thumbnail image at 00:00 instead of black screen */}
					{(elapsedTime === '00:00' ||
						elapsedTime === '00:00:00' ||
						showThumbnail) && (
						<div
							className={classes.thumbnailOverlay}
							style={{
								opacity: showThumbnail ? 0.75 : 1,
							}}
						>
							<Image
								src={createVideoThumbnail(
									meeting?.videoMetadata?.playbackID,
									meeting?.thumbnailTime
								)}
								alt='Video Thumbnail'
								className={classes.thumbnailImage}
							/>
						</div>
					)}
				</AspectRatio>
				<PlayerControls
					ref={controlsRef}
					name={name}
					onPlayPause={handlePlayPause}
					playing={playing}
					onRewind={handleRewind}
					onForward={handleForward}
					onMute={handleMute}
					muted={muted}
					onVolumeChange={handleVolumeChange}
					onVolumeSeekUp={handleVolumeSeekUp}
					volume={volume}
					onPlaybackRateChange={handlePlaybackRateChange}
					playbackRate={playbackRate}
					onToggleFullScreen={handleToggleFullScreen}
					played={played}
					onSeek={handleSeekChange}
					onSeekMouseDown={handleSeekMouseDown}
					onSeekMouseUp={handleSeekMouseUp}
					elapsedTime={elapsedTime}
					totalDuration={totalDuration}
					meeting={meeting}
					setMeeting={setMeeting}
					nameInputWidth={nameInputWidth}
					audio_enabled={audio_enabled}
					// isSupport={isSupport}
				/>
			</Container>
		);
	}
);
