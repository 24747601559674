export interface UserList {
	email?: string;
}

export enum UserRoles {
	Support = 'support',
	Member = 'member',
	Admin = 'admin',
	SuperAdmin = 'superadmin',
	MasterAdmin = 'masteradmin',
	InviteToOrganization = 'invite-to-organization',
	CreateMeeting = 'create-meeting',
	ProjectManager = 'project-manager',
	Guest = 'guest',
}

export interface UserLink {
	createdAt: string;
	id: string;
	meetingID: string;
	presented: boolean;
	priority: number;
	shareableLinkID: string | null;
	updatedAt: string;
	userID: string;
	status: string;
}

export interface InvitedUser {
	email: string;
	firstName: string;
	lastName: string;
	id: string;
	approved?: boolean;
	userlink: UserLink;
}
