import React, { useEffect, useState } from 'react';
import {
	TextInput,
	Button,
	Stack,
	Text,
	Group,
	createStyles,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { RegisterData } from '../types';
import { createHubSpotContact } from '../../../api/hubspot';
import { termsLink, privacyLink } from '../../../components/constants'; // Import the privacy policy link

const useStyles = createStyles((theme) => ({
	input: {
		backgroundColor: theme.colors['background-gray'][0],
		borderRadius: theme.radius.sm,
	},
	helpText: {
		fontStyle: 'italic',
		fontSize: 13,
		fontWeight: 400,
		color: theme.fn.primaryColor(),
		marginTop: theme.spacing.sm,
		width: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
		[`@media (min-width: ${theme.breakpoints.md}px)`]: {
			width: '70%',
		},
	},
	linkText: {
		cursor: 'pointer',
		textDecoration: 'underline',
		color: theme.fn.primaryColor(),
	},
	mainTitle: {
		fontSize: 32,
		fontWeight: 500,
		marginBottom: theme.spacing.lg,
		[`@media (max-width: ${theme.breakpoints.sm})`]: {
			fontSize: 24,
		},
	},
	subTitle: {
		fontSize: 20,
		fontWeight: 500,
		marginBottom: theme.spacing.md,
	},
}));

interface RegisterProps {
	onNext: () => void;
	data: RegisterData;
	setData: (data: RegisterData) => void;
	subTitle: string | JSX.Element;
	buttonText: string;
}

const Register: React.FC<RegisterProps> = ({
	onNext,
	data,
	setData,
	subTitle,
	buttonText,
}) => {
	const { classes } = useStyles();
	const [loading, setLoading] = useState(false);

	// Initialize Mantine useForm hook with validation
	const form = useForm({
		initialValues: data,
		validate: {
			firstName: (value) =>
				value.trim().length === 0 ? 'First name is required' : null,
			lastName: (value) =>
				value.trim().length === 0 ? 'Last name is required' : null,
			email: (value) =>
				/^\S+@\S+\.\S+$/.test(value) ? null : 'Please provide a valid email',
		},
	});

	// Handle form submission
	const handleSubmit = async (values: RegisterData) => {
		// Call HubSpot API to create contact
		try {
			setLoading(true);
			const { id } = await createHubSpotContact(
				values.firstName,
				values.lastName,
				values.email
			);
			setData({ ...values, contactId: id });
			// clear any future stale form data
			[
				'organizationData',
				'inviteTeamData',
				'infoCollectionData',
				'preferencesData',
				'calendarData',
			].forEach((key) => localStorage.removeItem(key));
			onNext(); // Move to the next step if successful
		} catch (error) {
			console.error('Failed to create HubSpot contact:', error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		form.setValues(data);
	}, [data]);

	return (
		<Stack spacing='md' p='xl' py={'md'}>
			<Text className={classes.mainTitle}>
				Make every meeting more productive with Reelay.
			</Text>
			<Text className={classes.subTitle}>{subTitle}</Text>

			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Group grow noWrap>
					<TextInput
						placeholder='First Name'
						required
						size='md'
						{...form.getInputProps('firstName')}
						classNames={{ input: classes.input }}
					/>
					<TextInput
						placeholder='Last Name'
						required
						size='md'
						{...form.getInputProps('lastName')}
						classNames={{ input: classes.input }}
					/>
				</Group>

				<TextInput
					placeholder='Email'
					type='email'
					required
					size='md'
					{...form.getInputProps('email')}
					classNames={{ input: classes.input }}
					mt='md'
				/>

				<Button
					type='submit'
					fullWidth
					mt='lg'
					size='md'
					radius={'md'}
					loading={loading}
				>
					{buttonText}
				</Button>
			</form>

			<Group w='100%' noWrap>
				<Text className={classes.helpText}>
					By clicking next, you agree to Reelay's{' '}
					<Text
						component='a'
						href={termsLink}
						target='_blank'
						rel='noopener noreferrer'
						className={classes.linkText}
					>
						Terms of Use
					</Text>{' '}
					and{' '}
					<Text
						component='a'
						href={privacyLink}
						target='_blank'
						rel='noopener noreferrer'
						className={classes.linkText}
					>
						Privacy Policy
					</Text>
					.
				</Text>
			</Group>
		</Stack>
	);
};

export default Register;
