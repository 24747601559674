const ADDRESS = 'address';
const ADDRESS_HQ = 'address_hq';
const BILLING_INFO = 'billing_info';
// Account MRR or ARR
const RECURRING_REVENUE = 'recurring_revenue';

// Admin user details
const ADMIN_DETAILS = 'admin_details';
// Preferred time to receive Daily Digest
const DAILY_DIGEST_TIME = 'daily_digest_time';
// Employee Identification Number/GUID (from Active Directory as an example)
const EMPLOYEE_GUID = 'employee_guid';

// Business type: SMB, Enterprise
const BUSINESS_TYPE = 'business_type';
const BUSINESS_INDUSTRY = 'industry';
// Number of Employees
const BUSINESS_EMPLOYEES = 'empoyees_number';

const CONTACTS = 'contacts';

// Contract Renewal Status/Timing
// (When is the contract set to renew?)
const CONTRACT_RENEWAL = 'contract_renewal';
// Contract Status (Signed contract or not?)
const CONTRACT_STATUS = 'contract_status';

const JOB_TITLE = 'job_title';

// SMS vs. Email Notification preference
const NOTIFICATION_PREFERENCE = 'notification_preference';

const LOCALE = 'locale';
const TIME_ZONE = 'time_zone';
const TIME_ZONE_SECONDARY = 'time_zone_secondary';

// Link to Employee Directory (e.g. Active Directory)
const LINK_DIRECTORY = 'link_directory';

const WEBSITE = 'website';
const LOGO_URL = 'logo_url';
const BIO = 'bio';

export const SETTINGS_ORGANIZATION = {
  ADDRESS,
  ADDRESS_HQ,
  BILLING_INFO,
  ADMIN_DETAILS,
  BUSINESS_TYPE,
  BUSINESS_INDUSTRY,
  BUSINESS_EMPLOYEES,
  CONTRACT_RENEWAL,
  CONTRACT_STATUS,
  LOGO_URL,
  TIME_ZONE,
  TIME_ZONE_SECONDARY,
  RECURRING_REVENUE,
  NOTIFICATION_PREFERENCE,
  WEBSITE,
  LOCALE,
  CONTACTS,
};
export const SETTINGS_USER = {
  ADDRESS,
  BILLING_INFO,
  CONTRACT_RENEWAL,
  CONTRACT_STATUS,
  DAILY_DIGEST_TIME,
  EMPLOYEE_GUID,
  JOB_TITLE,
  LINK_DIRECTORY,
  TIME_ZONE,
  LOCALE,
  RECURRING_REVENUE,
  NOTIFICATION_PREFERENCE,
  WEBSITE,
  CONTACTS,
  BIO,
};

export const ANYONE_WITH_LINK = 'anyone_with_link';
export const INVITE_ONLY = 'invite_only';
