import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const getChatByMeetingID = (
	meetingID: string,
	organizationID: string,
	sharingToken?: string,
) => {
	logger('info', 'Request sent to getChatByMeetingID', {
		meetingID,
		organizationID,
	});
	return axiosInstance.get(`v1/meeting/${meetingID}/chat`, {
		headers: {
			'x-organization-id': organizationID,
			'x-sharing-token': sharingToken,
		},
	});
};
