import {
	Box,
	Button,
	Center,
	ColorPicker,
	FileButton,
	Group,
	Image,
	Loader,
	Paper,
	Stack,
	Text,
	Title,
	useMantineTheme,
} from '@mantine/core';
import {
	BrandDetails,
	OrganizationSettings,
} from '../../../../../../../Atoms/settings';
import { styles } from '../../../../../../settings/tabs/styles';
import { useEffect, useRef, useState } from 'react';
import {
	deleteOrganizationSettings,
	saveOrganizationSettings,
} from '../../../../../../../api/settings';
import { useParams } from 'react-router';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../../../helpers/notifications';
import { logger } from '../../../../../../../helpers/logger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFloppyDisk,
	faTrash,
	faUpload,
} from '@fortawesome/pro-light-svg-icons';
import { DangerNoButton } from '../../../../../../../components/Buttons/DangerNoButton';
import { themeColors } from '../../../../../../../interfaces/colors';

interface Props {
	brand: BrandDetails;
	setBrand: React.Dispatch<React.SetStateAction<BrandDetails>>;
	type: 'primary' | 'secondary';
}

export default function ColorAndShadePicker({ brand, setBrand, type }: Props) {
	const theme = useMantineTheme();
	const { organizationID } = useParams();
	const divRef = useRef<HTMLDivElement>(null);
	const isPrimary = type === 'primary';
	const {
		logo_url,
		icon,
		color_primary,
		color_secondary,
		color_level_primary,
		color_level_secondary,
	} = brand;
	const primaryColor = isPrimary ? color_primary : color_secondary;
	const primaryColorLevel = isPrimary
		? color_level_primary
		: color_level_secondary;
	const [savingColors, setSavingColors] = useState(false);
	const [loading, setLoading] = useState(false);
	// hex color of primary colors
	const [color, setColor] = useState<string | null>(primaryColor || null);
	// hex color of color shade
	const [shade, setShade] = useState<string | null>(
		primaryColor?.length && typeof primaryColorLevel === 'number'
			? theme.colors[primaryColor][primaryColorLevel]
			: null
	);
	const primaryShadeIndex = 6;
	const colorLabel = {};
	const colorIndex = {};
	const primaryColorSwatches = themeColors.map((color, index) => {
		const hexColor = theme.colors[color][primaryShadeIndex];
		colorLabel[hexColor] = color;
		colorIndex[hexColor] = index;
		return theme.colors[color][primaryShadeIndex];
	});

	const [shadeIndex, setShadeIndex] = useState<number | null>(null);
	const [primaryColorShades, setPrimaryColorShades] = useState(
		theme.colors[color_primary] || []
	);
	const shadeIdxObj = {};
	if (primaryColorShades.length) {
		primaryColorShades.forEach((color, index) => {
			shadeIdxObj[color] = index;
		});
	}

	useEffect(() => {
		const actualColor = colorLabel[color];
		const shades = theme.colors[actualColor] || [];
		const shadeHexColor = shades[shadeIndex];
		setShade(shadeHexColor);
		setPrimaryColorShades(shades);
		setActiveButton(color, 0);
		setActiveButton(shadeHexColor, 1);
	}, [color]);

	useEffect(() => {
		try {
			setLoading(true);
			if (primaryColor?.length && typeof primaryColorLevel === 'number') {
				const hexColor = theme.colors[primaryColor][primaryShadeIndex];
				setColor(hexColor);
				const primaryColorShades = theme.colors[primaryColor] || [];
				setShadeIndex(primaryColorLevel);
				const shadeHexColor = primaryColorShades[primaryColorLevel];
				setShade(shadeHexColor);
				const shades = theme.colors[primaryColor] || [];
				setPrimaryColorShades(shades);
				setActiveButton(hexColor, 0);
				setActiveButton(shadeHexColor, 1);
			}
		} catch (error) {
			logger('error', 'Error setting primary color', error);
		} finally {
			setLoading(false);
		}
	}, [brand]);

	function setActiveButton(activeLabel, index: number) {
		// Get all buttons with an aria-label attribute
		const buttons = divRef.current.querySelectorAll('button[aria-label]');
		const buttonsArr = Array.from(buttons) as HTMLElement[];

		// Divide the buttons into primary and secondary
		const primaryButtons = buttonsArr.slice(0, 14);
		const secondaryButtons = buttonsArr.slice(14);

		// Determine the subset of buttons to update based on index
		let targetButtons: HTMLElement[];
		if (index === 0) {
			targetButtons = primaryButtons;
		} else {
			targetButtons = secondaryButtons;
		}

		// Apply border to the target subset of buttons
		targetButtons.forEach((button) => {
			if (button.getAttribute('aria-label') === activeLabel) {
				button.style.border = `2px solid ${theme.colors['reelay-purple'][5]}`;
			} else {
				button.style.border = 'none';
			}
		});

		// Additional code to handle active buttons, if necessary
		// ...
	}

	const saveColors = async () => {
		const actualColor = colorLabel[color];
		try {
			setSavingColors(true);
			if (!color || !shade) {
				showFailureNotification({
					message: `Please select a color and shade before saving.`,
				});
				return;
			}
			// save color and shade
			const payload = [
				{
					key: 'brand',
					value: {
						...brand,
						...(isPrimary
							? { color_primary: actualColor, color_level_primary: shadeIndex }
							: {
								color_secondary: actualColor,
								color_level_secondary: shadeIndex,
							}),
					},
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			setBrand({
				...res[0]?.value,
			});
			showSuccessNotification({
				message: `Organization ${type} color has been saved.`,
			});
		} catch (error) {
			logger('error', 'Error saving colors', error);
			showFailureNotification({
				message: `Sorry, there was an error saving your organization's colors. Please try again.`,
			});
		} finally {
			setSavingColors(false);
		}
	};

	return (
		<>
			{loading ? (
				<Center>
					<Loader size={'xl'} />
				</Center>
			) : (
				<div ref={divRef}>
					<Group position={'apart'} mt={'lg'}>
						<Stack spacing={0} w={'80%'}>
							<Title order={5}>
								{isPrimary ? 'Primary Color' : 'Secondary Color'}
							</Title>
							<Text size='sm' align='left'>
								First select a color then the shade you want.
							</Text>
							{loading ? (
								<Loader />
							) : (
								<ColorPicker
									format='hex'
									size='md'
									value={color}
									onChange={(color) => {
										setColor(color);
										setShade(primaryColorShades[shadeIndex]);
									}}
									withPicker={false}
									fullWidth
									swatches={primaryColorSwatches}
									swatchesPerRow={16}
									styles={{
										swatch: {
											borderRadius: '0.25rem',
										},
									}}
								/>
							)}
							<Text align='center' mt={5}>
								{colorLabel[color]}
							</Text>
						</Stack>
					</Group>
					<Group position={'apart'} mt={'lg'} noWrap>
						<Stack spacing={0} w={'80%'}>
							<Title order={5}>
								{isPrimary ? 'Primary Color Shade' : 'Secondary Color Shade'}
							</Title>
							{loading ? (
								<Loader />
							) : (
								<ColorPicker
									format='hex'
									size='md'
									value={shade}
									onChange={(color) => {
										setShade(color);
										setShadeIndex(shadeIdxObj[color]);
										setActiveButton(color, 1);
									}}
									withPicker={false}
									fullWidth
									swatches={primaryColorShades}
									swatchesPerRow={16}
									styles={{
										swatch: {
											borderRadius: '0.25rem',
										},
									}}
								/>
							)}

							<Text align='center' mt={5}>
								{shade}
							</Text>
						</Stack>
						<Button
							type={'submit'}
							variant={'filled'}
							radius={'xl'}
							rightIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
							color={'primary'}
							loading={savingColors}
							// disabled={orgBotNameValue === ''}
							onClick={saveColors}
						>
							Save Colors
						</Button>
					</Group>
				</div>
			)}
		</>
	);
}
