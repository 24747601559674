import React, { ReactNode } from 'react';
import { createStyles, Group, Loader, Stack, Text } from '@mantine/core';
import MobileVideoCard from '../mobile_video_card';
import { IMeeting } from '../../interfaces/meeting';
import { useRecoilValue } from 'recoil';
import { currentUserID, currentUserReelays } from '../../Atoms/userAtoms';
import { meetingsSharedWithMe } from '../../Atoms/meetingAtom';
import InfiniteScroll from 'react-infinite-scroll-component';

interface Props {
	meetings?: IMeeting[];
	viewingRecommendations?: boolean;
	meetingCount?: number;
	fetchData?: () => void;
	bottomContainerRef?: ReactNode;
}

export default function MobileVideoQueue({
	meetings,
}: // viewingRecommendations = false,
	// fetchData,
	// meetingCount,
	// bottomContainerRef,
	Props) {
	return (
		<Stack spacing={0} pb={'4em'}>
			{meetings.length ? (
				// <InfiniteScroll
				//   dataLength={allMeetings.length} //This is important field to render the next data
				//   next={fetchData}
				//   hasMore={meetingCount > allMeetings.length}
				//   scrollableTarget={bottomContainerRef}
				//   loader={
				//     <Group w={'100%'} position={'center'} my={'xl'}>
				//       <Loader />
				//     </Group>
				//   }
				//   endMessage={
				//     <Text align={'center'} my={'xl'} color={'secondary-text'}>
				//       Congrats! No more meetings.
				//     </Text>
				//   }
				// >
				<>
					{meetings.map((meeting: IMeeting, i) => {
						return <MobileVideoCard key={i} meeting={meeting} />;
					})}
					<Text align={'center'} my={'xl'} color={'secondary-text'}>
						You reached the bottom. No more meetings.
					</Text>
				</>
			) : (
				// </InfiniteScroll>
				<Text align={'center'} my={'xl'} color={'secondary-text'}>
					Congrats! No more meetings.
				</Text>
			)}
		</Stack>
	);
}
