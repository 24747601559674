import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const getMeetingNotifications = () => {
  return axiosInstance
    .get(`v1/meeting/notifications`)
    .then((res) => {
      logger('info', 'Response received from getMeetingNotifications', res);
      return res?.data?.pendingRequests;
    })
    .catch((err) => {
      logger('error', 'Error getting user settings', err);
      return [];
    });
};
