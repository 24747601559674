import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { InvitedUser } from '../interfaces/user';

const { persistAtom } = recoilPersist();

export interface MeetingRequestNotification {
	id: string;
	meetingID: string;
	presented: boolean;
	priority: number;
	shareableLinkID: string;
	createdAt: string;
	status: string;
	name: string;
	user: {
		id: string;
		email: string;
		firstName: string;
		lastName: string;
	};
	userID: string;
}

export const notifications = atom<MeetingRequestNotification[]>({
	key: 'notifications',
	default: [],
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});
