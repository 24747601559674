import {
	googleMeetIcon,
	outlookTeamsIcon,
	zoomIcon,
} from '../components/constants';

export const generateSlackLink = (organizationID: string) => {
	const redirectLink =
		'https://slack.com/oauth/v2/authorize?client_id=2971454907714.3766463750914&scope=app_mentions%3Aread%2Cchannels%3Amanage%2Cchat%3Awrite.customize%2Cchat%3Awrite%2Cgroups%3Ahistory%2Cgroups%3Aread%2Cgroups%3Awrite%2Cim%3Aread%2Cim%3Awrite%2Cusers%3Aread.email%2Cusers%3Aread%2Cusers.profile%3Aread&user_scope=chat%3Awrite&state=%7B%22organizationID%22%3A%22${orgID}%22%7D'.replace(
			'${orgID}',
			`${organizationID}`
		);
	return redirectLink;
};

// this is the number system of calendar integrations settings
// enum CalendarAutoRecord {
//   None = 0,
//   Owned = 1,
//   Internal = 2,
//   External = 4,
// }

export interface CalendarIntegration {
	autoRecord: number;
	createdAt: string;
	events: [];
	id: string;
	organizationID: string;
	platform: string;
	recallCalendarID: string;
	recallData: RecallData;
	status: string;
	updatedAt: string;
	userID: string;
}

export interface RecallData {
	created_at: string;
	id: string;
	oauth_client_id: '5fc950c6-f4d7-4eb1-9e79-67527c9f8eb6';
	oauth_client_secret: string;
	oauth_email: string | null;
	oauth_refresh_token: string;
	platform: string;
	platform_email: null;
	status: string;
	status_changes: { status: string; created_at: string }[];
	updated_at: string;
	webhook_url: string;
}

export const getPlatformIcon = (platform: string) => {
	switch (platform) {
		case 'google_meet':
			return googleMeetIcon;
		case 'microsoft_teams':
			return outlookTeamsIcon;
		case 'zoom':
			return zoomIcon;
		default:
			return '';
	}
};
