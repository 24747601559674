import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const createCollection = (name: string, organizationID: string) => {
	return axiosInstance.post(`v1/collections?organizationID=${organizationID}`, {
		name,
	});
};

export const getCollections = (organizationID: string) => {
	return axiosInstance
		.get(`v1/collections?organizationID=${organizationID}`)
		.then((res) => {
			logger('info', 'Response received from getCollection', res);
			return res?.data?.data || [];
		})
		.catch((err) => {
			logger('error', 'Error getCollection', err);
			return err;
		});
};

export const getCollectionByID = (
	collectionID: string,
	organizationID: string
) => {
	return axiosInstance
		.get(`v1/collections/${collectionID}?organizationID=${organizationID}`)
		.then((res) => {
			logger('info', 'Response received from getCollection', res);
			return res?.data || [];
		})
		.catch((err) => {
			logger('error', 'Error getCollection', err);
			return err;
		});
};

export const updateCollection = (
	payload: object,
	collectionID: string,
	organizationID: string
) => {
	return axiosInstance
		.patch(
			`v1/collections/${collectionID}?organizationID=${organizationID}`,
			payload
		)
		.then((res) => {
			logger('info', 'Response received from getCollection', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error getCollection', err);

			throw new Error(err);
		});
};

export const deleteCollection = (
	collectionID: string,
	organizationID: string
) => {
	return axiosInstance
		.delete(`v1/collections/${collectionID}?organizationID=${organizationID}`)
		.then((res) => {
			logger('info', 'Response received from getCollection', res);
			return res || [];
		})
		.catch((err) => {
			logger('error', 'Error getCollection', err);
			return err;
		});
};

export const getCollectionContent = (collectionID: string) => {
	return axiosInstance
		.get(`v1/collections/${collectionID}/content`)
		.then((res) => {
			logger('info', 'Response received from getCollectionContent', res);
			return res?.data?.data || [];
		})
		.catch((err) => {
			logger('error', 'Error getCollectionContent', err);
			return err;
		});
};

export const updateCollectionContent = (
	collectionID: string,
	data: { meetingID: string }[]
) => {
	return axiosInstance.put(`v1/collections/${collectionID}/content`, data);
};

export const deleteCollectionContent = (
	collectionID: string,
	contentID: string
) => {
	return axiosInstance
		.delete(`v1/collections/${collectionID}/content/${contentID}`)
		.then((res) => {
			logger('info', 'Response received from updateCollectionContent', res);
			return res?.data || [];
		})
		.catch((err) => {
			logger('error', 'Error updateCollectionContent', err);
			return err;
		});
};

export const getCollectionUsers = (collectionID: string) => {
	return axiosInstance
		.get(`v1/collections/${collectionID}/users`)
		.then((res) => {
			logger('info', 'Response received from getCollectionUsers', res);
			return res?.data?.data || [];
		})
		.catch((err) => {
			logger('error', 'Error getCollectionUsers', err);
			return err;
		});
};

export const addCollectionUsers = (
	collectionID: string,
	userIDs: { userID: string }[]
) => {
	return axiosInstance
		.put(`v1/collections/${collectionID}/users`, userIDs)
		.then((res) => {
			logger('info', 'Response received from addCollectionUsers', res);
			return res?.data || [];
		})
		.catch((err) => {
			logger('error', 'Error addCollectionUsers', err);
			return err;
		});
};

export const deleteCollectionUser = (collectionID: string, userID: string) => {
	return axiosInstance
		.delete(`v1/collections/${collectionID}/users/${userID}`)
		.then((res) => {
			logger('info', 'Response received from deleteCollectionUser', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error deleteCollectionUser', err);
			return err;
		});
};
