import { IMeeting } from '../../interfaces/meeting';
import { DateTime } from 'luxon';
import { convertSecondsToHHMMSS } from '../../_utils/time';

export interface MeetingTableProps extends IMeeting {
	organizationName: string;
	host: string;
	formattedAuditTime?: string;
	formattedReviewTime?: string;
	formattedJoinAt?: string;
	formattedCreatedAt: string;
}

export const createMeetingTableData = (
	meetings: IMeeting[],
	organizations
): MeetingTableProps[] => {
	const result: MeetingTableProps[] = meetings.map((meeting: IMeeting) => {
		return {
			...meeting,
			host:
				meeting.author === null
					? 'N/A'
					: meeting.author?.firstName + ' ' + meeting.author?.lastName,
			organizationName: getOrgDetails(organizations, meeting.organizationID),
			formattedAuditTime: formatTimeStampIntoHHMMSS(meeting.auditTime)?.time,
			formattedReviewTime: formatTimeStampIntoHHMMSS(meeting.reviewTime)?.time,
			viewingDeadline:
				meeting.viewingDeadline === null
					? 'N/A'
					: `${DateTime.fromISO(meeting.viewingDeadline).toLocaleString(
							DateTime.DATE_MED
					  )}`,
			formattedJoinAt: meeting?.botMetadata?.joinAt
				? DateTime.fromISO(meeting?.botMetadata?.joinAt).toFormat(
						'LLL. dd yyyy, h:mm a'
				  )
				: 'N/A',
			formattedCreatedAt:
				typeof meeting.createdAt === 'string'
					? `${DateTime.fromISO(meeting.createdAt).toLocaleString(
							DateTime.DATE_MED
					  )}`
					: 'N/A',
		};
	});
	return result;
};

export const transformMeetingDataForLiveBotsTable = (
	meetings: IMeeting[],
	organizations
) => {
	return meetings.map((meeting) => ({
		...meeting,
		organizationName: getOrgDetails(organizations, meeting.organizationID),
		owner:
			meeting.author === null
				? 'N/A'
				: meeting.author?.firstName + ' ' + meeting.author?.lastName,
		date: meeting?.botMetadata?.joinAt
			? DateTime.fromISO(meeting?.botMetadata?.joinAt).toFormat(
					'LLL. dd yyyy, h:mm a'
			  )
			: 'Live Meeting',
		lastUpdated: meeting?.botMetadata?.updatedAt
			? DateTime.fromISO(meeting?.botMetadata?.updatedAt).toFormat(
					'LLL. dd yyyy, h:mm a'
			  )
			: 'N/A',
		status: meeting?.botMetadata?.status || 'N/A',
	}));
};

export const getOrgDetails = (organizations, orgID: string) => {
	const org = organizations.find((org) => org.id === orgID);
	return typeof org?.name === 'string' ? org?.name : 'Unknown Organization';
};

export const formatTimeStampIntoHHMMSS = (timestamp: string | null) => {
	if (timestamp === null) return { time: 'N/A', greaterThanTwoHours: false };
	const now = DateTime.local();
	const diff = now
		.diff(DateTime.fromISO(timestamp), 'seconds')
		.toObject().seconds; // Calculate time difference in seconds
	const SECONDS_IN_TWO_HOURS = 7200;
	const greaterThanTwoHours = diff > SECONDS_IN_TWO_HOURS;
	const time = convertSecondsToHHMMSS(diff);
	return { time, greaterThanTwoHours };
};

export const calculateSeconds = (time: string): number => {
	const [hours, minutes, seconds] = time.split(':').map(Number);
	return hours * 3600 + minutes * 60 + seconds;
};
