import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const saveXmlConfigToApi = (xmlConfig) => {
	return axiosInstance
		.post(`v1/auth/saveSamlMetaConfig`, {
			meta: xmlConfig,
		})
		.then((res) => {
			logger('info', 'saveXmlConfigToApi request', res);
			return res?.data?.data;
		})
		.catch((err) => {
			logger('error', 'ErrorgetMicrosoftGroups', err);
			return err;
		});
};

export const createNewSCIMDirectory = (tenant, product, userID) => {
	return axiosInstance
		.post(`v1/auth/newScimDirectory`, {
			tenant,
			product,
			userID,
		})
		.then((res) => {
			logger('info', 'saveXmlConfigToApi request', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'ErrorgetMicrosoftGroups', err);
			return err;
		});
};

export const getMicrosoftGroups = () => {
	return axiosInstance
		.post(`v1/auth/get-microsoft-groups`)
		.then((res) => {
			logger('info', 'Response received from getMicrosoftGroups', res);
			return res.data.data.groups;
		})
		.catch((err) => {
			logger('error', 'ErrorgetMicrosoftGroups', err);
			return err;
		});
};

export const getMicrosoftRoles = () => {
	return axiosInstance
		.post(`v1/auth/get-microsoft-roles`)
		.then((res) => {
			logger('info', 'Response received from getMicrosoftRoles', res);
			return res.data.data.roles;
		})
		.catch((err) => {
			logger('error', 'Error getMicrosoftRoles', err);
			return err;
		});
};

export const ensureActiveDirectoryCustomRolesExist = () => {
	return axiosInstance
		.post(`v1/auth/ensure-custom-roles-exist`)
		.then((res) => {
			logger(
				'info',
				'Response received from ensureActiveDirectoryCustomRolesExist',
				res
			);
			return res?.data?.data?.customRoles;
		})
		.catch((err) => {
			logger('error', 'Error getMicrosoftRoles', err);
			return err;
		});
};

export const saveActiveDirectoryGroupsToSync = (groupIds: string[]) => {
	return axiosInstance
		.post(`v1/auth/save-groups-to-sync`, { groupIds })
		.then((res) => {
			logger(
				'info',
				'Response received from saveActiveDirectoryGroupsToSync',
				res
			);
			return res?.data?.data;
		})
		.catch((err) => {
			logger('error', 'Error getMicrosoftRoles', err);
			return err;
		});
};

export const assignActiveDirectoryRole = (userIds: string[], role: string) => {
	return axiosInstance
		.post(`v1/auth/assign-ad-role`, { userIds, role })
		.then((res) => {
			logger('info', 'Response received from assignActiveDirectoryRole', res);
			return res?.data?.data;
		})
		.catch((err) => {
			logger('error', 'Error getMicrosoftRoles', err);
			return err;
		});
};

export const syncActiveDirectoryGroups = () => {
	return axiosInstance
		.post(`v1/auth/sync-groups`)
		.then((res) => {
			logger('info', 'Response received from syncActiveDirectoryGroups', res);
			return res?.data?.data;
		})
		.catch((err) => {
			logger('error', 'Error getMicrosoftRoles', err);
			return err;
		});
};
