import { Anchor, Group, Paper, ScrollArea, Stack, Text } from '@mantine/core';
import { replaceApostropheHTMLEntityWithSingleQuote } from '../../_utils/handy-functions';
import { IMeeting } from '../../interfaces/meeting';
import { styles } from '../aside/TabPanels/styles';
import { useEffect, useRef, useState } from 'react';
import { getChatByMeetingID } from '../../api/chat';
import { logger } from '../../helpers/logger';
import { Chat } from '../../interfaces/chat';
import ReelayLogoPageLoader from '../loaders/ReelayLogoPageLoader';
import { DateTime } from 'luxon';
import { convertMillisecondsToHHMMSS } from '../../_utils/time';
import { useRecoilState } from 'recoil';
import { chatAtom } from '../../Atoms/meetingAtom';

interface Props {
	meeting: IMeeting;
	isAuditPage: boolean;
	chatProps?: Chat[];
}

export default function ChatPanel({ meeting, isAuditPage, chatProps }: Props) {
	const { classes } = styles();
	const { name } = meeting;
	const viewportRef = useRef(null);
	const [showGradient, setShowGradient] = useState(true);
	const [loading, setLoading] = useState(false);
	const [chat, setChat] = useRecoilState(chatAtom);
	const gradientHeight = 300; // The height of your gradient

	// const linkify = (inputText: string) => {
	// 	const urlRegex =
	// 		/(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
	// 	return inputText.replace(urlRegex, function (url) {
	// 		return (
	// 			<Anchor href={url} target='_blank'>
	// 				{inputText}
	// 			</Anchor>
	// 		);
	// 	});
	// };

	const handleScroll = ({ y }) => {
		// Assuming you have a ref to your ScrollArea, let's call it `scrollAreaRef`
		const scrollHeight = viewportRef.current.scrollHeight;
		const clientHeight = viewportRef.current.clientHeight;
		// Calculate the maximum Y position for scrolling
		const maxScrollY = scrollHeight - clientHeight;
		// The threshold to hide the gradient is the maximum Y minus the gradient height
		// i divided by 2 because i felt it was turning off too early.
		const gradientHideThreshold = maxScrollY - gradientHeight / 2;
		// If the current scroll position is greater than or equal to the threshold, hide the gradient
		setShowGradient(y < gradientHideThreshold);
	};

	useEffect(() => {
		const getChatData = async () => {
			try {
				setLoading(true);
				const res = await getChatByMeetingID(
					meeting.id,
					meeting.organizationID
				);
				setChat(res?.data?.data || []);
			} catch (error) {
				logger('error', 'Error fetching chat data', error);
			} finally {
				setLoading(false);
			}
		};
		if (!isAuditPage) getChatData();
	}, []);

	return (
		<>
			{loading ? (
				<ReelayLogoPageLoader />
			) : (
				<Stack mx={'sm'} h={'100%'}>
					<Text className={'sub-header'} color={'primary-text'}>
						{replaceApostropheHTMLEntityWithSingleQuote(name)}
					</Text>
					<ScrollArea
						viewportRef={viewportRef}
						type={'always'}
						offsetScrollbars
						scrollbarSize={10}
						className={'transcript-scroll-area'}
						onScrollPositionChange={handleScroll}
						styles={{
							root: {
								flex: 2,
							},
							viewport: {
								'::after': {
									display: showGradient && !isAuditPage ? 'block' : 'none', // Control the display based on the state
									content: '""',
									position: 'absolute',
									left: 0,
									right: 0,
									bottom: 0,
									height: `${gradientHeight}px` /* Adjust the height to control how long the fade effect is */,
									pointerEvents:
										'none' /* This makes sure the fade doesn't interfere with scrolling or clicking */,
									background:
										'linear-gradient(to bottom, rgba(255, 255, 255, 0), #FFFFFF)' /* Adjust the color to match your background */,
								},
							},
						}}
					>
						{chat.length ? (
							<Paper className={classes.paper} p='md' shadow={'xs'} h={'100%'}>
								<Stack spacing={'md'} h={'100%'}>
									{chat.map((chat: Chat) => (
										<Stack key={chat.id} spacing={0}>
											<Group position='left' spacing={'xs'} align='center'>
												<Text weight={500} size={'sm'}>
													{chat.sender}
												</Text>
												<Text size={'xs'} color={'secondary-text'}>
													{chat.timestamp && chat.recordingStartTime
														? convertMillisecondsToHHMMSS(
															chat.timestamp - chat.recordingStartTime
														)
														: DateTime.fromMillis(
															Number(chat.timestamp)
														).toLocaleString(DateTime.DATETIME_SHORT)}
												</Text>
											</Group>
											<Text size={'sm'}>
												{chat.content}
												{/* {linkify(chat.content)} */}
												{/* <Text
													component='a'
													c={'blue'}
													href='https://mantine.dev/core/'
													target='_blank'
												>
													Anchor element
												</Text> */}
											</Text>
										</Stack>
									))}
								</Stack>
							</Paper>
						) : (
							<Text>No Chat available.</Text>
						)}
					</ScrollArea>
				</Stack>
			)}
		</>
	);
}
