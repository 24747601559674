import React from 'react';
import {
	Avatar,
	Center,
	createStyles,
	MantineNumberSize,
	Tooltip,
} from '@mantine/core';
import { speakerAvatarColors } from '../constants';

const useStyles = createStyles((theme) => ({
	avatar: {
		cursor: 'pointer',
	},
	unClickableAvatar: {},
	activeAvatar: {
		padding: '3px',
		borderColor: theme.colors['primary'][0],
		height: '43px',
		width: '43px',
	},
}));

interface Props {
	index: number;
	size: number;
	speakerName: string;
	speakerColor: string;
	initials: string;
	selectedSpeakers?: string[];
	setSelectedSpeakers?: (selectedSpeakers: string[]) => void;
	clickable?: boolean;
}

const SpeakerInitialsAvatar = ({
	speakerColor,
	speakerName,
	size,
	initials,
	index,
	selectedSpeakers,
	setSelectedSpeakers,
	clickable = false,
}: Props) => {
	const { classes } = useStyles();
	const isActive = selectedSpeakers?.includes(speakerName);
	const handleAvatarClick = () => {
		if (selectedSpeakers.includes(speakerName)) {
			setSelectedSpeakers(
				selectedSpeakers.filter((name) => name !== speakerName)
			); // Remove the avatar if it's already selected
		} else {
			setSelectedSpeakers([...selectedSpeakers, speakerName]); // Add the avatar to the selected avatars
		}
	};

	const avatarClass = [
		clickable ? classes.avatar : classes.unClickableAvatar,
		isActive ? classes.activeAvatar : '',
	];

	return (
		<>
			{clickable ? (
				<Tooltip
					key={index}
					label={speakerName}
					position={'bottom'}
					withArrow
					onClick={clickable ? handleAvatarClick : null}
				>
					<Avatar
						className={avatarClass.join(' ')}
						styles={{
							placeholder: {
								backgroundColor: speakerColor,
							},
							root: {
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								height: '40px',
								width: '40px',
								border: `1px solid transparent`,
							},
						}}
						variant={'filled'}
						radius={'xl'}
						size={isActive ? size + 3 : size}
						children={
							<Center mb={1} mr={1} fw={400}>
								{initials}
							</Center>
						}
					/>
				</Tooltip>
			) : (
				<Avatar
					className={avatarClass.join(' ')}
					styles={{
						placeholder: {
							backgroundColor: speakerColor,
						},
						root: {
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						},
					}}
					variant={'filled'}
					radius={'xl'}
					size={isActive ? size + 3 : size}
					children={
						<Center mb={1} mr={1} fw={400}>
							{initials}
						</Center>
					}
				/>
			)}
		</>
	);
};

export default SpeakerInitialsAvatar;
