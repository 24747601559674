import React from 'react';
import {
	Container,
	Image,
	Text,
	Stack,
	Title,
	Box,
	createStyles,
} from '@mantine/core';
import managedByReelayImage from '../../../../../assets/images/not-found-image.png';

const useStyles = createStyles((theme) => ({
	wrapper: {
		height: '100%',
		padding: theme.spacing.md,
	},
	header: {
		marginBottom: theme.spacing.md,
	},
	title: {
		color: theme.colorScheme === 'dark' ? theme.white : theme.black,
	},
	description: {
		color:
			theme.colorScheme === 'dark'
				? theme.colors.dark[1]
				: theme.colors.gray[7],
		marginTop: theme.spacing.xs,
	},
	content: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flex: 1,
	},
	image: {
		maxWidth: '100%',
		height: 'auto',
	},
}));

const ManualSubscription: React.FC = () => {
	const { classes } = useStyles();

	return (
		<Container size='lg' className={classes.wrapper}>
			<Stack spacing='md'>
				<Box component='header' className={classes.header}>
					<Title order={2} className={classes.title}>
						Billing
					</Title>
					<Text className={classes.description}>
						It looks like your account is being managed by Reelay. If you have
						questions, please email{' '}
						<a href='mailto:support@reelay.com'>support@reelay.com</a>.
					</Text>
				</Box>
				<Box component='main' className={classes.content}>
					<Image
						src={managedByReelayImage}
						alt='Managed by Reelay'
						className={classes.image}
					/>
				</Box>
			</Stack>
		</Container>
	);
};

export default ManualSubscription;
