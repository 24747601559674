import { logger } from '../helpers/logger';
import axios from 'axios';
import _ from 'lodash';
import { showFailureNotification } from '../helpers/notifications';
import { IMeeting } from '../interfaces/meeting';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_AI_SERVICE_URL, // Set your base URL
	timeout: 180000, // Set the timeout for requests
	// You can add more default configurations as needed
});

// http://localhost:3002/meetings/process/MET_cmkmk0c6cgwp85rryha0/highlights
export const getMeetingDetailsFromAI = (
	meetingID: string,
	type: string,
	signal,
	model,
	headers = {}
) => {
	return axiosInstance
		.get(`meetings/process/${meetingID}/${model}/${type}`, {
			headers,
			signal,
		})
		.then((res) => {
			logger('info', 'Response received from getOrganizationSettings', res);
			return _.get(res, 'data.data', []);
		})
		.catch((err) => {
			if (axios.isCancel(err)) {
				return err;
			} else {
				logger('error', 'Error getting meeting details', err);
				throw err;
			}
		});
};

export const fetchMeetingDetails = async (
	meeting: IMeeting,
	type: string,
	modelValue: any,
	user: any,
	controller: AbortController | null = null,
	showErrorNotification = true
) => {
	try {
		const abortController = controller ? controller : new AbortController();

		const pluralType = ['highlight', 'action', 'question'].includes(type)
			? `${type}s`
			: type === 'longSummary'
			? 'summary'
			: type;

		// Fetch meeting details from AI service.
		const response = await getMeetingDetailsFromAI(
			meeting.id,
			pluralType,
			abortController.signal,
			modelValue,
			{
				recallBotID: meeting?.botMetadata?.recallBotID || null,
				userID: user?.id,
			}
		);

		if (Array.isArray(response[pluralType]) && !response[pluralType].length) {
			showFailureNotification({
				message: `No ${pluralType} were found for this meeting. If you think this is inaccurate, you can try regenerating again.`,
			});
		}

		return { response, pluralType };
	} catch (error) {
		showErrorNotification &&
			showFailureNotification({
				title: 'Error!',
				message:
					'Sorry, we encountered an issue while trying to get the meeting details. Please try refreshing the page or navigating back and to this page again.',
			});
		console.error('fetchMeetingDetails: error:', error);
		throw error;
	}
};
