import { Group, Text, createStyles } from '@mantine/core';
import { MILLISECONDS_PER_SECOND } from '../../../../../../components/constants';
import {
	convertMillisecondsToHHMMSS,
	formatSecondsToMinutesAndSeconds,
} from '../../../../../../_utils/time';
import { Ref } from 'react';
import { DateTime } from 'luxon';
import { Utterance } from '../../../../../../helpers/data';
import { Chat } from '../../../../../../interfaces/chat';
import reactStringReplace from 'react-string-replace';

const useStyles = createStyles((theme) => ({
	timestampContainer: {
		padding: '6px 10px',
		backgroundColor: '#DDE7E9',
		borderRadius: 8,
		fontWeight: 700,
		fontSize: 14,
		cursor: 'pointer',
		':hover': {
			backgroundColor: theme.fn.primaryColor(),
			color: 'white',
		},
	},
	contentAndMenu: {
		flex: 2,
		borderBottom: '1px solid #D3D9DD',
	},
	chapterBar: {
		cursor: 'pointer',
		// border: `1px solid ${theme.colors.primary[0]}`,
	},
	title: {
		color: theme.colors['primary-text'],
		fontWeight: 600,
		fontSize: 14,
		lineHeight: 1.55,
	},
	activeChapter: {
		// border: `1px solid ${theme.colors?.['primary'][0]}`,
		cursor: 'pointer',
		backgroundColor: 'rgba(79, 70, 229, 0.1)',
		borderRadius: 8,
	},
	claimedText: {
		fontWeight: 600,
		fontSize: 10,
		lineHeight: '14px',
		color: theme.colors?.primary[5],
	},
}));

interface Props {
	item: Utterance | Chat;
	index: number;
	handleTranscriptClick: (time: number, index: number) => void;
	scrollRef: Ref<HTMLDivElement>;
	activeIndex: number;
	searchValue?: string;
}

export default function TranscriptRow({
	item,
	index,
	handleTranscriptClick,
	scrollRef,
	activeIndex,
	searchValue = '',
}: Props) {
	const { theme, classes } = useStyles();
	const time =
		'start' in item
			? item.start
			: item.timestamp < item.recordingStartTime
			? 0
			: item.timestamp - item.recordingStartTime;
	const text =
		'speaker' in item
			? `${item.speaker}: ${item.text}`
			: `${item.sender}: ${item.content}`;
	const timestamp =
		'start' in item
			? formatSecondsToMinutesAndSeconds(time / MILLISECONDS_PER_SECOND)
			: item.timestamp && item.recordingStartTime
			? convertMillisecondsToHHMMSS(
					item.timestamp < item.recordingStartTime
						? 0
						: item.timestamp - item.recordingStartTime
			  )
			: DateTime.fromMillis(Number(item.timestamp)).toLocaleString(
					DateTime.DATETIME_SHORT
			  );

	const highlightText = (text: string | undefined, searchValue: string) => {
		return reactStringReplace(text, searchValue, (match, i) => (
			<span key={i} style={{ backgroundColor: 'yellow' }}>
				{match}
			</span>
		));
	};

	const textToUse = searchValue.length
		? highlightText(text, searchValue)
		: text;

	return (
		<Group
			ref={scrollRef}
			noWrap
			w={'100%'}
			p={'sm'}
			pl={0}
			spacing={'lg'}
			align='flex-start'
		>
			<div
				className={classes.timestampContainer}
				onClick={() => handleTranscriptClick(index, time)}
				style={{
					backgroundColor:
						activeIndex === index ? theme.fn.primaryColor() : '#DDE7E9',
					color: activeIndex === index ? 'white' : 'black',
				}}
			>
				{timestamp}
			</div>
			<Group
				position='apart'
				noWrap
				pl={'sm'}
				className={classes.contentAndMenu}
			>
				<Text fw={400} size={14} lh={'25px'}>
					{textToUse}
				</Text>
			</Group>
		</Group>
	);
}
