import {
	componentLoadedAtom,
	currentMeeting,
	elapsedTimeAtom,
	playButtonClicked,
	showThumbnailAtom,
	timeDisplayFormatAtom,
	timestamp,
	totalDurationAtom,
	videoPlayerCurrentTime,
	videoPlayerElapsedTime,
	videoPlayerStateAtom,
	watchedEventSentAtom,
} from '../../../../../Atoms/meetingAtom';
import { MILLISECONDS_PER_SECOND } from '../../../../../components/constants';
import {
	faArrowRotateLeft,
	faArrowRotateRight,
} from '@fortawesome/pro-light-svg-icons';
import { faCirclePlay, faCirclePause } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionIcon, Group, Slider, Text, createStyles } from '@mantine/core';
import { formatSecondsToMinutesAndSeconds } from '../../../../../_utils/time';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { sendAnalyticEvent } from '../../../../../api/api';
import { useEffect } from 'react';
import { currentOrganizationID } from '../../../../../Atoms/userAtoms';

const useStyles = createStyles((theme) => ({
	playPauseGroup: {
		borderRadius: theme.spacing.md,
	},
}));

interface Props {
	playerRef: any;
}

const TranscriptPlayer = ({ playerRef }: Props) => {
	const { classes, theme } = useStyles();
	const meeting = useRecoilValue(currentMeeting);
	const { videoMetadata } = meeting;
	const { duration = 0, playbackID } = videoMetadata;
	const [timestampValue, setTimestampValue] = useRecoilState(timestamp);
	const [currentTime, setCurrentTime] = useRecoilState(videoPlayerCurrentTime);
	const playButtonWasClicked = useRecoilValue(playButtonClicked);
	const [elapsedTime, setElapsedTime] = useRecoilState(videoPlayerElapsedTime);
	const organizationID = useRecoilValue(currentOrganizationID);

	// This effect updates the slider and elapsed time whenever the current time changes
	useEffect(() => {
		setElapsedTime(elapsedTime); // Update elapsed time based on current video time
	}, [elapsedTime]);

	const handlePlayPause = () => {
		if (meeting?.status === 'published') {
			sendAnalyticEvent(
				{
					name: 'reelay.video',
					sourceID: meeting?.id,
					targetID: videoMetadata.id,
					data: {
						type: playerRef?.current?.paused ? 'play' : 'pause',
						startTime: currentTime * MILLISECONDS_PER_SECOND,
					},
				},
				organizationID
			);

			// create setTimeout to track if user watches video for 10 secs.
		}
		if (playerRef.current) {
			if (playerRef.current.paused) {
				playerRef.current
					.play()
					.catch((error) =>
						console.error('Error trying to play the video:', error)
					);
			} else {
				playerRef.current.pause();
			}
		}
	};

	const handleSeekChange = (value: number) => {
		const newTime = playerRef.current?.duration * (value / 100);
		if (playerRef.current && newTime !== undefined) {
			playerRef.current.currentTime = newTime;
		}
	};

	// const handleSeekMouseDown = (value: number) => {
	// 	const newTime = playerRef.current?.duration * (value / 100);
	// 	if (playerRef.current && newTime !== undefined) {
	// 		playerRef.current.currentTime = newTime;
	// 	}
	// };

	// const handleSeekMouseUp = (value: number) => {
	// 	const newTime = playerRef.current?.duration * (value / 100);
	// 	if (playerRef.current && newTime !== undefined) {
	// 		playerRef.current.currentTime = newTime;
	// 	}
	// };

	const handleRewind = (time: number) => {
		playerRef.current.currentTime = currentTime - time;
	};

	const handleForward = (time: number) => {
		playerRef.current.currentTime = currentTime + time;
	};

	const PlaybackIcon = ({
		seconds,
		direction,
		left = 0,
	}: {
		seconds: number;
		direction: 'left' | 'right';
		left?: number;
	}) => {
		return (
			<ActionIcon
				variant='transparent'
				color='#686868'
				onClick={() =>
					direction === 'left' ? handleRewind(seconds) : handleForward(seconds)
				}
				style={{
					position: 'relative',
					left: `${left}px`,
				}}
			>
				<FontAwesomeIcon
					icon={direction === 'left' ? faArrowRotateLeft : faArrowRotateRight}
					size='2xl'
					color={'#686868'}
				/>
				<Text
					fz={10}
					fw={700}
					color={'#686868'}
					style={{
						position: 'relative',
						right: '23px',
						top: '1px',
					}}
				>
					{seconds}
				</Text>
			</ActionIcon>
		);
	};
	return (
		<Group noWrap position='apart' w={'100%'} spacing={'lg'}>
			<Group
				noWrap
				bg={'#E5E6E6'}
				spacing={'xs'}
				py={'xs'}
				px={'sm'}
				className={classes.playPauseGroup}
			>
				<ActionIcon variant='transparent' onClick={handlePlayPause}>
					{playerRef.current && !playerRef.current.paused ? (
						<FontAwesomeIcon icon={faCirclePause} size='2xl' color='#97999B' />
					) : (
						<FontAwesomeIcon
							icon={faCirclePlay}
							size='2xl'
							color={theme.fn.primaryColor()}
						/>
					)}
				</ActionIcon>
			</Group>
			<Group noWrap style={{ flex: 2 }}>
				<Group position='apart' w={'100%'} spacing={'lg'}>
					<PlaybackIcon seconds={10} direction='left' />
					<Slider
						labelAlwaysOn
						color={'primary'}
						size={'md'}
						value={
							(Number(elapsedTime) / (duration / MILLISECONDS_PER_SECOND)) * 100
						}
						label={formatSecondsToMinutesAndSeconds(Number(currentTime))}
						onChange={handleSeekChange}
						// onMouseDown={handleSeekMouseDown}
						// onChangeEnd={handleSeekMouseUp}
						min={0}
						max={100}
						step={1}
						styles={{
							root: {
								flex: 2,
							},
							mark: { display: 'none' },
							markLabel: {
								paddingTop: 5,
								paddingRight: 32,
							},
						}}
						marks={[
							{
								value: 100,
								label: formatSecondsToMinutesAndSeconds(
									duration / MILLISECONDS_PER_SECOND
								),
							},
						]}
					/>
					<PlaybackIcon seconds={10} direction='right' left={9} />
				</Group>
			</Group>
		</Group>
	);
};

export default TranscriptPlayer;
