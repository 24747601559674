import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const getRecallBotEvent = (
	recallEventID: string,
	organizationID: string
) => {
	return axiosInstance
		.get(`v1/recall-ai/event/${recallEventID}`, {
			headers: { 'x-organization-id': organizationID },
		})
		.then((res) => {
			logger('info', 'Bot event received', res);
			return res?.data;
		})
		.catch((err) => {
			logger('error', 'Error getting bot event', err);
			return err;
		});
};
