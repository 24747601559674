import React from 'react';
import { CopyButton, createStyles, Text, Tooltip } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  disabledTextInput: {
    cursor: 'pointer',
    backgroundColor: '#f1f3f5',
    color: '#909296',
    opacity: 0.6,
    display: 'block',
    textAlign: 'left',
    minHeight: '36px',
    paddingLeft: '12px',
    paddingRight: '12px',
    borderRadius: '4px',
    border: '1px solid #ced4da',
    fontFamily: 'Inter,sans-serif',
    height: '36px',
    WebkitTapHighlightColor: 'transparent',
    lineHeight: '34px',
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    MsAppearance: 'none',
    appearance: 'none',
    resize: 'none',
    boxSizing: 'border-box',
    fontSize: '14px',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));
export const DisabledTextLink = ({ url }: { url: string }) => {
  const { classes } = useStyles();
  const formattedURL = url.substring(8);

  return (
    <CopyButton value={url} timeout={2000}>
      {({ copied, copy }) => (
        <Tooltip
          style={{ flex: 3 }}
          label={copied ? 'Copied' : 'Copy'}
          withArrow
          position='right'
        >
          <Text
            style={{ flex: 3 }}
            className={classes.disabledTextInput}
            onClick={copy}
          >
            {formattedURL}
          </Text>
        </Tooltip>
      )}
    </CopyButton>
  );
};
