import { atom, selector } from 'recoil';

import { recoilPersist } from 'recoil-persist';
import { MILLISECONDS_PER_SECOND } from '../components/constants';

const { persistAtom } = recoilPersist();

// @TODO: Create a new File in ./Atoms called Auth.ts for us to store all auth related Atoms including the ones below and some additional ones like the JWT Tokens.

// in epoch time
export const authTokenExpirationTime = atom({
	key: 'authTokenExpirationTime',
	default: null,
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const isAuthenticated = selector({
	key: 'isAuthenticated',
	get: ({ get }) => {
		const isExpired =
			get(authTokenExpirationTime) * MILLISECONDS_PER_SECOND < Date.now();
		// there is an existing auth token and its expiration date is not expired.
		return get(userAuthenticated) && !isExpired;
	},
});

export const userAuthenticated = atom({
	key: 'userAuthenticated',
	default: false,
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const currentAuthStatus = selector({
	key: 'currentAuthStatus',
	get: ({ get }) => {
		return get(userAuthenticated);
	},
});

export const reelayLoader = atom({
	key: 'reelayLoader',
	default: false,
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const originalURL = atom({
	key: 'originalURL',
	default: '',
});
