import { Paper, Stack, Switch, Title } from '@mantine/core';
import { styles } from '../../styles';
import useUserSettings from '../../../../../customHooks/useUserSettings';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../helpers/notifications';
import { logger } from '../../../../../helpers/logger';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { organizationSettings } from '../../../../../Atoms/settings';
import { getEnvForGleap } from '../../../../../_utils/trackers';

export default function MeetingMinutesCard() {
	const { classes } = styles();
	const { userSettings, updateUserSettings } = useUserSettings();
	const { meeting_minutes } = useRecoilValue(organizationSettings);
	const [checked, setChecked] = useState<boolean>(
		meeting_minutes || userSettings?.meeting_minutes
	);

	const handleMeetingMinutesChange = async (value: boolean) => {
		try {
			setChecked(value);
			await updateUserSettings([
				{
					key: 'meeting_minutes',
					value,
				},
			]);
			showSuccessNotification({
				message: 'You have successfully changed the meeting minutes setting.',
			});
		} catch (error) {
			logger('error', 'error changing meeting_minutes', error);
			showFailureNotification({
				message:
					'Sorry, there was an error changing the meeting_minutes setting. Please try again.',
			});
		}
	};

	return (
		<Paper
			className={classes.card}
			shadow='xs'
			p='md'
			radius={'md'}
			id={`VIS_ctf9nfdtmsxfgg2vmcfg_${getEnvForGleap()}`}
		>
			<Stack>
				<Switch
					labelPosition='left'
					label={
						<Title order={4} color='primary-text'>
							Meeting Minutes Docx Feature
						</Title>
					}
					description='Enabling this feature will have you receive the meeting minutes email instead of the fast summary one.'
					size='sm'
					w={'100%'}
					width={'100%'}
					mb={'lg'}
					checked={checked}
					disabled={meeting_minutes}
					onChange={(e) => handleMeetingMinutesChange(e.currentTarget.checked)}
					styles={{
						body: {
							width: '100%',
							justifyContent: 'space-between',
							flex: 1,
						},
					}}
				/>
			</Stack>
		</Paper>
	);
}
