/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useRef } from 'react';
import gsap, { Power2, TimelineMax } from 'gsap';
import classes from './PlayPauseButton.module.css';
import { set } from 'lodash';
import { createStyles, useMantineTheme } from '@mantine/core';

const DURATIONS = { toggleIcon: 0.5 };
const SELECTORS = {
	pauseBarRight: {
		origin: '.shape-element__pause-right',
		destinationGuide: '.guide-element__pause-right',
	},
	pauseBarLeft: {
		origin: '.shape-element__pause-left',
		destinationGuide: '.guide-element__pause-left',
	},
};

const EASINGS = { toggleIcon: Power2.easeOut };

function boundUpdateAttr(attr, value) {
	this.setAttribute(attr, value.toString());
}

function parsePathPointsAttr(attrString) {
	var pathExpression = /[achlmrqstvz]|(-?\d*\.?\d*(?:e[\-+]?\d+)?)[0-9]/gi;
	var path = attrString.match(pathExpression).map(function (n) {
		return isNaN(+n) ? n : +n;
	});

	path.prefix = isNaN(path[0]) ? path.shift() : '';
	path.string = function () {
		return path.prefix + path.join(' ');
	};

	return path;
}

// function boundSetStateOnToggle() {
//     this.isAnimating = false;
//     this.isShowingPlay = !this.isShowingPlay;
// };

function createPlayPauseTL(icon, setAnimating) {
	var TL = new TimelineMax({
		paused: true,
		onComplete: () => {
			setAnimating(false);
		},
		onReverseComplete: () => {
			setAnimating(false);
		},
	});
	var _arr = [
		icon.POLYGON_POINTS.pauseBarLeft,
		icon.POLYGON_POINTS.pauseBarRight,
	];
	for (var _i = 0; _i < _arr.length; _i++) {
		if (window.CP && window.CP.shouldStopExecution(1)) {
			break;
		}
		var pointSet = _arr[_i];
		TL.to(
			pointSet.start,
			DURATIONS.toggleIcon,
			{
				endArray: pointSet.end,
				ease: EASINGS.toggleIcon,
				onUpdate: boundUpdateAttr.bind(
					pointSet.correspondingDOMElem,
					'points',
					pointSet.start
				),
			},
			0
		);
	}
	window.CP && window.CP.exitedLoop(1);
	return TL;
}

function wireUpPolygonPoints(icon) {
	icon.POLYGON_POINTS = {
		pauseBarRight: {
			start: parsePathPointsAttr(
				icon.DOM_REFS.pauseBarRight.getAttribute('points')
			),
			end: parsePathPointsAttr(
				icon.DOM_REFS.pauseBarRightGuide.getAttribute('points')
			),
			correspondingDOMElem: icon.DOM_REFS.pauseBarRight,
		},
		pauseBarLeft: {
			start: parsePathPointsAttr(
				icon.DOM_REFS.pauseBarLeft.getAttribute('points')
			),
			end: parsePathPointsAttr(
				icon.DOM_REFS.pauseBarLeftGuide.getAttribute('points')
			),
			correspondingDOMElem: icon.DOM_REFS.pauseBarLeft,
		},
	};
}

function init(svgElem) {
	let inst = {};

	inst.svgElem = svgElem;
	inst.isAnimating = false;
	inst.DOM_REFS = {
		pauseBarLeft: inst.svgElem.querySelector(SELECTORS.pauseBarLeft.origin),
		pauseBarLeftGuide: inst.svgElem.querySelector(
			SELECTORS.pauseBarLeft.destinationGuide
		),
		pauseBarRight: inst.svgElem.querySelector(SELECTORS.pauseBarRight.origin),
		pauseBarRightGuide: inst.svgElem.querySelector(
			SELECTORS.pauseBarRight.destinationGuide
		),
	};

	wireUpPolygonPoints(inst);
	return inst;
}

const useStyles = createStyles((theme) => ({
	anchorVideo: {
		'background:before': theme.colors[theme.primaryColor][theme.primaryShade],
		'background:after': theme.colors[theme.primaryColor][theme.primaryShade],
		background: theme.colors[theme.primaryColor][theme.primaryShade],
	},
}));;


export default function PlayPauseButton ({ recording, pauseRecording, resumeRecording }) {
	const { classes: mantineStyles } = useStyles();
	const theme = useMantineTheme();
	const [isAnimating, setAnimating] = useState(false);
	const [instance, setInstance] = useState(null);

	const svgRef = useRef(null);

	function click() {
		if (!isAnimating) {
			setAnimating(true);

			if (recording) {
				instance.mainIconTL.reverse(0);
				pauseRecording();
			} else {
				instance.mainIconTL.play(0);
				resumeRecording();
			}
			return;
		}
	}

	useEffect(() => {
		const inst = init(svgRef.current);
		inst.mainIconTL = createPlayPauseTL(inst, setAnimating);
		setInstance(inst);
		window.root.style.setProperty('--test-color', theme.fn.primaryColor());
	}, []);

	useEffect(() => {
		if (recording && instance) {
			instance?.mainIconTL?.play(0);
		}
	}, [recording, instance]);

	return (
		<div className={classes.outerWrapper}
		>
			<a
			id='play-video'
			className={`${classes.videoPlayButton} ${mantineStyles.anchorVideo}`}
			style={{
				'background:before': theme.fn.primaryColor(),
				'background:after': theme.fn.primaryColor(),
				background: theme.fn.primaryColor(),
			 }}
			href='#'
			>
				<span></span>
			</a>

			<div className={classes.mainViewContainer} onClick={() => click()}>
				<svg
					version='1.1'
					xmlns='http://www.w3.org/2000/svg'
					xmlnsXlink='http://www.w3.org/1999/xlink'
					width='100%'
					height='100%'
					className={classes.PlayPauseButton}
				>
					<svg
						ref={svgRef}
						className={classes.PlayPauseButton}
						xmlns='http://www.w3.org/2000/svg'
						viewBox='0 0 48 48'
					>
						<g id='guide-shapes' fill='none'>
							<polygon
								id='guide-shape--pause-right'
								className='guide-element guide-element__pause-right'
								points='26.5,13 38,13 38,36 26.5,36'
							/>
							<polygon
								id='guide-shape--pause-left'
								className='guide-element__pause-left'
								points='10,13 21.5,13 21.5,36 10,36'
							/>
						</g>
						<g id='main-shapes'>
							<polygon
								id='pauseBarLeft'
								className='shape-element shape-element__pause-left'
								points='18,13 24.35,16.585 24.35,32.415 18,36'
							/>
							<polygon
								id='pauseBarRight'
								className='shape-element shape-element__pause-right'
								points='24.2,16.5 38,24.5 38,24.5 24.2,32.5'
							/>
						</g>
					</svg>
				</svg>
			</div>
		</div>
	);
};
