import React, { useState } from 'react';
import {
	createStyles,
	Input,
	Text,
	Tooltip,
	useMantineTheme,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons';
import InputMask from 'react-input-mask';
import {
	convertSecondsToHHMMSS,
	convertTimeToSeconds,
	formatTimestamp,
	validateTime,
} from '../../_utils/time';
import { IHighlight } from '../../interfaces/highlight';
import { IAction } from '../../interfaces/action';

const useStyles = createStyles((theme) => ({
	toolTip: {
		fontSize: 12,
	},
	labels: {
		color: theme.colors?.['secondary-text'],
		fontSize: '10px',
		lineHeight: '14px',
		fontWeight: 600,
	},
}));

interface Props {
	value: string;
	setValue: React.Dispatch<React.SetStateAction<string>>;
	duration: number;
	error: boolean;
	setError: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TimestampInput = ({
	value,
	setValue,
	duration,
	error,
	setError,
}: Props) => {
	const { classes } = useStyles();
	const theme = useMantineTheme();
	const videoDuration = convertSecondsToHHMMSS(duration / 1000);
	const isAtleastOneHourLong = videoDuration?.substring(0, 2) !== '00';
	// value needs to be in hh:mm:ss format if video is at least one hour long, else mm:ss
	// add leading zeroes 00: if value is less than 1hr long
	const valueInCorrectFormat = formatTimestamp(value);

	const editHighlightTimestamp = (e) => {
		const time = e.target.value;
		if (!time) return;
		// add a validation check here
		// make sure the time inputted is <= the video duration
		const timestampDuration = convertTimeToSeconds(time);
		const videoDurationInSeconds = Math.floor(duration / 1000);
		// throws error label on input if time is greater than video
		setError(timestampDuration > videoDurationInSeconds);
		setValue(time);
	};

	const beforeMaskedValueChange = (newState) => {
		// mm:ss - minutes and seconds cannot exceed 59. If they do change to 59.
		// for example, i should not be able to enter 69 seconds or 76 minutes.
		// that is awkward. If minutes exceeds 59 then there should be a hh:mm:ss format instead
		const value = validateTime(newState.value);
		return {
			...newState,
			value,
		};
	};

	return (
		<>
			<Text className={classes.labels}>Start time</Text>
			<InputMask
				mask={isAtleastOneHourLong ? '99:99:99' : '99:99'} // either hh:mm:ss or mm:ss depending on video duration.
				value={valueInCorrectFormat}
				onChange={editHighlightTimestamp}
				placeholder={isAtleastOneHourLong ? 'hh:mm:ss' : 'mm:ss'}
				beforeMaskedValueChange={beforeMaskedValueChange}
				// Any other props supported by react-input-mask
			>
				{(inputProps) => (
					<Input.Wrapper
						error={
							error ? 'Start time cannot be greater than video duration.' : ''
						}
					>
						<Input
							{...inputProps}
							w={isAtleastOneHourLong ? 110 : 100}
							color={'primary.8'}
							variant='filled'
							radius='md'
							size='sm'
							error={error}
							rightSection={
								<Tooltip
									label='Enter new start time here if it needs to be changed.'
									withArrow
									className={classes.toolTip}
								>
									<div>
										<FontAwesomeIcon
											color={theme.colors['secondary-text'][0]}
											icon={faCircleExclamation}
											size={'sm'}
										/>
									</div>
								</Tooltip>
							}
							styles={(theme) => ({
								input: {
									'&:disabled': {
										color: theme.colors.primary[8],
									},
								},
							})}
						/>
					</Input.Wrapper>
				)}
			</InputMask>
		</>
	);
};

export default TimestampInput;
