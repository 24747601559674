import { showNotification } from '@mantine/notifications';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
} from '../components/constants';

export const showSuccessNotification = ({
	title = 'Success!',
	message = 'Great work!',
}) => {
	showNotification({
		...defaultSuccessNotificationProps,
		title,
		message,
	});
};

export const showFailureNotification = ({
	title = 'Error!',
	message = 'Something went wrong. Please try again.',
}: {
	title?: string;
	message?: string;
}) => {
	showNotification({
		...defaultFailureNotificationProps,
		title,
		message,
	});
};
