import {
	Box,
	Button,
	ColorPicker,
	FileButton,
	Group,
	Image,
	Paper,
	Stack,
	Text,
	Title,
} from '@mantine/core';
import {
	BrandDetails,
	OrganizationSettings,
} from '../../../../../../../Atoms/settings';
import { styles } from '../../../../../../settings/tabs/styles';
import { useEffect, useState } from 'react';
import {
	deleteOrganizationSettings,
	saveOrganizationSettings,
} from '../../../../../../../api/settings';
import { useParams } from 'react-router';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../../../helpers/notifications';
import { logger } from '../../../../../../../helpers/logger';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFloppyDisk,
	faTrash,
	faUpload,
} from '@fortawesome/pro-light-svg-icons';
import { DangerNoButton } from '../../../../../../../components/Buttons/DangerNoButton';
import { themeColors } from '../../../../../../../interfaces/colors';
import { set } from 'lodash';
import ColorAndShadePicker from '../ColorAndShadePicker';
import { getImageUploadLink } from '../../../../../../../api/api';
import axios from 'axios';

interface Props {
	organizationSettings: OrganizationSettings;
	setOrganizationSettings: React.Dispatch<
		React.SetStateAction<OrganizationSettings>
	>;
	brand: BrandDetails;
	setBrand: React.Dispatch<React.SetStateAction<BrandDetails>>;
}

export default function CustomBranding({
	organizationSettings,
	setOrganizationSettings,
	brand,
	setBrand,
}: Props) {
	const { classes, theme } = styles();
	const { organizationID } = useParams();
	const {
		logo_url,
		icon,
		color_primary,
		color_secondary,
		color_level_primary,
		color_level_secondary,
	} = brand;
	// logo_url state
	const [logoPreview, setLogoPreview] = useState<string | null>(
		logo_url || null
	);
	const [uploadingLogo, setUploadingLogo] = useState(false);
	const [removingLogo, setRemovingLogo] = useState(false);
	// icon state
	const [iconPreview, setIconPreview] = useState<string | null>(icon || null);
	const [uploadingIcon, setUploadingIcon] = useState(false);
	const [removingIcon, setRemovingIcon] = useState(false);

	useEffect(() => {
		setLogoPreview(logo_url);
	}, [logo_url]);

	useEffect(() => {
		setIconPreview(icon);
	}, [icon]);

	const handleFileChange = async (file: File, isLogo) => {
		try {
			if (!file) return;
			isLogo ? setUploadingLogo(true) : setUploadingIcon(true);

			const res = await getImageUploadLink({
				contentType: file.type,
			});
			const signedUploadUrl = res.signedUploadUrl;
			const objectUrl = res.objectUrl;

			const response = await axios.put(signedUploadUrl, file, {
				headers: {
					'Content-Type': file.type,
				},
			});

			if (response.status === 200) {
				const payload = [
					{
						key: 'brand',
						value: {
							...brand,
							...(isLogo ? { logo_url: objectUrl } : { icon: objectUrl }),
						},
					},
				];
				const res = await saveOrganizationSettings(payload, organizationID);
				setBrand(res[0]?.value);
				isLogo ? setLogoPreview(objectUrl) : setIconPreview(objectUrl);
				showSuccessNotification({
					message: `Organization ${isLogo ? 'logo' : 'icon'} has been saved.`,
				});
			}
		} catch (error) {
			console.log('error:', error);
			logger(
				'error',
				`Error saving organization ${isLogo ? 'logo' : 'icon'}`,
				error
			);
			showFailureNotification({
				message: `Sorry, there was an error saving your organization's ${
					isLogo ? 'logo' : 'icon'
				}. Please try again or with a different file. Your file may also be too large.`,
			});
		} finally {
			setUploadingLogo(false);
			setUploadingIcon(false);
		}
	};

	const removeLogo = async () => {
		try {
			setRemovingLogo(true);
			const payload = [
				{
					key: 'brand',
					value: {
						...brand,
						logo_url: '',
					},
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			setBrand({
				...res[0]?.value,
			});
			showSuccessNotification({
				message: 'Organization logo has been removed.',
			});
		} catch (error) {
			logger('error', 'Error removing logo', error);
			showFailureNotification({
				message: `Sorry, there was an error removing your organization's logo. Please try again.`,
			});
		} finally {
			setRemovingLogo(false);
		}
	};

	const removeIcon = async () => {
		try {
			setRemovingIcon(true);
			const payload = [
				{
					key: 'brand',
					value: {
						...brand,
						icon: '',
					},
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			setBrand({
				...res[0]?.value,
			});
			showSuccessNotification({
				message: 'Organization icon has been removed.',
			});
		} catch (error) {
			logger('error', 'Error removing icon', error);
			showFailureNotification({
				message: `Sorry, there was an error removing your organization's icon. Please try again.`,
			});
		} finally {
			setRemovingIcon(false);
		}
	};

	return (
		<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
			<Group position={'apart'} mt={'lg'}>
				<Stack spacing={0}>
					{logoPreview ? (
						<Title order={5}>Logo</Title>
					) : (
						<Text mb={4} size={'sm'}>
							Upload a picture for your organization's logo
						</Text>
					)}

					{logoPreview ? (
						<Image
							src={logoPreview}
							alt='Logo image preview'
							width={200}
							height={75}
						/>
					) : (
						<Image
							width={200}
							height={75}
							src={null}
							alt='With default placeholder'
							withPlaceholder
						/>
					)}
					<Text size='sm' align='left' mt='sm'>
						accepts jpeg, png files. Image should be 16:9. Recommended
						resolution is 640x360.
					</Text>
				</Stack>

				<Stack>
					<FileButton
						onChange={(file) => handleFileChange(file, true)}
						accept='image/jpeg,image/png'
					>
						{(props) => (
							<Button
								loading={uploadingLogo}
								radius={'xl'}
								variant={'filled'}
								rightIcon={<FontAwesomeIcon icon={faUpload} />}
								color={'primary'}
								{...props}
							>
								Upload logo
							</Button>
						)}
					</FileButton>
					{logo_url?.length > 0 ? (
						<DangerNoButton
							clickFunc={removeLogo}
							text={'Remove'}
							leftIcon={<FontAwesomeIcon icon={faTrash} />}
							radius={'lg'}
							loading={removingLogo}
						/>
					) : null}
				</Stack>
			</Group>
			<Group position={'apart'} mt={'lg'}>
				<Stack spacing={0}>
					{iconPreview ? (
						<Title order={5}>Icon</Title>
					) : (
						<Text mb={4} size={'sm'}>
							Upload a picture for your organization's icon
						</Text>
					)}

					{iconPreview ? (
						<Image
							src={iconPreview}
							alt='Icon image preview'
							width={75}
							height={75}
						/>
					) : (
						<Image
							width={75}
							height={75}
							src={null}
							alt='With default placeholder'
							withPlaceholder
						/>
					)}
					<Text size='sm' align='left' mt='sm'>
						accepts jpeg, png files. Image should be 16:9. Recommended
						resolution is 640x360.
					</Text>
				</Stack>

				<Stack>
					<FileButton
						onChange={(file) => handleFileChange(file, false)}
						accept='image/jpeg,image/png'
					>
						{(props) => (
							<Button
								loading={uploadingIcon}
								radius={'xl'}
								variant={'filled'}
								rightIcon={<FontAwesomeIcon icon={faUpload} />}
								color={'primary'}
								{...props}
							>
								Upload icon
							</Button>
						)}
					</FileButton>
					{icon?.length > 0 ? (
						<DangerNoButton
							clickFunc={removeIcon}
							text={'Remove'}
							leftIcon={<FontAwesomeIcon icon={faTrash} />}
							radius={'lg'}
							loading={removingIcon}
						/>
					) : null}
				</Stack>
			</Group>

			<ColorAndShadePicker brand={brand} setBrand={setBrand} type='primary' />
			<ColorAndShadePicker brand={brand} setBrand={setBrand} type='secondary' />
		</Paper>
	);
}
