import React, { useEffect, useState } from 'react';
import {
	Navbar,
	Menu,
	Avatar,
	Divider,
	Text,
	Button,
	useMantineTheme,
	Group,
	Stack,
	Tooltip,
	Card,
	ScrollArea,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faQuestionCircle,
	faFileAlt,
	faSignOutAlt,
	faChevronDown,
	faUserPlus,
} from '@fortawesome/pro-regular-svg-icons';

import MeetingRecordingStack from '../../MeetingRecordingStack';
import UpcomingMeetingStack from '../../UpcomingMeetingStack';
import MainLinks from '../../mainLinks';
import { useRecoilValue } from 'recoil';
import { currentUser } from '../../../Atoms/userAtoms';
import Gleap from 'gleap';
import { helpLink } from '../../constants';
import { isSuperadmin, isSupportUser } from '../../../helpers/auth';
import { useLocation, useNavigate } from 'react-router';
import AdminLinks from '../../mainLinks/AdminLinks';

interface CustomNavbarProps {
	isAuditPage: boolean;
	isMobile: boolean;
	navbarHidden: boolean;
	authenticated: boolean;
	logout: () => void;
}

const CustomNavbar: React.FC<CustomNavbarProps> = ({
	isAuditPage,
	isMobile,
	navbarHidden,
	authenticated,
	logout,
}) => {
	const location = useLocation();
	const theme = useMantineTheme();
	const user = useRecoilValue(currentUser);
	const navigate = useNavigate();
	const {
		firstName,
		lastName,
		email,
		userAvatarURL,
		roles,
		avatarSizes,
		updatedAt,
	} = user;
	const isSuperAdmin = isSuperadmin(roles);
	const [openAccordionValue, setOpenAccordionValue] = useState('');
	const isSupport = isSupportUser(roles);
	const avatarURL =
		typeof userAvatarURL === 'string' &&
		userAvatarURL.length &&
		Array.isArray(avatarSizes) &&
		avatarSizes[0]
			? `${userAvatarURL}-${avatarSizes[0]}.png`
			: '';
	const timestamp = Date.now();
	const avatarImageURLWithTimestamp = `${avatarURL}?t=${timestamp}`;

	// when the navbar mounts
	// take the location.pathname and use that
	// to set the openAccordionValue.
	useEffect(() => {
		const path = location.pathname.split('/')[1];
		setOpenAccordionValue(path);
	}, []);

	if (isAuditPage || isMobile || navbarHidden) return null;

	return (
		<Navbar p='md' pl={36} width={{ base: 300 }}>
			{!authenticated && (
				<a
					href={'https://www.reelay.com/request-a-demo'}
					target='_blank'
					rel='noopener noreferrer'
				>
					<div
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							width: '100%',
							height: '100%',
							backgroundColor: 'rgba(0, 0, 0, 0.07)',
							zIndex: 9999,
							backdropFilter: 'blur(2.5px)',
						}}
					/>
				</a>
			)}
			<ScrollArea style={{ flex: 1 }} scrollbarSize={10}>
				<Navbar.Section mb={'md'}>
					{isSupport ? (
						<AdminLinks
							openAccordionValue={openAccordionValue}
							setOpenAccordionValue={setOpenAccordionValue}
						/>
					) : (
						<MainLinks
							openAccordionValue={openAccordionValue}
							setOpenAccordionValue={setOpenAccordionValue}
						/>
					)}
				</Navbar.Section>
				{authenticated && !isSupport && (
					<Navbar.Section
						grow
						id='happening-now-upcoming-navbar-section'
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-start',
							flex: 2,
							width: '100%',
							alignItems: 'flex-end',
							overflow: 'hidden',
							gap: theme.spacing.xs,
						}}
					>
						<MeetingRecordingStack
							openAccordionValue={openAccordionValue}
							setOpenAccordionValue={setOpenAccordionValue}
						/>
						<UpcomingMeetingStack
							openAccordionValue={openAccordionValue}
							setOpenAccordionValue={setOpenAccordionValue}
						/>
					</Navbar.Section>
				)}
			</ScrollArea>
			{authenticated && (
				<>
					{isSuperAdmin && (
						<Navbar.Section>
							<Card withBorder shadow='md' radius='md' p='md' mt='xs'>
								<Group position='center' noWrap spacing={'lg'}>
									<FontAwesomeIcon icon={faUserPlus} size='lg' />

									<Stack spacing={0}>
										<Text size='sm' weight={500}>
											Invite Members
										</Text>
										<Text size='xs' c={'secondary-text'}>
											Add colleagues to collaborate
										</Text>
									</Stack>
								</Group>

								<Button
									fullWidth
									mt='sm'
									size='sm'
									onClick={() => navigate('/settings/members')}
								>
									Add Members
								</Button>
							</Card>
						</Navbar.Section>
					)}

					<Navbar.Section>
						<Menu
							shadow='md'
							position='top'
							width={`calc(100% - ${theme.spacing.md})`}
							styles={(theme) => ({
								item: {
									fontWeight: 500,
								},
							})}
						>
							<Menu.Target>
								<Button
									variant='transparent'
									fullWidth
									px={0}
									py={'xs'}
									h={80}
									styles={{
										label: {
											width: '100%',
										},
									}}
								>
									<Group w={'100%'} noWrap position='apart' p={'md'} px={'xs'}>
										<Group
											noWrap
											style={{
												flex: 1,
												overflow: 'hidden',
											}}
										>
											{avatarURL ? (
												<Avatar src={avatarImageURLWithTimestamp} radius='xl' />
											) : (
												<Avatar
													styles={(theme) => ({
														placeholder: {
															backgroundColor: theme.fn.primaryColor(),
															color: 'white',
														},
													})}
													radius='xl'
												>
													{typeof firstName === 'string' && firstName.length
														? firstName[0].toUpperCase()
														: ''}
													{typeof lastName === 'string' && firstName.length
														? lastName[0].toUpperCase()
														: ''}
												</Avatar>
											)}

											<Stack spacing={0} style={{ minWidth: 0 }}>
												<Tooltip label={`${firstName} ${lastName}`} withArrow>
													<Text size='sm' weight={500} lineClamp={1}>
														{`${firstName} ${lastName}`}
													</Text>
												</Tooltip>
												<Tooltip label={email} withArrow>
													<Text fz={'xs'} c={'secondary-text'} lineClamp={1}>
														{email}
													</Text>
												</Tooltip>
											</Stack>
										</Group>
										<FontAwesomeIcon icon={faChevronDown} size='xs' />
									</Group>
								</Button>
							</Menu.Target>

							<Menu.Dropdown>
								{/* Uncomment below when this feature is available */}
								{/* <Menu.Item
									icon={<FontAwesomeIcon icon={faUserGroup} size='sm' />}
									onClick={() =>
										showFailureNotification({
											message: 'This feature is not yet available',
										})
									}
								>
									Switch Organization
								</Menu.Item> */}
								<Menu.Item
									icon={<FontAwesomeIcon icon={faQuestionCircle} size='sm' />}
									onClick={() => Gleap.open()}
								>
									Get Help
								</Menu.Item>
								<Menu.Item
									icon={<FontAwesomeIcon icon={faFileAlt} size='sm' />}
									onClick={() => window.open(helpLink, '_blank')}
								>
									View Docs
								</Menu.Item>
								<Divider />
								<Menu.Item
									color='red'
									icon={<FontAwesomeIcon icon={faSignOutAlt} size='sm' />}
									onClick={logout}
								>
									Log out
								</Menu.Item>
							</Menu.Dropdown>
						</Menu>
					</Navbar.Section>
				</>
			)}
		</Navbar>
	);
};

export default CustomNavbar;
