import React, { useEffect, useState } from 'react';
import { Container, createStyles } from '@mantine/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { EditScheduledMeetingModal } from '../../components/Modals/EditScheduledMeetingModal';
import { useSearchParams } from 'react-router-dom';
import { decode } from 'js-base64';
import { useMediaQuery } from 'react-responsive';
import { MobileLandingPage } from '../mobileLanding';
import { getMeetingNotifications } from '../../api/notifications';
import { notifications } from '../../Atoms/notifications';
import Meetings from '../../components/Meetings/Meetings';
import { currentUser } from '../../Atoms/userAtoms';
import { meetingsView, reloadMeetingsAtom } from '../../Atoms/meetingAtom';
import MeetingToAddToCollectionModal from '../../components/Modals/MeetingAddToCollectionModal';

const useStyles = createStyles(() => ({
	outerContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	topContainer: {
		flex: 1,
	},
	bottomContainer: {
		height: 'auto',
	},
}));

export interface MeetingsRouteProps {
	segmentValue: string;
	recapOnly?: boolean;
}

export default function MeetingsPage(props: MeetingsRouteProps) {
	const { classes, theme } = useStyles();
	const { segmentValue, recapOnly } = props;
	const [searchParams, setSearchParams] = useSearchParams();
	const viewType = useRecoilValue(meetingsView);
	const skipParam = searchParams.get('skip');
	const skip = isNaN(skipParam as unknown as number) ? 0 : Number(skipParam);
	const order = searchParams.get('order') ?? '';
	const searchParam = searchParams.get('search');
	const search = searchParam ? String(searchParam).trim() : '';
	const setNotifications = useSetRecoilState(notifications);
	const isMobile = useMediaQuery({
		query: `(max-width: ${theme.breakpoints.xs})`,
	});

	const [currentSkip, setSkip] = useState(skip);
	const [currentSearch, setSearch] = useState(searchParam);

	useEffect(() => {
		//skip = isNaN(skipParam as unknown as number) ? 0 : Number(skipParam);
		if (skip !== currentSkip) {
			setSkip(skip);
		}
		if (search !== currentSearch) {
			setSearch(search);
		}

		const getNotifications = async () => {
			const newResponse = await getMeetingNotifications();
			setNotifications(newResponse);
		};
		getNotifications();
	}, []);

	return (
		<>
			{isMobile ? (
				<MobileLandingPage />
			) : (
				<Container className={classes.outerContainer} size={'xl'} pb={'xl'}>
					<Meetings
						segmentValue={segmentValue}
						recapOnly={recapOnly}
						skip={skip}
						search={decode(search)}
						order={order}
					/>
					<div id='sharing-modals'>
						<EditScheduledMeetingModal />
						<MeetingToAddToCollectionModal />
					</div>
				</Container>
			)}
		</>
	);
}
