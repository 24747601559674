import React, { useEffect, useState } from 'react';
import { ActionIcon } from '@mantine/core';

import { useRecoilState, useRecoilValue } from 'recoil';
import {
	currentUserFormattedTeams,
	User,
	userOrganization,
} from '../../Atoms/userAtoms';
import { useForm } from '@mantine/form';
import { showNotification } from '@mantine/notifications';
import { getUsersByOrg, updateTeam } from '../../api/api';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
} from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import { logger } from '../../helpers/logger';
import UsersModal from '../Modals/UsersModal';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';

interface Props {
	teamID: string;
	teamName: string;
	teamMembers: User[];
}

export const EditTeamButton = ({ teamID, teamName, teamMembers }: Props) => {
	const membersEmails = teamMembers.map((member) => member.email);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>();
	const [opened, setOpened] = useState(false);
	const [organizationMembers, setOrganizationMembers] = useState<
		{ value: string; label: string }[]
	>([]);
	const organization = useRecoilValue(userOrganization);
	const [teams, setTeams] = useRecoilState(currentUserFormattedTeams);

	const organizationID = organization.id;

	const form = useForm({
		initialValues: {
			name: teamName,
			emails: membersEmails,
		},
		validate: {
			emails: (value) =>
				value.length > 0 ? null : 'Teammates are required to create a team',
		},
	});

	const handleUpdateTeam = async (values: any) => {
		setLoading(true);
		try {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const response = await updateTeam({
				teamName: values.name,
				emails: values.emails,
				teamID,
				organizationID,
			});
			//update team list in atom here !!!
			setTeams(
				teams.map((team) =>
					team.id === response.data.data.id ? response.data.data : team
				)
			);
			setLoading(false);
			showSuccessNotification({
				message: 'Team was successfully updated!  Great work!',
			});
			setOpened(false);
		} catch (err: any) {
			logger('error', 'Error updating team', err);
			setError(err.message);
			setLoading(false);
			showFailureNotification({
				message:
					'Sorry, there was an error when updating the team. Please try again.',
			});
		}
	};

	return (
		<>
			<UsersModal
				opened={opened}
				setOpened={setOpened}
				data={organizationMembers}
				handleUpdate={handleUpdateTeam}
				form={form}
				loading={loading}
				name={'emails'}
				title={'Edit a Team'}
				buttonText={'Save'}
			/>
			<ActionIcon variant='transparent' onClick={() => setOpened(true)}>
				<FontAwesomeIcon icon={faPencil} />
			</ActionIcon>
		</>
	);
};
