import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const getOrganizationSettings = (organizationID: string) => {
	return axiosInstance
		.get(`v1/organization/${organizationID}/settings`)
		.then((res) => {
			logger('info', 'Response received from getOrganizationSettings', res);
			return res.data.data;
		})
		.catch((err) => {
			logger('error', 'Error getting user settings', err);
			return err;
		});
};

export const getOrganizationBrand = (organizationID: string) => {
	return axiosInstance
		.get(`v1/organization/${organizationID}/brand`)
		.then((res) => {
			logger('info', 'Response received from getOrganizationBrand', res);
			return res.data.data;
		})
		.catch((err) => {
			logger('error', 'Error getting user settings', err);
			return err;
		});
};

export const saveOrganizationSettings = (payload, organizationID: string) => {
	return axiosInstance
		.post(`v1/organization/${organizationID}/settings`, payload)
		.then((res) => {
			logger('info', 'Response received from saveOrganizationSettings', res);
			return res.data.data;
		})
		.catch((err) => {
			logger('error', 'Error getting saveOrganizationSettings', err);
			return err;
		});
};

export const deleteOrganizationSettings = (
	keys: string,
	organizationID: string
) => {
	return axiosInstance
		.delete(`v1/organization/${organizationID}/settings`, {
			params: {
				keys,
			},
		})
		.then((res) => {
			logger('info', 'Response received from getOrganizationSettings', res);
			return res.data.data;
		})
		.catch((err) => {
			logger('error', 'Error getting user settings', err);
			return err;
		});
};
