import { Utterance } from './data';
import { speakerAvatarColors } from '../components/aside/TabPanels/Transcript/constants';
import reactStringReplace from 'react-string-replace';

export const getSpeakersFromTranscriptWithAssignedColors = (
	utterances: Utterance[]
) => {
	let indexCounter = 0;
	return utterances.reduce((prev, cur) => {
		// handle null values

		if (prev.findIndex((item) => item.name === cur.speaker) === -1) {
			prev.push({
				name: cur.speaker,
				color: speakerAvatarColors[indexCounter],
			});
			indexCounter++;
			return prev;
		}
		return prev;
	}, []);
};

export const getInitialsFromSpeakerName = (speaker: string) => {
	// handle null values (unknown speaker);
	if (speaker === 'Unknown Speaker') return '?';

	const initials = speaker.split(' ');
	const firstInitial = initials[0]?.substring(0, 1);
	const lastInitial = initials[initials.length - 1]?.substring(0, 1);
	return `${firstInitial}${
		initials.length - 1 !== 0 ? lastInitial : ''
	}`.toUpperCase();
};
