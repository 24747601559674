import React, { SetStateAction } from 'react';
import { TransferList, TransferListData } from '@mantine/core';

interface Props {
  data: TransferListData;
  setData: React.Dispatch<SetStateAction<TransferListData>>;
}

export default function OrganizationStep({ data, setData }: Props) {
  return (
    <div style={{ flex: 9 }}>
      <TransferList
        value={data}
        onChange={setData}
        searchPlaceholder='Search...'
        nothingFound='Nothing found'
        titles={['Groups', 'Groups to be added']}
        breakpoint='sm'
        radius={'lg'}
      />
    </div>
  );
}
