import React, { useRef, useState } from 'react';
import { Avatar, createStyles, FileInput, Loader, Text } from '@mantine/core';
import { updateUserAvatar } from '../../../api/api';
import { showNotification } from '@mantine/notifications';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
} from '../../constants';
import { useRecoilState } from 'recoil';
import { user } from '../../../Atoms/userAtoms';
import { swapBackgroundColor } from '../../../_utils/handy-functions';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';

const useStyles = createStyles(() => ({
	avatar: {
		cursor: 'pointer',
		position: 'relative',
	},
	overlayContainer: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		backgroundColor: 'rgba(0,0,0,0.75)',
		zIndex: 100000,
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	overlayText: {
		zIndex: 10000,
		fontSize: '14px',
		width: '100%',
		color: 'white',
	},
}));
interface Props {
	firstName: string;
	lastName: string;
	avatarURL: string;
	size: number;
}
export const UserAvatar = ({ firstName, lastName, avatarURL, size }: Props) => {
	const { classes, theme } = useStyles();

	const uploadRef: any = useRef();

	const [userInfo, setUserInfo] = useRecoilState(user);

	const [isHovering, setIsHovering] = useState(false);
	const [uploadingAvatar, setUploadingAvatar] = useState<boolean>(false);

	const avatarURLWithCorrectBackgroundColor = swapBackgroundColor(
		avatarURL,
		theme.fn.primaryColor()
	);

	const handleMouseOver = () => {
		setIsHovering(true);
	};

	const handleMouseOut = () => {
		setIsHovering(false);
	};

	const uploadAvatar = async (file: File) => {
		if (!file) return;
		setUploadingAvatar(true);
		const attachment: FormData = new FormData();
		attachment.append('file', file, file?.name);
		attachment.append('fileName', file?.name);
		updateUserAvatar({
			data: attachment,
			fileName: file.name,
			userID: userInfo.id,
			organizationID: userInfo.currentOrganizationID,
		})
			.then((res) => {
				setUserInfo((userInfo) => ({
					...userInfo,
					userAvatarURL: res.data.data.baseURL,
					urls: res.data.data.urls,
				}));
				showSuccessNotification({
					message: 'Your avatar was updated successfully.',
				});
			})
			.catch(() => {
				showFailureNotification({
					message:
						"Sorry, we can't update your avatar right now. Please try again.",
				});
			})
			.finally(() => {
				setUploadingAvatar(false);
			});
	};

	return (
		<>
			<FileInput
				ref={uploadRef}
				style={{ display: 'none' }}
				onChange={(file: File) => uploadAvatar(file)}
			/>
			<Avatar
				onMouseOver={handleMouseOver}
				onMouseOut={handleMouseOut}
				color={'primary'}
				variant={'filled'}
				src={avatarURLWithCorrectBackgroundColor}
				size={size}
				radius={size}
				mx='auto'
				className={classes.avatar}
				onClick={() => uploadRef.current.click()}
			>
				<div
					className={
						isHovering || uploadingAvatar ? classes.overlayContainer : ''
					}
				>
					{isHovering && (
						<Text align={'center'} className={classes.overlayText}>
							+ Add Picture
						</Text>
					)}
					{uploadingAvatar && <Loader size={'md'} />}
				</div>
				{`${firstName?.substring(0, 1).toUpperCase()}${lastName
					?.substring(0, 1)
					.toUpperCase()}`}
			</Avatar>
		</>
	);
};
