import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { User } from './userAtoms';

const { persistAtom } = recoilPersist();

export interface Organization {
	createdAt: string;
	id: string;
	name: string;
	updatedAt: string;
	supportLinks?: SupportLink[];
	subscriptionId?: string | null;
	stripeCustomerId?: string | null;
}

export interface OrganizationStats {
	usersAmount: number;
	countByStatus: { [status: string]: number };
	countByPlatform: { [platform: string]: number };
}

export const defaultOrganization: Organization = {
	id: '',
	name: '',
	createdAt: '',
	updatedAt: '',
	supportLinks: [],
	subscriptionId: null,
	stripeCustomerId: null,
};

export interface SupportLink {
	email: string;
	id: string;
	organizationID: string;
	user: User;
	userID: string;
}

export const organizationAtom = atom({
	key: 'organizationAtom',
	default: defaultOrganization,
	// eslint-disable-next-line camelcase
	// effects_UNSTABLE: [persistAtom],
});

export const organizations = atom({
	key: 'organizations',
	default: [] as Organization[],
});

export const allSupportUsers = atom<User[]>({
	key: 'allSupportUsers',
	default: [],
});
