import { useEffect, useState } from 'react';
import OrganizationMembersTable from '../../../components/organization_members_table';
import {
	Title,
	Paper,
	Group,
	ActionIcon,
	Container,
	Space,
	Tabs,
	Modal,
	Stack,
	Select,
	Button,
	createStyles,
} from '@mantine/core';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { SupportMeetingsTable } from '../../../components/SupportMeetingsTable';
import {
	getAllOrganizations,
	getCurrentOrganization,
	getSupportUsers,
} from '../../../api/api';
import {
	allSupportUsers,
	Organization,
	organizationAtom,
	organizations,
} from '../../../Atoms/organizationAtom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { currentUser } from '../../../Atoms/userAtoms';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { statusChips } from '../../../components/constants';
import { logger } from '../../../helpers/logger';
import { IMeeting } from '../../../interfaces/meeting';
import { decode } from 'js-base64';
import { assignSupporterToOrganization } from '../../../api/organizations';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { useDisclosure } from '@mantine/hooks';
import {
	getOrganizationBrand,
	getOrganizationSettings,
} from '../../../api/settings';
import { BrandDetails, OrganizationSettings } from '../../../Atoms/settings';
import OrganizationOverview from './Tabs/OrganizationOverview';
import ReelayLogoPageLoader from '../../../components/loaders/ReelayLogoPageLoader';
import AdminBillingPage from './components/AdminBillingPage/AdminBillingPage';

const useStyles = createStyles((theme) => ({
	paper: {
		overflowY: 'auto',
	},
	tabPanel: {
		height: '100%',
	},
}));
export default function EditOrganization(props) {
	const { classes } = useStyles();
	const chipValue = statusChips.map((chip) => chip.value);
	const user = useRecoilValue(currentUser);
	const [searchParams] = useSearchParams();
	const { segmentValue } = props;
	const skipParam = searchParams.get('skip');
	const skip = isNaN(skipParam as unknown as number) ? 0 : Number(skipParam);
	const searchParam = searchParams.get('search');
	const order = searchParams.get('order') ?? '';
	const search = searchParam ? String(searchParam).trim() : '';
	const { organizationID } = useParams();
	const [opened, { open, close }] = useDisclosure(false);
	const [supportUsers, setSupportUsers] = useRecoilState(allSupportUsers);
	const [orgs, setOrgs] = useRecoilState(organizations);
	const [organizationSettings, setOrganizationSettings] =
		useState<OrganizationSettings>({});
	const [brand, setBrand] = useState<BrandDetails>({});
	const [value, setValue] = useState<string | null>(null);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [organization, setOrganization] =
		useRecoilState<Organization>(organizationAtom);
	const [loading, setLoading] = useState(true);
	const [meetingsBeingViewed, setMeetingsBeingViewed] = useState<IMeeting[]>(
		[]
	);

	const selectData = supportUsers
		.filter(
			(user) =>
				!organization?.supportLinks?.some((item) => item.userID === user.id)
		)
		.map((user) => ({
			label: `${user.firstName} ${user.lastName} - ${user.email}`,
			value: user.id,
		}));

	useEffect(() => {
		if (organizationID)
			handleGetOrganizationInfo(organizationID).then(() => setLoading(false));
	}, [organizationID]);

	useEffect(() => {
		const pageLoad = async () => {
			try {
				setLoading(true);
				// get the assigned user for this org.
				await getOrganizationsOnLoad();
				const res = await getSupportUsers();
				setSupportUsers(res);
			} catch (error) {
				logger('error', 'failed to getSuuportUsers', { error });
			} finally {
				setLoading(false);
			}
		};
		pageLoad();
	}, []);

	const getOrganizationsOnLoad = async () => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		const organizations = await getAllOrganizations();
		logger('info', 'Retrieved organizations', organizations);
		const orgData = organizations.data.data;
		const currentOrganization = orgData.find(
			(org) => org.id === organizationID
		);
		if (currentOrganization) {
			setOrganization(currentOrganization);
			const res = await getOrganizationSettings(currentOrganization.id);
			const settings = res.reduce((acc, curr) => {
				if (curr.key !== 'brand') acc[curr.key] = curr.value;
				return acc;
			}, {});
			setOrganizationSettings(settings);

			// get brand details
			const brandData = await getOrganizationBrand(currentOrganization.id);
			setBrand(
				brandData?.value || {
					name: currentOrganization.name,
				}
			);
		}
		setOrgs(orgData);
	};

	const handleGetOrganizationInfo = async (organizationID: string) => {
		try {
			// If I found an organization in my organizations atom then use that
			// else I will use org id to retrieve individual org.
			const foundOrg = orgs.find(
				(org: Organization) => org.id === organizationID
			);
			if (foundOrg) {
				setOrganization(foundOrg);
				const res = await getOrganizationSettings(foundOrg.id);
				const settings = res.reduce((acc, curr) => {
					if (curr.key !== 'brand') acc[curr.key] = curr.value;
					return acc;
				}, {});
				setOrganizationSettings(settings);
			} else {
				const orgResponse = await getCurrentOrganization(organizationID);
				setOrganization(orgResponse.data.data);
			}
		} catch (err) {
			logger('error', 'Error getting organization info', err);
		}
	};

	const assignSupportUser = async () => {
		try {
			setButtonLoading(true);
			if (!value) {
				showFailureNotification({
					message: 'Please select a support user before saving.',
				});
				return;
			}
			const res = await assignSupporterToOrganization(value, organizationID);
			if (res.code === 'ERR_BAD_REQUEST') {
				showFailureNotification({
					message:
						'Failed to assign support user. The user may already be assigned to this organization.',
				});
				return;
			}
			const organizations = await getAllOrganizations();
			logger('info', 'Retrieved organizations', organizations);
			const newOrganizations = organizations.data.data;
			const newOrganization = newOrganizations.find(
				(org) => org.id === organizationID
			);
			setOrgs(newOrganizations);
			setOrganization(newOrganization);
			showSuccessNotification({
				message: 'Successfully assigned new support user.',
			});
			close();
		} catch (error) {
			logger('error', 'failed to assignSupportUser', { error });
			showFailureNotification({
				message:
					'Failed to assign support user. The user may already be assigned to this organization.',
			});
		} finally {
			setButtonLoading(false);
		}
	};

	return (
		<Container
			size={'xl'}
			h={'100%'}
			style={{ display: 'flex', flexDirection: 'column' }}
		>
			<div id='sharing-modals'>
				<Modal
					centered
					withinPortal={false}
					opened={opened}
					onClose={close}
					title='Assign Support User'
					xOffset={300}
					styles={{
						inner: {
							position: 'absolute',
							right: 0,
							top: -300,
						},
					}}
				>
					<Stack>
						<Select
							value={value}
							onChange={setValue}
							defaultValue={value}
							data={selectData}
							placeholder='Select Support User'
						/>
						<Group position={'right'}>
							<Button onClick={assignSupportUser} loading={buttonLoading}>
								Save
							</Button>
							<Button variant={'outline'}>Cancel</Button>
						</Group>
					</Stack>
				</Modal>
			</div>
			<Group position={'left'}>
				<ActionIcon
					m={10}
					variant='transparent'
					component={Link}
					to={'/admin/organizations'}
				>
					<FontAwesomeIcon icon={faArrowLeft} />
				</ActionIcon>
				<Title weight={400} order={3}>
					{organization ? organization.name : 'No name found'}
				</Title>
			</Group>
			<Space h={'lg'} />
			<Tabs
				variant={'pills'}
				orientation={'vertical'}
				radius='md'
				defaultValue='overview'
				style={{
					overflowY: 'auto',
				}}
			>
				<Tabs.List m={'md'}>
					<Tabs.Tab value='overview'>Overview</Tabs.Tab>
					<Tabs.Tab value='members'>Members</Tabs.Tab>
					<Tabs.Tab value='meetings'>Meetings</Tabs.Tab>
					<Tabs.Tab value='billing'>Billing</Tabs.Tab>
				</Tabs.List>
				<Paper
					bg={'white'}
					p={'lg'}
					shadow={'lg'}
					radius={'md'}
					w={'100%'}
					h={'100%'}
					className={classes.paper}
				>
					<Tabs.Panel value='overview' pt='xs' className={classes.tabPanel}>
						{loading ? (
							<ReelayLogoPageLoader />
						) : (
							<OrganizationOverview
								open={open}
								organization={organization}
								organizationSettings={organizationSettings}
								setOrganizationSettings={setOrganizationSettings}
								brand={brand}
								setBrand={setBrand}
							/>
						)}
					</Tabs.Panel>

					<Tabs.Panel value='members' pt='xs' h={'100%'}>
						<OrganizationMembersTable
							organizationID={organizationID}
							auditSide={true}
							organizationSettings={organizationSettings}
							showSkipAudit={true}
						/>
					</Tabs.Panel>

					<Tabs.Panel value='meetings' pt='xs'>
						<Title order={3} mb='xl'>
							Meetings
						</Title>
						<SupportMeetingsTable
							isAdminTable={false}
							segmentValue={segmentValue}
							skip={skip}
							search={decode(search)}
							order={order}
						/>
					</Tabs.Panel>
					<Tabs.Panel value='billing' pt='xs'>
						<Title order={3} mb='xl'>
							Billing
						</Title>
						<AdminBillingPage />
					</Tabs.Panel>
				</Paper>
			</Tabs>
		</Container>
	);
}
