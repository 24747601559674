import React from 'react';
import {
  createStyles,
  Group,
  Stack,
  Text,
  Paper,
  useMantineTheme,
  Chip,
  MantineSizes,
  MantineSize,
} from '@mantine/core';

export interface CustomChipProps {
  color: string;
  size: MantineSize;
  value: string;
  label: string;
}

const useStyles = createStyles(() => ({
  iconWrapper: {
    display: 'none',
  },
  chipLabel: {
    padding: '0px 7.5px',
  },
}));

export default function CustomChip({
  color,
  size,
  value,
  label,
}: CustomChipProps) {
  const { classes } = useStyles();

  return (
    <Chip
      classNames={{
        iconWrapper: classes.iconWrapper,
        label: classes.chipLabel,
      }}
      size={size}
      value={value}
      color={color}
    >
      {label}
    </Chip>
  );
}
