import React from 'react';
import { Group, TextInput } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-light-svg-icons';
import { debounce } from 'lodash';

interface SearchBarProps {
	placeholder?: string;
	searchValue: string;
	// setSearchValue: (value: string) => void;
	handleSearchChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	width?: number;
	flex?: number;
}
const SearchBar = ({
	placeholder = 'Search',
	searchValue,
	handleSearchChange,
	width,
	flex,
}: SearchBarProps) => {
	return (
		<Group
			w={width}
			maw={1408}
			noWrap
			position={'apart'}
			align={'center'}
			style={{
				flex: flex,
			}}
		>
			<TextInput
				w={'100%'}
				placeholder={placeholder}
				icon={<FontAwesomeIcon icon={faMagnifyingGlass} size={'sm'} />}
				value={searchValue}
				onChange={(e) => handleSearchChange(e)}
			/>
		</Group>
	);
};

export default SearchBar;
