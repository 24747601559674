import React, { Dispatch, SetStateAction } from 'react';
import { Grid, Stack } from '@mantine/core';
import { IMeeting } from '../../interfaces/meeting';
import VerticalMeetingCard from '../VerticalMeetingCard';
import Introduction from '../../pages/meetings/components/Introduction';
import { useRecoilValue } from 'recoil';
import { hasCalendar } from '../../Atoms/integrations';
import SkeletonCard from '../Skeletons/SkeletonCard';

interface Props {
	meetings: IMeeting[];
	setMeetings: Dispatch<SetStateAction<IMeeting[]>>;
	searchValue: string;
	cardsPerPage: number;
	meetingCount: number;
	loading: boolean;
	handleGetMeetingsData?: any;
	segmentValue?: string;
	openSharingModal?: () => void;
}

const MeetingsCardView = ({
	meetings,
	setMeetings,
	searchValue,
	handleGetMeetingsData,
	loading,
	segmentValue,
	openSharingModal,
}: Props) => {
	const calendarIntegrated = useRecoilValue(hasCalendar);
	const meetingCountPerPage = 20;
	const skeletonCardArray = Array.from(
		{ length: meetingCountPerPage },
		(_, index) => index
	);

	return (
		<>
			{/*Meetings List View*/}
			{loading ? (
				<Stack w={'100%'} spacing={'xl'}>
					<Grid gutterSm={32} gutterXl={32} h={'100%'} pr={10}>
						{skeletonCardArray.map((idx) => (
							<SkeletonCard key={idx} />
						))}
					</Grid>
				</Stack>
			) : meetings.length ? (
				<Stack w={'100%'} spacing={'xl'}>
					<Grid gutterSm={32} gutterXl={32} h={'100%'} pr={10}>
						{meetings.map((meeting: IMeeting) => (
							<VerticalMeetingCard
								key={meeting.id}
								meeting={meeting}
								setMeetings={setMeetings}
								searchValue={searchValue}
								handleGetMeetingsData={handleGetMeetingsData}
								openSharingModal={openSharingModal}
							/>
						))}
					</Grid>
				</Stack>
			) : (
				<Introduction segmentValue={segmentValue} />
			)}
		</>
	);
};

export default MeetingsCardView;
