import React, { Dispatch, MutableRefObject, Ref, SetStateAction } from 'react';
import { Utterance } from '../../../../../helpers/data';
import { createStyles, Paper, Stack, Text, Group } from '@mantine/core';
import SpeakerInitialsAvatar from '../SpeakerInitialsAvatar';
import { getInitialsFromSpeakerName } from '../../../../../helpers/transcript';
import reactStringReplace from 'react-string-replace';
import { useRecoilValue } from 'recoil';
import {
	transcriptSearchQuery,
	videoPlayerCurrentTime,
} from '../../../../../Atoms/meetingAtom';
import {
	convertSecondsToHHMMSS,
	formatSecondsToMinutesAndSeconds,
} from '../../../../../_utils/time';
import { MILLISECONDS_PER_SECOND } from '../../../../constants';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
	playButtonClicked as playButtonClickedAtom,
	timestamp,
} from '../../../../../Atoms/meetingAtom';
import { useFlags } from 'flagsmith/react';

const useStyles = createStyles((theme) => ({
	transcript: {
		backgroundColor: theme.colors.primary[0],
		borderColor: theme.colors.primary[5],
		borderRadius: '16px',
		border: '1px',
		cursor: 'pointer',
	},
	active: {
		backgroundColor: 'rgba(79, 70, 229, 0.1)',
		borderColor: theme.colors.primary[5],
		borderRadius: '16px',
		border: '1px',
		cursor: 'pointer',
	},
	speakerName: {
		color: '#8E8D94',
		fontWeight: 400,
		fontSize: 10,
		lineHeight: '12px',
	},
	bodyText: {
		fontWeight: 400,
		lineHeight: '20px',
	},
}));

interface Props {
	utterance: Utterance;
	speakerColorHashTable: Record<string, string>;
	index: number;
	isAuditPage: boolean;
	activeIndex: number;
	setActiveIndex: Dispatch<SetStateAction<number>>;
}

const TranscriptText = React.forwardRef(
	(
		{
			utterance,
			speakerColorHashTable,
			index,
			isAuditPage,
			activeIndex,
			setActiveIndex,
		}: Props,
		ref: any
	) => {
		const { classes } = useStyles();
		const searchQuery = useRecoilValue(transcriptSearchQuery);
		const flags = useFlags(['audit_options']);
		const auditOptionsEnabled = flags?.audit_options?.enabled;
		const auditOptionsParsed = JSON.parse(flags?.audit_options?.value as any);
		const { transcript_readable } = !auditOptionsEnabled
			? {
				transcript_readable: false,
			}
			: auditOptionsParsed;
		const [playButtonClicked, setPlayButtonClicked] = useRecoilState(
			playButtonClickedAtom
		);
		const setTimestamp = useSetRecoilState(timestamp);

		const highlightText = (text: string | undefined) => {
			return reactStringReplace(text, searchQuery, (match, i) => (
				<span key={i} style={{ backgroundColor: 'yellow' }}>
					{match}
				</span>
			));
		};

		const updateVideoPlayerCurrentTime = (seconds: number) => {
			if (activeIndex !== index) {
				setTimestamp({ time: Math.floor(seconds) });
				setPlayButtonClicked(!playButtonClicked);
				setActiveIndex(index);
				ref?.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'start',
				});
			}
		};

		return (
			<Stack
				key={utterance.start}
				spacing={'xs'}
				className={`transcript-search-result-${index}`}
				ref={ref}
			>
				<Group position={'apart'}>
					<Group position={'left'} spacing={'xs'}>
						<SpeakerInitialsAvatar
							index={index}
							size={32}
							speakerName={utterance.speaker}
							speakerColor={speakerColorHashTable[utterance.speaker]}
							initials={getInitialsFromSpeakerName(utterance.speaker)}
						/>
						<Text className={classes.speakerName}>
							{searchQuery.length > 0
								? highlightText(utterance.speaker)
								: utterance.speaker}
						</Text>
					</Group>
					<Text
						color={'primary-text'}
						size={14}
						className={classes.bodyText}
						style={{
							cursor: 'pointer',
						}}
						onClick={() =>
							updateVideoPlayerCurrentTime(
								utterance.start / MILLISECONDS_PER_SECOND
							)
						}
					>
						{formatSecondsToMinutesAndSeconds(
							utterance.start / MILLISECONDS_PER_SECOND
						)}
					</Text>
				</Group>
				<Paper
					className={
						index === activeIndex ? classes.active : classes.transcript
					}
					p='sm'
					shadow={'xs'}
					onClick={() =>
						updateVideoPlayerCurrentTime(
							utterance.start / MILLISECONDS_PER_SECOND
						)
					}
				>
					<Text
						color={
							!isAuditPage || transcript_readable ? 'primary-text' : 'white'
						}
						size={14}
						className={classes.bodyText}
					>
						{searchQuery.length > 0
							? highlightText(utterance.text)
							: utterance.text}
					</Text>
				</Paper>
			</Stack>
		);
	}
);

export default TranscriptText;
