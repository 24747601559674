import React from 'react';
import { Button } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

interface Props {
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
  text: string;
}

export default function CreateButton({ setOpened, text }: Props) {
  return (
    <Button
      variant={'outline'}
      radius={'md'}
      leftIcon={<FontAwesomeIcon icon={faPlus} />}
      onClick={() => setOpened(true)}
      color={'primary'}
    >
      {text}
    </Button>
  );
}
