import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { user } from '../Atoms/userAtoms';
import { userSettings as userSettingsAtom } from '../Atoms/settings';
import { getUserSettings, saveUserSettings } from '../api/api';
import { destructureSettingsResponse } from '../helpers/settings/settings'; // Update with your actual utility functions
import { logger } from '../helpers/logger';

function useUserSettings(
	{
		doNotFireUseEffect = false,
	}: {
		doNotFireUseEffect: boolean;
	} = { doNotFireUseEffect: false }
) {
	const userInfo = useRecoilValue(user);
	const [userSettings, setUserSettings] = useRecoilState(userSettingsAtom);

	const updateUserSettings = async (payload: any, userID = '') => {
		// update user settings
		const updatedUserSettings = await saveUserSettings(
			userID ? userID : userInfo.id,
			payload
		);
		const newSettings = {
			...userSettings,
			...updatedUserSettings,
		};
		// update state
		setUserSettings(newSettings);
		return newSettings;
	};

	useEffect(() => {
		// fetch user settings
		const fetchUserData = async () => {
			try {
				// fetch user settings
				const userSettings = await getUserSettings(userInfo.id);
				// organize data
				const data = destructureSettingsResponse(userSettings);
				// save to state
				setUserSettings(data);
			} catch (error) {
				// Handle errors as needed
				logger('error', 'Error fetching user settings', error);
			}
		};

		// Call the fetchUserData function when the component mounts
		if (userInfo.id && !doNotFireUseEffect) {
			fetchUserData();
		}
	}, [userInfo.id, setUserSettings]);

	// You can return any additional data or functions that the components might need here
	return {
		userSettings,
		updateUserSettings,
	};
}

export default useUserSettings;
