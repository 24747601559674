import React, { useEffect, useState } from 'react';
import {
	Modal,
	TransferList,
	Select,
	Button,
	Loader,
	TransferListData,
	Group,
	Stack,
	SelectItem,
} from '@mantine/core';
import { searchMeetings } from '../../../api/api';
import { transferMeetingsOwner } from '../../../api/support';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { useDebouncedValue } from '@mantine/hooks';
import { userDefaultValue } from '..';
import { useRecoilValue } from 'recoil';
import { currentUser } from '../../../Atoms/userAtoms';

export default function TransferMeetingsModal({
	opened,
	setOpened,
	members: users,
	fromMember: initialFromUser,
	setFromMember,
	isDeleting,
	openRemoveUserFromOrganizationModal,
	removingFromAllOrganizations,
	membersHashMap,
}) {
	const user = useRecoilValue(currentUser);
	const [fromUser, setFromUser] = useState(initialFromUser.id);
	const [toUser, setToUser] = useState(null);
	const usersForSelecting = users.map((user) => {
		let label = '';
		if (user.firstName) label += user.firstName;
		if (user.lastName) label += `${label ? ' ' : ''}${user.lastName}`;
		if (!label) {
			label = user.email;
		} else {
			label += ` (${user.email})`;
		}
		return { label, value: user.id };
	});
	const fromUsersForSelecting = usersForSelecting.filter(
		({ value }) => value !== toUser
	);
	const toUsersForSelecting = usersForSelecting.filter(
		({ value }) => value !== fromUser
	);
	const [transferListSearchRaw, setTransferListSearchRaw] = useState<
		[string, string]
	>(['', '']);

	const debouncedSearch = useDebouncedValue(transferListSearchRaw, 300);
	useEffect(() => {
		const [[leftSearch]] = debouncedSearch;
		refreshMeetings(fromUser, leftSearch, true);
	}, [JSON.stringify(debouncedSearch[0])]);

	const [transferListData, setTransferListData] = useState<TransferListData>([
		[],
		[],
	]);
	const [loading, setLoading] = useState(true);
	const [transferButtonLoading, setTransferButtonLoading] = useState(false);
	useEffect(() => {
		if (!opened) {
			// reset the states please
			setTransferListData([[], []]);
			setFromUser(null);
			setToUser(null);
		}
	}, [opened]);

	useEffect(() => {
		if (opened) {
			setFromUser(initialFromUser.id);
			refreshMeetings(initialFromUser.id);
		}
	}, [initialFromUser, opened]);

	const refreshMeetings = async (
		userID: string,
		search?: string,
		isTriggeredBySearch = false
	) => {
		if (!userID) return;
		if (!isTriggeredBySearch) {
			setTransferListSearchRaw(['', '']);
		} else {
			setTransferButtonLoading(true);
		}

		const query = {
			userID,
			organizationID: user.currentOrganizationID,
			search,
			limit: 100 + transferListData[1].length,
		};

		try {
			const {
				data: { data: meetings },
			} = await searchMeetings(query);

			const transferredMeetings = isTriggeredBySearch
				? transferListData[1]
				: [];
			const existingMeetingsData = meetings
				.filter(
					({ id }) =>
						!transferListData[1].some((meeting) => id === meeting.value)
				)
				.map(({ id, name, publishedAt }) => {
					const formattedPublishedAt = formatDate(publishedAt);
					return {
						value: id,
						label: `${name} · ${formattedPublishedAt}`,
					};
				});
			setTransferListData([existingMeetingsData, transferredMeetings]);
		} catch (error) {
			console.error(error);
		} finally {
			setFromUser(userID);
			setLoading(false);
			setTransferButtonLoading(false);
		}
	};

	const handleTransfer = () => {
		if (!toUser || !fromUser || transferListData[1].length === 0) {
			return showFailureNotification({
				message:
					'Please select both source and receiving users and have at least 1 meeting transferred.',
			});
		}
		const fromUserObj = membersHashMap.get(fromUser);
		const toUserObj = membersHashMap.get(toUser);
		setTransferButtonLoading(true);
		const meetingIDs = transferListData[1].map(({ value }) => value);
		transferMeetingsOwner(meetingIDs, toUser, user.currentOrganizationID)
			.then((response) => {
				showSuccessNotification({
					title: 'Meeting transfer complete!',
					message: `You have successfully transferred meetings from ${fromUserObj.firstName} ${fromUserObj.lastName} to ${toUserObj.firstName} ${toUserObj.lastName}.`,
				});
				setTransferListData([[], []]);
				setToUser(null);
				setFromUser(null);
				setOpened(false);
				setFromMember(userDefaultValue);
				if (isDeleting) {
					// reopen the delete modal.
					openRemoveUserFromOrganizationModal(initialFromUser);
				}
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setTransferButtonLoading(false);
			});
	};

	const formatDate = (inputDate: Date | string | undefined) => {
		let date = inputDate;
		if (!date) return '';
		if (typeof date === 'string') date = new Date(date);
		const yearPublishedAt = date.getFullYear();
		const datePublishedAt = date.getDate();
		const monthPublishedAt = date.getMonth() + 1;
		let monthPrefix = '';
		if (monthPublishedAt < 10) monthPrefix = '0';
		return [
			`${monthPrefix}${monthPublishedAt}`,
			datePublishedAt,
			yearPublishedAt,
		].join('/');
	};

	const handleTransferListSearch = ([leftSearch, rightSearch]) => {
		setTransferListSearchRaw([leftSearch, rightSearch]);
	};

	const filteredTransferredMeetings = transferListData[1].filter(({ label }) =>
		label.toLowerCase().includes(transferListSearchRaw[1].toLowerCase())
	);

	return (
		<Modal
			size={'xl'}
			opened={opened}
			onClose={() => setOpened(false)}
			title='Transfer Meetings'
			styles={{
				inner: {
					'section:first-of-type': {
						height: 'auto !important',
						overflow: 'hidden',
					},
				},
			}}
		>
			<Stack>
				<Group position={'apart'}>
					<Select
						data={fromUsersForSelecting}
						value={fromUser}
						defaultValue={initialFromUser.id}
						onChange={refreshMeetings}
						placeholder='Select Source User'
						searchable
					/>
					<Select
						data={toUsersForSelecting}
						value={toUser}
						onChange={setToUser}
						placeholder='Select Receiver User'
						searchable
					/>
				</Group>
				{loading ? (
					<Loader />
				) : (
					<TransferList
						value={[transferListData[0], filteredTransferredMeetings]}
						onChange={setTransferListData}
						searchValues={transferListSearchRaw}
						onSearch={handleTransferListSearch}
						searchPlaceholder='Search...'
						nothingFound='Nothing found'
						titles={[`Source Meetings`, `Transferred Meetings`]}
						breakpoint='sm'
						radius={'lg'}
						transferAllMatchingFilter
						listHeight={400}
					/>
				)}
				<Button
					onClick={handleTransfer}
					disabled={transferButtonLoading}
					rightIcon={transferButtonLoading && <Loader />}
				>
					{transferButtonLoading ? '' : 'Transfer Meetings'}
				</Button>
			</Stack>
		</Modal>
	);
}
