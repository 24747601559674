import { useEffect, useState } from 'react';
import {
	Modal,
	TextInput,
	Button,
	Group,
	ActionIcon,
	Text,
	Stack,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconTrash, IconPlus } from '@tabler/icons-react';
import { addEmailAlias, deleteEmailAlias } from '../../../api/user';
import { User } from '../../../Atoms/userAtoms';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import ModalTitle from '../../Titles/ModalTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';

interface ManageAliasesModalProps {
	opened: boolean;
	onClose: () => void;
	organizationID: string;
	userAliases: string[];
	user: User;
}

const ManageAliasesModal = ({
	opened,
	onClose,
	organizationID,
	userAliases,
	user,
}: ManageAliasesModalProps) => {
	const [aliasList, setAliasList] = useState(userAliases);
	const [loading, setLoading] = useState(false);

	const form = useForm({
		initialValues: {
			newAlias: '',
		},
		validate: {
			newAlias: (value) =>
				/\S+@\S+\.\S+/.test(value) ? null : 'Invalid email address',
		},
	});

	useEffect(() => {
		setAliasList(userAliases);
	}, [userAliases]);

	const handleAddAlias = async (values: { newAlias: string }) => {
		setLoading(true);
		try {
			await addEmailAlias(user.id, organizationID, values.newAlias);
			setAliasList([...aliasList, values.newAlias]);
			form.reset();
			showSuccessNotification({
				message: `Email alias ${values.newAlias} added successfully.`,
			});
		} catch (error) {
			console.error('Failed to add email alias:', error);
			showFailureNotification({
				message: `Failed to add email alias: ${error.message}.`,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleRemoveAlias = async (alias: string) => {
		setLoading(true);
		try {
			await deleteEmailAlias(user.id, organizationID, alias);
			setAliasList(aliasList.filter((a) => a !== alias));
			showSuccessNotification({
				message: `Email alias ${alias} removed successfully.`,
			});
		} catch (error) {
			console.error('Failed to delete email alias:', error);
			showFailureNotification({
				message: `Failed to delete email alias: ${error.message}.`,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Modal
			opened={opened}
			onClose={onClose}
			title={<ModalTitle text={'Manage Email Aliases'} />}
			radius={'md'}
			padding={'lg'}
		>
			<div>
				<Text mb='sm'>Current Email Aliases:</Text>
				<Stack spacing='xs'>
					{aliasList.length === 0 ? (
						<Text>No aliases found</Text>
					) : (
						aliasList.map((alias, index) => (
							<Group key={index} spacing='xs' position='apart' noWrap>
								<Text>{alias}</Text>
								{alias !== user.email ? (
									<ActionIcon
										color='red'
										variant='transparent'
										onClick={() => handleRemoveAlias(alias)}
										title='Remove alias'
										disabled={loading}
									>
										<FontAwesomeIcon icon={faTrash} />
									</ActionIcon>
								) : (
									<Text fs={'italic'} fz={'sm'}>
										primary email
									</Text>
								)}
							</Group>
						))
					)}
				</Stack>

				<form onSubmit={form.onSubmit(handleAddAlias)}>
					<Stack mt='md' spacing={'xl'}>
						<TextInput
							label='New Email Alias'
							placeholder='Enter email alias'
							{...form.getInputProps('newAlias')}
						/>
						<Group position='right' noWrap>
							<Button
								type='submit'
								leftIcon={<IconPlus size={16} />}
								radius='md'
								loading={loading}
								disabled={loading}
							>
								Add
							</Button>
						</Group>
					</Stack>
				</form>
			</div>
		</Modal>
	);
};

export default ManageAliasesModal;
