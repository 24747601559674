import { useState, useEffect, useRef } from 'react';
import { NumberInputHandlers } from '@mantine/core';
import FreeTrial from './components/FreeTrial';
import SubscriptionManagement from './components/SubscriptionManagement';
import useStripe from '../../../../customHooks/useStripe';
import { ReelayLoader } from '../../../../components/ReelayLoader';
import { updateSeatsNumber } from '../../../../api/billing';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import SubscriptionCanceled from './components/SubscriptionCanceled';
import ManualSubscription from './components/ManualSubscription';

export default function Billing() {
	const { subscription, setSubscription, hasPaymentMethod, isLoading } =
		useStripe(true);
	const handlers = useRef<NumberInputHandlers>();
	const { isTrialing, seatsPurchased } = subscription;
	const [totalSeats, setTotalSeats] = useState<number>(seatsPurchased);
	const [seatDifference, setSeatDifference] = useState<number>(0);
	const [counterValue, setCounterValue] = useState<number>(seatsPurchased);
	const [buttonLoading, setButtonLoading] = useState(false);
	const subscriptionEnded = !isTrialing && !subscription?.renewal?.renewalDate;
	const isManual = subscription.type === 'manual' || !subscription?.id;

	const handleInputChange = (value: number) => {
		setSeatDifference(value - seatsPurchased);
		setCounterValue(value);
	};

	useEffect(() => {
		if (subscription?.id) {
			setTotalSeats(subscription.seatsPurchased);
			setCounterValue(subscription.seatsPurchased);
		}
	}, [subscription]);

	useEffect(() => {
		setSeatDifference(totalSeats - seatsPurchased);
	}, [totalSeats]);

	useEffect(() => {
		setCounterValue(seatsPurchased);
	}, [seatsPurchased]);

	const handleSeatChange = async () => {
		try {
			setButtonLoading(true);
			const res = await updateSeatsNumber({
				subscriptionId: subscription.id,
				seats: counterValue,
			});

			setSubscription({
				...subscription,
				...res?.subscription,
			});
			showSuccessNotification({
				message: 'Seats updated successfully.',
			});
		} catch (error) {
			showFailureNotification({
				message: 'Failed to update seats. Please try again or contact support.',
			});
		} finally {
			setButtonLoading(false);
		}
	};

	return (
		<>
			{isLoading ? (
				<ReelayLoader />
			) : isManual ? (
				<ManualSubscription />
			) : isTrialing && !hasPaymentMethod ? (
				<FreeTrial buttonLoading={buttonLoading} />
			) : subscriptionEnded ? (
				<SubscriptionCanceled buttonLoading={buttonLoading} />
			) : (
				<SubscriptionManagement
					handlers={handlers}
					handleInputChange={handleInputChange}
					handleSeatChange={handleSeatChange}
					seatDifference={seatDifference}
					counterValue={counterValue}
					buttonLoading={buttonLoading}
				/>
			)}
		</>
	);
}
