import React, { useState } from 'react';
import {
	Modal,
	Button,
	Group,
	Stack,
	Text,
	List,
	TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
	Organization,
	organizations,
	OrganizationStats,
} from '../../Atoms/organizationAtom';
import { logger } from '../../helpers/logger';
import { removeOrganization } from '../../api/organizations';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';

interface Props {
	organization: Organization;
	organizationStats: OrganizationStats;
	opened: boolean;
	close: () => void;
}

const platformLabels = {
	google_calendar: 'Google Calendar',
	microsoft_outlook: 'Microsoft Outlook',
};

export default function DeleteOrganizationModal({
	organization,
	organizationStats,
	opened,
	close,
}: Props) {
	const [loading, setLoading] = useState(false);
	const setOrganizations = useSetRecoilState(organizations);

	const form = useForm({
		initialValues: {
			organizationName: '',
		},
		validate: {
			organizationName: (value) =>
				value !== organization.name ? 'Organization name does not match' : null,
		},
	});

	async function deleteOrganization() {
		try {
			setLoading(true);
			const data = await removeOrganization(organization.id);
			if (data?.success) {
				showSuccessNotification({
					message: 'Organization was successfully deleted. Great work!',
				});
				setOrganizations((orgs) =>
					orgs.filter((org) => org.id !== organization.id)
				);
			} else {
				logger('error', 'Error deleting organization - ', data);
				showFailureNotification({
					message:
						'Sorry, there was an error when deleting an organization. Please try again.',
				});
			}
		} catch (err: unknown) {
			logger('error', 'Error deleting user - ', err);
			showFailureNotification({
				message:
					'Sorry, there was an error when deleting an organization. Please try again.',
			});
		} finally {
			setLoading(false);
			form.reset();
			close();
		}
	}

	return (
		<Modal
			opened={opened}
			centered={true}
			onClose={close}
			title='Are you sure?'
		>
			<form onSubmit={form.onSubmit(deleteOrganization)}>
				<Stack>
					<Text size='sm'>
						Removing an organization will result in a loss of data.
						<br />
						This organization currently has:
					</Text>
					{organizationStats !== null && (
						<List listStyleType={'circle'}>
							<List.Item>{`${organizationStats.usersAmount} users`}</List.Item>
							{Object.keys(organizationStats.countByStatus).map((status) => {
								const count = organizationStats.countByStatus[status];
								return (
									<List.Item>
										{count}
										{count > 0 ? ` ${status}` : ''} meetings
									</List.Item>
								);
							})}
							{Object.keys(organizationStats.countByPlatform).map(
								(platform) => {
									const count = organizationStats.countByPlatform[platform];
									return (
										<List.Item>
											{`${
												platformLabels[platform] || platform
											}: ${count} connected`}
										</List.Item>
									);
								}
							)}
							{organizationStats.countByPlatform.length === 0 && (
								<List.Item>No connected calendars</List.Item>
							)}
						</List>
					)}
					<Text size={'sm'} fw={500} fs={'underline'}>
						This action cannot be undone.
					</Text>{' '}
					<Text size={'sm'}>
						If you are sure you would like to perform this action then type the
						organization's name below:
					</Text>
					{organization && (
						<Text size={'sm'} fs={'italic'} data-autofocus>
							{organization.name}
						</Text>
					)}
					<TextInput
						placeholder={'Enter organization name here'}
						{...form.getInputProps('organizationName')}
					/>
					<Group position='apart'>
						<Button type={'submit'} loading={loading} color={'red'}>
							Delete All Data
						</Button>
						<Button variant={'outline'} onClick={close}>
							Keep Organization
						</Button>
					</Group>
				</Stack>
			</form>
		</Modal>
	);
}
