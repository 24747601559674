import React, { useState } from 'react';
import {
	ActionIcon,
	Button,
	Group,
	List,
	Paper,
	Stack,
	Text,
	TextInput,
} from '@mantine/core';
import { styles } from '../../../../../../settings/tabs/styles';
import { OrganizationSettings } from '../../../../../../../Atoms/settings';
import { logger } from '../../../../../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../../../helpers/notifications';
import { saveOrganizationSettings } from '../../../../../../../api/settings';
import { useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { useRecoilValue } from 'recoil';
import { currentUser } from '../../../../../../../Atoms/userAtoms';

interface Props {
	organizationSettings: OrganizationSettings;
	setOrganizationSettings: React.Dispatch<
		React.SetStateAction<OrganizationSettings>
	>;
	settingKey: string;
	successAddMessage: (value: string) => string;
	successRemoveMessage: (value: string) => string;
	description: string;
	placeholder: string;
}

export default function EmailDomainsCard({
	organizationSettings,
	setOrganizationSettings,
	settingKey,
	successAddMessage,
	successRemoveMessage,
	description,
	placeholder,
}: Props) {
	const { classes, theme } = styles();
	const { currentOrganizationID: organizationID } = useRecoilValue(currentUser);
	const settingValues = organizationSettings[settingKey] || [];
	const [value, setValue] = useState('');
	const [error, setError] = useState('');
	const [loading, setLoading] = useState(false);

	const handleAddValue = async (value: string) => {
		try {
			setLoading(true);
			const values = [...settingValues, value].map((val) =>
				val.toLowerCase().trim()
			);
			const payload = [
				{
					key: settingKey,
					value: values,
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				[details?.key]: details?.value,
			}));
			showSuccessNotification({
				message: successAddMessage(value),
			});
		} catch (error) {
			logger('error', `error adding ${settingKey}`, error);
			showFailureNotification({
				message: `An error occurred while adding a new ${settingKey}. Please try again.`,
			});
		} finally {
			setValue('');
			setLoading(false);
		}
	};

	const handleRemoveValue = async (value: string) => {
		try {
			setLoading(true);
			const values = settingValues
				.filter((val) => val !== value)
				.map((val) => val.toLowerCase().trim());
			const payload = [
				{
					key: settingKey,
					value: values,
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				[details?.key]: details?.value,
			}));
			showSuccessNotification({
				message: successRemoveMessage(value),
			});
		} catch (error) {
			logger('error', `error removing ${settingKey}`, error);
			showFailureNotification({
				message: `An error occurred while removing a ${settingKey}. Please try again.`,
			});
		} finally {
			setLoading(false);
		}
	};

	const validateValue = (value: string) => {
		if (!value.includes('.')) {
			setError('The value must contain a period "."');
			return false;
		} else if (settingValues.includes(value)) {
			setError('This value already exists');
			return false;
		}
		setError('');
		return true;
	};

	return (
		<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
			<Stack>
				<Text mb={4} size={'sm'}>
					{description}
				</Text>
				<List
					spacing='xs'
					size='sm'
					center
					icon={<FontAwesomeIcon color={theme.fn.primaryColor()} icon={faAt} />}
					styles={{
						itemWrapper: {
							width: '100%',
							'span:nth-child(2)': {
								flex: 2,
							},
						},
					}}
				>
					{settingValues.length ? (
						settingValues.map((val) => (
							<List.Item key={val}>
								<Group w={'100%'} position='apart'>
									<Text>{val}</Text>
									<ActionIcon
										variant='transparent'
										color='red'
										onClick={() => handleRemoveValue(val)}
									>
										<FontAwesomeIcon icon={faTrash} />
									</ActionIcon>
								</Group>
							</List.Item>
						))
					) : (
						<Text>No values added yet.</Text>
					)}
				</List>
				<Group noWrap align='center' w={'100%'} position='apart'>
					<TextInput
						icon={<FontAwesomeIcon icon={faAt} />}
						value={value}
						onChange={(event) => setValue(event.currentTarget.value)}
						placeholder={placeholder}
						withAsterisk
						w={'50%'}
						error={error}
					/>
					<Button
						disabled={!value.length}
						type={'submit'}
						variant={'filled'}
						radius={'xl'}
						color={'primary'}
						onClick={() => {
							if (validateValue(value)) {
								handleAddValue(value);
							}
						}}
						loading={loading}
					>
						Add
					</Button>
				</Group>
			</Stack>
		</Paper>
	);
}
