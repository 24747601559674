'use client';

import React, { useState } from 'react';
import {
	TextInput,
	Button,
	Stack,
	Text,
	Divider,
	Group,
	PinInput,
	Loader,
	Image,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
	microsoftIcon,
	privacyLink,
	termsLink,
} from '../../../components/constants';
import { useNavigate } from 'react-router';

interface LoginFormProps {
	logo: string;
	primaryColor: string;
	secondaryColor: string;
	handleSendAuthCode: (email: string) => void;
	handleAuthCode: (values: { authCode: string; email: string }) => void;
	brand?: { sso?: boolean };
	loginWithMicrosoft: () => void;
}

export function LoginForm({
	logo,
	primaryColor,
	secondaryColor,
	handleSendAuthCode,
	handleAuthCode,
	brand,
	loginWithMicrosoft,
}: LoginFormProps) {
	const navigate = useNavigate();
	const [showOTP, setShowOTP] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const form = useForm({
		initialValues: {
			email: '',
		},
		validate: {
			email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
		},
	});

	const otpForm = useForm({
		initialValues: {
			authCode: '',
		},
		validate: {
			authCode: (value) => (value.length === 6 ? null : 'OTP must be 6 digits'),
		},
	});

	const handleEmailSubmit = async (values: { email: string }) => {
		setIsLoading(true);
		await handleSendAuthCode(values.email);
		setIsLoading(false);
		setShowOTP(true);
	};

	const handleOTPSubmit = async (values: { authCode: string }) => {
		await handleAuthCode({
			authCode: values.authCode,
			email: form.values.email,
		});
	};

	const handleResendCode = () => {
		handleSendAuthCode(form.values.email);
	};

	if (!showOTP) {
		return (
			<Stack spacing='xl' style={{ width: '100%', maxWidth: '400px' }}>
				<Stack spacing='xs' align='center'>
					<Text size='xl' weight={600}>
						Sign in to your account
					</Text>
					<Text size='sm' color='dimmed' fw={500}>
						Enter your email below to sign in
					</Text>
				</Stack>

				<form onSubmit={form.onSubmit(handleEmailSubmit)}>
					<Stack spacing='md'>
						<TextInput
							required
							placeholder='name@example.com'
							{...form.getInputProps('email')}
							size='md'
						/>
						<Button type='submit' fullWidth loading={isLoading}>
							Continue
						</Button>
					</Stack>
				</form>

				{brand?.sso && (
					<>
						<Divider
							labelPosition='center'
							label={
								<Text size='lg' align='center' color='dimmed'>
									OR
								</Text>
							}
						/>

						<Button
							onClick={loginWithMicrosoft}
							variant='outline'
							fullWidth
							loading={isLoading}
							leftIcon={
								<Image
									maw={25}
									radius='md'
									src={microsoftIcon}
									alt='Microsoft logo'
								/>
							}
							styles={{
								inner: {
									justifyContent: 'flex-start',
								},
								label: {
									flex: 1,
								},
							}}
						>
							Sign In with Microsoft
						</Button>
					</>
				)}

				<Divider label='OR' labelPosition='center' />

				<Button
					variant='outline'
					fullWidth
					onClick={() => navigate('/register')}
				>
					Register for an account
				</Button>

				<Text size='xs' color='dimmed' align='center'>
					By clicking continue, you agree to our{' '}
					<Text
						component='a'
						href={termsLink}
						target='_blank'
						inherit
						underline
					>
						Terms of Service
					</Text>{' '}
					and{' '}
					<Text
						component='a'
						href={privacyLink}
						target='_blank'
						inherit
						underline
					>
						Privacy Policy
					</Text>
					.
				</Text>
			</Stack>
		);
	}

	return (
		<Stack spacing='xl' style={{ width: '100%', maxWidth: '400px' }}>
			<Stack spacing='xs' align='center'>
				<Text size='xl' weight={600}>
					Enter verification code
				</Text>
				<Text size='sm' color='dimmed' align='center' fw={500}>
					Enter the one-time password you received in your email below.
				</Text>
			</Stack>

			<form onSubmit={otpForm.onSubmit(handleOTPSubmit)}>
				<Stack
					spacing='md'
					style={{ width: '100%', maxWidth: '352px', margin: '0 auto' }}
				>
					<PinInput
						length={6}
						type='number'
						{...otpForm.getInputProps('authCode')}
						size='md'
						style={{ width: '100%', justifyContent: 'space-between' }}
						onComplete={(value) => {
							otpForm.setFieldValue('authCode', value);
							handleOTPSubmit({ authCode: value });
						}}
					/>
					<Button
						type='submit'
						fullWidth
						styles={(theme) => ({ root: { backgroundColor: primaryColor } })}
					>
						Verify
					</Button>
				</Stack>
				<Group position='center' spacing={0} noWrap mt={'xl'}>
					<Text size='sm' color='dimmed'>
						Didn't receive a code?
					</Text>
					<Button variant='subtle' compact onClick={handleResendCode}>
						Click to resend
					</Button>
					<Text size='sm' color='dimmed'>
						or
					</Text>
					<Button variant='subtle' compact onClick={() => setShowOTP(false)}>
						go back
					</Button>
				</Group>
			</form>
		</Stack>
	);
}
