import { styles } from '../styles';
import {
	Stack,
	Box,
	Text,
	Group,
	RingProgress,
	Divider,
	Button,
	Title,
	Card,
	rem,
	useMantineTheme,
	List,
	SimpleGrid,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCalendar,
	faExclamationTriangle,
	faCheckCircle,
} from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue } from 'recoil';
import { DateTime } from 'luxon';
import { currentUser } from '../../../../../Atoms/userAtoms';

import useStripe from '../../../../../customHooks/useStripe';
import { organizationAtom } from '../../../../../Atoms/organizationAtom';
import { sendSlackNeedMoreTimeNotification } from '../../../../../api/organizations';
import {
	HOURS_PER_DAY,
	MILLISECONDS_PER_SECOND,
	MINUTES_PER_HOUR,
	SECONDS_PER_MINUTE,
} from '../../../../../components/constants';
import { pricingTiers } from '../constants';

interface Props {
	buttonLoading: boolean;
}

const FreeTrial = ({ buttonLoading }: Props) => {
	const { classes } = styles();
	const theme = useMantineTheme();
	const user = useRecoilValue(currentUser);
	const organization = useRecoilValue(organizationAtom);
	const { subscription, hasPaymentMethod } = useStripe(false);

	const { seatsAssigned, trialStart, trialEnd, isTrialing } = subscription;

	const selectedTier =
		seatsAssigned <= 50
			? pricingTiers[0]
			: seatsAssigned <= 199
			? pricingTiers[1]
			: pricingTiers[2];

	const trialEndDate = DateTime.fromMillis(trialEnd).toLocaleString(
		DateTime.DATE_FULL
	);

	const trialDaysLeft = Math.ceil(
		(trialEnd - Date.now()) /
			(MILLISECONDS_PER_SECOND *
				SECONDS_PER_MINUTE *
				MINUTES_PER_HOUR *
				HOURS_PER_DAY)
	);
	const trialDuration = Math.ceil(
		(trialEnd - trialStart) /
			(MILLISECONDS_PER_SECOND *
				SECONDS_PER_MINUTE *
				MINUTES_PER_HOUR *
				HOURS_PER_DAY)
	);
	const trialProgress = (trialDaysLeft / trialDuration) * 100;

	const ringSections = [
		{
			value: trialProgress,
			color: theme.colors.primary[6],
		},
		{
			value: 100 - trialProgress,
			color: theme.colors.gray[3],
		},
	];

	const handleSubscription = async () => {
		window.open(process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL, '_blank');
	};

	const handleNeedMoreTime = async () => {
		const res = await sendSlackNeedMoreTimeNotification({
			requestor_email: user.email,
			requestor_name: `${user.firstName} ${user.lastName}`,
			organization: organization.name,
		});
	};

	return (
		<Stack h={'100%'}>
			<Box className={classes.header}>
				<Group position='apart' align='center'>
					<Title order={3} className={classes.primaryText}>
						Reelay Trial Information
					</Title>
					<Button
						component='a'
						color='red'
						variant='outline'
						onClick={() =>
							window.open(
								process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL,
								'_blank'
							)
						}
					>
						Cancel Subscription
					</Button>
				</Group>
				<Text className={classes.secondaryText}>
					Manage your subscription and billing details
				</Text>
				<Text className={classes.secondaryText}>
					Your free trial status and subscription options
				</Text>
			</Box>
			<Box className={classes.content}>
				<Group className={classes.grid} spacing='lg'>
					<Box className={classes.section}>
						<Text className={classes.sectionTitle}>Trial Status</Text>
						<Box className={classes.chartContainer}>
							<RingProgress
								size={200}
								thickness={20}
								sections={ringSections}
								label={
									<Text align='center' size='lg' weight={700}>
										{trialDaysLeft} days left
									</Text>
								}
							/>
						</Box>
						<Text align='center' size='sm' mt='sm'>
							{trialDaysLeft} out of {trialDuration} days remaining
						</Text>
					</Box>

					<Box className={classes.section}>
						<Group
							className={classes.sectionTitle}
							position='apart'
							noWrap
							align='center'
						>
							<Text>Trial End Date</Text>
							<Button onClick={handleNeedMoreTime} variant='subtle'>
								Need more time?
							</Button>
						</Group>
						<Box className={classes.billingInfo}>
							<Group spacing='xs' noWrap align='center'>
								<FontAwesomeIcon icon={faCalendar} />
								<Text>{trialEndDate}</Text>
							</Group>

							<Text className={classes.sectionTitle} mb={0}>
								What happens after the trial?
							</Text>
							{isTrialing && !hasPaymentMethod ? (
								<Stack spacing={'xs'}>
									<Text size='sm' color='dimmed'>
										If you don't subscribe before your trial ends:
									</Text>
									<List spacing={0}>
										<List.Item>
											<Text size='sm' color='dimmed'>
												You'll lose the ability to add Reelay to new meetings
											</Text>
										</List.Item>
										<List.Item>
											<Text size='sm' color='dimmed'>
												You'll retain access to view existing meetings for 60
												days
											</Text>
										</List.Item>
										<List.Item>
											<Text size='sm' color='dimmed'>
												All data will be deleted after 60 days
											</Text>
										</List.Item>
									</List>
								</Stack>
							) : (
								<Stack spacing={'xs'}>
									<Text size='sm' color='dimmed'>
										Your account will automatically convert to a paid
										subscription on {trialEndDate}.
									</Text>
									<List spacing={0}>
										<List.Item>
											<Text size='sm' color='dimmed'>
												You'll continue to have full access to Reelay for all
												your meetings
											</Text>
										</List.Item>
										<List.Item>
											<Text size='sm' color='dimmed'>
												Your card will be charged based on your current usage
												and selected plan
											</Text>
										</List.Item>
									</List>
								</Stack>
							)}
						</Box>
					</Box>
				</Group>
				<Divider />
				<Box className={classes.pricing}>
					<Text className={classes.sectionTitle}>Pricing Tiers</Text>
					<SimpleGrid cols={pricingTiers.length} spacing='md' mt='md'>
						{pricingTiers.map((tier, index) => {
							const isSelected = selectedTier.min === tier.min;
							return (
								<Card
									key={index}
									withBorder
									shadow='sm'
									padding='md'
									radius='md'
									style={{
										borderColor: isSelected
											? theme.colors.primary[6]
											: theme.colors.gray[3],
									}}
								>
									<Text weight={500}>
										{tier.min}-{tier.max === Infinity ? '+' : tier.max} seats
									</Text>
									{isSelected && (
										<Text
											fz={'sm'}
											c={'dimmed'}
										>{`Seats assigned: ${seatsAssigned}`}</Text>
									)}

									<Text size='xl' weight={700} mt='sm'>
										${tier.price}/user/month
									</Text>
								</Card>
							);
						})}
					</SimpleGrid>

					{hasPaymentMethod ? (
						<Box
							mt='xl'
							p='md'
							style={{
								backgroundColor: theme.colors.green[1],
								borderRadius: theme.radius.md,
							}}
						>
							<Group align='flex-start' noWrap>
								<FontAwesomeIcon
									icon={faCheckCircle}
									style={{ marginTop: rem(2), color: theme.colors.green[7] }}
								/>
								<Text size='sm'>
									Your payment method has been successfully added. Your
									subscription will start automatically on {trialEndDate}.
								</Text>
							</Group>
						</Box>
					) : (
						<Box
							mt='xl'
							p='md'
							style={{
								backgroundColor: theme.colors.gray[1],
								borderRadius: theme.radius.md,
							}}
						>
							<Group align='flex-start' noWrap>
								<FontAwesomeIcon
									icon={faExclamationTriangle}
									style={{ marginTop: rem(2), color: theme.colors.yellow[7] }}
								/>
								<Text size='sm'>
									Remember, subscribing now will ensure uninterrupted access to
									Reelay for all your meetings. Don't risk losing valuable
									insights!
								</Text>
							</Group>
						</Box>
					)}
					<Group mt='xl' grow>
						{hasPaymentMethod && isTrialing ? (
							<Button
								size='lg'
								className={classes.updateButton}
								color='primary'
								onClick={handleSubscription}
								loading={buttonLoading}
							>
								Manage Subscription
							</Button>
						) : (
							<Button
								size='lg'
								className={classes.updateButton}
								color='primary'
								onClick={handleSubscription}
								loading={buttonLoading}
							>
								Subscribe Now
							</Button>
						)}
					</Group>
				</Box>
			</Box>
		</Stack>
	);
};

export default FreeTrial;
