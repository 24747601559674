import React, { useEffect } from 'react';
import {
	Button,
	Divider,
	Group,
	Modal,
	Space,
	Stack,
	TextInput,
	Title,
} from '@mantine/core';
import { UsersMultiSelect } from '../../UsersMultiSelect';

/**
 * This modal can be used for any array of users ADD/EDIT/DELETE functionality
 * Used on teams page and collections page.
 */

interface Props {
	opened: boolean;
	setOpened: (opened: boolean) => void;
	handleUpdate: (values: any) => void;
	title: string;
	buttonText: string;
	data: { value: string; label: string }[];
	form: any;
	name: string;
	loading: boolean;
	showTeamName?: boolean;
	label?: string;
	placeholder?: string;
}

export default function UsersModal({
	opened,
	setOpened,
	handleUpdate,
	title,
	buttonText,
	name,
	data,
	form,
	loading,
	showTeamName = true,
	label = 'Teammates',
	placeholder = 'Select Teammates',
}: Props) {
	return (
		<Modal
			h={400}
			title={
				<Title order={4} weight={400}>
					{title}
				</Title>
			}
			opened={opened}
			onClose={() => setOpened(false)}
			styles={{
				content: {
					height: 400,
				},
			}}
		>
			<>
				<Divider size='xs' />
				<form onSubmit={form.onSubmit((values) => handleUpdate(values))}>
					{showTeamName ? (
						<>
							<Space h={'xl'} />
							<TextInput
								required
								error='Team Name required'
								placeholder='Team name'
								{...form.getInputProps('name')}
							/>
						</>
					) : null}

					<Space h={20} />
					<Stack justify={'space-between'}>
						{' '}
						<UsersMultiSelect
							error='Teammates are required!'
							data={data}
							placeholder={placeholder}
							{...form.getInputProps(name)}
							name={name}
							form={form}
							labelText={label}
						/>
						{/*<Space h={'xl'} />*/}
						<Group position={'right'}>
							<Button radius={'md'} type='submit' loading={loading}>
								{buttonText}
							</Button>
						</Group>
					</Stack>
				</form>
			</>
		</Modal>
	);
}
