import {
	createStyles,
	Stack,
	Title,
	Text,
	AspectRatio,
	Card,
	Avatar,
	Group,
	Badge,
	BackgroundImage,
} from '@mantine/core';
import theme from '../../_utils/theme';
import { createVideoThumbnail } from '../../helpers/data';
import { IMeeting } from '../../interfaces/meeting';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import { calculateTimeStamp } from '../../_utils/time';
import { useSetRecoilState } from 'recoil';
import { currentHost } from '../../Atoms/userAtoms';
import { isMeetingExpired } from '../../helpers/meetings';
import { StatusBadge } from '../Badges/StatusBadge';
import { swapBackgroundColor } from '../../_utils/handy-functions';
import { formatDeadlineTime } from '../../helpers/date';

const useStyles = createStyles(() => ({
	card: {
		padding: 0,
		margin: 0,
		overflow: 'visible',
	},
	ellipsis: {
		color: theme.colors?.['primary'],
	},
	timestampContainer: {
		backgroundColor: 'black',
		opacity: 0.8,
		color: 'white',
		marginBottom: '2.5%',
		marginRight: '1.5%',
		borderRadius: '4px',
		padding: 3,
	},
	badgeContainer: {
		marginBottom: '2.5%',
		marginLeft: '1.5%',
	},
	title: {
		fontWeight: 500,
		lineHeight: 1.25,
	},
}));

interface Props {
	meeting: IMeeting;
}

export default function MobileVideoCard({ meeting }: Props) {
	const { classes, theme } = useStyles();
	const navigate = useNavigate();
	const setCurrentHost = useSetRecoilState(currentHost);
	const imageURL = createVideoThumbnail(
		meeting?.videoMetadata?.playbackID,
		meeting?.thumbnailTime
	);

	const handleCardClick = () => {
		navigate(`/meetings/${meeting.friendlyID}`);
	};

	/**
	 * set the current host being viewed.
	 */
	const handleHostClick = () => {
		setCurrentHost(meeting.author);
	};

	const dueSoon = (meetingDeadline: string) => {
		const oneDay = new Date().getTime() + 1 * 24 * 60 * 60 * 1000;
		return (
			new Date(meetingDeadline) < new Date(oneDay) &&
			new Date(meetingDeadline) > new Date()
		);
	};

	let avatarImageURL = `${meeting.author.userAvatarURL}-512.png`;
	avatarImageURL = swapBackgroundColor(avatarImageURL, theme.fn.primaryColor());
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return (
		<>
			{meeting && (
				<Card className={classes.card} shadow='sm'>
					<Stack>
						<Card.Section onClick={handleCardClick}>
							<AspectRatio ratio={16 / 9}>
								{/*<Image src={imageURL} alt="With default placeholder" withPlaceholder/>*/}
								<BackgroundImage src={imageURL}>
									<Stack
										justify={'flex-end'}
										align={'stretch'}
										h={'100%'}
										w={'100%'}
									>
										<Group
											position={'apart'}
											align={'center'}
											pt={'2.5%'}
											pr={'1.5%'}
										>
											{dueSoon(meeting.viewingDeadline) ? (
												<Badge
													variant={'filled'}
													size={'xs'}
													className={classes.badgeContainer}
												>
													Due Soon
												</Badge>
											) : isMeetingExpired(meeting.viewingDeadline) ? (
												<StatusBadge
													variant={'filled'}
													status={'expired'}
													botStatus={meeting?.botMetadata?.status}
												/>
											) : (
												<StatusBadge
													variant={'filled'}
													status={meeting.status}
													botStatus={meeting?.botMetadata?.status}
												/>
											)}
											<Text fz={'xs'} className={classes.timestampContainer}>
												{calculateTimeStamp(meeting?.videoMetadata?.duration)}
											</Text>
										</Group>
										{/*<Group position={'right'} align={'center'}>*/}
										{/* */}
										{/*</Group>*/}
									</Stack>
								</BackgroundImage>
							</AspectRatio>
						</Card.Section>
						<Stack>
							<Group position={'apart'} align={'flex-start'} noWrap>
								<Group noWrap align={'flex-start'}>
									<Avatar
										component={Link}
										onClick={handleHostClick}
										size='md'
										src={avatarImageURL}
										alt='host image'
										radius='xl'
										to={`/users/${meeting?.author?.id}`}
									>
										{`${meeting?.author?.firstName?.substring(
											0,
											1
										)}${meeting?.author?.lastName?.substring(0, 1)}`}
									</Avatar>
									<Stack spacing={0}>
										<Group>
											<Title className={classes.title} size={14} lineClamp={2}>
												{meeting.name}
											</Title>
										</Group>
										{/*//add views data*/}
										<Text size={12} c='dimmed'>{`${
											meeting?.author?.firstName
										} ${meeting?.author?.lastName} • ${
											meeting.views || 0
										} views • ${
											meeting.status === 'scheduled'
												? formatDeadlineTime(meeting?.botMetadata?.joinAt)
												: meeting.viewingDeadline
												? formatDeadlineTime(meeting.viewingDeadline)
												: 'N/A'
										}`}</Text>
									</Stack>
								</Group>
							</Group>
						</Stack>
					</Stack>
				</Card>
			)}
		</>
	);
}
