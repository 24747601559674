import React, { useEffect, useState } from 'react';
import { Modal, Button, TextInput, Group, Switch } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useRecoilState, useRecoilValue } from 'recoil';
import { User, userOrganizationMembers } from '../../../Atoms/userAtoms';
import { roles } from '../../constants';
import { UsersMultiSelect } from '../../UsersMultiSelect';
import { logger } from '../../../helpers/logger';
import { updateOrganizationUser } from '../../../api/api';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { stripeSubscriptionAtom } from '../../../Atoms/billing';
import { activateSeat, assignSeat, deactivateSeat } from '../../../api/billing';
import { setUser } from '@sentry/browser';
import { UserRoles } from '../../../interfaces/user';

interface Props {
	member: User;
	opened: boolean;
	setOpened: (opened: boolean) => void;
}

export default function EditUserModal({ member, opened, setOpened }: Props) {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>();
	const [subscription, setSubscription] = useRecoilState(
		stripeSubscriptionAtom
	);
	const [users, setUsers] = useRecoilState(userOrganizationMembers);
	const { id: subscriptionId } = subscription;

	const form = useForm({
		initialValues: {
			firstName: '',
			lastName: '',
			email: '',
			roles: [],
		},
		validate: {
			firstName: (value) => (value?.length ? null : 'Invalid first name'),
			lastName: (value) => (value?.length ? null : 'Invalid last name'),
			email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
			roles: (value) => (value?.length ? null : 'A role is required.'),
		},
	});

	useEffect(() => {
		form.setValues({
			firstName: member.firstName,
			lastName: member.lastName,
			email: member.email,
			roles: member.roles,
		});
	}, [member]);

	async function updateUser(data: any) {
		setLoading(true);
		try {
			let seat;
			// if there is a subscription, we need to remove a seat if the user lost their create-meeting role.
			if (
				subscriptionId &&
				!data.roles.includes(UserRoles.CreateMeeting) &&
				member.roles.includes(UserRoles.CreateMeeting)
			) {
				seat = await deactivateSeat({ subscriptionId, userId: member.id });

				setSubscription((prev) => ({
					...prev,
					seatsAssigned: prev.seatsAssigned - 1,
				}));
			}
			// if there is a subscription, we need to add a seat if the user gained the create-meeting role.
			else if (
				subscriptionId &&
				data.roles.includes(UserRoles.CreateMeeting) &&
				!member.roles.includes(UserRoles.CreateMeeting)
			) {
				// check if you have enough seats in the first place.
				if (
					subscription.seatsAssigned >= subscription.seatsPurchased &&
					subscriptionId
				) {
					showFailureNotification({
						message:
							'Sorry, it looks like you are out of seats… to invite this user please increase your available seats.',
					});
					return;
				}
				// first check for an existing inactive seat.
				const hasSeat = member?.seat?.id;

				if (hasSeat) {
					seat = await activateSeat({ subscriptionId, userId: member.id });
				} else {
					seat = await assignSeat({ subscriptionId, userId: member.id });
				}
				setSubscription((prev) => ({
					...prev,
					seatsAssigned: prev.seatsAssigned + 1,
				}));
			}

			const updatedUser = await updateOrganizationUser(
				data,
				member.id,
				member.currentOrganizationID
			);
			const newUser = updatedUser.data.data;

			setUsers(
				users.map((user) =>
					user.id === newUser.id
						? {
								...user,
								...newUser,
								seat: seat ? seat : user.seat,
						  }
						: user
				)
			);

			showSuccessNotification({
				message: 'User was successfully updated! Great work!',
			});
		} catch (err: unknown) {
			logger('error', 'Error updating user', err);
			showFailureNotification({
				message:
					'Sorry, there was an error when updating the user. Please try again.',
			});
		} finally {
			setLoading(false);
			setOpened(false);
		}
	}

	function compareArrays(array1, array2) {
		if (array1.length !== array2.length) {
			return false;
		}

		// Sort both arrays to ensure the elements are in the same order
		const sortedArray1 = array1.slice().sort();
		const sortedArray2 = array2.slice().sort();

		// Iterate over the elements and compare them
		for (let i = 0; i < sortedArray1.length; i++) {
			if (sortedArray1[i] !== sortedArray2[i]) {
				return false;
			}
		}
		return true;
	}

	return (
		<Modal
			opened={opened}
			centered={true}
			onClose={() => setOpened(false)}
			title='Edit Member'
		>
			<form onSubmit={form.onSubmit((values) => updateUser(values))}>
				<TextInput
					mb='sm'
					label={'First Name'}
					{...form.getInputProps('firstName')}
				/>
				<TextInput
					mb='sm'
					label={'Last Name'}
					{...form.getInputProps('lastName')}
				/>
				<TextInput mb='sm' label={'Email'} {...form.getInputProps('email')} />
				<UsersMultiSelect
					errorText='A role is required.'
					data={roles}
					labelText={'Roles'}
					placeholder='Select Roles'
					name={'roles'}
					form={form}
				/>
				<Group mt={'md'} position={'right'}>
					<Button radius={'md'} type='submit' loading={loading}>
						Save
					</Button>
				</Group>
			</form>
		</Modal>
	);
}
