import { Select, useMantineTheme } from '@mantine/core';
import { Dispatch, SetStateAction } from 'react';
import { timezones } from '../../../helpers/timezone';
import React from 'react';

interface Props {
	value?: string | null;
	setValue?: Dispatch<SetStateAction<string | null>>;
	form?: any;
	showLabel?: boolean;
	label?: string;
	width?: number | string;
}

export default function TimezoneSelect({
	value,
	setValue,
	form,
	showLabel = true,
	width = 'auto',
	label = 'Time zone',
}: Props) {
	const theme = useMantineTheme();

	const safeToLower = (text: any) =>
		typeof text === 'string' ? text.toLowerCase() : '';

	if (form)
		return (
			<Select
				label={label}
				data={timezones}
				{...form.getInputProps('time_zone')}
				searchable
				maxDropdownHeight={400}
				nothingFound='Time zone not found.'
				filter={(value, item) =>
					safeToLower(item.value).includes(safeToLower(value).trim()) ||
					safeToLower(item.label).includes(safeToLower(value).trim())
				}
				styles={(theme) => ({
					input: {
						backgroundColor: theme.colors.primary[0],
					},
					root: {
						width,
					},
					label: {
						fontSize: theme.fontSizes.lg,
					},
				})}
			/>
		);

	return (
		<Select
			label={label}
			data={timezones}
			value={value}
			onChange={setValue}
			searchable
			maxDropdownHeight={400}
			nothingFound='Time zone not found.'
			filter={(value, item) =>
				safeToLower(item.value).includes(safeToLower(value).trim()) ||
				safeToLower(item.label).includes(safeToLower(value).trim())
			}
			styles={(theme) => ({
				input: {
					backgroundColor: theme.colors.primary[0],
				},
				root: {
					width,
				},
				label: {
					fontSize: theme.fontSizes.lg,
				},
			})}
		/>
	);
}
