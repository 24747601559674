import React, { useState } from 'react';
import { showFailureNotification } from '../../../helpers/notifications';
import { createSubscription } from '../../../api/billing';
import {
	Button,
	Container,
	Stack,
	Text,
	Title,
	List,
	Anchor,
	createStyles,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
	input: {
		backgroundColor: '#F5F5F5',
		borderRadius: theme.radius.sm,
	},
	helpText: {
		fontStyle: 'italic',
		fontSize: 13,
		fontWeight: 400,
		color: theme.fn.primaryColor(),
		textDecoration: 'underline',
		marginTop: theme.spacing.sm,
		width: '100%',
		cursor: 'pointer',
		marginLeft: 'auto',
		marginRight: 'auto',
		[`@media (min-width: ${theme.breakpoints.md}px)`]: {
			width: '70%',
		},
	},
	mainTitle: {
		fontSize: 32,
		fontWeight: 500,
		marginBottom: '1.5rem',
		[`@media (max-width: ${theme.breakpoints.sm})`]: {
			fontSize: 24,
			marginBottom: 0,
		},
	},
	listItem: {
		'& strong': {
			color: theme.colors.primary[6],
		},
		fontSize: theme.fontSizes.md,
		fontWeight: 500,
		marginBottom: '1rem',
	},
}));

interface SubscriptionSetupProps {
	organizationID: string;
}

const SubscriptionSetup: React.FC<SubscriptionSetupProps> = ({
	organizationID,
}) => {
	const { classes } = useStyles();
	const [loading, setLoading] = useState(false);

	const handleSetUpSubscription = async () => {
		try {
			setLoading(true);
			const session = await createSubscription({
				organizationId: organizationID,
				planId: process.env.REACT_APP_STRIPE_PRICE_ID,
				numberOfSeats: 1,
			});

			window.location.href = session.url; // Redirect to Stripe checkout page
		} catch (error: any) {
			console.error('Failed to create subscription session:', error);
			showFailureNotification({
				title: 'Failed to create subscription session',
				message:
					error?.message ||
					'An error occurred while creating subscription session',
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Container>
			<Stack spacing={0} p='xl' py={'md'}>
				<Title className={classes.mainTitle}>Terms of Trial</Title>
				<div className='w-full'>
					<div>
						<List spacing='sm' size='sm' withPadding>
							<List.Item className={classes.listItem}>
								<strong>30-Day Unlimited Trial:</strong> Enjoy full access to
								all Reelay features during your trial, including unrestricted
								team member usage and collaboration.
							</List.Item>
							<List.Item className={classes.listItem}>
								<strong>No Storage Limits:</strong> Experiment freely with no
								caps on storage for the trial period.
							</List.Item>
							<List.Item className={classes.listItem}>
								<strong>No Commitment:</strong> No payment is required upfront.
								Simplify upgrade to a subscription at the end of the trial if
								you love Reelay.
							</List.Item>
							<List.Item className={classes.listItem}>
								<strong>Cancellation:</strong> Cancel anytime during the trial
								period without incurring charges.
							</List.Item>
							<List.Item className={classes.listItem}>
								<strong>Feedback:</strong> Give us feedback on what you love or
								would like improved through{' '}
								<Anchor
									href='mailto:support@reelay.com'
									className='text-primary-foreground underline'
								>
									support@reelay.com
								</Anchor>
							</List.Item>
						</List>
					</div>

					<Button
						onClick={handleSetUpSubscription}
						fullWidth
						mt='lg'
						size='md'
						radius={'md'}
						loading={loading}
					>
						Accept, and connect your calendar
					</Button>
				</div>
			</Stack>
		</Container>
	);
};

export default SubscriptionSetup;
