export interface Setting {
  key: string;
  value?: any;
  stringValue?: string;
  objectValue?: object;
}

// return an object with key value pairs
export const destructureSettingsResponse = (settings: Setting[]) => {
  return settings.reduce((acc, setting) => {
    acc[setting.key] = setting.value;
    return acc;
  }, {});
};
