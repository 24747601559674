import { useMantineTheme } from '@mantine/core';
import { useMediaQuery } from 'react-responsive';

// mantine theme breakpoints
// breakpoints: {
//     xs: '480px',
//     sm: '768px',
//     md: '1024px',
//     lg: '1184px',
//     xl: '1440px',
// },

function useMediaQueries() {
	const theme = useMantineTheme();
	const { breakpoints } = theme;
	const widthLessThanLG = useMediaQuery({
		query: `(max-width: ${breakpoints.lg})`,
	});
	const heightLessThanLG = useMediaQuery({
		query: `(max-height: ${breakpoints.lg})`,
	});
	const heightLessThanXL = useMediaQuery({
		query: `(max-height: ${breakpoints.xl})`,
	});
	const widthLessThanXL = useMediaQuery({
		query: `(max-width: ${breakpoints.xl})`,
	});
	const isLessThan2000 = useMediaQuery({
		query: `(max-width: 2000px)`,
	});

	const isTabletOrSmaller = useMediaQuery({
		query: `(max-width: ${theme.breakpoints.sm})`,
	});
	const isMobile = useMediaQuery({
		query: `(max-width: ${theme.breakpoints.xs})`,
	});

	const maxMediumWidth = useMediaQuery({
		query: `(max-width: ${theme.breakpoints.md})`,
	});
	const maxMediumHeight = useMediaQuery({
		query: `(max-height: ${theme.breakpoints.md})`,
	});

	const widthOrHeightLessThanLG = widthLessThanLG || heightLessThanLG;
	const maxMediumBreakpoints = maxMediumWidth || maxMediumHeight;
	const widthOrHeightLessThanXL = widthLessThanXL || heightLessThanXL;

	return {
		widthLessThanLG,
		heightLessThanLG,
		heightLessThanXL,
		isLessThan2000,
		isTabletOrSmaller,
		isMobile,
		widthOrHeightLessThanLG,
		maxMediumBreakpoints,
		widthOrHeightLessThanXL,
		widthLessThanXL,
	};
}

export default useMediaQueries;
