import { IAttachment } from '../Atoms/userAtoms';
import { sendAnalyticEvent } from '../api/api';
import { logger } from './logger';
import { showFailureNotification } from './notifications';
import {
	faFileDoc,
	faFileExcel,
	faFilePdf,
	faFilePowerpoint,
	faFile,
	faFileJpg,
	faFilePng,
	faFileVideo,
} from '@fortawesome/pro-regular-svg-icons';

export const downloadAttachment = async (
	attachment: IAttachment,
	organizationID: string
) => {
	fetch(attachment.attachmentURL)
		.then((response) => {
			response.blob().then((blob) => {
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				a.href = url;
				a.download = attachment.fileName;
				a.click();
				sendAnalyticEvent(
					{
						name: 'reelay.attachment',
						sourceID: attachment?.meetingID,
						targetID: attachment?.id,
						data: {
							type: 'download',
						},
					},
					organizationID
				);
			});
		})
		.catch((err) => {
			logger('error', 'error downloading attachment', err);
			showFailureNotification({
				message:
					'Apologies, we encountered an issue while trying to download the attachment. Please attempt this action again or get in touch with our support team for assistance.',
			});
		});
};

export const getAttachmentIcon = (fileName: string) => {
	const fileExtension = fileName.split('.').pop();
	let icon;
	switch (fileExtension) {
		case 'pdf':
			icon = faFilePdf;
			break;
		case 'doc':
		case 'docx':
			icon = faFileDoc;
			break;
		case 'xls':
		case 'xlsx':
			icon = faFileExcel;
			break;
		case 'ppt':
		case 'pptx':
			icon = faFilePowerpoint;
			break;
		case 'jpg':
		case 'jpeg':
			icon = faFileJpg;
			break;
		case 'png':
			icon = faFilePng;
			break;
		case 'mp4':
		case 'mov':
		case 'wmv':
		case 'avi':
		case 'flv':
		case 'mkv':
		case 'webm':
			icon = faFileVideo;
			break;
		default:
			icon = faFile;
	}

	return icon;
};
