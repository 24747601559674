export interface RecallBot {
	id: string;
	eventID: string;
	joinAt: string | null;
	meetingID: string;
	meetingLink: string;
	recallBotID: string;
	recallEventID: string;
	status: string;
	updatedAt: string;
	createdAt: string;
	userID: string;
}

export enum BotStatus {
	Ready = 'ready',
	JoiningCall = 'joining_call',
	InWaitingRoom = 'in_waiting_room',
	ParticipantInWaitingRoom = 'participant_in_waiting_room',
	InCallNotRecording = 'in_call_not_recording',
	InCallRecording = 'in_call_recording',
	CallEnded = 'call_ended',
	RecordingDone = 'recording_done',
	Done = 'done',
	AnalysisDone = 'analysis_done',
	AnalysisFailed = 'analysis_failed',
	MediaExpired = 'media_expired',
	BotDeleted = 'bot_deleted',
	Fatal = 'fatal',
	RecordingPermissionAllowed = 'recording_permission_allowed',
	RecordingPermissionDenied = 'recording_permission_denied',
}

export const BotStatusMapping = {
	[BotStatus.Ready]: 'Ready',
	[BotStatus.JoiningCall]: 'Joining Call',
	[BotStatus.InWaitingRoom]: 'In Waiting Room',
	[BotStatus.InCallNotRecording]: 'Not Recording',
	[BotStatus.InCallRecording]: 'Recording',
	[BotStatus.CallEnded]: 'Call Ended',
	[BotStatus.RecordingDone]: 'Recording Done',
	[BotStatus.Done]: 'Done',
	[BotStatus.AnalysisDone]: 'Analysis Done',
	[BotStatus.AnalysisFailed]: 'Analysis Failed',
	[BotStatus.MediaExpired]: 'Media Expired',
	[BotStatus.BotDeleted]: 'Bot Deleted',
	[BotStatus.RecordingPermissionAllowed]: 'Recording Permission Allowed',
	[BotStatus.RecordingPermissionDenied]: 'Recording Permission Denied',
	[BotStatus.Fatal]: 'Fatal',
	[BotStatus.ParticipantInWaitingRoom]: 'Participant In Waiting Room',
};

export enum BotCreationStatus {
	PENDING = 'PENDING', // Bot creation is pending
	CREATED = 'CREATED', // Bot was created successfully
	NOT_CREATED_RESTRICTED_DOMAIN = 'NOT_CREATED_RESTRICTED_DOMAIN', // Bot not created due to restricted email domain
	NOT_CREATED_OTHER_REASON = 'NOT_CREATED_OTHER_REASON', // Bot not created due to some other failure
}
