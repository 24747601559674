import React from 'react';
import { User } from '../../Atoms/userAtoms';
import { Avatar, Center, Tooltip, useMantineTheme } from '@mantine/core';
import { swapBackgroundColor } from '../../_utils/handy-functions';

interface Props {
	users: User[];
	paddingLeft?: number | string;
	showCount?: number;
}
const AvatarGroup = ({ users, paddingLeft = 12, showCount = 4 }: Props) => {
	const theme = useMantineTheme();
	let mappedUsers = [];

	if (users.length < showCount + 1) {
		mappedUsers = users;
	} else if (users.length >= showCount + 1) {
		mappedUsers = users.slice(0, showCount);
	}

	const additionalMembers = users.length - showCount;

	return (
		<Tooltip.Group openDelay={100} closeDelay={100}>
			<Avatar.Group spacing='sm' pl={paddingLeft}>
				{mappedUsers.map((user) => {
					const label =
						user.firstName && user.lastName
							? `${user.firstName} ${user.lastName}`
							: user.email;
					return (
						<Tooltip key={user.id} label={label} withArrow>
							{user.userAvatarURL ? (
								<Avatar
									src={swapBackgroundColor(
										`${user.userAvatarURL}-196.png`,
										theme.fn.primaryColor()
									)}
									radius='xl'
									size={34}
								/>
							) : (
								<Avatar
									color={'primary'}
									variant={'filled'}
									src={''}
									radius={'xl'}
									children={
										<Center>
											{user.firstName && user.lastName
												? `${user?.firstName?.substring(
														0,
														1
												  )}${user?.lastName?.substring(0, 1)}`.toUpperCase()
												: '?'}
										</Center>
									}
								/>
							)}
						</Tooltip>
					);
				})}
				{users.length >= showCount + 1 && (
					<Tooltip
						withArrow
						label={
							<>
								{users.map((user, index) => {
									return index > showCount - 1 ? (
										<div key={user.id}>
											{user.firstName && user.lastName
												? `${user.firstName} ${user.lastName}`
												: user.email}
										</div>
									) : (
										<div key={user.id}></div>
									);
								})}
							</>
						}
					>
						<Avatar radius='xl'>{`+${additionalMembers}`}</Avatar>
					</Tooltip>
				)}
			</Avatar.Group>
		</Tooltip.Group>
	);
};

export default AvatarGroup;
