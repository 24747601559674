import { Card, Grid, Group, Skeleton, createStyles } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
	card: {
		backgroundColor:
			theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
		height: 500,
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	stats: {
		display: 'flex',
		justifyContent: 'space-around',
		borderBottom: `1px solid ${
			theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[3]
		}`,
		padding: theme.spacing.xs,
		flex: 1,
	},
}));

interface SkeletonCardProps {
	animate?: boolean;
}

const SkeletonCard = ({ animate = true }: SkeletonCardProps) => {
	const { classes } = useStyles();
	const isBelowMdBreakpoint = useMediaQuery('(max-width: 1024px)');
	const SKELETON_MARGIN = 8;

	return (
		<Grid.Col
			xs={6}
			sm={6}
			md={4}
			lg={4}
			xl={3}
			style={{
				...(isBelowMdBreakpoint
					? {
							display: 'flex',
							justifyContent: 'center',
					  }
					: {}),
			}}
		>
			<Card
				withBorder
				radius='md'
				p='md'
				shadow={'xl'}
				className={classes.card}
			>
				<Card.Section p={'md'} pb={'sm'}>
					<Skeleton height={165} animate={animate} />
				</Card.Section>
				<Card.Section px={'md'}>
					<Skeleton animate={animate} height={25} />
					<Skeleton animate={animate} height={25} mt={4} />
					<Skeleton animate={animate} height={100} mt={10} />
				</Card.Section>
				<Card.Section className={classes.stats} mt={SKELETON_MARGIN}>
					<Skeleton animate={animate} height={45} width={'25%'} />
					<Skeleton animate={animate} height={45} width={'25%'} />
					<Skeleton animate={animate} height={45} width={'25%'} />
				</Card.Section>
				<Group p={'xs'} align={'flex-end'} style={{ flex: 1 }}>
					<Skeleton animate={animate} height={36} radius={'xl'} />
				</Group>
			</Card>
		</Grid.Col>
	);
};

export default SkeletonCard;
