import React, { useState, useRef, useEffect } from 'react';
import { ActionIcon, Group, Text, TextInput } from '@mantine/core';
import { Utterance } from '../../../../../helpers/data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faAngleUp,
	faAngleDown,
	faXmark,
} from '@fortawesome/pro-solid-svg-icons';
import { debounce } from 'lodash';
import { useRecoilState } from 'recoil';
import { transcriptSearchQuery } from '../../../../../Atoms/meetingAtom';

interface Props {
	utterances: Utterance[];
}
const TranscriptTextSearch = ({ utterances }: Props) => {
	const searchInputRef = useRef(null);
	const [searchResults, setSearchResults] = useState([]);
	const [currentResultIndex, setCurrentResultIndex] = useState(0);
	const [searchQuery, setSearchQuery] = useRecoilState(transcriptSearchQuery);

	useEffect(() => {
		handleSearch({ target: { value: searchQuery } });
	}, [utterances]);
	const handleSearch = (e) => {
		const query = e.target.value.toLowerCase();
		setSearchQuery(query);

		if (query === '') {
			setSearchResults([]);
			setCurrentResultIndex(0);
			return;
		}

		// Find matches in the page content (you can replace this with your own data or API call)
		// can match speaker name or text content.
		const matches = utterances
			.map((item, index) => ({ item, index })) // Map each item with its index
			.filter(
				({ item }) =>
					item.text.toLowerCase().includes(query) ||
					item.speaker.toLowerCase().includes(query)
			); // Filter based on the search query
		const resultIndices = matches.map(({ index }) => index); // Output: [0, 3]

		setSearchResults(resultIndices || []);
		setCurrentResultIndex(0);

		// Listen for Enter/Return key press
		if (e.key === 'Enter' && matches.length > 0) {
			e.preventDefault(); // Prevent form submission or page reload
			handleNextResult();
		}
	};

	const scrollToResult = (index) => {
		const results = Array.from(
			document.querySelectorAll(
				`.transcript-search-result-${searchResults[index]}`
			)
		);
		if (results.length > 0) {
			const result = results[0];
			result.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		}
	};

	const handlePrevResult = () => {
		const newIndex =
			currentResultIndex === 0
				? searchResults.length - 1
				: currentResultIndex - 1;
		setCurrentResultIndex(newIndex);
		scrollToResult(newIndex);
	};

	const handleNextResult = () => {
		const newIndex =
			currentResultIndex === searchResults.length - 1
				? 0
				: currentResultIndex + 1;
		setCurrentResultIndex(newIndex);
		scrollToResult(newIndex);
	};

	useEffect(() => {
		const handleKeyPress = (e) => {
			if (e.key === 'Enter') {
				handleNextResult();
			}
		};

		const inputElement = searchInputRef.current;
		if (inputElement) {
			inputElement.addEventListener('keypress', handleKeyPress);
		}

		return () => {
			if (inputElement) {
				inputElement.removeEventListener('keypress', handleKeyPress);
			}
		};
	}, [handleNextResult]);

	return (
		<Group noWrap>
			<TextInput
				ref={searchInputRef}
				onChange={debounce(handleSearch, 300)}
				placeholder='Search Transcript...'
				w={'80%'}
				rightSection={
					searchQuery.length > 0 ? (
						<Text
							lineClamp={1}
							color={'secondary-text'}
							size={'sm'}
							mr={'xs'}
						// w={50}
						>
							{`${currentResultIndex === 0 && searchResults.length === 0
									? 0
									: currentResultIndex + 1
								} / ${searchResults.length}`}
						</Text>
					) : (
						<></>
					)
				}
				styles={{
					rightSection: {
						width: 'auto',
					},
				}}
			/>
			{searchQuery.length > 0 ? (
				<Group noWrap spacing={'xs'}>
					<ActionIcon variant='subtle' radius='md' onClick={handlePrevResult}>
						<FontAwesomeIcon icon={faAngleUp} />
					</ActionIcon>
					<ActionIcon variant='subtle' radius='md' onClick={handleNextResult}>
						<FontAwesomeIcon icon={faAngleDown} />
					</ActionIcon>
					<ActionIcon
						variant='subtle'
						radius='md'
						onClick={() => {
							searchInputRef.current.value = '';
							setSearchQuery('');
						}}
					>
						<FontAwesomeIcon icon={faXmark} />
					</ActionIcon>
				</Group>
			) : (
				<></>
			)}
		</Group>
	);
};

export default TranscriptTextSearch;
