import React, { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMantineTheme } from '@mantine/core';
import Hls from 'hls.js';
import { useSearchParams } from 'react-router-dom';
import { sendAnalyticEvent } from '../../../../api/api';
import {
	MILLISECONDS_PER_SECOND,
	reelay_logo,
	WATCH_DURATION,
} from '../../../../components/constants';
import {
	timestamp,
	videoPlayerCurrentTime,
	videoPlayerElapsedTime,
} from '../../../../Atoms/meetingAtom';
import {
	currentOrganizationID,
	currentUser,
} from '../../../../Atoms/userAtoms';
import { IMeeting } from '../../../../interfaces/meeting';
import { Logo } from '../../../../components/reelayLogo';
import {
	createVideoThumbnail,
	reelayVideoThumbnailFallbackImage,
} from '../../../../helpers/data';

export interface Props {
	playbackID: string;
	meeting: IMeeting;
	playerRef: any;
}

export default function ReelayVideoPlayer({
	playbackID,
	meeting,
	playerRef,
}: Props) {
	const theme = useMantineTheme();
	const { videoMetadata } = meeting;
	const user = useRecoilValue(currentUser);
	const timestampValue = useRecoilValue(timestamp);
	const [sentWatchedEvent, setSentWatchedEvent] = useState(false);
	const [pageLoaded, setPageLoaded] = useState(false);
	const [userInteracted, setUserInteracted] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const sharingToken = searchParams.get('vt');
	const organizationID = useRecoilValue(currentOrganizationID);

	useEffect(() => {
		if (Hls.isSupported() && videoMetadata?.playbackURL) {
			const hls = new Hls();
			hls.loadSource(videoMetadata.playbackURL);
			if (playerRef.current) {
				hls.attachMedia(playerRef.current);
			}
		}
	}, [meeting]);

	useEffect(() => {
		const listenToTimestampValueChanges = async () => {
			if (playerRef && playerRef.current) {
				setPageLoaded(true);
				try {
					if (userInteracted) playerRef.current.play();
					playerRef.current.currentTime = timestampValue.time;
				} catch (error) {}
			}
		};
		listenToTimestampValueChanges();
	}, [timestampValue]);

	useEffect(() => {
		if (Object.keys(meeting).length && playerRef.current) {
			playerRef.current.addEventListener('loadedmetadata', function () {
				if (playerRef.current) {
					playerRef.current.addEventListener('play', fireOnVideoStart);
				}
			});
		}

		return () => {
			if (playerRef.current) {
				playerRef.current.removeEventListener('play', fireOnVideoStart);
			}
		};
	}, [meeting]);

	useEffect(() => {
		if (Object.keys(meeting).length && playerRef.current) {
			playerRef.current.addEventListener('loadedmetadata', function () {
				if (playerRef.current) {
					playerRef.current.addEventListener('pause', fireOnVideoPause);
				}
			});
		}

		return () => {
			if (playerRef.current) {
				playerRef.current.removeEventListener('pause', fireOnVideoPause);
			}
		};
	}, [meeting]);

	useEffect(() => {
		if (Object.keys(meeting).length && playerRef.current) {
			playerRef.current.addEventListener('loadedmetadata', function () {
				if (playerRef.current) {
					playerRef.current.addEventListener('timeupdate', handleTimeUpdate);
				}
			});
		}

		return () => {
			if (playerRef.current) {
				playerRef.current.removeEventListener('timeupdate', handleTimeUpdate);
			}
		};
	}, [meeting]);

	// Add setter functions for the atoms you want to update
	const setCurrentTime = useSetRecoilState(videoPlayerCurrentTime);
	const setElapsedTime = useSetRecoilState(videoPlayerElapsedTime);

	useEffect(() => {
		// This effect listens to the video's current time updates
		const handleTimeUpdate = (event) => {
			const currentTime = event.target.currentTime;
			setCurrentTime(currentTime); // Update the current time atom
			setElapsedTime(currentTime); // Optionally, update the elapsed time atom if needed
		};

		const videoElement = playerRef.current;
		if (videoElement) {
			videoElement.addEventListener('timeupdate', handleTimeUpdate);
		}

		return () => {
			if (videoElement) {
				videoElement.removeEventListener('timeupdate', handleTimeUpdate);
			}
		};
	}, [setCurrentTime, setElapsedTime]); // Include setter functions in the dependency array

	function handleTimeUpdate(event) {
		if (event.target.currentTime > WATCH_DURATION && !sentWatchedEvent) {
			sendAnalyticEvent(
				{
					name: 'reelay.video',
					sourceID: meeting?.id,
					targetID: meeting?.videoMetadata?.id,
					data: {
						type: 'watched',
						startTime: 0,
						endTime: event.target.currentTime * MILLISECONDS_PER_SECOND,
					},
				},
				organizationID,
				sharingToken
			);
			if (playerRef.current) {
				playerRef.current.removeEventListener('timeupdate', handleTimeUpdate);
			}
			setSentWatchedEvent(true);
		}
	}

	const fireOnVideoStart = () => {
		if (playerRef?.current?.currentTime) {
			// Send play event analytic when the video starts/resumes playing
			sendAnalyticEvent(
				{
					name: 'reelay.video',
					sourceID: meeting?.id,
					targetID: meeting?.videoMetadata?.id,
					data: {
						type: 'play',
						startTime:
							playerRef?.current?.currentTime * MILLISECONDS_PER_SECOND,
					},
				},
				organizationID,
				sharingToken
			);
		}
		setUserInteracted(true);
	};

	const fireOnVideoPause = () => {
		if (playerRef?.current?.currentTime) {
			// send pause event when the video pauses
			sendAnalyticEvent(
				{
					name: 'reelay.video',
					sourceID: meeting?.id,
					targetID: meeting?.videoMetadata?.id,
					data: {
						type: 'pause',
						startTime:
							playerRef?.current?.currentTime * MILLISECONDS_PER_SECOND,
					},
				},
				organizationID,
				sharingToken
			);
		}
		setUserInteracted(true);
	};

	return (
		<video
			controls
			ref={playerRef}
			style={{
				borderRadius: 16,
				height: '100%',
				width: '100%',
			}}
			playsInline
			poster={reelayVideoThumbnailFallbackImage}
		></video>
	);
}
