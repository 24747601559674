import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const addEmailAlias = (
	userID: string,
	organizationID: string,
	email: string
) => {
	const payload = { organizationID, email };
	return axiosInstance
		.post(`v1/user/${userID}/email-aliases`, payload)
		.then((res) => {
			logger('info', 'Email alias added', res);
			return res.data;
		})
		.catch((err) => {
			logger('error', 'Error adding email alias', err);
			throw new Error(err?.response?.data || 'Error adding email alias');
		});
};

export const deleteEmailAlias = (
	userID: string,
	organizationID: string,
	email: string
) => {
	const payload = { organizationID, email };
	return axiosInstance
		.delete(`v1/user/${userID}/email-aliases`, { data: payload })
		.then((res) => {
			logger('info', 'Email alias deleted', res);
			return res.data;
		})
		.catch((err) => {
			logger('error', 'Error deleting email alias', err);
			throw new Error(err?.response?.data || 'Error deleting email alias');
		});
};
