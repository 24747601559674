import React from 'react';
import { isToday } from '../_utils/time';
import { useMantineTheme } from '@mantine/core';

export default function CustomCalendarDay({ date }: { date: Date }) {
	const theme = useMantineTheme();
	const day = date.getDate();
	const isTodayDate = isToday(date);
	const underlineStyle = {
		padding: isTodayDate ? '10px' : 'none',
		background: isTodayDate ? theme.colors['gray'][2] : 'none',
		borderRadius: '5px',
	};

	return <div style={{ ...underlineStyle }}>{day}</div>;
}
