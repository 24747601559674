import { RichTextEditor, Link } from '@mantine/tiptap';
import { BubbleMenu, useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import { Placeholder } from '@tiptap/extension-placeholder';
import React, { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { IMeeting } from '../../interfaces/meeting';
interface Props {
	placeholder: string;
	meeting?: IMeeting | null;
	richTextValue?: string;
	setRichTextValue?: React.Dispatch<React.SetStateAction<string>>;
	setObjValue?: React.Dispatch<React.SetStateAction<object>>;
	field?: string;
	height?: string;
	aiResponse?: string;
}

export const RichTextEditorComponent = ({
	placeholder,
	richTextValue,
	aiResponse,
	setRichTextValue,
	meeting = null,
	field,
	height = 'auto',
}: Props) => {
	const editor = useEditor({
		extensions: [
			StarterKit,
			Underline,
			Link,
			Superscript,
			SubScript,
			Placeholder.configure({ placeholder }),
			Highlight,
			TextAlign.configure({ types: ['heading', 'paragraph'] }),
		],
		content: richTextValue,
		onUpdate: ({ editor }) => {
			setRichTextValue(editor.getHTML());
		},
	});

	useEffect(() => {
		if (editor && meeting[field]) {
			editor.commands.setContent(meeting[field]);
		}
	}, [meeting]);

	useEffect(() => {
		if (editor && aiResponse) {
			editor.commands.setContent(aiResponse);
			setRichTextValue(aiResponse);
		}
	}, [aiResponse]);

	return (
		<RichTextEditor
			editor={editor}
			style={{ fontSize: '12px' }}
			withTypographyStyles={false}
			styles={{
				content: {
					fontSize: '0.875rem',
					height,
					overflowY: 'auto',
				},
				control: {
					backgroundColor: 'white',
				},
			}}
		>
			{editor && (
				<BubbleMenu editor={editor}>
					<RichTextEditor.ControlsGroup>
						<RichTextEditor.Bold />
						<RichTextEditor.Italic />
						<RichTextEditor.Link />
					</RichTextEditor.ControlsGroup>
				</BubbleMenu>
			)}
			<RichTextEditor.Toolbar sticky={true}>
				<RichTextEditor.ControlsGroup>
					<RichTextEditor.Bold />
					<RichTextEditor.Italic />
					<RichTextEditor.Underline />
					{/*<RichTextEditor.Strikethrough />*/}
					{/*<RichTextEditor.ClearFormatting />*/}
					{/*<RichTextEditor.Highlight />*/}
					{/*<RichTextEditor.Code />*/}
				</RichTextEditor.ControlsGroup>

				<RichTextEditor.ControlsGroup>
					<RichTextEditor.H1 />
					<RichTextEditor.H2 />
					{/*<RichTextEditor.H3 />*/}
					{/*<RichTextEditor.H4 />*/}
				</RichTextEditor.ControlsGroup>

				<RichTextEditor.ControlsGroup>
					{/*<RichTextEditor.Blockquote />*/}
					{/*<RichTextEditor.Hr />*/}
					<RichTextEditor.BulletList />
					<RichTextEditor.OrderedList />
					{/*<RichTextEditor.Subscript />*/}
					{/*<RichTextEditor.Superscript />*/}
				</RichTextEditor.ControlsGroup>

				{/*<RichTextEditor.ControlsGroup>*/}
				{/*  <RichTextEditor.Link />*/}
				{/*  <RichTextEditor.Unlink />*/}
				{/*</RichTextEditor.ControlsGroup>*/}

				{/*<RichTextEditor.ControlsGroup>*/}
				{/*  <RichTextEditor.AlignLeft />*/}
				{/*  <RichTextEditor.AlignCenter />*/}
				{/*  <RichTextEditor.AlignJustify />*/}
				{/*  <RichTextEditor.AlignRight />*/}
				{/*</RichTextEditor.ControlsGroup>*/}
			</RichTextEditor.Toolbar>

			<RichTextEditor.Content />
		</RichTextEditor>
	);
};

export default RichTextEditor;
