import React, { useEffect, useState } from 'react';
import {
	Button,
	Center,
	Checkbox,
	Group,
	Loader,
	Modal,
	Stack,
	Text,
	Textarea,
	TransferList,
	TransferListData,
	TransferListItem,
	TransferListItemComponent,
	TransferListItemComponentProps,
} from '@mantine/core';
import {
	fetchMeetingDetails,
	getMeetingDetailsFromAI,
} from '../../../../api/reelayAIService';
import { IMeeting } from '../../../../interfaces/meeting';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentMeeting,
	currentMeetingChapters,
	currentMeetingHighlights as HighlightAtom,
} from '../../../../Atoms/meetingAtom';
import { logger } from '../../../../helpers/logger';
import { RichTextEditorComponent } from '../../../../components/RichTextEditorComponent';
import {
	createMeetingChapter,
	createMeetingHighlight,
	destroyMeetingChapter,
	destroyMeetingHighlights,
	updateMeeting,
	updateMeetingChapters,
} from '../../../../api/api';
import { showNotification } from '@mantine/notifications';
import {
	MILLISECONDS_PER_SECOND,
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
} from '../../../../components/constants';
import { currentUser, currentUserReelays } from '../../../../Atoms/userAtoms';
import RegenerateMeetingDetailsButton from './RegenerateMeetingDetailsButton';
import axios from 'axios';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import { IHighlight } from '../../../../interfaces/highlight';
import {
	convertSecondsToHHMMSS,
	convertTimeToSeconds,
	formatSecondsToMinutesAndSeconds,
} from '../../../../_utils/time';
import { Chapter, createHighlights } from '../../../../helpers/meetings';
import { number } from 'prop-types';
import _ from 'lodash';
import { safelyParseJSON } from '../../../../_utils/handy-functions';

interface Props {
	opened: boolean;
	close: () => void;
	title: string;
	model: string;
	type: string;
	currentSummary?: string;
}

export default function RegenerateMeetingDetailsModal({
	opened,
	close,
	title,
	model,
	type,
	currentSummary,
}: Props) {
	const [meeting, setMeeting] = useRecoilState(currentMeeting);
	const [meetings, setMeetings] = useRecoilState(currentUserReelays);
	const [currentHighlights, setCurrentHighlights] =
		useRecoilState(HighlightAtom);
	const [currentChapters, setCurrentChapters] = useRecoilState(
		currentMeetingChapters
	);
	const user = useRecoilValue(currentUser);
	const [keepButtonLoading, setKeepButtonLoading] = useState<boolean>(false);
	const [newButtonLoading, setNewButtonLoading] = useState<boolean>(false);
	const [fetchingData, setFetchingData] = useState<boolean>(true);
	const [data, setData] = useState<TransferListData>([[], []]);
	const [currentValue, setCurrentValue] = useState<string>(
		currentSummary ?? ''
	);
	const [newValue, setNewValue] = useState<string>('');
	const [aiValue, setAiValue] = useState<string>('');

	const typeText = type === 'chapters' ? 'topics' : type;

	const getMeetingDetails = async (modelValue, type) => {
		setFetchingData(true);
		try {
			const { response, pluralType } = await fetchMeetingDetails(
				meeting,
				type,
				modelValue,
				user
			);

			if (type === 'summary') {
				const summary = _.get(response, 'short_summary.content', '');
				setAiValue(summary);
			} else if (type === 'longSummary') {
				const longSummary = _.get(response, 'long_summary.content', '');
				setAiValue(longSummary);
			} else if (type === 'chapters') {
				setChaptersResponseData(response);
			} else {
				const currentHighlightsFormatted = currentHighlights
					.filter((hl) => hl.type === type)
					.map((highlight: IHighlight) => {
						const content = highlight.content || 'Unknown Content';
						return {
							value: content,
							label: content,
							timeStartMS: highlight.timeStartMS,
							id: highlight.id,
							isTopQuestion: highlight.isTopQuestion,
						};
					});

				const newHighlights = response[pluralType];
				const newHighlightsFormatted = newHighlights
					.filter(
						(hl) =>
							!currentHighlights.some(
								(highlight) => hl.content === highlight.content
							)
					)
					.map((highlight: any) => {
						const content = highlight.content || 'Unknown Content';
						return {
							value: content,
							label: content,
							timeStartMS: highlight.time,
							id: null,
							isTopQuestion: highlight.isTopQuestion,
						};
					});

				setData([newHighlightsFormatted, currentHighlightsFormatted]);
			}
		} catch (error) {
			setData([
				[],
				currentChapters.map((chapter) => {
					const content = chapter.content || 'Unknown Content';
					return {
						value: content,
						label: content,
						timeStartMS: chapter.time,
					};
				}),
			]);
		} finally {
			setFetchingData(false);
		}
	};

	const setChaptersResponseData = (response) => {
		const chapters = _.get(response, 'chapters', []);
		if (!Array.isArray(chapters)) {
			throw new Error(`topics is not an array`);
		}
		chapters.sort((a, b) => {
			if (a.time > b.time) {
				return 1;
			} else if (a.time < b.time) {
				return -1;
			} else {
				return 0;
			}
		});
		const newChapters = chapters
			.filter(
				(chapter) =>
					chapter.content &&
					!currentChapters.some((c) => chapter.content === c.content)
			)
			.map((chapter) => {
				const content = chapter.content || 'Unknown Content';
				return {
					...chapter,
					value: content,
					label: content,
					timeStartMS: chapter.time,
				};
			});

		const currentChaptersFormatted = currentChapters.map((chapter) => {
			const content = chapter.content || 'Unknown Content';
			return {
				...chapter,
				value: content,
				label: content,
				timeStartMS: chapter.time,
			};
		});
		setData([newChapters, currentChaptersFormatted]);
	};

	useEffect(() => {
		const controller = new AbortController();
		if (opened) {
			getMeetingDetails(model, type);
		}

		return () => {
			controller.abort('API call was canceled due to modal closure.');
			setFetchingData(false);
			setNewValue('');
			setData([[], []]);
		};
	}, [opened]);

	useEffect(() => {
		setCurrentValue(currentSummary ?? '');
		if (opened) {
			setCurrentValue(currentSummary);
		}
	}, [currentSummary, opened]);

	const handleUpdate = async (action: string) => {
		try {
			action === 'current'
				? setKeepButtonLoading(true)
				: setNewButtonLoading(true);

			if (type === 'summary' || type === 'longSummary') {
				await updateMeetingField(
					action === 'current' ? currentValue : newValue
				);
			} else if (type === 'chapters') {
				await updateChapters();
			} else {
				await updateHighlights();
			}
			close();
		} catch (error) {
			logger('error', 'RegenerateMeetingDetailsModal.handleKeepCurrent', error);
		} finally {
			action === 'current'
				? setKeepButtonLoading(false)
				: setNewButtonLoading(false);
		}
	};

	const updateMeetingField = async (value: string) => {
		try {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const updatedMeetingResponse = await updateMeeting(
				{ [type]: value },
				meeting.id
			);
			const newMeeting = updatedMeetingResponse.data.data;
			showSuccessNotification({
				message: 'Meeting was successfully updated!  Great work!',
			});
			// update meeting
			setMeeting(newMeeting);
			// update meetings[] state
			setMeetings(
				meetings.map((meeting: IMeeting) =>
					meeting.id === newMeeting.id ? newMeeting : meeting
				)
			);
		} catch (err: unknown) {
			logger('error', 'Error updating meeting', err);
			showFailureNotification({
				message: 'Error updating meeting. Please try again.',
			});
		}
	};

	const updateChapters = async () => {
		try {
			const payload = [];
			const chaptersData = _.get(data, '1', []);
			if (Array.isArray(chaptersData)) {
				for (const chapterData of chaptersData) {
					payload.push({
						summary: _.get(chapterData, 'summary'),
						headline: _.get(chapterData, 'value'),
						content: _.get(chapterData, 'value'),
						gist: _.get(chapterData, 'value'),
						start: _.get(chapterData, 'timeStartMS'),
						end: _.get(chapterData, 'timeStartMS'),
						isTopQuestion: _.get(chapterData, 'isTopQuestion'),
					});
				}
			}
			const result = await updateMeetingChapters(
				{
					chapters: payload,
				},
				{
					meetingID: meeting.id,
					organizationID: meeting.organizationID,
				}
			);
			if (!result?.id) {
				throw new Error(`Unexpected response data`);
			}

			const chapters = _.get(result, 'chapters', []);

			const currentChaptersFormatted = chapters
				.map((chapter) => {
					return {
						id: chapter.id,
						content: chapter.gist || chapter.headline || chapter.content,
						time: chapter.start,
						summary: chapter.summary,
					};
				})
				.sort((a, b) => {
					if (a.time > b.time) {
						return 1;
					} else if (a.time < b.time) {
						return -1;
					} else {
						return 0;
					}
				});
			setCurrentChapters(currentChaptersFormatted);

			showNotification({
				...defaultSuccessNotificationProps,
				title: 'Success',
				message: 'Meeting topics have been updated',
			});
		} catch (error) {
			console.error(
				`RegenerateMeetingDetailsModal.updateChapters: error:`,
				error
			);

			const message =
				_.get(error, 'response.data.error.message') ||
				_.get(error, 'response.data.error') ||
				_.get(error, 'response.data') ||
				_.get(error, 'message') ||
				`Failed to update topics`;
			showNotification({
				...defaultFailureNotificationProps,
				title: 'Error',
				message,
			});
			throw new Error(error);
		}
	};

	const updateHighlights = async () => {
		try {
			const highlightsToAdd = data[1].filter((hl) => !hl.id);
			const highlightsToDelete = data[0]
				.filter((hl) => hl.id)
				.map((hl) => hl.id);
			let deletedHighlightIDs = [];
			if (highlightsToDelete.length)
				deletedHighlightIDs = await deleteHighlights(highlightsToDelete);
			const updatedHighlights = currentHighlights.filter(
				(hl) => !deletedHighlightIDs.includes(hl.id)
			);
			const newHighlights = await createHighlights(
				highlightsToAdd,
				meeting.id,
				meeting.organizationID,
				type
			);
			const finalHighlights: any = [...updatedHighlights, ...newHighlights];
			setCurrentHighlights(finalHighlights);
		} catch (error) {
			logger('error', 'RegenerateMeetingDetailsModal.updateHighlights', error);
		}
	};

	const deleteHighlights = async (highlightIDs: string[]) => {
		try {
			const res = await destroyMeetingHighlights(
				meeting.id,
				highlightIDs,
				meeting.organizationID
			);
			const deletedHighlightIDs = res?.data?.data?.meetingHighlightIDs;
			if (deletedHighlightIDs) {
				showSuccessNotification({
					message: 'Highlights were successfully destroyed! Great work!',
				});
				return deletedHighlightIDs;
			} else {
				showFailureNotification({
					message: 'Error deleting highlights. Please try again.',
				});
				return [];
			}
		} catch (error) {
			logger('error', 'Error deleting highlights', error);
		}
	};

	const HighlightItem: TransferListItemComponent = ({
		data,
		selected,
	}: TransferListItemComponentProps) => {
		return (
			<Group noWrap>
				<Checkbox
					checked={selected}
					onChange={() => null}
					tabIndex={-1}
					sx={{ pointerEvents: 'none' }}
				/>
				<Stack spacing={'xs'}>
					<Text size='sm' weight={500}>
						{data.label}
					</Text>
					<Text size='xs' color='secondary-text' weight={400}>
						{formatSecondsToMinutesAndSeconds(
							(data?.timeStartMS || 0) / MILLISECONDS_PER_SECOND
						)}
					</Text>
				</Stack>
			</Group>
		);
	};

	return (
		<Modal
			size={'xl'}
			opened={opened}
			onClose={close}
			title={title}
			styles={{
				inner: {
					'section:first-of-type': {
						height: 'auto !important',
						overflow: 'scroll !important',
					},
				},
			}}
		>
			{type === 'summary' || type === 'longSummary' ? (
				<Stack>
					<Text>
						Compare your summary with the newly generated one. You can
						regenerate again or try a different AI model.
					</Text>
					<Group position={'right'}>
						<RegenerateMeetingDetailsButton
							handleMenuSelect={getMeetingDetails}
							fetchingData={fetchingData}
							insideModal
							type={type}
						/>
					</Group>
					<Group noWrap grow align={'start'}>
						<Stack h={'100%'}>
							<Text>Current Summary</Text>
							<RichTextEditorComponent
								placeholder={`Please add a ${typeText}.`}
								richTextValue={currentValue}
								setRichTextValue={setCurrentValue}
								height={'400px'}
								meeting={meeting}
							/>
						</Stack>
						<Stack h={'100%'}>
							<Text>New Summary</Text>

							{fetchingData ? (
								<Stack justify={'center'} align={'center'}>
									<Loader size={'lg'} mt={10} />
									<Text>{`Fetching new ${typeText}...`}</Text>
								</Stack>
							) : (
								<RichTextEditorComponent
									placeholder={`Please add a ${typeText}.`}
									richTextValue={newValue}
									setRichTextValue={setNewValue}
									aiResponse={aiValue}
									height={'400px'}
									meeting={meeting}
								/>
							)}
						</Stack>
					</Group>
					<Group position={'right'}>
						<Button
							loading={keepButtonLoading}
							variant={'outline'}
							onClick={() => handleUpdate('current')}
						>
							Keep Current
						</Button>
						<Button
							loading={newButtonLoading}
							onClick={() => handleUpdate('new')}
							disabled={fetchingData}
						>
							Save New
						</Button>
					</Group>
				</Stack>
			) : (
				<Stack>
					<Text>
						{`Regenerate ${typeText} for this meeting. You can regenerate again or
              try a different AI model. Mix and match to find the best ${typeText}
              for your meeting.`}
					</Text>
					<Group position={'right'}>
						<RegenerateMeetingDetailsButton
							handleMenuSelect={getMeetingDetails}
							fetchingData={fetchingData}
							insideModal
							type={type}
						/>
					</Group>
					{fetchingData ? (
						<Stack justify={'center'} align={'center'}>
							<Loader size={'lg'} mt={10} />
							<Text>{`Fetching new ${typeText}...`}</Text>
						</Stack>
					) : (
						<TransferList
							value={data}
							onChange={setData}
							itemComponent={HighlightItem}
							searchPlaceholder='Search...'
							nothingFound='Nothing found'
							titles={[`New ${typeText}`, `Current ${typeText}`]}
							breakpoint='sm'
							radius={'lg'}
							transferAllMatchingFilter
							listHeight={800}
						/>
					)}

					<Group position={'right'}>
						<Button
							loading={newButtonLoading}
							onClick={() => handleUpdate('new')}
							disabled={fetchingData}
						>
							Save
						</Button>
					</Group>
				</Stack>
			)}
		</Modal>
	);
}
