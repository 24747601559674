import React, { useState } from 'react';
import {
	createStyles,
	Center,
	Paper,
	Table,
	Button,
	Group,
	Popover,
	ActionIcon,
	Text,
	UnstyledButton,
	Title,
	Stack,
	Tooltip,
	ScrollArea,
} from '@mantine/core';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showNotification } from '@mantine/notifications';
import { destroyTeams } from '../../api/api';
import { EditTeamButton } from '../edit_team_button';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	currentUserFormattedTeams,
	User,
	userOrganization,
} from '../../Atoms/userAtoms';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
} from '../constants';
import CreateTeamButton from '../Buttons/create_team_button';
import { faSort, faSortDown, faSortUp } from '@fortawesome/pro-solid-svg-icons';
import { TeamsTablePagination } from './TeamsTablePagination';
import { Team } from '../../helpers/data';
import TeamsAvatarGroup from '../AvatarGroup';
import { logger } from '../../helpers/logger';
import SearchBar from '../SearchBars/SearchBar';
import { filterActionsBySearch } from '../../helpers/meetings';
import reactStringReplace from 'react-string-replace';
import { useMediaQuery } from 'react-responsive';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import AvatarGroup from '../AvatarGroup';
import { getEnvForGleap } from '../../_utils/trackers';

const MAXIMUM_NUMBER_OF_EMAILS_TO_SHOW = 4;

const useStyles = createStyles((theme) => ({
	control: {
		width: '100%',
		padding: `${theme.spacing.xs}px ${theme.spacing.md}px`,

		'&:hover': {
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[6]
					: theme.colors.gray[0],
		},
	},

	icon: {
		width: 21,
		height: 21,
		borderRadius: 21,
	},
	header: {
		zIndex: 100,
		position: 'sticky',
		top: 0,
		backgroundColor:
			theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
		transition: 'box-shadow 150ms ease',

		'&::after': {
			content: '""',
			position: 'absolute',
			left: 0,
			right: 0,
			bottom: 0,
			borderBottom: `1px solid ${
				theme.colorScheme === 'dark'
					? theme.colors.dark[3]
					: theme.colors.gray[2]
			}`,
		},
	},

	scrolled: {
		boxShadow: theme.shadows.sm,
	},
}));

interface TableSortProps {
	data: User[];
}

interface ThProps {
	children: React.ReactNode;
	reversed: boolean;
	sorted: boolean;
	onSort(): void;
}

function sortData(data: User[], payload: { reversed: boolean }) {
	const { reversed } = payload;
	return [...data].sort((a, b) => {
		if (reversed) return b['name'].localeCompare(a['name']);

		return a['name'].localeCompare(b['name']);
	});
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
	const getIconElement = (sorted: boolean, reversed: boolean) => {
		if (!sorted) return <FontAwesomeIcon icon={faSort} />;

		if (reversed) return <FontAwesomeIcon icon={faSortUp} />;

		return <FontAwesomeIcon icon={faSortDown} />;
	};

	const { classes } = useStyles();
	const Icon = getIconElement(sorted, reversed);

	return (
		<th>
			<UnstyledButton onClick={onSort} className={classes.control}>
				<Group position='apart' noWrap>
					<Text weight={500} size='sm'>
						{children}
					</Text>
					<Center className={classes.icon}>{Icon}</Center>
				</Group>
			</UnstyledButton>
		</th>
	);
}

export default function TeamsTable() {
	const [sortedData, setSortedData] = useRecoilState(currentUserFormattedTeams);
	const [teams, setTeams] = useState(sortedData);
	const [reverseSortDirection, setReverseSortDirection] = useState(false);
	const { classes, cx } = useStyles();
	const [scrolled, setScrolled] = useState(false);
	const organization = useRecoilValue(userOrganization);
	const organizationID = organization.id;

	// filter search bar
	const [searchValue, setSearchValue] = useState('');

	const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.currentTarget;
		setSearchValue(value);
		// filter data based off team name and user's firstName, lastName, email.
		const filteredTeams = sortedData.filter((team) => {
			const lowercaseValue = value.toLowerCase();
			return (
				team.name.toLowerCase().includes(lowercaseValue) ||
				team.users.some((user) => {
					return (
						user?.firstName?.toLowerCase()?.includes(lowercaseValue) ||
						user?.lastName?.toLowerCase()?.includes(lowercaseValue) ||
						user?.email?.toLowerCase()?.includes(lowercaseValue)
					);
				})
			);
		});
		setTeams(filteredTeams);
	};

	const setSorting = () => {
		const reversed = !reverseSortDirection;
		setReverseSortDirection(reversed);
		setSortedData(sortData(teams, { reversed }));
	};

	const destroyTeam = async (data: { teamIDs: string[] }) => {
		try {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const deleteTeam = await destroyTeams({
				teamIDs: data.teamIDs,
				organizationID,
			});
			showSuccessNotification({
				message: 'Team was successfully destroyed! Great work!',
			});
			const filteredList = sortedData.filter(
				(e: any) => e.id !== deleteTeam.data.data.teamIDs[0]
			);
			setSortedData(filteredList);
			return deleteTeam.data.data;
		} catch (err: unknown) {
			logger('error', 'Error destroying team', err);
			showFailureNotification({
				message:
					'Apologies, the team was not successfully destroyed. Please try the operation again.',
			});
		}
	};

	const ths = (
		<tr>
			<Th
				sorted={true}
				reversed={reverseSortDirection}
				onSort={() => setSorting()}
			>
				Name
			</Th>
			<th>Team Members</th>
			<th></th>
		</tr>
	);
	const EmailsText = ({ users }: { users: User[] }) => {
		const firstFourUsers = users.slice(0, MAXIMUM_NUMBER_OF_EMAILS_TO_SHOW);
		const emailText = firstFourUsers.reduce((string, user, index, arr) => {
			if (
				index === MAXIMUM_NUMBER_OF_EMAILS_TO_SHOW - 1 &&
				users.length > MAXIMUM_NUMBER_OF_EMAILS_TO_SHOW
			)
				return string + `${user.email}...`;
			if (index + 1 === arr.length) return string + ` ${user.email}.`;
			return string + `${user.email}, `;
		}, '');

		if (users.length > MAXIMUM_NUMBER_OF_EMAILS_TO_SHOW)
			return (
				<Tooltip withArrow label={'See edit for more.'}>
					<Text>{highlightText(emailText)}</Text>
				</Tooltip>
			);

		return <Text>{highlightText(emailText)}</Text>;
	};

	const highlightText = (text: string | undefined) => {
		return reactStringReplace(text, searchValue, (match, i) => (
			<span key={i} style={{ backgroundColor: 'yellow' }}>
				{match}
			</span>
		));
	};

	const rows = teams.map((team: Team) => (
		<tr key={team.id} id={`VIS_ctf9nfdtmsxfgg2vmcr0_${getEnvForGleap()}`}>
			<td>{highlightText(team.name)}</td>
			<td>
				<Group position={'left'} noWrap>
					<AvatarGroup users={team.users} />
					<EmailsText users={team.users} />
				</Group>
			</td>
			<td>
				<Group position={'right'} noWrap>
					<ActionIcon m={10} variant='transparent'>
						{/*<FontAwesomeIcon icon={faPencil} />*/}
						<EditTeamButton
							teamID={team.id}
							teamName={team.name}
							teamMembers={team.users}
						/>
					</ActionIcon>
					<Popover position={'bottom-end'} withArrow shadow='md' width={200}>
						<Popover.Target>
							<ActionIcon p={2} variant='transparent'>
								<FontAwesomeIcon icon={faTrash} />
							</ActionIcon>
						</Popover.Target>
						<Popover.Dropdown>
							<Text size='sm'>
								Are you sure you want to permanently delete this team?
							</Text>
							<Group position={'right'}>
								<Button
									radius={'md'}
									onClick={() => destroyTeam({ teamIDs: [team.id] })}
									color='red'
									uppercase
									mt={'md'}
								>
									Delete
								</Button>
							</Group>
						</Popover.Dropdown>
					</Popover>
				</Group>
			</td>
		</tr>
	));

	return (
		<Stack h={'100%'} justify={'space-between'}>
			<Group position={'right'} pt='sm'>
				<Group
					noWrap
					align={'center'}
					id={`VIS_ctf9nfdtmsxfgg2vmcsg_${getEnvForGleap()}`}
				>
					<SearchBar
						searchValue={searchValue}
						placeholder={'Search teams, members, etc.'}
						handleSearchChange={handleSearchChange}
					/>
					<CreateTeamButton type={'top'} />
				</Group>
			</Group>
			<Paper radius='md' shadow='md' withBorder style={{ flex: 2 }} mah={'85%'}>
				<ScrollArea
					h={'100%'}
					onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
				>
					<Table
						captionSide='bottom'
						striped
						highlightOnHover
						horizontalSpacing='xl'
						verticalSpacing='sm'
						h={'100%'}
					>
						<thead
							className={cx(classes.header, { [classes.scrolled]: scrolled })}
						>
							{ths}
						</thead>
						{sortedData.length ? <tbody>{rows}</tbody> : <tbody>loading</tbody>}
					</Table>
				</ScrollArea>
			</Paper>
			<TeamsTablePagination teams={teams} setTeams={setTeams} />
		</Stack>
	);
}
