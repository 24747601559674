import React, { useEffect, useState } from 'react';
import {
	Group,
	Pagination,
	Select,
	Text,
	useMantineTheme,
} from '@mantine/core';
import { useRecoilState } from 'recoil';
import { currentUserFormattedTeams } from '../../../Atoms/userAtoms';
import { useMediaQuery } from 'react-responsive';
const perPageOptions = ['5', '10', '25', '50', '100'];

const CustomLabel = ({ labelText }: { labelText: string }) => {
	return (
		<Text
			weight={700}
			style={{ width: '90px' }}
			color='secondary-text'
			align={'center'}
			size={10}
		>
			{labelText}
		</Text>
	);
};

interface Props {
	teams: [];
	setTeams: any;
}

export const TeamsTablePagination = ({ teams, setTeams }: Props) => {
	const theme = useMantineTheme();
	const [sortedData, setSortedData] = useRecoilState(currentUserFormattedTeams);
	const [activePage, setPage] = useState(1);
	const [totalPages, setTotalPages] = useState(10);
	const isLaptopOrSmaller = useMediaQuery({
		query: `(max-height: ${theme.breakpoints.md}px)`,
	});
	const ITEMS_PER_PAGE = isLaptopOrSmaller ? 5 : 10;

	useEffect(() => {
		setTotalPages(calculateTotalPages(sortedData.length, ITEMS_PER_PAGE));
		buildTeamsToView(activePage);
	}, [sortedData]);

	const calculateTotalPages = (teams: number, teamsPerPage: number) => {
		const teamsPerPageNumber = +teamsPerPage;
		let fullPages = Math.floor(teams / teamsPerPageNumber);
		if (teams % teamsPerPageNumber > 0) fullPages++;
		return fullPages;
	};

	const currentTeamsInTable = () => {
		let lastTeamNum = activePage * ITEMS_PER_PAGE;
		const firstTeamNum = lastTeamNum - ITEMS_PER_PAGE + 1;
		if (lastTeamNum >= sortedData.length) lastTeamNum = sortedData.length;
		return `${firstTeamNum}-${lastTeamNum}`;
	};

	const buildTeamsToView = (page: number) => {
		// need to create the array of teams for the current page;
		let lastTeamNum = page * ITEMS_PER_PAGE;
		const firstTeamNum = lastTeamNum - ITEMS_PER_PAGE + 1;
		if (lastTeamNum >= sortedData.length) lastTeamNum = sortedData.length;
		const teamsInView = sortedData.slice(firstTeamNum - 1, lastTeamNum);
		if (teamsInView.length) {
			setTeams(teamsInView);
		} else {
			setPage((page: number) => page - 1);
			buildTeamsToView(page - 1);
		}
	};

	return (
		<Group position={'apart'}>
			<Group p={'md'} align={'end'}>
				<Text
					mb={'10px'}
					color='secondary-text'
					align={'center'}
					size={12}
				>{`${currentTeamsInTable()} of ${sortedData.length}`}</Text>
			</Group>
			<Pagination
				my={'md'}
				mr={'md'}
				value={activePage}
				onChange={(page: number) => {
					setPage(page);
					setTotalPages(calculateTotalPages(sortedData.length, ITEMS_PER_PAGE));
					buildTeamsToView(page);
				}}
				total={totalPages}
				size={'sm'}
			/>
		</Group>
	);
};
