// types.ts
export interface RegisterData {
	firstName: string;
	lastName: string;
	email: string;
	contactId?: string;
}

export interface OrganizationData {
	name: string;
	domain: string;
	additionalDomains?: string[];
	organizationID?: string | null;
}

export interface InviteTeamData {
	inviteEmail: string;
	invites: string[];
}

export interface InfoCollectionData {
	industry: string;
	// goal: string;
	numberOfEmployees: number;
}

export interface PreferencesData {
	autoRecord: string;
	postMeetingDistribution: string;
	recordingConsent?: boolean;
	dailySchedule: boolean;
}

export const hubspotIndustries = [
	{ label: 'Accounting', value: 'ACCOUNTING' },
	{ label: 'Airlines/Aviation', value: 'AIRLINES_AVIATION' },
	{
		label: 'Alternative Dispute Resolution',
		value: 'ALTERNATIVE_DISPUTE_RESOLUTION',
	},
	{ label: 'Alternative Medicine', value: 'ALTERNATIVE_MEDICINE' },
	{ label: 'Animation', value: 'ANIMATION' },
	{ label: 'Apparel & Fashion', value: 'APPAREL_FASHION' },
	{ label: 'Architecture & Planning', value: 'ARCHITECTURE_PLANNING' },
	{ label: 'Arts and Crafts', value: 'ARTS_AND_CRAFTS' },
	{ label: 'Automotive', value: 'AUTOMOTIVE' },
	{ label: 'Aviation & Aerospace', value: 'AVIATION_AEROSPACE' },
	{ label: 'Banking', value: 'BANKING' },
	{ label: 'Biotechnology', value: 'BIOTECHNOLOGY' },
	{ label: 'Broadcast Media', value: 'BROADCAST_MEDIA' },
	{ label: 'Building Materials', value: 'BUILDING_MATERIALS' },
	{
		label: 'Business Supplies and Equipment',
		value: 'BUSINESS_SUPPLIES_AND_EQUIPMENT',
	},
	{ label: 'Capital Markets', value: 'CAPITAL_MARKETS' },
	{ label: 'Chemicals', value: 'CHEMICALS' },
	{ label: 'Civic & Social Organization', value: 'CIVIC_SOCIAL_ORGANIZATION' },
	{ label: 'Civil Engineering', value: 'CIVIL_ENGINEERING' },
	{ label: 'Commercial Real Estate', value: 'COMMERCIAL_REAL_ESTATE' },
	{ label: 'Computer & Network Security', value: 'COMPUTER_NETWORK_SECURITY' },
	{ label: 'Computer Games', value: 'COMPUTER_GAMES' },
	{ label: 'Computer Hardware', value: 'COMPUTER_HARDWARE' },
	{ label: 'Computer Networking', value: 'COMPUTER_NETWORKING' },
	{ label: 'Computer Software', value: 'COMPUTER_SOFTWARE' },
	{ label: 'Internet', value: 'INTERNET' },
	{ label: 'Construction', value: 'CONSTRUCTION' },
	{ label: 'Consumer Electronics', value: 'CONSUMER_ELECTRONICS' },
	{ label: 'Consumer Goods', value: 'CONSUMER_GOODS' },
	{ label: 'Consumer Services', value: 'CONSUMER_SERVICES' },
	{ label: 'Cosmetics', value: 'COSMETICS' },
	{ label: 'Dairy', value: 'DAIRY' },
	{ label: 'Defense & Space', value: 'DEFENSE_SPACE' },
	{ label: 'Design', value: 'DESIGN' },
	{ label: 'Education Management', value: 'EDUCATION_MANAGEMENT' },
	{ label: 'E-Learning', value: 'E_LEARNING' },
	{
		label: 'Electrical/Electronic Manufacturing',
		value: 'ELECTRICAL_ELECTRONIC_MANUFACTURING',
	},
	{ label: 'Entertainment', value: 'ENTERTAINMENT' },
	{ label: 'Environmental Services', value: 'ENVIRONMENTAL_SERVICES' },
	{ label: 'Events Services', value: 'EVENTS_SERVICES' },
	{ label: 'Executive Office', value: 'EXECUTIVE_OFFICE' },
	{ label: 'Facilities Services', value: 'FACILITIES_SERVICES' },
	{ label: 'Farming', value: 'FARMING' },
	{ label: 'Financial Services', value: 'FINANCIAL_SERVICES' },
	{ label: 'Fine Art', value: 'FINE_ART' },
	{ label: 'Fishery', value: 'FISHERY' },
	{ label: 'Food & Beverages', value: 'FOOD_BEVERAGES' },
	{ label: 'Food Production', value: 'FOOD_PRODUCTION' },
	{ label: 'Fund-Raising', value: 'FUND_RAISING' },
	{ label: 'Furniture', value: 'FURNITURE' },
	{ label: 'Gambling & Casinos', value: 'GAMBLING_CASINOS' },
	{ label: 'Glass, Ceramics & Concrete', value: 'GLASS_CERAMICS_CONCRETE' },
	{ label: 'Government Administration', value: 'GOVERNMENT_ADMINISTRATION' },
	{ label: 'Government Relations', value: 'GOVERNMENT_RELATIONS' },
	{ label: 'Graphic Design', value: 'GRAPHIC_DESIGN' },
	{
		label: 'Health, Wellness and Fitness',
		value: 'HEALTH_WELLNESS_AND_FITNESS',
	},
	{ label: 'Higher Education', value: 'HIGHER_EDUCATION' },
	{ label: 'Hospital & Health Care', value: 'HOSPITAL_HEALTH_CARE' },
	{ label: 'Hospitality', value: 'HOSPITALITY' },
	{ label: 'Human Resources', value: 'HUMAN_RESOURCES' },
	{ label: 'Import and Export', value: 'IMPORT_AND_EXPORT' },
	{
		label: 'Individual & Family Services',
		value: 'INDIVIDUAL_FAMILY_SERVICES',
	},
	{ label: 'Industrial Automation', value: 'INDUSTRIAL_AUTOMATION' },
	{ label: 'Information Services', value: 'INFORMATION_SERVICES' },
	{
		label: 'Information Technology and Services',
		value: 'INFORMATION_TECHNOLOGY_AND_SERVICES',
	},
	{ label: 'Insurance', value: 'INSURANCE' },
	{ label: 'International Affairs', value: 'INTERNATIONAL_AFFAIRS' },
	{
		label: 'International Trade and Development',
		value: 'INTERNATIONAL_TRADE_AND_DEVELOPMENT',
	},
	{ label: 'Investment Banking', value: 'INVESTMENT_BANKING' },
	{ label: 'Investment Management', value: 'INVESTMENT_MANAGEMENT' },
	{ label: 'Judiciary', value: 'JUDICIARY' },
	{ label: 'Law Enforcement', value: 'LAW_ENFORCEMENT' },
	{ label: 'Law Practice', value: 'LAW_PRACTICE' },
	{ label: 'Legal Services', value: 'LEGAL_SERVICES' },
	{ label: 'Legislative Office', value: 'LEGISLATIVE_OFFICE' },
	{ label: 'Leisure, Travel & Tourism', value: 'LEISURE_TRAVEL_TOURISM' },
	{ label: 'Libraries', value: 'LIBRARIES' },
	{ label: 'Logistics and Supply Chain', value: 'LOGISTICS_AND_SUPPLY_CHAIN' },
	{ label: 'Luxury Goods & Jewelry', value: 'LUXURY_GOODS_JEWELRY' },
	{ label: 'Machinery', value: 'MACHINERY' },
	{ label: 'Management Consulting', value: 'MANAGEMENT_CONSULTING' },
	{ label: 'Maritime', value: 'MARITIME' },
	{ label: 'Market Research', value: 'MARKET_RESEARCH' },
	{ label: 'Marketing and Advertising', value: 'MARKETING_AND_ADVERTISING' },
	{
		label: 'Mechanical or Industrial Engineering',
		value: 'MECHANICAL_OR_INDUSTRIAL_ENGINEERING',
	},
	{ label: 'Media Production', value: 'MEDIA_PRODUCTION' },
	{ label: 'Medical Devices', value: 'MEDICAL_DEVICES' },
	{ label: 'Medical Practice', value: 'MEDICAL_PRACTICE' },
	{ label: 'Mental Health Care', value: 'MENTAL_HEALTH_CARE' },
	{ label: 'Military', value: 'MILITARY' },
	{ label: 'Mining & Metals', value: 'MINING_METALS' },
	{ label: 'Motion Pictures and Film', value: 'MOTION_PICTURES_AND_FILM' },
	{ label: 'Museums and Institutions', value: 'MUSEUMS_AND_INSTITUTIONS' },
	{ label: 'Music', value: 'MUSIC' },
	{ label: 'Nanotechnology', value: 'NANOTECHNOLOGY' },
	{ label: 'Newspapers', value: 'NEWSPAPERS' },
	{
		label: 'Nonprofit Organization Management',
		value: 'NONPROFIT_ORGANIZATION_MANAGEMENT',
	},
	{ label: 'Oil & Energy', value: 'OIL_ENERGY' },
	{ label: 'Online Media', value: 'ONLINE_MEDIA' },
	{ label: 'Outsourcing/Offshoring', value: 'OUTSOURCING_OFFSHORING' },
	{ label: 'Package/Freight Delivery', value: 'PACKAGE_FREIGHT_DELIVERY' },
	{ label: 'Packaging and Containers', value: 'PACKAGING_AND_CONTAINERS' },
	{ label: 'Paper & Forest Products', value: 'PAPER_FOREST_PRODUCTS' },
	{ label: 'Performing Arts', value: 'PERFORMING_ARTS' },
	{ label: 'Pharmaceuticals', value: 'PHARMACEUTICALS' },
	{ label: 'Philanthropy', value: 'PHILANTHROPY' },
	{ label: 'Photography', value: 'PHOTOGRAPHY' },
	{ label: 'Plastics', value: 'PLASTICS' },
	{ label: 'Political Organization', value: 'POLITICAL_ORGANIZATION' },
	{
		label: 'Primary/Secondary Education',
		value: 'PRIMARY_SECONDARY_EDUCATION',
	},
	{ label: 'Printing', value: 'PRINTING' },
	{
		label: 'Professional Training & Coaching',
		value: 'PROFESSIONAL_TRAINING_COACHING',
	},
	{ label: 'Program Development', value: 'PROGRAM_DEVELOPMENT' },
	{ label: 'Public Policy', value: 'PUBLIC_POLICY' },
	{
		label: 'Public Relations and Communications',
		value: 'PUBLIC_RELATIONS_AND_COMMUNICATIONS',
	},
	{ label: 'Public Safety', value: 'PUBLIC_SAFETY' },
	{ label: 'Publishing', value: 'PUBLISHING' },
	{ label: 'Railroad Manufacture', value: 'RAILROAD_MANUFACTURE' },
	{ label: 'Ranching', value: 'RANCHING' },
	{ label: 'Real Estate', value: 'REAL_ESTATE' },
	{
		label: 'Recreational Facilities and Services',
		value: 'RECREATIONAL_FACILITIES_AND_SERVICES',
	},
	{ label: 'Religious Institutions', value: 'RELIGIOUS_INSTITUTIONS' },
	{ label: 'Renewables & Environment', value: 'RENEWABLES_ENVIRONMENT' },
	{ label: 'Research', value: 'RESEARCH' },
	{ label: 'Restaurants', value: 'RESTAURANTS' },
	{ label: 'Retail', value: 'RETAIL' },
	{
		label: 'Security and Investigations',
		value: 'SECURITY_AND_INVESTIGATIONS',
	},
	{ label: 'Semiconductors', value: 'SEMICONDUCTORS' },
	{ label: 'Shipbuilding', value: 'SHIPBUILDING' },
	{ label: 'Sporting Goods', value: 'SPORTING_GOODS' },
	{ label: 'Sports', value: 'SPORTS' },
	{ label: 'Staffing and Recruiting', value: 'STAFFING_AND_RECRUITING' },
	{ label: 'Supermarkets', value: 'SUPERMARKETS' },
	{ label: 'Telecommunications', value: 'TELECOMMUNICATIONS' },
	{ label: 'Textiles', value: 'TEXTILES' },
	{ label: 'Think Tanks', value: 'THINK_TANKS' },
	{ label: 'Tobacco', value: 'TOBACCO' },
	{
		label: 'Translation and Localization',
		value: 'TRANSLATION_AND_LOCALIZATION',
	},
	{
		label: 'Transportation/Trucking/Railroad',
		value: 'TRANSPORTATION_TRUCKING_RAILROAD',
	},
	{ label: 'Utilities', value: 'UTILITIES' },
	{
		label: 'Venture Capital & Private Equity',
		value: 'VENTURE_CAPITAL_PRIVATE_EQUITY',
	},
	{ label: 'Veterinary', value: 'VETERINARY' },
	{ label: 'Warehousing', value: 'WAREHOUSING' },
	{ label: 'Wholesale', value: 'WHOLESALE' },
	{ label: 'Wine and Spirits', value: 'WINE_AND_SPIRITS' },
	{ label: 'Wireless', value: 'WIRELESS' },
	{ label: 'Writing and Editing', value: 'WRITING_AND_EDITING' },
];
