import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { user } from '../../Atoms/userAtoms';
import MeetingsHeader from './MeetingsHeader';
import { meetingsView, reloadMeetingsAtom } from '../../Atoms/meetingAtom';
import { useLocation, useNavigate } from 'react-router';
import {
	destroyMeeting,
	getQuery,
	getUserCalendars,
	searchMeetings,
} from '../../api/api';
import { useEffect, useRef, useState } from 'react';
import { Group, Pagination, ScrollArea, Text } from '@mantine/core';
import MeetingsCardView from '../MeetingsCardView';
import MeetingTable from '../meeting_table';
import { encode } from 'js-base64';
import { integrations } from '../../Atoms/integrations';
import { logger } from '../../helpers/logger';
import {
	addMeetingToCollectionModalOpenedAtom,
	archiveBulkButtonLoadingAtom,
	archiveCollectionAtom,
	bulkDeletingCollectionMeetingsSelection,
	meetingBeingAddedToCollection,
	meetingCollectionIDsUpdatedToggleAtom,
	meetingsBeingAddedToCollection,
} from '../../Atoms/collections';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { useCollections } from '../../customHooks/useCollections';
import { useSearchParams } from 'react-router-dom';
import { set } from 'lodash';
import { off } from 'process';
import { useDisclosure } from '@mantine/hooks';
import SharingModal from '../Modals/SharingModal';
import axios from 'axios';
import {
	upcomingMeetings,
	updateMeetingLibraryToggleAtom,
} from '../../Atoms/recallAI';

export interface MeetingsProps {
	segmentValue: string;
	skip?: number;
	search?: string;
	recapOnly?: boolean;
	order?: string;
}
export interface NavigateParams {
	segment?: string;
	search?: string;
	skip?: number;
	limit?: number;
	order?: string;
	startDate?: number;
	endDate?: number;
}

export default function Meetings(props: MeetingsProps) {
	const navigate = useNavigate();
	const location = useLocation();
	const { addMeetingsToCollection } = useCollections(false);
	const currentUser = useRecoilValue(user);
	const [cancelTokenSource, setCancelTokenSource] = useState(
		axios.CancelToken.source()
	);
	const [reloadMeetings, setReloadMeetings] =
		useRecoilState(reloadMeetingsAtom);
	const { segmentValue, recapOnly, skip, search = '', order } = props;
	const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([
		null,
		null,
	]);

	const [currentOrder, setCurrentOrder] = useState('-publishedAt');
	const viewport = useRef<HTMLDivElement>(null);
	const [selection, setSelection] = useRecoilState(
		bulkDeletingCollectionMeetingsSelection
	);
	const setArchiveBulkButtonLoading = useSetRecoilState(
		archiveBulkButtonLoadingAtom
	);
	const [
		addMeetingToCollectionModalOpened,
		setAddMeetingToCollectionModalOpened,
	] = useRecoilState(addMeetingToCollectionModalOpenedAtom);
	const [meetingsToAddToCollection, setMeetingsToAddToCollection] =
		useRecoilState(meetingsBeingAddedToCollection);
	const [opened, setOpened] = useState(false);
	const archiveCollection = useRecoilValue(archiveCollectionAtom);
	const [searchParams, setSearchParams] = useSearchParams();
	const [
		sharingModalOpened,
		{ open: openSharingModal, close: closeSharingModal },
	] = useDisclosure(false);

	// const changeSegmentValue = (value: string) => {
	// 	navigateTo({
	// 		segment: value,
	// 		search: encode(search),
	// 	});
	// };

	const changePage = (value: number) => {
		navigateTo({
			skip: (value > 0 ? value - 1 : 0) * limit,
			limit,
			search: encode(search),
			order,
		});
	};

	const changeSort = (value: string) => {
		navigateTo({
			skip,
			limit,
			order: value,
			search: encode(search),
		});
	};

	const handleSearch = (value: string) => {
		setCurrentSearchValue(value);
		return navigateTo({
			// segment: currentSegmentValue,
			search: encode(value),
		});
	};

	const navigateTo = (params: NavigateParams) => {
		const { segment, skip, limit, order, search } = params;
		const query = getQuery({ skip, limit, order, search });
		const uri = `${location.pathname}?${query}`;
		return navigate(uri);
	};

	const [isInitialized, setInitialized] = useState(false);

	useEffect(() => {
		const loadPage = async () => {
			await fetchIntegrations();
			setInitialized(true);
		};
		loadPage();
	}, [order]);

	useEffect(() => {
		if (!isInitialized) return;
		getMeetings({
			skip,
			limit,
			order,
			search,
		});
	}, [reloadMeetings]);

	useEffect(() => {
		return () => {
			setSelection([]);
		};
	}, []);

	useEffect(() => {
		return () => {
			cancelTokenSource.cancel('Component unmounted');
		};
	}, []);

	const [currentViewType, setCurrentViewType] = useState('card');
	const [currentSearchValue, setCurrentSearchValue] = useState('');

	useEffect(() => {
		if (currentViewType !== viewType) {
			setCurrentViewType(viewType);
			scrollToTop();
		}
		refresh();
	}, [skip, currentSearchValue, order, recapOnly, segmentValue]);

	useEffect(() => {
		if (!isInitialized) return;
		if (currentViewType !== viewType) {
			setCurrentViewType(viewType);
			scrollToTop();
		}

		if (dateRange[0] && dateRange[1]) {
			refresh();
		} else if (dateRange[0] === null && dateRange[1] === null) {
			refresh();
		}
	}, [dateRange]);

	const meetingBeingAddedToCollectionValue = useRecoilValue(
		meetingBeingAddedToCollection
	);
	const meetingCollectionIDsUpdated = useRecoilValue(
		meetingCollectionIDsUpdatedToggleAtom
	);

	useEffect(() => {
		if (meetingBeingAddedToCollectionValue) {
			setData((data) =>
				data.map((meeting) => {
					if (meetingBeingAddedToCollectionValue?.id === meeting.id) {
						return {
							...meeting,
							collectionIDs: meetingBeingAddedToCollectionValue.collectionIDs,
						};
					}
					return meeting;
				})
			);
		}
	}, [meetingCollectionIDsUpdated]);

	const setCalendarIntegrations = useSetRecoilState(integrations);
	const fetchIntegrations = async () => {
		try {
			const res = await getUserCalendars(currentUser.currentOrganizationID);
			const calendars = res.data.data;
			setCalendarIntegrations(calendars);
			return calendars;
		} catch (error) {
			console.error(`Meetings.fetchIntegrations: error:`, error);
			logger('error', 'fetchIntegrations err', error);
		}
	};

	const refresh = async (newTotal = null) => {
		try {
			const currentSkip = (page - 1) * limit;

			if (skip !== currentSkip) {
				await getMeetings({
					skip,
					limit,
					order,
					search,
					newTotal,
				});
				scrollToTop();
			} else if (currentSearchValue !== search) {
				setCurrentSearchValue(search);
				await getMeetings({
					skip: 0,
					search,
					newTotal,
				});
				scrollToTop();
			} else if (currentOrder !== order) {
				setCurrentOrder(order);
				await getMeetings({
					skip,
					limit,
					order,
					search,
					newTotal,
				});
				scrollToTop();
			} else {
				await getMeetings({
					skip,
					limit,
					order,
					search,
					newTotal,
				});
				scrollToTop();
			}
		} catch (error) {
			console.error(`Meetings.refresh: error:`, error);
			logger('error', 'Meetings.refresh err', error);
		} finally {
			setLoading(false);
		}
	};

	const scrollToTop = () => {
		if (viewport?.current) {
			viewport.current.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(20);
	const viewType = useRecoilValue(meetingsView);
	const [upcomingMeetingsValue, setUpcomingMeetings] =
		useRecoilState(upcomingMeetings);
	const [updateMeetingLibraryToggle, setUpdateMeetingLibraryToggle] =
		useRecoilState(updateMeetingLibraryToggleAtom);

	useEffect(() => {
		const upcomingMeetingsDict = upcomingMeetingsValue.reduce(
			(acc, meeting) => {
				acc[meeting.id] = meeting;
				return acc;
			},
			{}
		);
		if (Object.keys(upcomingMeetingsDict).length) {
			setData((data) =>
				data.map((meeting) => {
					const upcomingMeeting = upcomingMeetingsDict[meeting.id];
					if (upcomingMeeting) {
						return {
							...meeting,
							recapOnly: upcomingMeeting.recapOnly ?? meeting.recapOnly,
							botMetadata: upcomingMeeting.botMetadata || meeting.botMetadata,
						};
					}
					return meeting;
				})
			);
		}
	}, [updateMeetingLibraryToggle]);

	useEffect(() => {
		const meetingsDict = data.reduce((acc, meeting) => {
			acc[meeting.id] = meeting;
			return acc;
		}, {});

		setUpcomingMeetings((prev) =>
			prev.map((meeting) => {
				const updatedMeeting = meetingsDict[meeting.id];
				if (updatedMeeting) {
					return {
						...meeting,
						recapOnly: updatedMeeting.recapOnly ?? meeting.recapOnly,
						botMetadata: updatedMeeting.botMetadata || meeting.botMetadata,
					};
				}
				return meeting;
			})
		);
	}, [data]);

	const getMeetings = async (params?: any) => {
		try {
			if (cancelTokenSource) {
				cancelTokenSource.cancel('New request coming in'); // Cancel the previous request
			}

			const newSource = axios.CancelToken.source();
			setCancelTokenSource(newSource);

			setLoading(true);
			const { searchValue = '', skip = 0, newTotal } = params || {};

			const filter = {
				status: undefined,
				isExpired: undefined,
				startAt: undefined,
				recapOnly: undefined,
			};

			let currentOrder = order;

			if (segmentValue === 'live') {
				if (!order) currentOrder = '-publishedAt';
				filter.status = 'published';
				filter.isExpired = false;
			} else if (segmentValue === 'upcoming') {
				filter.status = 'scheduled,created,planned';
				if (!order) currentOrder = 'startAt';
			} else if (segmentValue === 'review') {
				filter.status = 'review,audit,processing,draft,pending';
			} else if (segmentValue === 'expired') {
				filter.status = 'published';
				filter.isExpired = true;
			}

			filter.recapOnly = recapOnly;

			if (dateRange[0] && dateRange[1]) {
				filter.startAt = {
					start: dateRange[0]?.getTime(),
					end: dateRange[1]?.getTime(),
				};
			}

			// if newTotal is provided, we need to take this value into account
			// in the query offset and also setting the pages...
			let offset;
			if (newTotal) {
				offset =
					newTotal % limit === 0
						? (Math.floor(newTotal / limit) - 1) * limit
						: Math.floor(newTotal / limit) * limit;
				offset = Math.min(offset, isNaN(params.skip) ? 0 : Number(params.skip));
			} else {
				offset = isNaN(params.skip) ? 0 : Number(params.skip);
			}
			const query = {
				organizationID: currentUser.currentOrganizationID,
				...filter,
				search: currentSearchValue ? currentSearchValue : search,
				limit: params.limit || 20,
				offset,
				order: currentOrder,
			};

			const searchParam = currentSearchValue
				? currentSearchValue
				: search || '';

			setSearchParams({
				skip: String(offset),
				...(searchParam.length > 0 && {
					search: encode(searchParam),
				}),
				offset,
			});

			const response = await searchMeetings(query, newSource.token);
			const { count, data } = response?.data || {};

			// page setting should always take the count into account
			// if the newTotal is provided, we should use that value
			// we need to take the current page into account as well and not only default to the last page
			if (newTotal) {
				const newPage =
					newTotal % limit === 0
						? Math.floor(newTotal / limit)
						: Math.floor(newTotal / limit) + 1;
				const lowestPage = Math.min(page, newPage);
				setPage(lowestPage);
			} else {
				setPage(Math.floor(offset / limit) + 1);
			}
			if (count !== undefined) setTotal(count);
			if (data !== undefined) setData(data);
		} catch (error) {
			console.error(`Meetings.getMeetings: error:`, error);
			logger('error', 'Meetings.getMeetings err', error);
		} finally {
			setLoading(false);
		}
	};

	const renderContent = () => {
		if (viewType === 'card') {
			return (
				<MeetingsCardView
					meetings={data}
					setMeetings={setData}
					searchValue={search}
					loading={!isInitialized || loading}
					meetingCount={total}
					cardsPerPage={limit}
					handleGetMeetingsData={getMeetings}
					segmentValue={segmentValue}
					openSharingModal={openSharingModal}
				/>
			);
		} else {
			return (
				<MeetingTable
					meetings={data}
					setMeetings={setData}
					search={search}
					meetingCount={total}
					// activePage={page}
					// setActivePage={setActivePage}
					rowsPerPage={limit}
					fetchingMeetings={loading}
					changeSort={changeSort}
					segmentValueProp={segmentValue}
					openSharingModal={openSharingModal}
				/>
			);
		}
	};

	const bulkActionDeleteMeetings = async () => {
		try {
			if (!selection.length) {
				showFailureNotification({
					message:
						'You must select at least one meeting before performing this bulk action.',
				});
				return;
			}
			const meetings = selection
				.map((id) => data.find((m) => m.id === id))
				.filter(Boolean);
			if (!meetings.every((m) => m?.owningUserID === currentUser.id)) {
				showFailureNotification({
					message:
						'You can only delete meetings you own. Please uncheck the meetings you are not an owner of.',
				});
				return;
			}
			const deletedMeetings = await Promise.all(
				meetings.map((meeting) =>
					destroyMeeting(meeting.id, currentUser.currentOrganizationID)
				)
			);
			setSelection([]);
			const deletedNumber = deletedMeetings.length;
			setSelection([]);
			await refresh(total - deletedNumber);
			setOpened(false);
			setReloadMeetings((o) => !o);
			showSuccessNotification({
				message: `Meetings were successfully deleted.`,
			});
		} catch (err) {
			logger('error', 'Error deleting meeting', err);
			showFailureNotification({
				message: 'Error deleting meeting. Please try again.',
			});
		}
	};

	const handleBulkAddToCollection = async (meetingIDs: string[]) => {
		if (!meetingIDs.length) {
			showFailureNotification({
				message:
					'You must select at least one meeting before performing this bulk action.',
			});
			return;
		}
		const meetingsToAdd = meetingIDs
			.map((id) => data.find((m) => m.id === id))
			.filter(Boolean);
		setAddMeetingToCollectionModalOpened(true);
		setMeetingsToAddToCollection(meetingsToAdd);
	};

	const handleBulkArchive = async (meetingIDs: string[]) => {
		try {
			setArchiveBulkButtonLoading(true);
			if (!meetingIDs.length) {
				showFailureNotification({
					message:
						'You must select at least one meeting before performing this bulk action.',
				});
				return;
			}

			const archivedMeetings = await addMeetingsToCollection(
				archiveCollection.id,
				meetingIDs
			);
			const archivedNumber = archivedMeetings.length;
			setSelection([]);
			await refresh(total - archivedNumber);
			showSuccessNotification({
				message: `Meetings were successfully archived.`,
			});
		} catch (err) {
			logger('error', 'Error archiving meeting', err);
			showFailureNotification({
				message: `Apologies, the meetings were not successfully archived. Please try again.`,
			});
		} finally {
			setArchiveBulkButtonLoading(false);
		}
	};

	return (
		<>
			<div id='sharing-modals'>
				<SharingModal
					opened={sharingModalOpened}
					close={closeSharingModal}
					organizationID={currentUser.currentOrganizationID}
				/>
			</div>
			<MeetingsHeader
				searchValue={search}
				handleSearch={handleSearch}
				opened={opened}
				setOpened={setOpened}
				bulkActionDeleteMeetings={bulkActionDeleteMeetings}
				handleBulkAddToCollection={handleBulkAddToCollection}
				handleBulkArchive={handleBulkArchive}
				dateRange={dateRange}
				setDateRange={setDateRange}
				segmentValue={segmentValue}
			/>

			<div
				style={{
					height: 'calc(100% - 90px)',
					width: '100%',
					margin: 0,
					padding: 0,
					paddingTop: '16px',
				}}
			>
				<ScrollArea
					viewportRef={viewport}
					h={'100%'}
					w={'calc(100% + 10px)'}
					pb={'sm'}
					offsetScrollbars
					styles={(theme) => ({
						viewport: {
							height: '100%',
							position: 'relative',
						},
						scrollbar: {
							right: 0,
						},
						thumb: {
							right: 0,
						},
					})}
				>
					{renderContent()}
				</ScrollArea>
			</div>

			<Group position='right' h={32}>
				<Pagination
					withEdges
					value={page}
					onChange={changePage}
					total={Math.ceil(total / limit)}
				/>
				<Text mt={0} color='secondary-text' align={'center'} size={12}>
					{`${skip}-${skip + limit > total ? total : skip + limit} of ${total}`}
				</Text>
			</Group>
		</>
	);
}
