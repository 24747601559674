import React, { useEffect, useState } from 'react';
import {
	Button,
	Checkbox,
	Group,
	Modal,
	Stack,
	Text,
	useMantineTheme,
} from '@mantine/core';
import useUserSettings from '../../../customHooks/useUserSettings';
import IntegrationCard from '../../integration_card';
import { calendarIntegrationList } from '../../constants';

interface Props {
	opened: boolean;
	close: () => void;
}

export default function CalendarIntegrationModal({ opened, close }: Props) {
	const theme = useMantineTheme();
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState(false);
	const [dontAskAgainChecked, setDontAskAgainChecked] = useState(false);
	const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
	const { updateUserSettings, userSettings } = useUserSettings();
	const [value, setValue] = useState<string | null>(
		userSettings?.time_zone || tz
	);

	const handleNotYet = async () => {
		// check of dont ask again is checked. if checked then set that as well
		if (dontAskAgainChecked) {
			const res = await updateUserSettings([
				{
					key: 'calendar_modal_dont_ask_again',
					value: true,
				},
			]);
		}
		// close modal
		close();
	};

	return (
		<Modal
			opened={opened}
			onClose={close}
			title={
				<Text size={32} weight={500}>
					Connect your work calendar
				</Text>
			}
			overlayProps={{
				opacity: 0.55,
				blur: 3,
			}}
			closeOnEscape={false}
			closeOnClickOutside={false}
			withCloseButton={false}
			trapFocus={false}
			radius={'lg'}
			styles={(theme) => ({
				header: {
					borderTopLeftRadius: theme.radius.lg,
					borderTopRightRadius: theme.radius.lg,
					paddingLeft: 32,
					paddingRight: 32,
				},
				body: {
					padding: 32,
					paddingBottom: theme.spacing.md,
				},
			})}
		>
			<Stack align={'center'}>
				<Text>
					With Reelay connected to your calendar we'll be able to assist you in
					any Zoom, Teams, Meet, or WebEx calls of your choosing, next you'll
					select which calls reelay can automatically join.
				</Text>
				<Group noWrap position='apart'>
					{calendarIntegrationList.map((item, index) => (
						<IntegrationCard
							key={item.platform}
							title={calendarIntegrationList[index]['Title']}
							image={calendarIntegrationList[index]['image']}
							enabled={calendarIntegrationList[index]['enabled']}
							description={calendarIntegrationList[index]['description']}
							data={calendarIntegrationList[index]}
							noButton={true}
						/>
					))}
				</Group>
				<Text color='secondary-gray' w={'100%'}>
					* Your IT Admin may need to approve this request
				</Text>
				<Group
					position={'apart'}
					w={`calc(100% + 32px + 32px)`}
					style={{
						borderTop: `1px solid ${theme.colors['reelay-secondary-light'][0]}`,
					}}
					pt={'md'}
					px={32}
				>
					<Checkbox
						checked={dontAskAgainChecked}
						onChange={(event) =>
							setDontAskAgainChecked(event.currentTarget.checked)
						}
						styles={(theme) => ({
							label: {
								color: theme.colors['secondary-gray'][0],
							},
						})}
						label={'Do not ask me again'}
						color='secondary-gray'
					/>
					<Button onClick={handleNotYet} variant={'subtle'}>
						{dontAskAgainChecked ? 'Confirm' : 'Not yet'}
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
