import { MantineTheme, MantineThemeColors } from '@mantine/core';

export const buildThemeWithBrandColors = (
  colors: MantineThemeColors,
  brand
) => {
  const defaultPrimary = [
    '#ffffff',
    '#EFF6FF',
    '#3b368f',
    '#3b368f',
    '#3b368f',
    '#3b368f',
    '#3b368f',
    '#3b368f',
    '#3b368f',
    '#3b368f',
  ];

  const defaultSecondary = [
    '#ffffff',
    '#EFF6FF',
    '#3b368f',
    '#3b368f',
    '#3b368f',
    '#3b368f',
    '#3b368f',
    '#3b368f',
    '#3b368f',
    '#3b368f',
  ];

  const primary: any = brand?.color_primary
    ? Array.from(
        { length: 10 },
        (_, index) => colors[brand.color_primary][index]
      )
    : defaultPrimary;
  const secondary: any = brand?.color_secondary
    ? Array.from(
        { length: 10 },
        (_, index) => colors[brand.color_secondary][index]
      )
    : defaultSecondary;

  return {
    colors: {
      ...colors,
      primary,
      secondary,
    },
    primaryShade: brand?.color_level_primary || 6,
  };
};
