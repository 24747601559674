import React from 'react';
import { Badge, BadgeVariant } from '@mantine/core';
import { BotCreationStatus, BotStatus } from '../../../interfaces/recallai';

export const StatusBadge = ({
	status,
	variant = 'light',
	isThumbnailBadge = false,
	botStatus = '',
	botCreationStatus = '',
}: {
	status: string;
	variant?: BadgeVariant;
	isThumbnailBadge?: boolean;
	botStatus?: string;
	botCreationStatus?: string;
}) => {
	const getStatusBadge = () => {
		const thumbnailStyling = isThumbnailBadge
			? {
					marginBottom: '1.5%',
					marginLeft: '1.5%',
			  }
			: {};

		if (
			botStatus === BotStatus.BotDeleted ||
			(typeof botCreationStatus === 'string' &&
				botCreationStatus.length &&
				botCreationStatus !== BotCreationStatus.CREATED)
		) {
			return (
				<Badge
					style={{ ...thumbnailStyling }}
					variant={variant}
					color={'red'}
					size='xs'
				>
					Don't Join
				</Badge>
			);
		}

		switch (status) {
			case 'created':
				return (
					<Badge
						style={{ ...thumbnailStyling }}
						variant={variant}
						color={'gray'}
						size='xs'
					>
						Created
					</Badge>
				);
			case 'scheduled':
				return (
					<Badge
						style={{ ...thumbnailStyling }}
						variant={variant}
						color={'gray'}
						size='xs'
					>
						Scheduled
					</Badge>
				);
			case 'pending':
				return (
					<Badge
						style={{ ...thumbnailStyling }}
						variant={variant}
						color='yellow'
						size='xs'
					>
						Pending
					</Badge>
				);
			case 'draft':
				return (
					<Badge
						style={{ ...thumbnailStyling }}
						variant={variant}
						color='blue'
						size='xs'
					>
						Draft
					</Badge>
				);
			case 'processing':
				return (
					<Badge
						style={{ ...thumbnailStyling }}
						variant={variant}
						color='green'
						size='xs'
					>
						Processing
					</Badge>
				);
			case 'audit':
				return (
					<Badge
						style={{ ...thumbnailStyling }}
						variant={variant}
						color='indigo'
						size='xs'
					>
						Audit
					</Badge>
				);
			case 'review':
				return (
					<Badge
						style={{ ...thumbnailStyling }}
						variant={variant}
						color='green'
						size='xs'
					>
						Review
					</Badge>
				);
			case 'expired':
				return (
					<Badge
						style={{ ...thumbnailStyling }}
						variant={variant}
						size='xs'
						color={'red'}
					>
						Expired
					</Badge>
				);
			case 'published':
				return (
					<Badge
						style={{ ...thumbnailStyling }}
						variant={variant}
						size='xs'
						color={'violet'}
					>
						Published
					</Badge>
				);
			case 'planned':
				return (
					<Badge
						style={{ ...thumbnailStyling }}
						variant={variant}
						size='xs'
						color={'orange'}
					>
						Planned
					</Badge>
				);
		}
	};
	return getStatusBadge();
};
