import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
	Modal,
	Button,
	Text,
	List,
	Radio,
	Group,
	Loader,
	Stack,
	useMantineTheme,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faCheckCircle,
	faCircle,
	faXmarkCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { reelayAIModelList } from '../../constants';
import { fetchMeetingDetails } from '../../../api/reelayAIService';
import { IMeeting } from '../../../interfaces/meeting';
import { useRecoilValue } from 'recoil';
import { currentUser } from '../../../Atoms/userAtoms';
import {
	clearAllHighlights,
	updateMeeting,
	updateMeetingChapters,
} from '../../../api/api';
import _ from 'lodash';
import { createHighlights } from '../../../helpers/meetings';
import ModalTitle from '../../Titles/ModalTitle';
import { logger } from '../../../helpers/logger';

interface ResolveMissingDetailsModalProps {
	opened: boolean;
	close: () => void;
	keys: string[];
	meeting: IMeeting;
	processingStatus: {
		[key: string]: boolean;
	};
	setProcessingStatus: Dispatch<
		SetStateAction<{
			[key: string]: boolean;
		}>
	>;
	currentKey: string;
	setCurrentKey: Dispatch<SetStateAction<string>>;
	publishMeeting: (meeting: IMeeting, confirmed?: boolean) => void;
}

const details = {
	summary: 'Summary',
	longSummary: 'Long Summary',
	highlights: 'Highlights',
	actions: 'Actions',
	questions: 'Questions',
	chapters: 'Chapters',
};

const ResolveMissingDetailsModal: React.FC<ResolveMissingDetailsModalProps> = ({
	opened,
	close,
	keys,
	meeting,
	processingStatus,
	setProcessingStatus,
	currentKey,
	setCurrentKey,
	publishMeeting,
}) => {
	const theme = useMantineTheme();
	const user = useRecoilValue(currentUser);
	const [selectedModel, setSelectedModel] = useState<string>('');
	const [currentStep, setCurrentStep] = useState<number>(0);
	const [askResolve, setAskResolve] = useState<boolean>(false);
	const [askRetry, setAskRetry] = useState<boolean>(false);
	const [askPublish, setAskPublish] = useState<boolean>(false);
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [buttonLoading, setButtonLoading] = useState<boolean>(false);

	useEffect(() => {
		if (opened) {
			setCurrentStep(0);
			setCurrentIndex(0);
		}

		return () => {
			setSelectedModel('');
			setAskPublish(false);
			setButtonLoading(false);
			setAskResolve(false);
			setAskRetry(false);
		};
	}, [opened]);

	useEffect(() => {
		if (currentStep === 1) {
			regenerateDetails(currentIndex);
		}
	}, [currentStep]);

	const regenerateDetails = async (index = 0) => {
		for (let i = index; i < keys.length; i++) {
			const key = keys[i];
			try {
				setCurrentKey(key);

				if (processingStatus[key]) {
					continue;
				}

				// Pause the loop and render question
				setAskResolve(true);
				setCurrentIndex(i); // Save the current index
				return;
			} catch (error) {
				logger('error', 'Error regenerating details', error);
			}
		}

		// All keys processed
		setAskPublish(true);
	};

	const handleUserChoice = async (choice: boolean) => {
		setAskResolve(false);
		if (choice) {
			let attempts = 0;
			const maxAttempts = 2;
			while (attempts < maxAttempts) {
				try {
					const { response } = await fetchMeetingDetails(
						meeting,
						currentKey,
						selectedModel,
						user,
						null,
						false
					);
					// process details here
					// summary and longSummary
					if (currentKey === 'summary' || currentKey === 'longSummary') {
						const value =
							currentKey === 'summary'
								? response?.short_summary?.content
								: response?.long_summary?.content;
						const updatedMeeting = await updateMeeting(
							{
								[currentKey]: value,
							},
							meeting.id,
							meeting.organizationID
						);
					}
					// chapters
					else if (currentKey === 'chapters') {
						const payload = [];
						const chaptersData = _.get(response, '1', []);
						if (Array.isArray(chaptersData)) {
							for (const chapterData of chaptersData) {
								payload.push({
									summary: _.get(chapterData, 'summary'),
									headline: _.get(chapterData, 'value'),
									content: _.get(chapterData, 'value'),
									gist: _.get(chapterData, 'value'),
									start: _.get(chapterData, 'timeStartMS'),
									end: _.get(chapterData, 'timeStartMS'),
									isTopQuestion: _.get(chapterData, 'isTopQuestion'),
								});
							}
						}
						const result = await updateMeetingChapters(
							{
								chapters: payload,
							},
							{
								meetingID: meeting.id,
								organizationID: meeting.organizationID,
							}
						);
					}
					// highlights
					else {
						const type = currentKey.slice(0, -1);
						// delete existing highlights first
						const clearedHighlights = await clearAllHighlights(
							meeting.id,
							type,
							meeting.organizationID
						);
						const newMoments = response[currentKey].map((highlight: any) => {
							const content = highlight.content || 'Unknown Content';
							return {
								value: content,
								label: content,
								timeStartMS: highlight.time,
								id: null,
								isTopQuestion: highlight.isTopQuestion,
							};
						});
						const moments = await createHighlights(
							newMoments,
							meeting.id,
							meeting.organizationID,
							type
						);
					}

					setProcessingStatus((prev) => ({
						...prev,
						[currentKey]: true,
					}));
					regenerateDetails(currentIndex + 1); // Resume from the next index
					return; // Exit the retry loop if the fetch is successful
				} catch (error) {
					attempts++;

					if (attempts >= maxAttempts) {
						setAskRetry(true);
					}
				}
			}
		} else {
			regenerateDetails(currentIndex + 1); // Skip to the next index if user chooses not to resolve
		}
	};

	const handleRetry = async (retry: boolean) => {
		setAskRetry(false);
		if (retry) {
			handleUserChoice(true);
		} else {
			regenerateDetails(currentIndex + 1); // Skip to the next index if user chooses not to retry
		}
	};

	const handleNextDetail = () => {
		setAskRetry(false);
		regenerateDetails(currentIndex + 1); // Resume from the next index
	};

	const handleNext = () => {
		if (currentStep === 0 && selectedModel) {
			setCurrentStep(1);
		} else if (currentStep === 1) {
			setCurrentStep(2);
			regenerateDetails();
		}
	};

	const handlePublishMeeting = async () => {
		try {
			setButtonLoading(true);
			await publishMeeting(meeting, true);
			close();
		} catch (error) {
			logger('error', 'Error publishing meeting', error);
			setButtonLoading(false);
		}
	};

	return (
		<Modal
			opened={opened}
			onClose={close}
			title={<ModalTitle text={'Resolve Missing Details'} />}
			closeOnClickOutside={currentStep === 0}
			closeOnEscape={currentStep === 0}
			padding={'xl'}
			radius={'lg'}
			size={'lg'}
		>
			<Stack spacing={'xl'}>
				{currentStep === 0 && (
					<>
						<Radio.Group
							name='aiModel'
							label='Select a model'
							description='Choose the AI model you want to use'
							withAsterisk
							value={selectedModel}
							onChange={setSelectedModel}
							style={{ marginBottom: '20px' }}
						>
							<Group mt='xs' noWrap>
								{reelayAIModelList.map((model) => (
									<Radio
										size='xs'
										key={model.value}
										value={model.value}
										label={model.label}
									/>
								))}
							</Group>
						</Radio.Group>
						<Group mt='lg' position='right'>
							<Button onClick={handleNext} disabled={!selectedModel}>
								Next
							</Button>
						</Group>
					</>
				)}

				{currentStep === 1 && (
					<List spacing='xs' size='sm' center>
						{keys.map((key, index) => (
							<List.Item
								key={key}
								icon={
									index > currentIndex && !askPublish ? (
										<FontAwesomeIcon
											icon={faCircle}
											style={{ color: 'teal', fontSize: '24px' }}
										/>
									) : processingStatus[key] ? (
										<FontAwesomeIcon
											icon={faCheckCircle}
											style={{ color: 'teal', fontSize: '24px' }}
										/>
									) : currentKey === key && !askResolve && !askRetry ? (
										<Loader size='24px' />
									) : (
										<FontAwesomeIcon
											icon={faXmarkCircle}
											style={{ color: 'red', fontSize: '24px' }}
										/>
									)
								}
							>
								{processingStatus[key]
									? `${details[key]} good`
									: currentKey === key
									? `Regenerating ${details[key]}...`
									: `${details[key]}`}
							</List.Item>
						))}
					</List>
				)}

				{askResolve && (
					<Stack>
						<Text>{`Do you want to resolve ${details[currentKey]} for this meeting?`}</Text>
						<Group position='right' noWrap>
							<Button onClick={() => handleUserChoice(false)} variant='outline'>
								No
							</Button>
							<Button onClick={() => handleUserChoice(true)}>Yes</Button>
						</Group>
					</Stack>
				)}

				{askRetry && (
					<Stack>
						<Text>{`Failed to regenerate ${details[currentKey]}. Do you want to retry?`}</Text>
						<Group position='right' noWrap>
							<Button onClick={() => handleRetry(false)} variant='outline'>
								No
							</Button>
							<Button onClick={() => handleNextDetail()} variant='outline'>
								Next Detail
							</Button>
							<Button onClick={() => handleRetry(true)}>Yes</Button>
						</Group>
					</Stack>
				)}
				{askPublish && (
					<Stack>
						<Text>Do you want to publish this meeting now?</Text>
						<Group position='right' noWrap>
							<Button onClick={close} variant='outline'>
								No
							</Button>
							<Button onClick={handlePublishMeeting} loading={buttonLoading}>
								Yes
							</Button>
						</Group>
					</Stack>
				)}
			</Stack>
		</Modal>
	);
};

export default ResolveMissingDetailsModal;
