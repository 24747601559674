import { SupportMeetingsTable } from '../../../components/SupportMeetingsTable';
import { decode } from 'js-base64';
import { useSearchParams } from 'react-router-dom';

export default function MeetingManagement(props) {
	const { segmentValue } = props;
	const [searchParams] = useSearchParams();
	const skipParam = searchParams.get('skip');
	const order = searchParams.get('order') ?? '';
	const skip = isNaN(skipParam as unknown as number) ? 0 : Number(skipParam);
	const searchParam = searchParams.get('search');
	const search = searchParam ? String(searchParam).trim() : '';

	return (
		<div style={{ overflowX: 'auto', height: '100%' }}>
			<SupportMeetingsTable
				isAdminTable
				segmentValue={segmentValue}
				skip={skip}
				search={decode(search)}
				order={order}
			/>
		</div>
	);
}
