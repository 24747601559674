import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { currentMeetingDistributionList } from '../Atoms/meetingAtom';
import { getMeetingInvitedUsers } from '../api/api';
import { logger } from '../helpers/logger';

const useDistributionList = (organizationID, meetingID) => {
	const [distributionList, setDistributionList] = useRecoilState(
		currentMeetingDistributionList
	);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchUsersLists = async () => {
			if (organizationID) {
				logger('info', 'fetching users lists', {
					meetingID,
					organizationID,
				});
				try {
					setLoading(true);
					const res = await getMeetingInvitedUsers(meetingID, organizationID);
					const invitedUsers = res?.data?.data?.invitedUsers || [];
					const filteredDistributionList = invitedUsers.filter(
						(user) => !user?.userlink?.presented
					);
					setDistributionList(filteredDistributionList);
				} catch (error) {
					logger('error', 'error fetching users lists', {
						meetingID,
						organizationID,
						error,
					});
				} finally {
					setLoading(false);
				}
			}
		};

		fetchUsersLists();
	}, [organizationID, meetingID, setDistributionList]);

	return { distributionList, loading, setDistributionList };
};

export default useDistributionList;
