import React, { useEffect, useState } from 'react';
import {
	TextInput,
	FileButton,
	Button,
	Modal,
	createStyles,
	Text,
	Group,
	Title,
	Stack,
	RingProgress,
	Divider,
	Center,
	FileInputProps,
	Alert,
	CloseButton,
	Grid,
	Space,
	ActionIcon,
	FileInput,
	List,
	Tooltip,
	Checkbox,
	Indicator,
} from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';
import theme from '../../_utils/theme';
import { useForm } from '@mantine/form';
import * as UpChunk from '@mux/upchunk';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentUserReelays,
	User,
	user as userAtom,
	userOrganizationMembers,
	userOrganization,
} from '../../Atoms/userAtoms';
import {
	meetingVideoUploadProgressBars,
	myMeetings as MyMeetingsAtom,
	uploadingVideoAtom,
} from '../../Atoms/meetingAtom';
import {
	addAttachmentsToMeeting,
	createMeeting,
	destroyMeeting,
	inviteUsersToMeeting,
	updateMeeting,
} from '../../api/api';
import { IMeeting, MeetingDetails } from '../../interfaces/meeting';
import { DateInput, TimeInput } from '@mantine/dates';
import { DateTime } from 'luxon';
import { UsersMultiSelect } from '../UsersMultiSelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faArrowLeft,
	faCircleInfo,
	faPaperclip,
	faPlus,
	faUpload,
} from '@fortawesome/pro-solid-svg-icons';
import {
	faTrash,
	faTriangleExclamation,
} from '@fortawesome/pro-light-svg-icons';
import { FilePreviewModal } from './FilePreviewModal';
import ReelayActionCard from './ReelayActionCard';
import AddRecallBotForm from './AddRecallBotForm';
import { logger } from '../../helpers/logger';
import ReelayLogoPageLoader from '../loaders/ReelayLogoPageLoader';
import { ReelayLoader } from '../ReelayLoader';
import { RichTextEditorComponent } from '../RichTextEditorComponent';
import { capitalizeFirstLetter } from '../../_utils/handy-functions';
import { combineDateTime, handleFormatTime } from '../../helpers/date';
import { isToday } from '../../_utils/time';
import CustomCalendarDay from '../CustomCalendarDay';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { faCloudArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { botActionCards, uploadActionCards } from './constants';
import {
	filestackModalOpenedAtom,
	filestackUploadingAtom,
	usingUploadMeetingButtonAtom,
} from '../../Atoms/filestack';
import { set } from 'lodash';

const useStyles = createStyles((theme) => ({
	dateInput: {
		borderRadius: '8px',
		fontSize: '12px',
		width: '120px',
		// height: '40px',
	},
	timeInput: {
		borderRadius: '8px',
		fontSize: '12px',
		width: '100px',
		height: '40px',
	},
	addAttachmentButton: {
		maxWidth: '100px',
	},
	trashIcon: {
		cursor: 'pointer',
	},
	previewLink: {
		cursor: 'pointer',
	},
	webViewer: {
		height: '500px',
	},
	rte: {
		// maxHeight: '250px',
	},
}));

interface FormValues {
	name: string;
	objective: string;
	agenda: string;
	attachments: File[];
	invitees: string[];
	date: string;
	time: string;
	publishAtDate: string;
	publishAtTime: string;
	publishedLaterChecked: boolean;
}

const Value = ({ file }: { file: File }) => {
	return (
		<Center
			inline
			sx={(theme) => ({
				backgroundColor: theme.colors.gray[1],
				fontSize: theme.fontSizes.xs,
				padding: '3px 7px',
				borderRadius: theme.radius.sm,
			})}
		>
			<FontAwesomeIcon icon={faPaperclip} />
			<span
				style={{
					whiteSpace: 'nowrap',
					textOverflow: 'ellipsis',
					overflow: 'hidden',
					maxWidth: 200,
					display: 'inline-block',
					marginLeft: 10,
				}}
			>
				{file.name}
			</span>
		</Center>
	);
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
const ValueComponent: FileInputProps['valueComponent'] = ({ value }) => {
	if (Array.isArray(value)) {
		return (
			<Group spacing={'sm'}>
				{value.map((file, index) => (
					<Value file={file} key={index} />
				))}
			</Group>
		);
	}
};

export interface FilePreview {
	uri: string;
}

const CreateReelayModal = ({
	uploadMeetingFromMinutes,
}: {
	uploadMeetingFromMinutes?: (meeting: IMeeting) => void;
}) => {
	const { classes, theme } = useStyles();
	const [myMeetings, setMyMeetings] = useRecoilState(MyMeetingsAtom);
	const [allMeetings, setAllMeetings] = useRecoilState(currentUserReelays);
	const [opened, setOpened] = useState(false);
	const [meetingFile, setMeetingFile] = useState<File | null>(null);
	const [agendaValue, setAgendaValue] = useState('');
	const [active, setActive] = useState(0);
	const [alertActive, setAlertActive] = useState(false);
	const [meetingLinkPage, setMeetingLinkPage] = useState(false);
	const [formType, setFormType] = useState('');
	const [uploadComplete, setUploadComplete] = useState(false);
	const [uploadingVideo, setUploadingVideo] =
		useRecoilState(uploadingVideoAtom);
	// const [progress, setProgress] = useState(0);
	const [videoUploadProgressBars, setVideoUploadProgressBars] = useRecoilState(
		meetingVideoUploadProgressBars
	);
	const setFilestackModalOpened = useSetRecoilState(filestackModalOpenedAtom);
	const filestackUploading = useRecoilValue(filestackUploadingAtom);
	const setUsingUploadMeetingButton = useSetRecoilState(
		usingUploadMeetingButtonAtom
	);
	const [statusMessage, setStatusMessage] = useState('');
	const [meetingID, setMeetingID] = useState('');
	const [createButtonLoading, setCreateButtonLoading] = useState(false);
	const [files, setFiles] = useState<File[]>([]);
	const [filePreviews, setFilePreviews] = useState<any>({});
	const [currentFilePreview, setCurrentFilePreview] = useState<number>(0);
	const [previewClicked, setPreviewClicked] = useState<boolean>(false);
	const [viewingPreview, setViewingPreview] = useState<boolean>(false);
	const [meetingIDThatWasJustUploaded, setMeetingIDThatWasJustUploaded] =
		useState('');
	const organization = useRecoilValue(userOrganization);
	const organizationID = organization.id;
	const user = useRecoilValue(userAtom);
	const users = useRecoilValue(userOrganizationMembers);
	const userData = users
		.map((user: User) => ({
			...user,
			label: `${user.firstName ? user.firstName + ' ' : ''}${
				user.lastName ? user.lastName + ', ' : ''
			}${user.email}`,
			value: user.email,
		}))
		.filter((item: any) => user.id !== item.id);

	window.onbeforeunload = function () {
		if (uploadingVideo)
			return 'You are currently uploading a video. Your progress will be lost if you leave the page, are you sure to leave?';
	};

	/**
	 * The purpose of this useEffect is to update the status of the meeting
	 * to be a draft when it completes uploading. in the callback upload.on (success)
	 * function, myMeetings does not contain the new meeting in its array.
	 * So i will just update here when uploadComplete is true.
	 */
	useEffect(() => {
		if (uploadComplete) {
			setMyMeetings(
				myMeetings.map((meeting) => {
					if (meeting.id === meetingID) {
						return {
							...meeting,
							status: 'draft',
						};
					}
					return meeting;
				})
			);
		}
	}, [uploadComplete]);

	useEffect(() => {
		// whenever modal closes, reset data and states
		if (!opened) {
			setMeetingFile(null);
			setMeetingLinkPage(false);
			setFormType('');
			setActive(0);
		}
	}, [opened]);

	const nextStep = () =>
		setActive((current) => {
			if (form.validate().hasErrors) return current;

			return current < 2 ? current + 1 : current;
		});

	const prevStep = () =>
		setActive((current) => (current > 0 ? current - 1 : current));

	const destroyReelay = async () => {
		try {
			if (meetingID) {
				await destroyMeeting(meetingID, organizationID);
			}
		} catch (err: any) {
			logger('error', 'Error destroying meeting', err);
		} finally {
			if (!meetingLinkPage) setOpened(false);
			setActive(0);
			form.reset();
			setUploadComplete(false);
			setAgendaValue('');
			setMeetingFile(null);
			setFormType('');
			setMeetingLinkPage(false);
			setVideoUploadProgressBars({
				...videoUploadProgressBars,
				[meetingID]: 0,
			});
		}
	};

	const updateReelay = async (values) => {
		let errorMessage;
		try {
			if (values.name.trim().length === 0) {
				errorMessage = 'Required name field is missing.';
				throw new Error(errorMessage);
			}

			setCreateButtonLoading(true);
			let addAttachments;
			if (form.validate().hasErrors) {
				setCreateButtonLoading(false);
				return;
			}

			let combinedDateTimeISOString;
			if (values.date) {
				const date = DateTime.fromJSDate(values.date).toLocaleString(
					DateTime.DATE_MED
				);
				const combinedDateTime = new Date(`${date} ${values.time}`);
				combinedDateTimeISOString = combinedDateTime.toISOString();
				if (new Date() > combinedDateTime) {
					errorMessage = 'Viewing deadline must be in future.';
					throw new Error(errorMessage);
				}
			}

			const meetingDetails: MeetingDetails = {
				name: values.name,
				objective: values.objective,
				agenda: agendaValue,
				organizationID,
				status: 'draft',
				viewingDeadline: combinedDateTimeISOString,
			};

			if (values.publishedLaterChecked) {
				const publishAtDate = DateTime.fromJSDate(
					values.publishAtDate
				).toLocaleString(DateTime.DATE_MED);
				const publishAtTime = DateTime.fromJSDate(
					values.publishAtTime
				).toLocaleString(DateTime.TIME_SIMPLE);
				const combinedPublishedAtDateTimeISOString = combineDateTime(
					values.publishAtDate,
					values.publishAtTime
				)
					.toJSDate()
					.getTime();
				meetingDetails.publishedAt = combinedPublishedAtDateTimeISOString;
			}

			const attachmentData = new FormData();
			files.forEach((file, index) => {
				attachmentData.append(`attachment${index}`, file, file.name);
			});
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			const updatedMeeting = await updateMeeting(meetingDetails, meetingID);
			if (values.invitees.length)
				await inviteUsersToMeeting({
					emails: values.invitees,
					meetingID,
					organizationID,
				});
			if (files.length > 0) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				addAttachments = await addAttachmentsToMeeting({
					data: attachmentData,
					params: { meetingID, organizationID },
					headers: { 'Content-Type': undefined },
				});
			}

			setAgendaValue('');
			const newMeeting = {
				...updatedMeeting.data.data,
				status: !uploadingVideo ? 'draft' : updatedMeeting.data.data.status,
			};
			setMyMeetings([newMeeting, ...myMeetings]);
			setAllMeetings([newMeeting, ...allMeetings]);
			setCreateButtonLoading(false);
			setFiles([]);
			setOpened(false);
			setActive(0);
			form.reset();
			setUploadComplete(false);
			setAgendaValue('');
			setMeetingFile(null);
			setFormType('');
			setMeetingLinkPage(false);
			setVideoUploadProgressBars({
				...videoUploadProgressBars,
				[meetingID]: 0,
			});
			setCreateButtonLoading(false);
			if (uploadMeetingFromMinutes) uploadMeetingFromMinutes(newMeeting);
			return { addAttachments, updatedMeeting };
		} catch (err) {
			logger('error', 'Error updating meeting', err);
			errorMessage = errorMessage || 'Please try again later.';
			showFailureNotification({
				message: `Failed to upload a meeting. ${errorMessage}`,
			});
			setCreateButtonLoading(false);
		}
	};

	const form = useForm<FormValues>({
		initialValues: {
			name: '',
			objective: '',
			agenda: '',
			attachments: [],
			invitees: [],
			date: '',
			time: '',
			publishAtDate: '',
			publishAtTime: '',
			publishedLaterChecked: false,
		},
		validate:
			active === 1
				? {
						name: (value) =>
							value.trim().length < 1 ? 'Reelay Title is required' : null,
						attachments: (value) =>
							value.every((file: File) => file.size <= 25000000)
								? null
								: 'File size too large. Must be 25MB or less.',
				  }
				: {
						// published date and time must be in the future
						date: (value, values) => {
							const {
								date,
								time,
								publishAtDate,
								publishAtTime,
								publishedLaterChecked,
							} = values;
							if (!publishedLaterChecked) {
								form.clearErrors();
								return null;
							}

							if (date < publishAtDate) {
								return 'Viewing deadline must be after publish date.';
							}
							form.setFieldError('date', null);
							return null;
						},
						time: (value, values) => {
							const {
								date,
								time,
								publishAtDate,
								publishAtTime,
								publishedLaterChecked,
							} = values;
							if (!publishedLaterChecked) {
								form.clearErrors();
								return null;
							}

							if (!publishAtTime || !publishAtDate) return null;

							const combinedDateTimeISOString = handleFormatTime(
								new Date(date),
								time
							);
							const combinedPublishedAtDateTimeISOString = combineDateTime(
								new Date(publishAtDate),
								publishAtTime
							).toJSDate();
							const date1 = new Date(combinedDateTimeISOString);
							// const date2 = new Date(combinedPublishedAtDateTimeISOString);
							if (date1 < combinedPublishedAtDateTimeISOString)
								return 'Viewing deadline must be after publish date.';

							form.setFieldError('time', null);
							return null;
						},
				  },
		validateInputOnChange: true,
	});

	const handleModalOpen = () => {
		setOpened(true);
	};

	const handleUpload = () => {
		// opens filestack modal
		setFilestackModalOpened(true);
		setUsingUploadMeetingButton(true);
	};

	const handleModalClose = async () => {
		if (meetingLinkPage) {
			setActive(0);
		} else if (active > 1) setAlertActive(true);
		setMeetingLinkPage(false);
		setFormType('');
	};

	const handleCloseButtonClicked = () => {
		setAlertActive(true);
	};

	const handleAlertClose = () => {
		setAlertActive(false);
	};

	const handleConfirmClose = async () => {
		// meetings are created when modal is opened. If the user closes modal before completing
		// form, then you need to destroy the meeting.
		destroyReelay();
	};

	const handleConfirmCloseStepTwo = async () => {
		// meetings are created when modal is opened. If the user closes modal before completing
		// form, then you need to destroy the meeting.
		destroyReelay();
	};

	const handleMeetingUpload = async (file: File) => {
		setMeetingFile(file);
		setUploadingVideo(true);
		const createdMeeting = await createMeeting({
			organizationID,
			platform: 'uploaded',
			recapOnly: false,
		});
		setMeetingID(createdMeeting.data.data.id);
		const upload = UpChunk.createUpload({
			endpoint: createdMeeting.data.data.videoMetadata.uploadURL, // Authenticated url
			file: file, // File object with your video file’s properties
			chunkSize: 5120, // Uploads the file in ~5mb chunks
		});

		// Subscribe to events
		upload.on('error', (error: unknown) => {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setStatusMessage(error.detail);
		});

		upload.on('progress', (progress) => {
			setVideoUploadProgressBars({
				...videoUploadProgressBars,
				[createdMeeting.data.data.id]: progress.detail,
			});
		});

		upload.on('success', async () => {
			setStatusMessage('Your meeting was successfully uploaded! 👋');
			showSuccessNotification({
				message: 'Your meeting was successfully uploaded!',
			});
			setMeetingIDThatWasJustUploaded(createdMeeting.data.data.id);
			setUploadComplete(true);
			setUploadingVideo(false);
		});
		nextStep();
	};

	const deleteAttachment = (index: number) => {
		const newFiles = [...files];
		newFiles.splice(index, 1);
		setFiles(newFiles);
	};

	const createFilePreviews = async (files: File[]) => {
		const result: FilePreview[] = [];
		for (let i = 0; i < files.length; i++) {
			const reader = new FileReader();
			await reader.readAsDataURL(files[i]);
			reader.onload = () => {
				result.push({ uri: reader.result as string });
				setFilePreviews((filePreviews: any) => ({
					...filePreviews,
					[i]: { uri: reader.result as string },
				}));
			};
			reader.onerror = () => {
				throw new Error('Fail to load the file');
			};
		}

		setFilePreviews(result);
	};

	const previewAttachment = (index: number) => {
		setCurrentFilePreview(index);
		setViewingPreview(true);
		setPreviewClicked(!previewClicked);
	};

	const navToMeetingLinkPage = (type: string) => {
		setMeetingLinkPage(true);
		setFormType(type);
	};

	const handleCardClick = (type) => {
		switch (type) {
			case 'past':
				return handleMeetingUpload;
			case 'live':
			case 'future':
				return navToMeetingLinkPage;
		}
	};

	const handleBackNav = () => {
		active === 1 ? setMeetingFile(null) : active === 2 ? setActive(1) : null;
	};

	return (
		<Group position='center' h={75}>
			<Modal
				centered
				opened={opened}
				onClose={() => handleModalClose()}
				radius={'md'}
				padding={25}
				size={'xl'}
				withCloseButton={false}
				zIndex={100000}
				styles={{
					body: {
						// minHeight: '550px',
					},
				}}
			>
				{alertActive && (
					<Alert color='red' radius='md' mb={25}>
						<Group position={'apart'}>
							<Group position={'left'}>
								<FontAwesomeIcon icon={faTriangleExclamation} color={'red'} />
								<Text size={12} color={'reelay-secondary-dark'}>
									Uploads and changes to this meeting will be lost. Are you sure
									you want to continue?
								</Text>
							</Group>

							<Group position={'right'}>
								<Button
									size={'sm'}
									radius={'md'}
									onClick={handleAlertClose}
									variant='outline'
									color='red'
								>
									No
								</Button>
								<Button
									size={'sm'}
									radius={'md'}
									onClick={handleConfirmCloseStepTwo}
									variant='filled'
									color='red'
								>
									Yes
								</Button>
							</Group>
						</Group>
					</Alert>
				)}
				<>
					{meetingLinkPage ? (
						<Group position={'apart'}>
							<Title order={3} weight={400}>
								{formType === 'live'
									? 'Add Reelay to a Live Meeting'
									: 'Add Reelay to an Upcoming Meeting'}
							</Title>
							<CloseButton onClick={handleConfirmClose} />
						</Group>
					) : !meetingFile ? (
						<Group position={'right'}>
							<CloseButton onClick={handleConfirmClose} />
						</Group>
					) : (
						<></>
					)}
					{(active === 1 || active === 2) && (
						<Group position={active === 1 ? 'right' : 'apart'}>
							{active === 2 && (
								<ActionIcon variant={'transparent'} onClick={handleBackNav}>
									<FontAwesomeIcon
										icon={faArrowLeft}
										color={'reelay-secondary-dark'}
									/>
								</ActionIcon>
							)}
							<Group position={'right'}>
								<Text size={12} color={'reelay-secondary-dark'}>
									Step {active} of 2
								</Text>
								<CloseButton onClick={handleCloseButtonClicked} />
							</Group>
						</Group>
					)}

					<Group
						position={'apart'}
						style={{ height: '100%' }}
						spacing='lg'
						grow
					>
						{meetingLinkPage ? (
							<AddRecallBotForm formType={formType} setOpened={setOpened} />
						) : (
							<form onSubmit={form.onSubmit((values) => updateReelay(values))}>
								<Grid>
									<Grid.Col span={!meetingFile ? 12 : 6}>
										{!meetingFile ? (
											<>
												<Stack align={'left'} spacing={'xs'}>
													<Title order={3} weight={500}>
														What do you want to use Reelay for?
													</Title>
													<Space h={'xs'} />
													<Grid align={'stretch'} h={360}>
														{botActionCards.map((card) => (
															<ReelayActionCard
																key={card.action}
																data={card}
																onClickHandler={handleCardClick(card.action)}
															/>
														))}
													</Grid>
												</Stack>
											</>
										) : (
											<Stack align={'stretch'} justify={'space-between'}>
												{/*//TODO: connect to upload status - pending, complete ect. */}
												<Space h={'xs'} />
												<Title order={3} weight={400}>
													Upload a meeting
												</Title>
												<Divider size={'xs'} mr={'md'} />
												<Space h={'xs'} />
												<Stack align={'center'}>
													<RingProgress
														sections={[
															{
																value: videoUploadProgressBars[meetingID],
																color: 'primary',
															},
														]}
														label={
															<Text
																color='primary'
																weight={400}
																align='center'
																size='xl'
															>
																{videoUploadProgressBars[meetingID]?.toFixed(
																	0
																) ?? 0}
																%
															</Text>
														}
													/>
													{/*<Text size="xs">* please do not close this window during upload</Text>*/}
												</Stack>
												<Stack align={'center'}>
													<Space h={'xs'} />
													<Text
														color={'reelay-secondary-dark'}
														size='sm'
														mt='md'
													>
														{meetingFile ? meetingFile.name : ''}
													</Text>
												</Stack>
											</Stack>
										)}
									</Grid.Col>

									{active === 1 ? (
										<Grid.Col span={6}>
											<>
												<Stack align={'stretch'} justify={'space-between'}>
													<Space h={'xs'} />
													<Title order={3} weight={400}>
														Meeting Info
													</Title>
													<TextInput
														required
														error='Meeting requires a title'
														placeholder={'The title of this meeting is...'}
														label={'Meeting Title'}
														labelProps={{ color: 'reelay-secondary-dark' }}
														{...form.getInputProps('name')}
													/>
													<TextInput
														error='Meeting requires an objective'
														placeholder={'The objective of this meeting is...'}
														label={'Meeting Objective'}
														color={'reelay-secondary-dark'}
														{...form.getInputProps('objective')}
													/>
													<Text size={14} color={'#212529'} weight={500}>
														Agenda
													</Text>
													<RichTextEditorComponent
														placeholder={'The agenda for this meeting is...'}
														richTextValue={agendaValue}
														setRichTextValue={setAgendaValue}
													/>
												</Stack>
											</>
										</Grid.Col>
									) : active === 2 ? (
										<Grid.Col span={6}>
											<Stack align={'stretch'}>
												<Space h={'xs'} />
												<Title order={3} weight={400}>
													Meeting Info
												</Title>
												<Stack spacing={8}>
													<Group
														position={'left'}
														align={'center'}
														spacing={'xs'}
													>
														<Text
															size={'sm'}
															lh={'14px'}
															color={'secondary-text'}
															fw={600}
														>
															View by date
														</Text>
														<Tooltip
															label={
																'Users will be required to view the Reelay by this date or it will expire'
															}
															withArrow
															position={'right'}
														>
															<FontAwesomeIcon icon={faCircleInfo} />
														</Tooltip>
													</Group>
													<Group position={'apart'} align={'start'} grow>
														<DateInput
															placeholder='Date'
															className={classes.dateInput}
															variant='filled'
															valueFormat='MMM D, YYYY'
															{...form.getInputProps('date')}
															clearable
															styles={{
																wrapper: {
																	height: 'auto',
																},
															}}
															renderDay={(date) => (
																<CustomCalendarDay date={date} />
															)}
														/>
														<TimeInput
															{...form.getInputProps('time')}
															className={classes.timeInput}
															variant='filled'
														/>
													</Group>
													<Checkbox
														{...form.getInputProps('publishedLaterChecked')}
														label='Want to schedule this for later?'
														checked={form.values.publishedLaterChecked}
													/>
													{form.values.publishedLaterChecked && (
														<Stack spacing={'xs'}>
															<Group
																position={'left'}
																align={'center'}
																spacing={'xs'}
															>
																<Text
																	size={'sm'}
																	lh={'14px'}
																	color={'secondary-text'}
																	fw={600}
																>
																	Publish at
																</Text>
																<Tooltip
																	label={
																		'This meeting will be published at the date and time you choose.'
																	}
																	withArrow
																	position={'right'}
																>
																	<FontAwesomeIcon icon={faCircleInfo} />
																</Tooltip>
															</Group>
															<Group position={'apart'} grow>
																<DateInput
																	placeholder='Date'
																	className={classes.dateInput}
																	variant='filled'
																	valueFormat='MMM D, YYYY'
																	{...form.getInputProps('publishAtDate')}
																	renderDay={(date) => (
																		<CustomCalendarDay date={date} />
																	)}
																/>
																<TimeInput
																	{...form.getInputProps('publishAtTime')}
																	className={classes.timeInput}
																	variant='filled'
																/>
															</Group>
														</Stack>
													)}
												</Stack>
												<UsersMultiSelect
													form={form}
													name={'invitees'}
													placeholder='Select invitees'
													labelText={'Recipients'}
													data={userData}
												/>
												<>
													<Group position='apart' align={'center'}>
														<Text color={'#212529'} size={14} mt='sm'>
															Attachments:
														</Text>
													</Group>

													{files.length ? (
														<List size='sm' mt={5} withPadding>
															{files.map((file, index) => (
																<List.Item key={index}>
																	<Group
																		position={'apart'}
																		align={'center'}
																		noWrap
																		w={'275px'}
																	>
																		{/*{ file.type === 'image/png' || file.type === 'image/jpeg' ? (*/}
																		<Button
																			bg={'background-gray'}
																			variant={'subtle'}
																			compact
																			w={250}
																			className={classes.previewLink}
																			onClick={() => previewAttachment(index)}
																		>
																			{file.name.length < 33
																				? file.name
																				: file?.name?.substring(0, 33) + '...'}
																		</Button>
																		{/*) : (*/}
																		{/*  <Text w={250}>*/}
																		{/*    {file.name.length < 33 ? file.name : ( file.name.substring(0, 33) + '...' )}*/}
																		{/*  </Text>*/}
																		{/*)}*/}
																		<FontAwesomeIcon
																			icon={faTrash}
																			className={classes.trashIcon}
																			onClick={() => deleteAttachment(index)}
																		/>
																	</Group>
																</List.Item>
															))}
														</List>
													) : null}
													<FileButton
														onChange={(value) => {
															const filesWithoutDuplicates = [
																...value,
																...files,
															].filter((file: File, idx, arr) => {
																return (
																	arr.findIndex(
																		(item: File) => item.name === file.name
																	) === idx
																);
															});
															setFiles(filesWithoutDuplicates);
															createFilePreviews(filesWithoutDuplicates);
														}}
														accept='image/png,image/jpeg,application/pdf,.ppt,.pptx,.doc,.docx,video/*'
														multiple
													>
														{(props) => (
															<Button
																className={classes.addAttachmentButton}
																{...props}
																variant={'outline'}
																size={'md'}
															>
																Add
															</Button>
														)}
													</FileButton>
												</>
											</Stack>
										</Grid.Col>
									) : active === 0 && uploadingVideo ? (
										<Grid.Col span={6}>
											<Center h={'100%'}>
												<ReelayLoader />
											</Center>
										</Grid.Col>
									) : (
										<></>
									)}
								</Grid>
								{active === 2 && (
									// @TODO: Fix button positioning
									<Group position={'right'} align={'right'} mt='xl' mr='md'>
										<Button
											type={'submit'}
											loading={createButtonLoading}
											loaderPosition={'center'}
											disabled={alertActive}
											radius={'md'}
										>
											Create Reelay
										</Button>
									</Group>
								)}
							</form>
						)}
					</Group>
				</>
				{active === 1 && (
					<Group position={'right'} align={'right'} mt='xl' mr='md'>
						<Button
							disabled={alertActive}
							onClick={() => setActive(2)}
							radius={'md'}
						>
							Next
						</Button>
					</Group>
				)}
			</Modal>

			{viewingPreview && (
				<FilePreviewModal
					files={files}
					filePreviews={filePreviews}
					viewingPreview={viewingPreview}
					setViewingPreview={setViewingPreview}
					currentFilePreview={currentFilePreview}
				/>
			)}
			{!uploadingVideo ? (
				<Group noWrap>
					<Button
						radius='md'
						onClick={handleUpload}
						size='sm'
						variant='outline'
					>
						Upload a Meeting
					</Button>
					<Button radius='md' onClick={handleModalOpen} size='sm'>
						Join Meeting
					</Button>
				</Group>
			) : (
				<Group position='center'>
					<Tooltip label='Video Upload in Progress'>
						<Group noWrap>
							<Button
								radius='md'
								onClick={handleUpload}
								size='sm'
								loading={filestackUploading}
								variant='outline'
							>
								{filestackUploading ? 'Uploading Meeting' : 'Upload a Meeting'}
							</Button>
							<Button
								radius='md'
								size='sm'
								data-disabled
								onClick={(event: any) => event.preventDefault()}
							>
								Join Meeting
							</Button>
						</Group>
					</Tooltip>
				</Group>
			)}
		</Group>
	);
};

export default CreateReelayModal;
