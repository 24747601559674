import React from 'react';
import { createStyles, Text, Stack, Group, Avatar, Card } from '@mantine/core';
import theme from '../../_utils/theme';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { HostDataObj } from '../../interfaces/meeting';
import { User } from '../../Atoms/userAtoms';
import { swapBackgroundColor } from '../../_utils/handy-functions';

const useStyles = createStyles((theme) => ({
	nameStack: {
		maxWidth: '75%',
	},
	backgroundTest: {
		backgroundColor: theme.colors['primary'][0],
		border: `2px solid ${theme.colors.primary[1]}`,
	},
}));

interface Props {
	host: User;
}

export default function MobileHostInfo({ host }: Props) {
	const { classes, theme } = useStyles();
	const avatarURL = swapBackgroundColor(
		`${host.userAvatarURL}-196.png`,
		theme.fn.primaryColor()
	);

	return (
		<Card shadow={'sm'} radius={'md'} className={classes.backgroundTest} m={20}>
			<Group position={'center'} spacing={0} py={'md'}>
				<Stack spacing={0} align={'center'} className={classes.nameStack}>
					<Avatar
						component={Link}
						size='lg'
						src={avatarURL}
						alt='host image'
						radius='xl'
						to={`/users/${host?.id}`}
						mb={'xs'}
					>
						{`${host?.firstName?.substring(0, 1)}${host?.lastName?.substring(
							0,
							1
						)}`}
					</Avatar>
					<Text size={18} fw={700}>
						{host.firstName} {host.lastName}
					</Text>
					<Text size={12} mb={'xs'}>
						{host.email}
					</Text>
					<Text align={'center'} size={12}>
						You are viewing all meetings that have been Reelayed to you from{' '}
						{host.firstName} {host.lastName}
					</Text>
				</Stack>
			</Group>
		</Card>
	);
}
