import React from 'react';
import {
	Stack,
	Box,
	Text,
	Group,
	RingProgress,
	Divider,
	Button,
	Title,
	useMantineTheme,
	ActionIcon,
	NumberInput,
	NumberInputHandlers,
} from '@mantine/core';
import { styles } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faDownload } from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue } from 'recoil';
import {
	StripeReceipt,
	stripeSubscriptionAtom,
} from '../../../../../Atoms/billing';
import { userSettings } from '../../../../../Atoms/settings';
import { getFullDateFromEpochMS } from '../../../../../helpers/date';
import { pricingTiers } from '../constants';

interface SubscriptionManagementProps {
	handlers: React.RefObject<NumberInputHandlers>;
	handleInputChange: (value: number | '') => void;
	handleSeatChange: () => void;
	seatDifference: number;
	counterValue: number;
	buttonLoading: boolean;
}

const SEAT_LIMIT_TIER_1 = 50;
const SEAT_LIMIT_TIER_2 = 199;
const PERCENTAGE_MULTIPLIER = 100;
const RECEIPT_LIMIT = 3;
const INPUT_WIDTH = 81;

const SubscriptionManagement: React.FC<SubscriptionManagementProps> = ({
	handlers,
	handleInputChange,
	handleSeatChange,
	seatDifference,
	counterValue,
	buttonLoading,
}) => {
	const { classes } = styles();
	const theme = useMantineTheme();
	const subscription = useRecoilValue(stripeSubscriptionAtom);
	const { time_zone } = useRecoilValue(userSettings);

	const { seatsPurchased, seatsAssigned, isTrialing, renewal, receipts } =
		subscription;

	const renewalDate = renewal ? renewal.renewalDate : null;
	const subscriptionCanceled = !isTrialing && !renewal;

	const currentTier =
		seatsPurchased <= SEAT_LIMIT_TIER_1
			? pricingTiers[0]
			: seatsPurchased <= SEAT_LIMIT_TIER_2
			? pricingTiers[1]
			: pricingTiers[2];

	const billingDate = getFullDateFromEpochMS(renewalDate, time_zone);

	const monthlyPrice = currentTier
		? (currentTier.price * seatsPurchased).toLocaleString('en-US', {
				style: 'currency',
				currency: 'USD',
		  })
		: '$0.00';
	const occupiedPercentage =
		(seatsAssigned / seatsPurchased) * PERCENTAGE_MULTIPLIER;

	const handleOpenReceipt = (url: string) => {
		window.open(url, '_blank');
	};

	const handleOpenStripeCustomerPortal = () => {
		window.open(process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL, '_blank');
	};

	return (
		<Stack h={'100%'}>
			<Box className={classes.header}>
				<Group position='apart' align='center'>
					<Title order={3} className={classes.primaryText}>
						Billing
					</Title>
					{!subscriptionCanceled && (
						<Button
							component='a'
							variant='outline'
							onClick={handleOpenStripeCustomerPortal}
						>
							Manage Subscription
						</Button>
					)}
				</Group>
				<Text className={classes.secondaryText}>
					Manage your subscription and billing details
				</Text>
			</Box>
			<Box className={classes.content}>
				<Group className={classes.grid} spacing='lg'>
					<Box className={classes.section}>
						<Text className={classes.sectionTitle}>Seat Usage</Text>
						<Box className={classes.chartContainer}>
							<RingProgress
								size={200}
								thickness={20}
								sections={[
									{
										value: occupiedPercentage,
										color: theme.colors.primary[6],
									},
								]}
								label={
									<Text align='center' size='lg' weight={700}>
										{seatsAssigned}/{seatsPurchased} occupied
									</Text>
								}
							/>
						</Box>
						<Group position='center' spacing={5}>
							<ActionIcon
								size={36}
								variant='default'
								onClick={() => handlers.current?.decrement()}
								// disabled={seatsPurchased <= seatsAssigned}
							>
								–
							</ActionIcon>
							<NumberInput
								hideControls
								value={counterValue}
								onChange={(value) => handleInputChange(value as number)}
								handlersRef={handlers}
								min={1}
								step={1}
								styles={{ input: { width: INPUT_WIDTH, textAlign: 'center' } }}
							/>
							<ActionIcon
								size={36}
								variant='default'
								onClick={() => handlers.current?.increment()}
							>
								+
							</ActionIcon>
						</Group>
					</Box>

					<Box className={classes.section}>
						<Text className={classes.sectionTitle}>Billing Information</Text>
						<Box className={classes.billingInfo}>
							<Text>Next Billing Date</Text>
							<Group spacing='xs'>
								<FontAwesomeIcon icon={faCalendar} />
								<Text>{billingDate}</Text>
							</Group>
							<Text>Past Receipts</Text>
							{receipts
								.slice(0, RECEIPT_LIMIT)
								.map(({ id, created, receiptUrl }: StripeReceipt) => (
									<Group key={id} position='apart' noWrap>
										<Text>{getFullDateFromEpochMS(created)}</Text>
										<Button
											onClick={() => handleOpenReceipt(receiptUrl)}
											variant='subtle'
											leftIcon={
												<FontAwesomeIcon
													icon={faDownload}
													style={{
														position: 'relative',
														bottom: '2px',
													}}
												/>
											}
										>
											View
										</Button>
									</Group>
								))}
						</Box>
					</Box>
				</Group>
				<Divider />
				<Box className={classes.pricing}>
					<Text className={classes.sectionTitle}>Pricing</Text>
					<Group
						className={classes.pricingDetails}
						spacing='md'
						align='start'
						style={{ width: '100%', textAlign: 'left' }}
					>
						<Stack className={classes.priceBox}>
							<Text className={classes.primaryText}>Current Plan</Text>
							<Stack spacing={0}>
								<Text
									size='1.5rem'
									weight={700}
									className={classes.primaryText}
								>
									${currentTier?.price}/user/month
								</Text>
								<Text className={classes.secondaryText}>
									Based on {seatsPurchased} seats
								</Text>
							</Stack>
						</Stack>
						<Stack className={classes.priceBox}>
							<Text className={classes.primaryText}>Monthly Total</Text>
							<Stack spacing={0}>
								<Text
									size='1.5rem'
									weight={700}
									className={classes.primaryText}
								>
									{monthlyPrice}
								</Text>
								<Text className={classes.secondaryText}>Billed monthly</Text>
							</Stack>
						</Stack>
						<Stack className={classes.priceBox}>
							<Text className={classes.primaryText}>Pricing Tiers</Text>
							<Stack spacing={0}>
								<Text className={classes.secondaryText}>
									1-50 seats: $10/user/month
								</Text>
								<Text className={classes.secondaryText}>
									51-199 seats: $8/user/month
								</Text>
								<Text className={classes.secondaryText}>
									200+ seats: $5/user/month
								</Text>
							</Stack>
						</Stack>
					</Group>
				</Box>
				<Button
					size='lg'
					className={classes.updateButton}
					color={seatDifference < 0 ? 'red' : theme.fn.primaryColor()}
					disabled={seatDifference === 0}
					onClick={handleSeatChange}
					loading={buttonLoading}
				>
					{seatDifference === 0
						? 'Update Subscription'
						: seatDifference > 0
						? `Add ${seatDifference} seat${seatDifference > 1 ? 's' : ''}`
						: `Remove ${Math.abs(seatDifference)} seat${
								Math.abs(seatDifference) > 1 ? 's' : ''
						  }`}
				</Button>
			</Box>
		</Stack>
	);
};

export default SubscriptionManagement;
