import {
	Button,
	Checkbox,
	Group,
	Stack,
	Text,
	Title,
	useMantineTheme,
} from '@mantine/core';
import { formatRelativeTime } from '../../../../_utils/time';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faTimesCircle } from '@fortawesome/pro-solid-svg-icons';
import { useHover } from '@mantine/hooks';
import { MeetingRequestNotification } from '../../../../Atoms/notifications';

interface Props {
	requestingUser: MeetingRequestNotification;
	updateRequest: (user: MeetingRequestNotification, status: string) => void;
	index?: number;
	checkboxValue?: any;
	handlers?: any;
	viewingMeeting?: boolean;
}
export const RequestNotificationCard = ({
	requestingUser,
	updateRequest,
	checkboxValue,
	index,
	handlers,
	viewingMeeting = true,
}: Props) => {
	const theme = useMantineTheme();
	const { firstName, lastName, email } = requestingUser.user;
	const { hovered, ref } = useHover();

	return (
		<Group
			p={'sm'}
			spacing={'xs'}
			bg={hovered ? theme.colors['primary'][0] : 'white'}
			position={'apart'}
			align={'start'}
			noWrap
			w={'100%'}
		>
			<Checkbox
				size={'sm'}
				key={checkboxValue.key}
				checked={checkboxValue.checked}
				onChange={(event) =>
					handlers.setItemProp(index, 'checked', event.currentTarget.checked)
				}
			/>
			<Stack style={{ flex: 2 }} spacing={'sm'} ref={ref}>
				<Group position={'apart'}>
					<Title order={6} color={'primary-text'} weight={500}>
						Meeting Access Request
					</Title>
					<Text size={'sm'} span c={'dimmed'}>
						{formatRelativeTime(requestingUser?.createdAt)}
					</Text>
				</Group>
				<Text size={'sm'} span c={'dimmed'} weight={500}>
					{viewingMeeting
						? `${firstName} ${lastName} (${email}) has requested to join this meeting. `
						: `${firstName} ${lastName} (${email}) has requested to join meeting ${requestingUser?.name ? requestingUser?.name : ''
						}. `}
				</Text>
				{'approved' in requestingUser ? (
					<>
						{requestingUser.approved ? (
							<Group position={'right'} pb={'xs'} pr={'xs'} spacing={'xs'}>
								<FontAwesomeIcon
									icon={faCircleCheck}
									size={'lg'}
									color={'green'}
								/>
								<Text size={'sm'} weight={500} c={'success'}>
									Approved
								</Text>
							</Group>
						) : (
							<Group position={'right'} pb={'xs'} pr={'xs'} spacing={'xs'}>
								<FontAwesomeIcon icon={faTimesCircle} color={'red'} />
								<Text size={'sm'} weight={500} c={'danger'}>
									Denied
								</Text>
							</Group>
						)}
					</>
				) : (
					<Group position={'right'}>
						<Button
							size={'xs'}
							variant={'light'}
							onClick={() => updateRequest(requestingUser, 'active')}
						>
							Approve
						</Button>
						<Button
							size={'xs'}
							variant={'light'}
							onClick={() => updateRequest(requestingUser, 'denied')}
						>
							Deny
						</Button>
					</Group>
				)}
			</Stack>
		</Group>
	);
};
