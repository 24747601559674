import { ScrollArea, Text } from '@mantine/core';
import { IAction } from '../../../../../interfaces/action';
import { IHighlight } from '../../../../../interfaces/highlight';
import { Chapter } from '../../../../../helpers/meetings';
import MomentRow from './MomentRow';
import { useSearchParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { currentUser } from '../../../../../Atoms/userAtoms';
import {
	mobileTimestamp,
	timestamp,
	videoPlayerCurrentTime,
} from '../../../../../Atoms/meetingAtom';
import { createRef, useEffect, useRef, useState } from 'react';
import { MILLISECONDS_PER_SECOND } from '../../../../../components/constants';

interface Props {
	items: (IHighlight | IAction | Chapter)[];
	emptyText: string;
	handleEditChapter: (chapter: Chapter) => void;
	tab: string;
}
export default function MomentsList({
	items,
	emptyText,
	handleEditChapter,
	tab,
}: Props) {
	const [searchParams, setSearchParams] = useSearchParams();
	const user = useRecoilValue(currentUser);
	const setTimestamp = useSetRecoilState(mobileTimestamp);
	const setWebTimestamp = useSetRecoilState(timestamp);
	const [activeChapterIndex, setActiveChapterIndex] = useState<number>(0);
	const currentTime = useRecoilValue(videoPlayerCurrentTime);

	// Create a ref for each MomentCard
	const momentRefs = useRef([]);
	momentRefs.current = items.map(
		(_, i) => momentRefs.current[i] ?? createRef()
	);
	const viewport = useRef<HTMLDivElement>(null);

	// Effect to calculate active chapter index and scroll to it
	useEffect(() => {
		let newActiveIndex = activeChapterIndex;
		// Find the index of the moment for the current time
		for (let i = 0; i < items.length; i++) {
			const { timeStartMS } = items[i];
			const time = timeStartMS
				? timeStartMS
				: 'time' in items[i]
				? items[i]['time']
				: 'start' in items[i]
				? items[i]['start']
				: 0;
			if (currentTime < Math.floor(time / MILLISECONDS_PER_SECOND)) {
				newActiveIndex = i === 0 ? i : i - 1;
				break;
			} else if (
				currentTime >= Math.floor(time / MILLISECONDS_PER_SECOND) &&
				i === items.length - 1
			) {
				newActiveIndex = i;
				break;
			}
		}
		if (newActiveIndex !== activeChapterIndex) {
			setActiveChapterIndex(newActiveIndex);
			// calculate the height of all the previous moments and subtract it from the scrollHeight
			let height = 0;

			for (let i = 0; i < newActiveIndex; i++) {
				height += momentRefs.current[i].current.clientHeight;
			}
			viewport.current.scrollTo({
				top: height,
				behavior: 'smooth',
			});
		}
	}, [activeChapterIndex]);

	useEffect(() => {
		let newActiveIndex = 0; // Start with an assumption that the first index is the default

		// Loop through items to find the highest index <= currentTime
		for (let i = 0; i < items.length; i++) {
			const { timeStartMS } = items[i];
			const time = timeStartMS
				? timeStartMS
				: 'time' in items[i]
				? items[i]['time']
				: 'start' in items[i]
				? items[i]['start']
				: 0;
			const timeStartSeconds = Math.floor(time / MILLISECONDS_PER_SECOND);
			if (currentTime >= timeStartSeconds) {
				newActiveIndex = i;
			} else {
				break; // Exit the loop early if we find a chapter that starts after currentTime
			}
		}

		// Check if the newly found index is different from the current activeChapterIndex
		if (newActiveIndex !== activeChapterIndex) {
			setActiveChapterIndex(newActiveIndex);

			// Optionally scroll the active chapter card into view
			if (
				momentRefs.current[newActiveIndex] &&
				momentRefs.current[newActiveIndex].current
			) {
				// calculate the height of all the previous moments and subtract it from the scrollHeight
				let height = 0;

				for (let i = 0; i < newActiveIndex; i++) {
					height += momentRefs.current[i].current.clientHeight;
				}
				viewport.current.scrollTo({
					top: height,
					behavior: 'smooth',
				});
			}
		}
	}, [tab, currentTime, items]); // Dependency array now only includes tab, isolating this effect from other changes

	useEffect(() => {
		const momentID = searchParams.get('moment');

		if (typeof momentID === 'string' && momentID.length) {
			const momentIndex = items.findIndex((moment) => moment.id === momentID);
			if (momentIndex >= 0) {
				handleMomentClick(momentIndex, items[momentIndex].timeStartMS);
			}
		}
	}, [searchParams.get('moment')]);

	useEffect(() => {
		const actionID = searchParams.get('actionID');
		if (typeof actionID === 'string' && actionID.length) {
			const actionIndex = items.findIndex((item) => item.id === actionID);
			if (actionIndex >= 0) {
				handleMomentClick(actionIndex, items[actionIndex].timeStartMS);
			}
		}
	}, [searchParams.get('actionID'), items]);

	const handleMomentClick = (index: number, timeStartMS: number) => {
		setActiveChapterIndex(index);
		setWebTimestamp({
			time: Math.floor(timeStartMS / MILLISECONDS_PER_SECOND),
		});
	};

	return (
		<ScrollArea
			viewportRef={viewport}
			p={'md'}
			px={0}
			pb={0}
			h={'100%'}
			lh={'25px'}
			type='always'
			offsetScrollbars
			scrollbarSize={10}
			styles={{
				root: {
					// height: '100%',
					flex: 3,
					marginBottom: '50px',
				},
				viewport: {
					overflowX: 'visible',
					width: '100%',
					maxWidth: '100%',
					fontSize: 12,
				},
			}}
		>
			{items.length ? (
				items.map((item: IHighlight | IAction | Chapter, index: number) => {
					return (
						<MomentRow
							key={item.id}
							item={item}
							index={index}
							activeIndex={activeChapterIndex}
							handleMomentClick={handleMomentClick}
							scrollRef={momentRefs.current[index]}
							handleEditChapter={handleEditChapter}
						/>
					);
				})
			) : (
				<Text pl={'md'}>{`No ${emptyText} found for this meeting.`}</Text>
			)}
		</ScrollArea>
	);
}
