
/**
 * @param {string} method - info | error | warn | debug
 * @param {string} message - The message to be displayed in the logs.
 * @param {any} data - The data to be displayed in the logs.
 */

export const logger = (
  method: 'info' | 'error' | 'warn' | 'debug',
  message: string,
  data: any
) => {
  switch (method) {
    case 'info': {
      break;
    }
    case 'error': {
      break;
    }
    case 'warn': {
      break;
    }
    case 'debug': {
      break;
    }
    default: {
      break;
    }
  }
};
