import React, { MutableRefObject, useEffect, useState } from 'react';
import { createStyles, Dialog, Stack, Text } from '@mantine/core';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useForm } from '@mantine/form';
import { getUsersByOrg, inviteUsersToMeeting } from '../../../../api/api';
import { IMeeting } from '../../../../interfaces/meeting';
import { CreateHighlightButton } from '../../components/CreateHighlightButton';
import {
	creatingHighlightAtom,
	currentUser,
	User,
	user as userAtom,
	userOrganizationMembers,
} from '../../../../Atoms/userAtoms';
import { UsersMultiSelect } from '../../../UsersMultiSelect';
import {
	fetchMembers,
	flattenValidateAndFilterInvitees,
} from '../../../../helpers/teams';
import { logger } from '../../../../helpers/logger';
import { InvitedUser } from '../../../../interfaces/user';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
interface AddViewersDialogProps {
	close: any;
	opened: any;
	asideRef?: MutableRefObject<any>;
	meeting: IMeeting;
	setDistributionList: React.Dispatch<React.SetStateAction<InvitedUser[]>>;
	isSupport: boolean;
	orgUsers: User[];
}

const useStyles = createStyles((theme) => ({
	cardText: {
		fontSize: 12,
		color: theme.colors?.primary[5],
	},
	title: {
		color: theme.colors?.['primary-text'],
		fontWeight: 600,
		fontSize: 18,
	},
	topBar: {
		marginTop: '24px',
	},
	toolTip: {
		fontSize: 12,
	},
	labels: {
		color: theme.colors?.['secondary-text'],
		fontSize: '10px',
		lineHeight: '14px',
		fontWeight: 600,
	},
	validationError: {
		color: '#fa5252',
		fontSize: '10px',
		lineHeight: 1.2,
		display: 'block',
	},
}));

const AddViewersDialog = ({
	close,
	opened,
	asideRef,
	meeting,
	setDistributionList,
	orgUsers,
}: AddViewersDialogProps) => {
	const { classes } = useStyles();
	const [teamData, setTeamData] = useState<
		{ value: string | string[]; label: string }[]
	>([]);
	const [buttonLoading, setButtonLoading] = useState(false);
	const user = useRecoilValue(currentUser);
	const users = useRecoilValue(userOrganizationMembers);
	const setCreatingHighlight = useSetRecoilState(creatingHighlightAtom);
	const { organizationID, id } = meeting;

	useEffect(() => {
		const setupUserData = async () => {
			const users = orgUsers.map((user: User) => ({
				...user,
				label: user.email,
				value: user.email,
				key: user.id,
			}));
			const members = await fetchMembers(users as any, meeting.organizationID);
			setTeamData(members);
		};
		setupUserData();
	}, [orgUsers]);

	const form = useForm({
		initialValues: {
			viewers: [],
		},
		validate: {
			viewers: (value) =>
				value.length ? null : 'At least one viewer is required.',
		},
	});

	const addViewers = async (values: { viewers: string[] }) => {
		setButtonLoading(true);
		try {
			const flattenedInvitees = flattenValidateAndFilterInvitees(
				values.viewers
			);
			// invite invitees to meet
			inviteUsersToMeeting({
				emails: flattenedInvitees,
				meetingID: id,
				organizationID,
			})
				.then((res) => {
					const invitedUsers = res.data.data;
					logger('info', 'users added to meeting', invitedUsers);
					// need to update viewers list with new users.
					setDistributionList((prev) => {
						const result = [...prev, ...invitedUsers].filter(
							(item, index, array) => {
								return array.findIndex((i) => i.id === item.id) === index;
							}
						);
						return result;
					});
					showSuccessNotification({
						message: 'Viewers were successfully added. Great work!',
					});
					form.reset();
					setCreatingHighlight(false);
					close();
				})
				.catch((error: any) => {
					logger('error', 'failed to invite users to meeting', error);
					throw new Error(error);
				});
		} catch (error: unknown) {
			logger('error', 'failed to invite users to meeting', error);
			showFailureNotification({
				message:
					"Sorry, there was an issue while trying to invite users to the meeting. Please try inviting users again, and if you encounter further difficulties, don't hesitate to contact our support team for assistance.",
			});
		} finally {
			setButtonLoading(false);
		}
	};

	return (
		<Dialog
			opened={opened}
			onClose={() => {
				setCreatingHighlight(false);
				close();
			}}
			withCloseButton
			size='lg'
			radius='md'
			transition={'slide-up'}
			position={{ bottom: 8, right: 16 }}
			p={16}
			w={asideRef?.current?.offsetWidth - 32}
		>
			<form onSubmit={form.onSubmit((values) => addViewers(values))}>
				<Stack spacing={24}>
					<Text align={'left'} className={classes.title}>
						New Viewers
					</Text>
					<UsersMultiSelect
						creatable={true}
						descriptionText={'Add Viewers'}
						variantType={'filled'}
						data={teamData}
						setData={setTeamData}
						clearable={false}
						searchable={true}
						labelText={
							<Text mb={10} size={18}>
								Viewers
							</Text>
						}
						placeholder='Add Viewers by selecting or entering their email address.'
						name={'viewers'}
						form={form}
					/>
					<CreateHighlightButton
						buttonText={'+ Add Viewers'}
						loading={buttonLoading}
					/>
				</Stack>
			</form>
		</Dialog>
	);
};

export default AddViewersDialog;
