import React, { useEffect, useState } from 'react';
import { Loader, Text } from '@mantine/core';
import { DateTime } from 'luxon';
import { convertSecondsToHHMMSS } from '../../../../_utils/time';
import { useRecoilValue } from 'recoil';
import { currentMeeting as MeetingAtom } from '../../../../Atoms/meetingAtom';

const ELAPSED_TIME_LIMIT_IN_SECONDS = 600;
const RUNNING_TIMER_INTERVAL = 1000;

export default function TimeInAuditTimer() {
	const currentMeeting = useRecoilValue(MeetingAtom);
	const startTime = DateTime.local(); // Set initial start time to current time
	const [elapsedTime, setElapsedTime] = useState('');
	const [elapsedTimeInSeconds, setElapsedTimeInSeconds] = useState(0);

	useEffect(() => {
		const intervalId = setInterval(() => {
			const now = DateTime.local();
			const diff = now
				.diff(DateTime.fromISO(currentMeeting.auditTime), 'seconds')
				.toObject().seconds; // Calculate time difference in seconds
			setElapsedTimeInSeconds(diff);
			const timeSinceAudit = convertSecondsToHHMMSS(diff);
			setElapsedTime(timeSinceAudit);
		}, RUNNING_TIMER_INTERVAL);

		return () => clearInterval(intervalId);
	}, [startTime]);

	return (
		<>
			{elapsedTime.length ? (
				<Text
					style={
						elapsedTimeInSeconds > ELAPSED_TIME_LIMIT_IN_SECONDS
							? {
									color: 'red',
							  }
							: {}
					}
				>{`Time In Audit: ${elapsedTime}`}</Text>
			) : (
				<Loader size={'sm'} />
			)}
		</>
	);
}
