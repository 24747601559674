import { Navigate } from 'react-router-dom';

// import { history } from '_helpers';
import { useRecoilValue } from 'recoil';
import { hasAdminAccess } from '../../Atoms/userAtoms';

export { ProtectedAdminRoute };

function ProtectedAdminRoute({ children }: { children: any }) {
	const adminAccess = useRecoilValue(hasAdminAccess);

	if (!adminAccess) {
		// not logged in so redirect to login page with the return url
		return <Navigate to='/unauthorized' />;
		// return <Navigate to="/login" state={{ from: history.location }} />
	}

	// write code for user roles here
	// if they are not a certain user they cannot access this route
	//

	// authorized so return child components
	return children;
}
