export enum ThemeColors {
	Blue = 'blue',
	Cyan = 'cyan',
	Dark = 'dark',
	Grape = 'grape',
	Gray = 'gray',
	Green = 'green',
	Indigo = 'indigo',
	Lime = 'lime',
	Pink = 'pink',
	Red = 'red',
	Orange = 'orange',
	Teal = 'teal',
	Yellow = 'yellow',
	Violet = 'violet',
}

export const themeColors = [
	ThemeColors.Dark,
	ThemeColors.Gray,
	ThemeColors.Red,
	ThemeColors.Pink,
	ThemeColors.Grape,
	ThemeColors.Violet,
	ThemeColors.Indigo,
	ThemeColors.Blue,
	ThemeColors.Cyan,
	ThemeColors.Teal,
	ThemeColors.Green,
	ThemeColors.Lime,
	ThemeColors.Yellow,
	ThemeColors.Orange,
];
