import { styles } from '../styles';
import {
	Stack,
	Box,
	Text,
	Group,
	RingProgress,
	Divider,
	Button,
	Title,
	Card,
	useMantineTheme,
	List,
	SimpleGrid,
	Badge,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { useRecoilValue } from 'recoil';
import { DateTime } from 'luxon';
import useStripe from '../../../../../customHooks/useStripe';
import { getFullDateFromEpochMS } from '../../../../../helpers/date';
import { userSettings } from '../../../../../Atoms/settings';
import {
	HOURS_IN_A_DAY,
	MILLISECONDS_IN_A_SECOND,
	MINUTES_IN_AN_HOUR,
	PERCENTAGE_MULTIPLIER,
	pricingTiers,
	SEAT_LIMIT_TIER_1,
	SEAT_LIMIT_TIER_2,
	SECONDS_IN_A_MINUTE,
} from '../constants';

interface Props {
	buttonLoading: boolean;
}

const SubscriptionCanceled = ({ buttonLoading }: Props) => {
	const { classes } = styles();
	const theme = useMantineTheme();
	const { time_zone } = useRecoilValue(userSettings);
	const { subscription } = useStripe(false);

	const { seatsAssigned, currentPeriodEnd } = subscription;

	const selectedTier =
		seatsAssigned <= SEAT_LIMIT_TIER_1
			? pricingTiers[0]
			: seatsAssigned <= SEAT_LIMIT_TIER_2
			? pricingTiers[1]
			: pricingTiers[2];

	const subscriptionDaysLeft = Math.ceil(
		(currentPeriodEnd - DateTime.now().toMillis()) /
			(MILLISECONDS_IN_A_SECOND *
				SECONDS_IN_A_MINUTE *
				MINUTES_IN_AN_HOUR *
				HOURS_IN_A_DAY)
	);

	const durationBeforeDataErase = Math.ceil(
		(DateTime.now().plus({ days: 59 }).toMillis() - currentPeriodEnd) /
			(MILLISECONDS_IN_A_SECOND *
				SECONDS_IN_A_MINUTE *
				MINUTES_IN_AN_HOUR *
				HOURS_IN_A_DAY)
	);

	const trialProgress =
		(subscriptionDaysLeft / durationBeforeDataErase) * PERCENTAGE_MULTIPLIER;
	const subscriptionEndDate = getFullDateFromEpochMS(
		currentPeriodEnd,
		time_zone
	);

	const ringSections = [
		{
			value: trialProgress,
			color: theme.colors.primary[6],
		},
		{
			value: PERCENTAGE_MULTIPLIER - trialProgress,
			color: theme.colors.gray[3],
		},
	];

	const handleSubscription = async () => {
		window.open(process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL, '_blank');
	};

	return (
		<Stack h={'100%'}>
			<Box className={classes.header}>
				<Group position='apart' align='center'>
					<Title order={3} className={classes.primaryText}>
						Billing
					</Title>
				</Group>
				<Text className={classes.secondaryText}>
					Manage your subscription and billing details
				</Text>
			</Box>
			<Box className={classes.content}>
				<Group className={classes.grid} spacing='lg'>
					<Box className={classes.section}>
						<Text className={classes.sectionTitle}>Subscription Status</Text>
						<Box className={classes.chartContainer}>
							<RingProgress
								size={200}
								thickness={20}
								sections={ringSections}
								label={
									<Text align='center' size='lg' weight={700}>
										{subscriptionDaysLeft} days left
									</Text>
								}
							/>
						</Box>
						<Text align='center' size='sm' mt='sm'>
							{subscriptionDaysLeft} out of {durationBeforeDataErase} days
							remaining
						</Text>
					</Box>

					<Box className={classes.section}>
						<Group
							className={classes.sectionTitle}
							position='apart'
							noWrap
							align='center'
						>
							<Text>Billing Information</Text>
							<Button onClick={handleSubscription} variant='subtle'>
								View Invoices
							</Button>
						</Group>
						<Box className={classes.billingInfo}>
							<Group position='apart' noWrap>
								<Text>Subscription End Date</Text>
								<Badge color='red'>Canceled</Badge>
							</Group>
							<Group spacing='xs'>
								<FontAwesomeIcon icon={faCalendar} />
								<Text c={'red'}>{subscriptionEndDate}</Text>
							</Group>
							<Text className={classes.sectionTitle} mb={0}>
								What happens after my subscription ends?
							</Text>
							<Stack spacing={'xs'}>
								<Text size='sm' color='dimmed'>
									If you don't resubscribe after your subscription ends:
								</Text>
								<List spacing={0}>
									<List.Item>
										<Text size='sm' color='dimmed'>
											You'll lose the ability to add Reelay to new meetings
										</Text>
									</List.Item>
									<List.Item>
										<Text size='sm' color='dimmed'>
											You'll retain access to view existing meetings for 60 days
										</Text>
									</List.Item>
									<List.Item>
										<Text size='sm' color='dimmed'>
											All data will be deleted after 60 days
										</Text>
									</List.Item>
								</List>
							</Stack>
						</Box>
					</Box>
				</Group>
				<Divider />
				<Box className={classes.pricing}>
					<Text className={classes.sectionTitle}>Pricing Tiers</Text>
					<SimpleGrid cols={pricingTiers.length} spacing='md' mt='md'>
						{pricingTiers.map((tier, index) => {
							const isSelected = selectedTier.min === tier.min;
							return (
								<Card
									key={index}
									withBorder
									shadow='sm'
									padding='md'
									radius='md'
									style={{
										borderColor: isSelected
											? theme.colors.primary[6]
											: theme.colors.gray[3],
									}}
								>
									<Text weight={500}>
										{tier.min}-{tier.max === Infinity ? '+' : tier.max} seats
									</Text>
									{isSelected && (
										<Text
											fz={'sm'}
											c={'dimmed'}
										>{`Seats assigned: ${seatsAssigned}`}</Text>
									)}

									<Text size='xl' weight={700} mt='sm'>
										${tier.price}/user/month
									</Text>
								</Card>
							);
						})}
					</SimpleGrid>

					<Group mt='xl' grow>
						<Button
							size='lg'
							className={classes.updateButton}
							onClick={handleSubscription}
							loading={buttonLoading}
						>
							Renew Subscription
						</Button>
					</Group>
				</Box>
			</Box>
		</Stack>
	);
};

export default SubscriptionCanceled;
