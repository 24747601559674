import React from 'react';
import { Button } from '@mantine/core';

interface Props {
	clickFunc: (val: (o) => boolean) => void;
	text?: string;
	leftIcon?: React.ReactNode | null;
	disabled?: boolean;
	radius?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	loading?: boolean;
}
export const DangerNoButton = ({
	clickFunc,
	text = 'No',
	leftIcon = null,
	disabled = false,
	radius = 'md',
	loading = false,
}: Props) => {
	return (
		<Button
			size={'sm'}
			radius={radius}
			onClick={() => clickFunc((o) => !o)}
			variant='outline'
			color='red'
			leftIcon={leftIcon}
			disabled={disabled}
			loading={loading}
		>
			{text}
		</Button>
	);
};
