import React, { useState } from 'react';
import {
	Grid,
	Group,
	Paper,
	Stack,
	Text,
	TextInput,
	Title,
} from '@mantine/core';
import { styles } from '../../styles';
import { EditSaveCancelButton } from './EditSaveCancelButton';
import { useForm } from '@mantine/form';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../helpers/notifications';
import useUserSettings from '../../../../../customHooks/useUserSettings';
import { logger } from '../../../../../helpers/logger';

const AddressCard = () => {
	const { classes, theme } = styles();
	const { userSettings, updateUserSettings } = useUserSettings({
		doNotFireUseEffect: true,
	});

	const { address } = userSettings || {};
	const {
		address_line_1 = '',
		address_line_2 = '',
		city = '',
		country = '',
		state = '',
		zip = '',
	} = address || {};
	const [isEditing, setIsEditing] = useState(false);

	const form = useForm({
		initialValues: {
			address_line_1,
			address_line_2,
			city,
			state,
			zip,
			country,
		},
		// validation does not let user remove data.
		// validate: {
		// 	address_line_1: (value) =>
		// 		value.trim() ? null : 'Address line 1 is required',
		// 	city: (value) => (value.trim() ? null : 'City is required'),
		// 	state: (value) => (value.trim() ? null : 'State is required'),
		// 	zip: (value) => (/^\d{5}$/.test(value) ? null : 'Invalid ZIP code'),
		// },
	});

	const handleEdit = () => {
		form.setValues(address);
		setIsEditing(!isEditing);
	};

	const updateAddress = async (values) => {
		try {
			// update user address
			const res = await updateUserSettings([
				{
					key: 'address',
					value: values,
				},
			]);
			const newAddress = res?.address;
			form.setValues(newAddress);
			// success notification
			showSuccessNotification({
				message: 'Your address information has been updated.',
			});
		} catch (error) {
			// failure notification
			showFailureNotification({
				message: "Sorry, we couldn't update the address. Please try again.",
			});
			logger('error', 'Failed to update address', error);
		} finally {
			setIsEditing(false);
		}
	};

	return (
		<form onSubmit={form.onSubmit(updateAddress)}>
			<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
				<Stack>
					<Group position={'apart'}>
						<Title order={4} color={'primary-text'}>
							Address
						</Title>
						<EditSaveCancelButton
							isEditing={isEditing}
							handleEdit={handleEdit}
						/>
					</Group>
					<Grid justify={'space-between'}>
						{/*1st row*/}
						<Grid.Col span={5}>
							<Text mb={4} size={'xs'}>
								Street Address
							</Text>
							{isEditing ? (
								<TextInput
									{...form.getInputProps('address_line_1')}
									radius='md'
									styles={{
										input: {
											backgroundColor: theme.colors.primary[0],
										},
									}}
								/>
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{address_line_1 || 'N/A'}
								</Text>
							)}
						</Grid.Col>
						<Grid.Col span={4}>
							<Text mb={4} size={'xs'}>
								Apt, suite, etc
							</Text>
							{isEditing ? (
								<TextInput
									{...form.getInputProps('address_line_2')}
									radius='md'
									styles={{
										input: {
											backgroundColor: theme.colors.primary[0],
										},
									}}
								/>
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{address_line_2 || 'N/A'}
								</Text>
							)}
						</Grid.Col>
						<Grid.Col span={3} />
						<Grid.Col span={5}>
							<Text mb={4} size={'xs'}>
								City
							</Text>
							{isEditing ? (
								<TextInput
									{...form.getInputProps('city')}
									radius='md'
									styles={{
										input: {
											backgroundColor: theme.colors.primary[0],
										},
									}}
								/>
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{city || 'N/A'}
								</Text>
							)}
						</Grid.Col>
						<Grid.Col span={4}>
							<Text mb={4} size={'sm'}>
								State
							</Text>
							{isEditing ? (
								<TextInput
									{...form.getInputProps('state')}
									radius='md'
									styles={{
										input: {
											backgroundColor: theme.colors.primary[0],
										},
									}}
								/>
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{state || 'N/A'}
								</Text>
							)}
						</Grid.Col>
						<Grid.Col span={3}>
							<Text mb={4} size={'xs'}>
								Zip Code
							</Text>
							{isEditing ? (
								<TextInput
									{...form.getInputProps('zip')}
									radius='md'
									styles={{
										input: {
											backgroundColor: theme.colors.primary[0],
										},
									}}
								/>
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{zip || 'N/A'}
								</Text>
							)}
						</Grid.Col>
						{/*3rd row*/}
						<Grid.Col span={2}>
							<Text mb={4} size={'xs'}>
								Country
							</Text>
							{isEditing ? (
								<TextInput
									{...form.getInputProps('country')}
									radius='md'
									styles={{
										input: {
											backgroundColor: theme.colors.primary[0],
										},
									}}
								/>
							) : (
								<Text color={'primary-text'} size={'sm'}>
									{country || 'N/A'}
								</Text>
							)}
						</Grid.Col>
						<Grid.Col span={2} />
					</Grid>
				</Stack>
			</Paper>
		</form>
	);
};

export default AddressCard;
