import { ReportHandler } from 'web-vitals';
import { Logtail } from '@logtail/browser';
import * as Sentry from '@sentry/browser';
import Gleap from 'gleap';

export const reportWebVitals = (onPerfEntry?: ReportHandler) => {
	if (process.env.NODE_ENV === 'development') return;

	if (onPerfEntry && onPerfEntry instanceof Function) {
		import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
			getCLS(onPerfEntry);
			getFID(onPerfEntry);
			getFCP(onPerfEntry);
			getLCP(onPerfEntry);
			getTTFB(onPerfEntry);
		});
	}
};

export const initSentry = () => {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_ENVIRONMENT,
	});
};

export const initLogtail = () => {
	const logtail = new Logtail(process.env.REACT_APP_LOGTAIL);

	const originalConsole = {
		log: console.log,
		error: console.error,
		warn: console.warn,
		info: console.info,
		debug: console.debug,
	};

	// eslint-disable-next-line no-shadow-restricted-names
	console.log = (...args) => {
		originalConsole.log(...args);
		(logtail as any).log(...args);
	};
	console.error = (...args) => {
		originalConsole.error(...args);
		(logtail as any).error(...args);
	};
	console.warn = (...args) => {
		originalConsole.warn(...args);
		(logtail as any).warn(...args);
	};
	console.info = (...args) => {
		originalConsole.info(...args);
		(logtail as any).info(...args);
	};
	console.debug = (...args) => {
		originalConsole.debug(...args);
		(logtail as any).debug(...args);
	};
};

export const getEnvForGleap = () => {
	let gleapENV: 'dev' | 'staging' | 'prod' = 'dev';
	switch (process.env.REACT_APP_SENTRY_ENV) {
		case 'development':
			gleapENV = 'dev';
			break;
		case 'production':
			gleapENV = 'prod';
			break;
		case 'staging':
			gleapENV = 'staging';
			break;
		default:
			gleapENV = 'dev';
	}
	return gleapENV;
};

export const initGleap = () => {
	Gleap.initialize(process.env.REACT_APP_GLEAP_KEY);

	const gleapENV = getEnvForGleap();
	Gleap.setEnvironment(gleapENV);
};

export const initTrackers = () => {
	initGleap();
	if (process.env.REACT_APP_ENVIRONMENT.toUpperCase() === 'DEVELOPMENT') return;
	initSentry();
	initLogtail();
};
