import React, { useState } from 'react';
import {
	TextInput,
	Button,
	Stack,
	Text,
	ScrollArea,
	createStyles,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { InviteTeamData } from '../types';

interface InviteTeamProps {
	onNext: () => void;
	data: InviteTeamData;
	setData: (data: InviteTeamData) => void;
}

const useStyles = createStyles((theme) => ({
	title: {
		fontSize: 32,
		fontWeight: 500,
		marginBottom: '1.5rem',
		[`@media (max-width: ${theme.breakpoints.sm})`]: {
			fontSize: 24,
		},
	},
	subtitle: {
		fontSize: 20,
		fontWeight: 500,
		marginBottom: '1rem',
	},
	input: {
		backgroundColor: '#F5F5F5',
		borderRadius: '4px',
	},
	inviteMore: {
		fontSize: 11,
		color: '#7F7F7F',
		fontWeight: 400,
		textAlign: 'right',
		marginTop: '0.5rem',
		cursor: 'pointer',
		fontStyle: 'italic',
	},
}));

const InviteTeam: React.FC<InviteTeamProps> = ({ onNext, data, setData }) => {
	const { classes } = useStyles();

	// Manage the invited emails dynamically
	const [invites, setInvites] = useState<string[]>(data.invites || ['']); // Initialize with data or empty array

	// Initialize Mantine useForm hook with validation
	const form = useForm({
		initialValues: {
			inviteEmail: data.inviteEmail || '',
		},
		validate: {
			inviteEmail: (value) =>
				value.trim().length > 0 && !/^\S+@\S+\.\S+$/.test(value)
					? 'Invalid email'
					: null, // Validate the first email if it's not empty
		},
	});

	// Handle form submission
	const handleSubmit = (values: typeof form.values) => {
		// Validate additional invites
		const invalidEmails = invites.some(
			(email) => email.trim().length > 0 && !/^\S+@\S+\.\S+$/.test(email)
		);

		if (invalidEmails) {
			alert('Please make sure all email addresses are valid.');
			return; // Prevent submission if any email is invalid
		}

		setData({ inviteEmail: values.inviteEmail, invites }); // Save data to state
		onNext(); // Move to the next step
	};

	// Function to add another invite input
	const addInvite = () => {
		setInvites([...invites, '']); // Add a new empty string for the new invite
	};

	// Function to handle invite changes
	const handleInviteChange = (index: number, value: string) => {
		const newInvites = [...invites];
		newInvites[index] = value;
		setInvites(newInvites);
	};

	return (
		<Stack spacing='md' p='xl' py={'md'}>
			<Text className={classes.title}>
				Reelay works best when everyone is included.
			</Text>
			<Text className={classes.subtitle}>
				Add anyone from your organization: (
				<Text italic span>
					optional{' '}
				</Text>
				)
			</Text>

			<form onSubmit={form.onSubmit(handleSubmit)}>
				<ScrollArea h={150}>
					{/* First invite input (optional and validated) */}
					<TextInput
						placeholder='Email'
						size='md'
						{...form.getInputProps('inviteEmail')}
						className={classes.input}
					/>

					{/* Scrollable area for additional invites with inline styling */}
					{invites.map((email, index) => (
						<TextInput
							key={index}
							placeholder={`Enter another email`}
							size='md'
							value={email}
							onChange={(e) => handleInviteChange(index, e.currentTarget.value)}
							className={classes.input}
							style={{ marginTop: '1rem' }}
						/>
					))}
				</ScrollArea>

				<Text className={classes.inviteMore} onClick={addInvite}>
					+ Invite more colleagues
				</Text>

				<Button type='submit' fullWidth mt='lg' size='md' radius={'md'}>
					Next, tell us about your company
				</Button>
			</form>
		</Stack>
	);
};

export default InviteTeam;
