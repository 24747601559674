import React, { MutableRefObject } from 'react';
import { Button, createStyles } from '@mantine/core';
import theme from '../../../../_utils/theme';

const useStyles = createStyles((theme) => ({
	addHighlightButton: {
		backgroundColor: theme.colors?.primary[8],
		// width:           '100%',
		// fontSize:        16,
		marginTop: 8,
	},
	'&:hover': {
		backgroundColor: theme.colors?.primary[8],
	},
}));

interface CreateHighlightButtonProps {
	onClickFunc?: any;
	loading?: boolean;
	buttonText?: string;
}
export const CreateHighlightButton = ({
	onClickFunc,
	buttonText = '+ Create Highlight',
	loading = false,
}: CreateHighlightButtonProps) => {
	const { classes } = useStyles();
	return (
		<Button
			type='submit'
			radius={'md'}
			size={'sm'}
			fullWidth
			className={classes.addHighlightButton}
			onClick={onClickFunc}
			loading={loading}
			loaderPosition='right'
		>
			{buttonText}
		</Button>
	);
};
