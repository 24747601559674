import React, { useState } from 'react';
import {
	ActionIcon,
	Container,
	Group,
	Loader,
	Menu,
	TextInput,
	useMantineTheme,
} from '@mantine/core';
import { IAction } from '../../../interfaces/action';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	actionItemsSearchValue,
	actionTableSelection,
	currentActions as currentActionsAtom,
} from '../../../Atoms/actions';
import { filterActionsBySearch } from '../../../helpers/meetings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ActionsToggle from '../../../pages/actions/components/ActionsToggle';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import {
	faArrowDownToLine,
	faUserPlus,
	faEnvelope,
	faUserPen,
	faDownload,
	faTrash,
} from '@fortawesome/pro-light-svg-icons';
import { showFailureNotification } from '../../../helpers/notifications';
import { MeetingSearch } from '../../MeetingSearch';
import { currentUser, isProjectManagerAtom } from '../../../Atoms/userAtoms';
import {getEnvForGleap} from "../../../_utils/trackers";

interface Props {
	viewType: string;
	setViewType: (value: string) => void;
	searchValue: string;
	handleSearch: (value: string) => void;
	changeSegmentValue: (value: string) => void;
	handleBulkAssign: () => void;
	handleBulkRemind: () => void;
	handleBulkDownload: () => void;
	handleBulkDelete: () => void;
}

const SearchActionsBar = ({
	viewType,
	setViewType,
	searchValue,
	handleSearch,
	changeSegmentValue,
	handleBulkAssign,
	handleBulkRemind,
	handleBulkDownload,
	handleBulkDelete,
}: Props) => {
	const theme = useMantineTheme();
	const [selection, setSelection] = useRecoilState(actionTableSelection);
	const user = useRecoilValue(currentUser);
	const isProjectManager = useRecoilValue(isProjectManagerAtom);

	return (
		<Group
			w={'100%'}
			mt={10}
			maw={1408}
			noWrap
			position={'apart'}
			align={'center'}
			mb={'md'}
		>
			<ActionsToggle
				viewType={viewType}
				setViewType={setViewType}
				changeSegmentValue={changeSegmentValue}
			/>
			{/* <MeetingSearch searchValue={searchValue} handleSearch={handleSearch} /> */}
			<Menu shadow='md' width={200} id={`VIS_ctf9nfdtmsxfgg2vmceg_${getEnvForGleap()}`}>
				<Menu.Target>
					<ActionIcon>
						<FontAwesomeIcon icon={faEllipsisVertical} size={'lg'} />
					</ActionIcon>
				</Menu.Target>
				<Menu.Dropdown>
					<Menu.Label>Bulk Actions</Menu.Label>
					{isProjectManager ? (
						<Menu.Item
							icon={
								<FontAwesomeIcon
									style={{
										color: theme.colors['secondary-text'][0],
										cursor: 'pointer',
									}}
									icon={faUserPlus}
									size={'sm'}
								/>
							}
							onClick={(e) => {
								e.stopPropagation();
								handleBulkAssign();
							}}
						>
							Assign
						</Menu.Item>
					) : null}
					{isProjectManager ? (
						<Menu.Item
							onClick={handleBulkRemind}
							icon={<FontAwesomeIcon icon={faEnvelope} />}
						>
							Remind
						</Menu.Item>
					) : null}

					<Menu.Item
						onClick={handleBulkDownload}
						icon={<FontAwesomeIcon icon={faDownload} />}
					>
						Download as .csv
					</Menu.Item>

					{isProjectManager ? (
						<Menu.Item
							onClick={handleBulkDelete}
							icon={<FontAwesomeIcon icon={faTrash} />}
						>
							Delete
						</Menu.Item>
					) : null}
				</Menu.Dropdown>
			</Menu>
		</Group>
	);
};

export default SearchActionsBar;
