import {
	Button,
	FileButton,
	Group,
	Image,
	Paper,
	Stack,
	Text,
	TextInput,
} from '@mantine/core';
import { styles } from '../../../../../../settings/tabs/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFloppyDisk,
	faTrash,
	faUpload,
} from '@fortawesome/pro-light-svg-icons';
import { useEffect, useState } from 'react';
import { logger } from '../../../../../../../helpers/logger';
import { OrganizationSettings } from '../../../../../../../Atoms/settings';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../../../helpers/notifications';
import {
	deleteOrganizationSettings,
	saveOrganizationSettings,
} from '../../../../../../../api/settings';
import { DangerNoButton } from '../../../../../../../components/Buttons/DangerNoButton';
import { useParams } from 'react-router';

// char limit is 1835008. 50,000 is subtracted for safety cushion
const BOT_PICTURE_CHAR_LIMIT = 1835008;
const SAFETY_MARGIN_FOR_ERROR = 50000;
const MAX_CHAR_LIMIT = BOT_PICTURE_CHAR_LIMIT - SAFETY_MARGIN_FOR_ERROR;

interface Props {
	organizationSettings: OrganizationSettings;
	setOrganizationSettings: React.Dispatch<
		React.SetStateAction<OrganizationSettings>
	>;
}

export default function OrganizationBranding({
	organizationSettings,
	setOrganizationSettings,
}: Props) {
	const { classes, theme } = styles();
	const { organizationID } = useParams();
	const { bot_name, bot_picture } = organizationSettings;
	const [orgBotNameValue, setOrgBotNameValue] = useState(bot_name || '');
	const [imagePreview, setImagePreview] = useState<string | null>(
		bot_picture || null
	);
	const [base64, setBase64] = useState<string | null>(null);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [uploadButtonLoading, setUploadButtonLoading] = useState(false);
	const [removeButtonLoading, setRemoveButtonLoading] = useState(false);

	useEffect(() => {
		setImagePreview(bot_picture || '');
		setOrgBotNameValue(bot_name || '');
	}, [organizationSettings]);

	const handleSaveOrgBotName = async () => {
		try {
			setButtonLoading(true);
			const payload = [
				{
					key: 'bot_name',
					value: orgBotNameValue,
				},
			];
			const res = await saveOrganizationSettings(payload, organizationID);
			const details = res[0];
			setOrganizationSettings((prev) => ({
				...prev,
				bot_name: details?.value,
			}));
			setOrgBotNameValue(details?.value);
			showSuccessNotification({
				message:
					'Organization bot name saved successfully. You should see it named this when it joins your meetings.',
			});
		} catch (error) {
			logger('error', 'Error saving org bot name', error);
			showFailureNotification({
				message:
					'Sorry, there was an error saving your organization bot name. Please try again.',
			});
		} finally {
			setButtonLoading(false);
		}
	};

	const handleFileChange = (file: File) => {
		try {
			if (!file) return;
			setUploadButtonLoading(true);

			// Convert the file to a base64 string
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = async () => {
				const base64String = reader.result as string;
				if (base64String.length > MAX_CHAR_LIMIT) {
					return showFailureNotification({
						message: `Sorry, your file is too large. Please try again with a smaller file.`,
					});
				}

				// Create a URL for the image preview
				const fileUrl = URL.createObjectURL(file);
				setImagePreview(fileUrl);

				const payload = [
					{
						key: 'bot_picture',
						value: base64String,
					},
				];
				const res = await saveOrganizationSettings(payload, organizationID);
				setOrganizationSettings((prev) => ({
					...prev,
					bot_picture: res[0]?.value,
				}));
				showSuccessNotification({
					message: 'Organization bot avatar has been saved.',
				});
			};
			reader.onerror = (error) => {
				showFailureNotification({
					message: `Sorry, there was an error saving your organization bot avatar. Please try again or with a different file. Your file may also be too large.`,
				});
			};
		} catch (error) {
			logger('error', `Error saving organization bot avatar`, error);
		} finally {
			setUploadButtonLoading(false);
		}
	};
	const removeBotPicture = async () => {
		try {
			setRemoveButtonLoading(true);
			const res = await deleteOrganizationSettings(
				'bot_picture',
				organizationID
			);
			if (res[0]?.key === 'bot_picture') {
				setOrganizationSettings((prev) => ({
					...prev,
					bot_picture: '',
				}));
				showSuccessNotification({
					message: 'Organization bot avatar has been removed.',
				});
			}
		} catch (error) {
			logger('error', 'Error removing bot picture', error);
			showFailureNotification({
				message: `Sorry, there was an error removing your organization bot avatar. Please try again.`,
			});
		} finally {
			setRemoveButtonLoading(false);
		}
	};

	return (
		<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
			<Group position={'apart'}>
				<Stack spacing={0}>
					<Text mb={4} size={'sm'}>
						Rename your organization's instance of Reelay
					</Text>
					<TextInput
						value={orgBotNameValue}
						onChange={(event) => setOrgBotNameValue(event.currentTarget.value)}
						radius='md'
						bg={'primary.0'}
						styles={{
							input: {
								backgroundColor: theme.colors.primary[0],
							},
						}}
						placeholder='Bot name'
					/>
				</Stack>
				<Button
					type={'submit'}
					variant={'filled'}
					radius={'xl'}
					rightIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
					color={'primary'}
					loading={buttonLoading}
					disabled={orgBotNameValue === ''}
					onClick={handleSaveOrgBotName}
				>
					Save
				</Button>{' '}
			</Group>
			<Group position={'apart'} mt={'lg'}>
				<Stack spacing={0}>
					<Text mb={4} size={'sm'}>
						Upload a picture for your organization's instance of Reelay
					</Text>
					{imagePreview ? (
						<Image
							src={imagePreview}
							alt='Bot Avatar image preview'
							width={150}
							height={150}
						/>
					) : (
						<Image
							width={150}
							height={150}
							src={null}
							alt='With default placeholder'
							withPlaceholder
						/>
					)}
					<Text size='sm' align='left' mt='sm'>
						accepts only jpeg files. Image should be 16:9. Recommended
						resolution is 640x360.
					</Text>
				</Stack>

				<Stack>
					<FileButton onChange={handleFileChange} accept='image/jpeg'>
						{(props) => (
							<Button
								loading={uploadButtonLoading}
								radius={'xl'}
								variant={'filled'}
								rightIcon={<FontAwesomeIcon icon={faUpload} />}
								color={'primary'}
								{...props}
							>
								Upload image
							</Button>
						)}
					</FileButton>
					{bot_picture?.length > 0 ? (
						<DangerNoButton
							clickFunc={removeBotPicture}
							text={'Remove'}
							leftIcon={<FontAwesomeIcon icon={faTrash} />}
							radius={'lg'}
							loading={removeButtonLoading}
						/>
					) : null}
				</Stack>
			</Group>
		</Paper>
	);
}
