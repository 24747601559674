import React from 'react';
import { Button } from '@mantine/core';

interface Props {
  clickFunc: () => void;
  text?: string;
  leftIcon?: React.ReactNode | null;
  disabled?: boolean;
  loading?: boolean;
}
export const DangerYesButton = ({
  clickFunc,
  text = 'Yes',
  leftIcon = null,
  disabled = false,
  loading = false,
}: Props) => {
  return (
    <Button
      loading={loading}
      disabled={disabled}
      size={'sm'}
      radius={'md'}
      onClick={clickFunc}
      variant='filled'
      color='red'
      leftIcon={leftIcon}
    >
      {text}
    </Button>
  );
};
