import React from 'react';
import { ActionIcon, createStyles, Loader, Stack, Text } from '@mantine/core';
import MobileVideoQueue from '../../components/mobile_video_queue';
import { useRecoilValue } from 'recoil';
import { currentHost, currentUserReelays, user } from '../../Atoms/userAtoms';
import { Link } from 'react-router-dom';
import MobileHostInfo from '../../components/mobile_host_data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { myMeetings } from '../../Atoms/meetingAtom';

const useStyles = createStyles(() => ({
	outerContainer: {
		overflowY: 'auto',
		height: '100vh',
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		height: 'auto',
	},
	bottomContainer: {
		flexGrow: 1,
		overflow: 'auto',
	},
}));
export const MobileHostView = () => {
	const { classes } = useStyles();
	const currentHostData = useRecoilValue(currentHost);
	const meetings = useRecoilValue(myMeetings);

	return (
		<>
			{currentHostData && meetings.length ? (
				<Stack spacing={0} className={classes.outerContainer}>
					<Stack spacing={0} className={classes.header}>
						<ActionIcon
							mx={30}
							my={10}
							variant='transparent'
							component={Link}
							to={'/meetings'}
						>
							<FontAwesomeIcon icon={faArrowLeft} />
							<Text ml='xs'>Back</Text>
						</ActionIcon>
					</Stack>
					<div className={classes.bottomContainer}>
						<MobileHostInfo host={currentHostData} />
						<MobileVideoQueue meetings={meetings} />
					</div>
				</Stack>
			) : (
				<Loader />
			)}
		</>
	);
};
