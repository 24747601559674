export const applyTranscriptSearchFilter = (list, value) => {
	return list.filter((item) => {
		const { speaker, text } = item;
		return (
			(speaker || '').toLowerCase().includes(value.toLowerCase()) ||
			(text || '').toLowerCase().includes(value.toLowerCase())
		);
	});
};

export const applyChatSearchFilter = (list, value) => {
	return list.filter((item) => {
		const { content, sender } = item;
		return (
			(content || '').toLowerCase().includes(value.toLowerCase()) ||
			(sender || '').toLowerCase().includes(value.toLowerCase())
		);
	});
};
