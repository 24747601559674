import { atom } from 'recoil';
import { IMeeting } from '../interfaces/meeting';

export const recallAIBotDetails = atom<Record<string, any>>({
	key: 'recallAIBotDetails',
	default: {},
});

export const recallAIBotEvent = atom<Record<string, any>>({
	key: 'recallAIBotEvent',
	default: {},
});

export const liveMeetingsWithBots = atom<IMeeting[]>({
	key: 'liveMeetingsWithBots',
	default: [],
});

export const upcomingMeetings = atom<IMeeting[]>({
	key: 'upcomingMeetings',
	default: [],
});

export const updateMeetingLibraryToggleAtom = atom<boolean>({
	key: 'updateMeetingLibraryToggleAtom',
	default: false,
});
