import React, { useRef, useState } from 'react';
import { Button, Group, Modal, Stack, TextInput, Title } from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import { createCollection } from '../../../api/collections';
import { logger } from '../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { useRecoilState, useRecoilValue } from 'recoil';
import { collectionsAtom } from '../../../Atoms/collections';
import { user } from '../../../Atoms/userAtoms';

interface Props {
	setOpened: React.Dispatch<React.SetStateAction<boolean>>;
	opened: boolean;
}

export default function AddCollectionModal({ opened, setOpened }: Props) {
	const focusTrapRef = useFocusTrap();
	const buttonRef = useRef(null);
	const [collections, setCollections] = useRecoilState(collectionsAtom);
	const currentUser = useRecoilValue(user);
	const { currentOrganizationID } = currentUser;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [value, setValue] = useState('');

	const handleCreateCollection = async () => {
		try {
			if (!value.length) {
				setError('Name is required');
				return;
			}

			setLoading(true);
			const res = await createCollection(value, currentOrganizationID);
			const newCollection = res.data;
			setCollections((cur) => [...cur, newCollection]);
			logger('info', 'Collection successfully made', newCollection);
			setOpened(false);
			setValue('');
			setError(null);
			showSuccessNotification({ message: 'Collection successfully made!' });
		} catch (error) {
			logger('error', 'Error creating collection', error);
			showFailureNotification({
				message: `Sorry, there was an error when creating a collection. Reason: ${
					error?.response?.data || error?.message
				}`,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && buttonRef.current) {
			buttonRef.current.click();
		}
	};

	return (
		<Modal
			size={'sm'}
			centered={true}
			title={
				<Title order={6} size={'h3'} fw={400}>
					New Collection
				</Title>
			}
			opened={opened}
			onClose={() => setOpened(false)}
		>
			<Stack ref={focusTrapRef}>
				<TextInput
					data-autofocus
					placeholder={'Enter name'}
					value={value}
					onChange={(event) => setValue(event.currentTarget.value)}
					disabled={loading}
					error={error}
					onKeyDown={handleKeyPress}
				/>
				<Group noWrap position={'right'} spacing={'xs'}>
					<Button
						variant='subtle'
						radius='md'
						onClick={() => setOpened(false)}
						px={'sm'}
						disabled={loading}
					>
						Cancel
					</Button>
					<Button
						ref={buttonRef}
						onClick={handleCreateCollection}
						variant='subtle'
						radius='md'
						px={'sm'}
						loading={loading}
					>
						Create
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
