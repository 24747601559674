import { Group, Stack, Text } from '@mantine/core';
import { Logo } from '../../../components/reelayLogo';
import { getCurrentYear } from '../../../helpers/data';

export function Footer({ version, isCustomBranded }) {
	return (
		<Stack align={'center'} pb={'xl'}>
			<Text size='sm' color='dimmed' fw={500}>
				v{version}
			</Text>
			{isCustomBranded && (
				<Group noWrap spacing={'xs'}>
					<Text color={'dimmed'} align={'center'} fz={'sm'} fw={500}>
						powered by
					</Text>
					<Logo width={60} showReelayLogo={true} showWoodmark={true} />
				</Group>
			)}
			<Text size='sm' color='dimmed' fw={500}>
				Copyright © {getCurrentYear()} Reelay Meetings, Inc.
			</Text>
		</Stack>
	);
}
