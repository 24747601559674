import React, { useState, useEffect } from 'react';
import { Modal, Input, Button } from '@mantine/core';

interface EditSpeakersModalProps {
  opened: boolean;
  speakerLabels: { name: string }[];
  onSave: (speakerLabelChanges: { [oldSpeakerLabel: string]: string }) => void;
  onClose: () => void;
}

const EditSpeakersModal: React.FC<EditSpeakersModalProps> = ({ opened, speakerLabels, onSave, onClose }) => {
  const [newSpeakerLabels, setNewSpeakerLabels] = useState<{ name: string }[]>([]);
  const [initialSpeakerLabels, setInitialSpeakerLabels] = useState<{ name: string }[]>([]);

  useEffect(() => {
    setNewSpeakerLabels([...speakerLabels]);
    if (initialSpeakerLabels.length === 0) {
      setInitialSpeakerLabels([...speakerLabels]);
    }
  }, [speakerLabels]);

  const handleSave = () => {
    const speakerLabelChanges = initialSpeakerLabels.reduce(
      (acc, item, index) => {
        if (item.name !== newSpeakerLabels[index].name) {
          acc[item.name] = newSpeakerLabels[index].name;
        }
        return acc;
      },
      {}
    );
    onSave(speakerLabelChanges);
  };

  return (
    <Modal title="Edit Speaker Labels" opened={opened} onClose={onClose}>
      {newSpeakerLabels.map((item, index) => (
        <Input
          key={index}
          value={item.name}
          onChange={(event) => {
            const updatedLabels = [...newSpeakerLabels];
            updatedLabels[index].name = event.currentTarget.value;
            setNewSpeakerLabels(updatedLabels);
          }}
        />
      ))}
      <Button onClick={handleSave}>Save</Button>
    </Modal>
  );
};

export default EditSpeakersModal;
