import axiosInstance from '../axios/axios';
import { replaceApostropheHTMLEntityWithSingleQuote } from '../_utils/handy-functions';
import { logger } from '../helpers/logger';
import { Chapter } from '../helpers/meetings';
import { number } from 'prop-types';
import { encode } from 'js-base64';
import { DateTime } from 'luxon';
import { destructureSettingsResponse } from '../helpers/settings/settings';
import { IHighlight } from '../interfaces/highlight';
import { useLocation } from 'react-router-dom';
import fingerprintjs from '@fingerprintjs/fingerprintjs';
import {
	InfoCollectionData,
	InviteTeamData,
	OrganizationData,
	PreferencesData,
	RegisterData,
} from '../pages/onboarding/types';

const fpPromise = fingerprintjs.load();
/** Users **/

/**
 *
 * Send a MagicLink to a user by passing in an email address.
 * @param {string}   email - The email where you would like the MagicLink sent to
 *
 */

export const sendMagicLinkToUser = (email: string) => {
	logger('info', 'Sending Magic Link to User', { email });
	return axiosInstance
		.post('/v1/auth/send-magic-link', { email })
		.then((response) => {
			logger('info', 'Magic Link Sent to User', response);
			return response.data;
		})
		.catch((err) => {
			logger('error', 'Error Sending Magic Link to User', err);
			return err.response.data;
		});
};

export const sendAuthCode = (email: string) => {
	logger('info', 'Sending auth code to User', { email });
	return axiosInstance
		.post('/v1/auth/send-code', { email })
		.then((response) => {
			logger('info', 'Magic Link Sent to User', response);
			return response.data.data;
		})
		.catch((err) => {
			logger('error', 'Error Sending Magic Link to User', err);
			return err.response.data;
		});
};

export const loginWithTheCode = (code: string, email: string) => {
	logger('info', 'loginWithTheCode fires', { code, email });
	return axiosInstance
		.post('/v1/auth/authenticate-code', { code, email })
		.then((response) => {
			logger('info', 'loginWithTheCode successful', response);
			return response.data.data;
		})
		.catch((err) => {
			logger('error', 'Error Sending Magic Link to User', err);
			return Error(err?.response?.data);
		});
};

export const getImageUploadLink = (payload) => {
	logger('info', 'getImageUploadLink fires', { ...payload });
	return axiosInstance
		.post('/v1/auth/get-image-upload-link', payload)
		.then((response) => {
			logger('info', 'getImageUploadLink successful', response);
			return response.data;
		})
		.catch((err) => {
			logger('error', 'Error with getImageUploadLink', err);
			throw new Error(err?.response?.data);
		});
};

export const getCurrentUser = (organizationID = '') => {
	logger('info', 'Request sent to getCurrentUser', {});

	const url = organizationID
		? `v1/user?organizationID=${organizationID}`
		: 'v1/user';

	return axiosInstance
		.get(url)
		.then((res) => {
			logger('info', 'Response received from getCurrentUser', res);

			return res.data.data;
		})
		.catch((err) => {
			logger('error', 'Error getting current user', err);
			throw err; // Ensure the error is propagated
		});
};
export const getUserByID = (userID: string, organizationID: string) => {
	logger('info', 'Request sent to getUserByID', { userID, organizationID });
	return axiosInstance
		.get('v1/user', {
			params: {
				userID,
			},
			headers: { 'x-organization-id': organizationID },
		})
		.then((res) => {
			logger('info', 'Response received from getUserByID', res);
			return res.data;
		})
		.catch((err) => logger('error', 'Error getting user by ID', err));
};

export const getUserByIDWithoutOrg = (userID: string) => {
	logger('info', 'Request sent to getUserByID', { userID });
	return axiosInstance
		.get('v1/user', {
			params: {
				userID,
			},
		})
		.then((res) => {
			logger('info', 'Response received from getUserByID', res);
			return res.data;
		})
		.catch((err) => logger('error', 'Error getting user by ID', err));
};

export const getSupportUsers = () => {
	logger('info', 'Request sent to getSupportUsers', {});
	return axiosInstance
		.get('v1/supporters', {
			// headers: { 'x-organization-id': organizationID },
		})
		.then((res) => {
			logger('info', 'Response received from getUsers', res);
			return res.data.data;
		})
		.catch((err) => logger('error', 'Error getting users', err));
};

export const getUsersByOrg = (
	organizationID: string,
	getEmailAliases = false
) => {
	logger('info', 'Request sent to getUsersByOrg', { organizationID });
	return axiosInstance
		.post(
			'v1/users',
			{
				limit: 1000,
				getEmailAliases,
			},
			{
				params: { organizationID },
			}
		)
		.then((res) => {
			logger('info', 'Response received from getUsersByOrg', res);
			// filter out duplicate user.id
			return res.data.data.filter((user, index, self) => {
				return index === self.findIndex((u) => u.id === user.id);
			});
		})
		.catch((err) => logger('error', 'Error getting users by org', err));
};

export const updateCurrentUser = (
	data: object,
	userID: string,
	organizationID: string
) => {
	logger('info', 'Request sent to updateCurrentUser', {
		data,
		userID,
		organizationID,
	});
	return axiosInstance.patch('v1/user', data, {
		params: { userID, organizationID },
	});
};

export const updateOrganizationUser = (
	data: {
		email: string;
		firstName?: string;
		lastName?: string;
		phone?: string;
		dob?: string;
		time_zone?: string;
	},
	userID: string,
	organizationID: string
) => {
	logger('info', 'Request sent to updateOrganizationUser', {
		data,
		userID,
		organizationID,
	});
	return axiosInstance.patch(
		`v1/organization/${organizationID}/user/${userID}`,
		data,
		{
			params: {
				userID,
				organizationID,
			},
		}
	);
};

export const removeUserFromOrganization = (
	userID: string,
	organizationID: string
) => {
	logger('info', 'Request sent to removeUserFromOrganization', {
		userID,
		organizationID,
	});
	return axiosInstance.delete(
		`v1/organization/${organizationID}/user/${userID}`
	);
};

/**
 * This removes user completely from all organization and the db.
 * @param userID
 * @param organizationID
 */
export const removeUser = (userID: string, organizationID?: string) => {
	logger('info', 'Request sent to removeUser', {
		params: {
			userID,
			organizationID,
		},
	});
	const organizationIDPath = organizationID
		? `/organization/${organizationID}`
		: '';
	return axiosInstance.delete(`v1${organizationIDPath}/user/${userID}`);
};

export const registerUser = (data: {
	email: string;
	firstName?: string;
	lastName?: string;
	phone?: string;
	dob?: string;
}) => {
	logger('info', 'Request sent to registerUser', { data });
	return axiosInstance.post('v1/auth/register-user', data);
};

export const searchUsers = (
	organizationID: string | undefined,
	searchTerm: string
) => {
	logger('info', 'Request sent to searchUsers', { organizationID, searchTerm });
	return axiosInstance.post(
		'v1/users',
		{
			filter: {
				'firstName*': `%${searchTerm}%`,
			},
		},
		{
			params: {
				organizationID,
			},
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const searchTeamsAndUsers = (
	organizationID: string | undefined,
	searchTerm: string
) => {
	logger('info', 'Request sent to searchTeamsAndUsers', {
		organizationID,
		searchTerm,
	});
	return axiosInstance.post(
		`v1/organization/${organizationID}/search-teams-and-users`,
		{
			filter: {
				teams: { 'name*': `%${searchTerm}%` },
				users: { 'firstName*': `%${searchTerm}%` },
			},
		},
		{
			params: { organizationID },
		}
	);
};

export const inviteUserToOrganization = (
	data: {
		email: string;
		roles?: string[];
	},
	organizationID: string
) => {
	logger('info', 'Request sent to inviteUserToOrganization', {
		data,
		organizationID,
	});
	return axiosInstance.post(
		`v1/organization/${organizationID}/invite-user`,
		data,
		{
			params: { organizationID },
		}
	);
};

export const updateUserAvatar = (data: {
	data: FormData;
	fileName: string;
	organizationID: string;
	userID?: string;
}) => {
	logger('info', 'Request sent to updateUserAvatar', { data });
	return axiosInstance.patch(
		`v1/organization/${data.organizationID}/user/${data.userID}/avatar`,
		data.data,
		{
			// params: { organizationID: data.organizationID, userID: data.userID },
			headers: { 'Content-Type': undefined },
		}
	);
};

/** Organizations **/

export const getCurrentOrganization = (orgID: string) => {
	logger('info', 'Request sent to getCurrentOrganization', { orgID });
	return axiosInstance.get(`v1/organization/${orgID}`, {
		params: {
			organizationID: orgID,
		},
	});
};

export const getOrganizationSupportUsers = (organizationID: string) => {
	logger('info', 'Request sent to getOrganizationSupportUsers', {
		organizationID,
	});
	return axiosInstance.get(`v1/organization/${organizationID}/supporters`);
};

export const createOrganization = (data: { name: string }) => {
	logger('info', 'Request sent to createOrganization', { data });
	return axiosInstance.put(`v1/organizations`, data);
};

/**
 *
 * This will allow an organization-less user the ability to get all the organizations that exist in the system.
 * There is no need to pass anything in to this method.
 *
 */

export const getAllOrganizations = () => {
	logger('info', 'Request sent to getAllOrganizations', {});
	return axiosInstance.get(`v1/organizations`, {
		params: {
			limit: 1000,
		},
	});
};

export const getOrganization = async (organizationID: string) => {
	logger('info', 'Request sent to getOrganization', { organizationID });
	try {
		const response = await axiosInstance.get(
			`v1/organization/${organizationID}`
		);
		return response.data.data;
	} catch (error) {
		logger('error', 'Error fetching organization', error);
		throw error;
	}
};
export const searchOrganizationsByUserID = (userID) => {
	logger('info', 'Request sent to getAllOrganizations', { userID });
	return axiosInstance.post(`v1/organizations`, {
		filter: {
			userID,
		},
	});
};

/** Meetings **/

export const getMeeting = async (
	meetingID: string | undefined,
	organizationID: string,
	sharingToken?: string
) => {
	logger('info', 'Request sent to getMeeting', { meetingID, organizationID });
	let visitorFingerprint;
	if (sharingToken) {
		const fpJS = await fpPromise;
		visitorFingerprint = (await fpJS.get()).visitorId;
	}
	return axiosInstance.get(`v1/meeting/${meetingID}`, {
		headers: {
			'x-organization-id': organizationID,
			'x-sharing-token': sharingToken,
			'x-fingerprint': visitorFingerprint,
		},
	});
};

export const getMeetingsByUserID = (userID: string) => {
	return getMeetings(userID);
};

export const getMeetings = (
	userID?: string,
	organizationID?: string,
	limit = 100
) => {
	logger('info', 'Request sent to getMeetings', { organizationID, userID });
	const headers = !organizationID
		? {}
		: { 'x-organization-id': organizationID };
	return axiosInstance.get('v1/meetings', {
		headers,
		params: {
			userID,
			limit,
		},
	});
};

export const createMeeting = (payload: {
	organizationID: string;
	platform: string;
	recapOnly: boolean;
	name?: string;
}) => {
	logger('info', 'Request sent to createMeeting', payload);
	return axiosInstance.put('v1/meetings', payload);
};

export const updateMeeting = (
	meetingDetails:
		| Record<string, unknown>
		| { summary: string }
		| { longSummary: string }
		| { name: string }
		| { thumbnailTime: number }
		| { auditedByUserID: string },
	meetingID: string | undefined,
	organizationID: string | undefined
) => {
	logger('info', 'Request sent to updateMeeting', {
		meetingDetails,
		meetingID,
		organizationID,
	});
	return axiosInstance.patch(`v1/meeting/${meetingID}`, meetingDetails, {
		headers: { 'x-organization-id': organizationID },
	});
};

export const destroyMeeting = (
	meetingID: string | undefined,
	organizationID: string | undefined
) => {
	logger('info', 'Request sent to destroyMeeting', {
		meetingID,
		organizationID,
	});
	return axiosInstance.delete('v1/meetings', {
		data: {
			meetingIDs: [meetingID],
		},
		headers: { 'x-organization-id': organizationID },
	});
};

export const filestackUpload = (payload: {
	organizationID: string;
	platform: string;
	recapOnly: boolean;
	name?: string;
	filestackData: {
		uploadID: string;
		url: string;
	};
	fullAsset: boolean;
}) => {
	logger('info', 'Request sent to createMeeting', payload);
	return axiosInstance.post('v1/meetings-filestack-upload', payload);
};

type InviteUsersToMeetingParams = {
	emails: string[];
	meetingID?: string;
	organizationID?: string;
	roles?: string[];
	expirationDate?: number | Date | null;
	emailMessage?: string | null;
	sendEmail?: boolean;
};

export const inviteUsersToMeeting = ({
	emails,
	meetingID,
	organizationID,
	roles = ['member'],
	expirationDate = null,
	emailMessage = null,
	sendEmail = true,
}: InviteUsersToMeetingParams) => {
	logger('info', 'Request sent to inviteUsersToMeeting', {
		emails,
		meetingID,
		organizationID,
		roles,
	});
	return axiosInstance.post(
		`v1/meeting/${meetingID}/invite-users`,
		{ emails, roles, expirationDate, emailMessage, sendEmail },
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const removeUsersFromAMeeting = (
	emails: string[],
	meetingID: string | undefined,
	organizationID: string | undefined
) => {
	logger('info', 'Request sent to inviteUsersToMeeting', {
		emails,
		meetingID,
		organizationID,
	});
	return axiosInstance.post(
		`v1/meeting/${meetingID}/remove-users`,
		{ emails },
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const updateSharingTokenIsActive = (
	{ targetID, value }: { targetID: string; value: boolean },
	organizationID: string
) => {
	logger('info', 'Request sent to updateSharingTokenIsActive', {
		targetID,
		value,
	});
	return axiosInstance.patch(
		`v1/sharing-token`,
		{
			targetID,
			active: value,
		},
		{
			headers: {
				'x-organization-id': organizationID,
			},
		}
	);
};

export const getSharingToken = (token: string) => {
	logger('info', 'Request sent to getSharingToken', { token });
	return axiosInstance.get(`v1/sharing-token?token=${token}`);
};

export const getQuery = (params: SearchMeetingsParams) => {
	const {
		filter,
		startAt,
		organizationID,
		limit = 20,
		offset = 0,
		skip = 0,
		order = '',
		search = '',
	} = params || {};
	const queryPairs = [];
	if (search) queryPairs.push(`search=${search}`);
	if (offset) queryPairs.push(`offset=${offset}`); // used for backend
	if (skip) queryPairs.push(`skip=${skip}`); // used for browser navigation
	if (limit !== 20 && limit > 0) queryPairs.push(`limit=${limit}`);
	if (order) queryPairs.push(`order=${order}`);
	if (filter) queryPairs.push(`filter=${JSON.stringify(filter)}`);
	if (startAt) queryPairs.push(`startAt=${JSON.stringify(startAt)}`);

	//

	Object.keys(params).forEach((key: string) => {
		if (
			[
				'offset',
				'skip',
				'limit',
				'order',
				'search',
				'filter',
				'startAt',
			].indexOf(key) === -1
		) {
			const value = params[key];
			if (value !== undefined) {
				queryPairs.push(`${key}=${value}`);
			}
		}
	});
	return queryPairs.join(`&`);
};

export interface SearchMeetingsParams {
	filter?: object;
	organizationID?: string;
	userID?: string;
	status?: string[];
	isExpired?: boolean;
	recapOnly?: boolean;
	limit?: number;
	offset?: number;
	skip?: number;
	order?: string;
	search?: string;
	collectionID?: string;
	startAt?: {
		start: number;
		end: number;
	};
}

export const searchMeetings = (
	{
		filter,
		organizationID,
		userID,
		status,
		search,
		isExpired,
		recapOnly,
		limit = 20,
		offset = 0,
		collectionID,
		startAt,
		order = '-createdAt',
	}: SearchMeetingsParams,
	source = null
) => {
	logger('info', 'Request sent to searchMeetings', { organizationID });
	const query = getQuery({
		filter,
		limit,
		offset,
		order,
		search: search ? encode(search) : '',
		status,
		isExpired,
		recapOnly,
		collectionID,
		organizationID,
		userID,
		startAt,
	});
	return axiosInstance.get(`v1/meetings?${query}`, {
		headers: { 'x-organization-id': organizationID },
		cancelToken: source?.token || source,
	});
};

export const getMeetingMinutes = (
	{
		filter,
		organizationID,
		status,
		search,
		isExpired,
		recapOnly,
		limit = 20,
		offset = 0,
		collectionID,
		startAt,
		order = '-publishedAt',
	}: SearchMeetingsParams,
	source = null
) => {
	logger('info', 'Request sent to getMeetingMinutes', { organizationID });
	const query = getQuery({
		filter,
		limit,
		offset,
		// order,
		search: search ? encode(search) : '',
		status,
		isExpired,
		recapOnly,
		collectionID,
		organizationID,
		startAt,
	});
	return axiosInstance.get(`v1/meetings-minutes?${query}`, {
		headers: { 'x-organization-id': organizationID },
		cancelToken: source?.token,
	});
};

export const searchAuditMeetings = (organizationID: string | undefined) => {
	logger('info', 'Request sent to searchMeetings', { organizationID });
	return axiosInstance.post(
		'v1/meetings',
		{
			filter: { organizationID, status: 'audit' },
			order: '+Meeting:auditTime',
		},
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const getMeetingAnalytics = (
	meetingID: string | undefined,
	organizationID: string | undefined,
	sharingToken?: string
) => {
	logger('info', 'Request sent to getMeetingAnalytics', {
		meetingID,
		organizationID,
	});
	return axiosInstance.get(`v1/meeting/${meetingID}/analytics`, {
		headers: {
			'x-organization-id': organizationID,
			'x-sharing-token': sharingToken,
		},
	});
};

/** Actions **/
/**
 * if userID is a user id string, then it is assigning the meeting to that user.
 * if userID is null, then any current users assigned will be unassigned.
 * @param data
 */
export const assignMeetingActions = (data: {
	meetingActionIDs: (string | undefined)[];
	userID: string | null;
	meetingID: string | undefined;
	organizationID: string | undefined;
}) => {
	const { meetingID, userID, meetingActionIDs, organizationID } = data;
	logger('info', 'Request sent to assignMeetingActions', {
		meetingID,
		userID,
		meetingActionIDs,
		organizationID,
	});
	return axiosInstance.post(
		`v1/meeting/${meetingID}/meeting-actions/assign`,
		{
			meetingActionIDs,
			userID,
		},
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const login = (data: { magicToken: string }) => {
	logger('info', 'Request sent to login', { data });
	return axiosInstance.post('v1/auth/authenticate', data);
};

export const logout = () => {
	logger('info', 'Request sent to logout', {});
	return axiosInstance.post('v1/auth/logout');
};

// max file size is 10mb
export const addAttachmentsToMeeting = (data: {
	data: FormData;
	params: { meetingID: string | undefined; organizationID?: string };
	headers?: object;
}) => {
	logger('info', 'Request sent to addAttachmentsToMeeting', { data });
	const { meetingID, organizationID } = data.params;
	return axiosInstance.post(
		`v1/meeting/${meetingID}/add-attachments`,
		data.data,
		{
			headers: { ...data.headers, 'x-organization-id': organizationID },
		}
	);
};

export const getAttachmentsForMeeting = (
	meetingID: string | undefined,
	organizationID: string | undefined,
	sharingToken?: string
) => {
	logger('info', 'Request sent to getAttachmentsForMeeting', {
		meetingID,
		organizationID,
	});
	return axiosInstance.get(`v1/meeting/${meetingID}/get-attachments`, {
		headers: {
			'x-organization-id': organizationID,
			'x-sharing-token': sharingToken,
		},
	});
};

export const removeAttachmentsFromMeeting = (
	attachmentIDs: string[],
	meetingID: string,
	organizationID?: string
) => {
	logger('info', 'Request sent to removeAttachmentsFromMeeting', {
		attachmentIDs,
		meetingID,
		organizationID,
	});
	return axiosInstance.post(
		`v1/meeting/${meetingID}/remove-attachments`,
		{ attachmentIDs },
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

/** Highlights **/

export const getHighlights = (
	meetingID: string | undefined | string[],
	organizationID: string | undefined,
	sharingToken?: string
) => {
	const meetingIDs = typeof meetingID === 'string' ? [meetingID] : meetingID;
	logger('info', 'Request sent to getHighlights', {
		meetingIDs,
		organizationID,
	});
	return axiosInstance
		.post(
			'v1/meeting-highlights',
			{ meetingIDs },
			{
				headers: {
					'x-organization-id': organizationID,
					'x-sharing-token': sharingToken,
				},
			}
		)
		.then((res) => {
			logger('info', 'Response received from getHighlights', res);
			return res.data.data.map((highlight: IHighlight) => ({
				...highlight,
				content: replaceApostropheHTMLEntityWithSingleQuote(highlight.content),
			}));
		})
		.catch((err) => logger('error', 'Error getting highlights', err));
};

export const getNotesByMeetingID = (
	meetingID: string | undefined,
	organizationID: string | undefined
) => {
	logger('info', 'Request sent to getNotesByMeetingID', {
		meetingID,
		organizationID,
	});
	return axiosInstance
		.post(
			'v1/meeting-highlights-notes',
			{ meetingIDs: [meetingID] },
			{
				headers: { 'x-organization-id': organizationID },
			}
		)
		.then((res) => {
			logger('info', 'Response received from getNotesByMeetingID', res);
			return res.data.data.map((highlight: IHighlight) => ({
				...highlight,
				content: replaceApostropheHTMLEntityWithSingleQuote(highlight.content),
			}));
		})
		.catch((err) => logger('error', 'Error getting notes by meeting ID', err));
};

export const destroyMeetingHighlights = (
	meetingID: string | undefined,
	meetingHighlightIDs: string[],
	organizationID = ''
) => {
	logger('info', 'Request sent to destroyMeetingHighlights', {
		meetingID,
		meetingHighlightIDs,
		organizationID,
	});
	return axiosInstance.delete(`v1/meeting/${meetingID}/highlights`, {
		data: { meetingHighlightIDs: meetingHighlightIDs },
		headers: { 'x-organization-id': organizationID },
	});
};

export const clearAllHighlights = (
	meetingID: string | undefined,
	type: string,
	organizationID = ''
) => {
	logger('info', 'Request sent to clearAllHighlights', {
		meetingID,
		type,
		organizationID,
	});
	return axiosInstance.delete(`v1/meeting/${meetingID}/highlights/clear`, {
		data: { type },
		headers: { 'x-organization-id': organizationID },
	});
};

export const createMeetingHighlight = (
	data: IHighlight,
	meetingID: string,
	organizationID: string
) => {
	logger('info', 'Request sent to createMeetingHighlight', {
		data,
		meetingID,
		organizationID,
	});
	return axiosInstance
		.put(`v1/meeting/${meetingID}/highlights`, data, {
			headers: { 'x-organization-id': organizationID },
		})
		.then((res) => {
			logger('info', 'Response received from createMeetingHighlight', res);
			return {
				...res.data.data,
				content: replaceApostropheHTMLEntityWithSingleQuote(
					res.data.data.content
				),
			};
		})
		.catch((err) => {
			logger('error', 'Error creating meeting highlight', err);
			return err;
		});
};

export const updateMeetingHighlight = ({
	data,
	meetingID,
	meetingHighlightID,
	organizationID,
}: {
	data: IHighlight;
	meetingID: string | undefined;
	meetingHighlightID: string | undefined;
	organizationID: string | undefined;
}) => {
	logger('info', 'Request sent to updateMeetingHighlight', {
		data,
		meetingID,
		meetingHighlightID,
		organizationID,
	});
	return axiosInstance
		.patch(`v1/meeting/${meetingID}/highlight/${meetingHighlightID}`, data, {
			headers: { 'x-organization-id': organizationID },
		})
		.then((res) => {
			logger('info', 'Response received from updateMeetingHighlight', res);
			return {
				...res.data.data,
				content: replaceApostropheHTMLEntityWithSingleQuote(
					res.data.data.content
				),
			};
		})
		.catch((err) => logger('error', 'Error updating meeting highlight', err));
};

/** Teams **/

/**
 *
 * This allows you to get all the teams for the current user.
 * Teams on exist on the user inside the organization. They will not transfer across the organizations.
 *
 * @param {string} organizationID - This should be the current organization for the user that is requesting the teams.
 *
 */

export const getTeams = (organizationID: string) => {
	logger('info', 'Request sent to getTeams', { organizationID });
	return axiosInstance.get('v1/teams', {
		headers: { 'x-organization-id': organizationID },
		params: {
			limit: 1000,
		},
	});
};

/**
 *
 * This allows you to create a team for the current user.
 *
 * @param {string}   name - The name for this specific Team.
 * @param {string[]} emails - The list of emails to the users who will make up the team.
 * @param {string}   organizationID - This should be the current organization for the user that is requesting the teams.
 *
 */

export const createTeam = (
	name: string,
	emails: string[],
	organizationID: string | undefined
) => {
	logger('info', 'Request sent to createTeam', {
		name,
		emails,
		organizationID,
	});
	return axiosInstance.put(
		'v1/teams',
		{ name, emails },
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const updateTeam = (data: {
	teamName: string;
	emails: string[];
	teamID: string;
	organizationID: string;
}) => {
	logger('info', 'Request sent to updateTeam', { data });
	const { teamName, teamID, organizationID, emails } = data;
	return axiosInstance.patch(
		`v1/team/${teamID}`,
		{ name: teamName, emails },
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const destroyTeams = (data: {
	teamIDs: string[];
	organizationID: string;
}) => {
	logger('info', 'Request sent to destroyTeams', { data });
	return axiosInstance.delete(`v1/teams`, {
		data: { teamIDs: data.teamIDs },
		headers: { 'x-organization-id': data.organizationID },
	});
};

export const getTranscriptionForMeeting = (
	meetingID: string | undefined,
	organizationID: string | undefined,
	sharingToken?: string
) => {
	logger('info', 'Request sent to getTranscriptionForMeeting', {
		meetingID,
		organizationID,
	});
	return axiosInstance.get(`v1/meeting/${meetingID}/transcription`, {
		headers: {
			'x-organization-id': organizationID,
			'x-sharing-token': sharingToken,
		},
	});
};

interface VideoEventPayload {
	name: string;
	sourceID?: string | undefined;
	targetID?: string | undefined;
	data: {
		type: string;
		startTime?: number;
		endTime?: number;
		timestamp?: string;
	};
}

export const sendAnalyticEvent = (
	data: VideoEventPayload,
	organizationID: string | undefined,
	sharingToken?: string
) => {
	logger('info', 'Request sent to sendAnalyticEvent', { data, organizationID });
	return axiosInstance.put(`v1/analytics`, data, {
		headers: {
			'x-organization-id': organizationID,
			'x-sharing-token': sharingToken,
		},
	});
};

export const getActions = (query) => {
	const { organizationID, actionableStatus } = query;
	logger('info', 'Request sent to getActions', {
		organizationID,
		actionableStatus,
	});
	return axiosInstance.post(`v1/meeting-actions-status`, query, {
		params: {
			...query,
		},
		headers: { 'x-organization-id': organizationID },
	});
};

export const markActionItems = (
	actionIDs: string[],
	actionableStatus: string,
	meetingID: string,
	organizationID: string
) => {
	logger('info', 'Request sent to markActionItems', {
		actionIDs,
		actionableStatus,
		meetingID,
		organizationID,
	});
	return axiosInstance.post(
		`v1/meeting/${meetingID}/meeting-actions/mark`,
		{ meetingActionIDs: actionIDs, actionableStatus },
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const sendActionReminderEmail = (
	actionIDs: string[],
	meetingID: string,
	organizationID: string
) => {
	logger('info', 'Request sent to sendActionReminderEmail', {
		actionIDs,
		meetingID,
		organizationID,
	});
	return axiosInstance.post(
		`v1/meeting/${meetingID}/meeting-actions/remind`,
		{ meetingActionIDs: actionIDs },
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const findAllTranscriptsForOrganization = (
	search: string,
	organizationID: string
) => {
	logger('info', 'Request sent to findAllTranscriptsForOrganization', {
		search,
		organizationID,
	});
	return axiosInstance.post(
		`v1/organization/${organizationID}/find`,
		{
			search,
		},
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

/**
 *
 * This allows you to retrieve the chapters for a Meeting by its ID
 *
 * @param {string} meetingID - The ID of the Meeting that you would like to retrieve Chapters for.
 * @param {string} organizationID - This should be the ID of the current organization for the user.
 *
 */
export const getChaptersByMeetingID = (
	meetingID: string,
	organizationID: string,
	sharingToken?: string
) => {
	logger('info', 'Request sent to getChaptersByMeetingID', {
		meetingID,
		organizationID,
	});
	return axiosInstance.get(`v1/meeting/${meetingID}/chapters`, {
		headers: {
			'x-organization-id': organizationID,
			'x-sharing-token': sharingToken,
		},
	});
};

export const createMeetingChapter = (
	data: {
		headline: string;
		gist: string;
		content: string;
		summary: string;
		start: number;
		end: number;
	},
	meetingID: string,
	organizationID: string
) => {
	logger('info', 'Request sent to createMeetingChapter', {
		data,
		meetingID,
	});
	return axiosInstance.post(`v1/meeting/${meetingID}/chapters`, data, {
		headers: { 'x-organization-id': organizationID },
	});
};

export const updateMeetingChapters = async (
	payload,
	{ meetingID, organizationID }
) => {
	const { status, data } = await axiosInstance.patch(
		`v1/meeting/${meetingID}/chapters`,
		payload,
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
	return data;
};

export const updateMeetingChapter = (
	data: {
		headline: string;
		gist: string;
		content: string;
		start: number;
		end: number;
		summary: string;
	},
	meetingID: string,
	chapterID: string,
	organizationID: string
) => {
	logger('info', 'Request sent to updateMeetingChapter', {
		data,
		meetingID,
	});
	return axiosInstance.patch(
		`v1/meeting/${meetingID}/chapter/${chapterID}`,
		data,
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const destroyMeetingChapter = (
	meetingID: string,
	chapterID: string,
	organizationID: string
) => {
	logger('info', 'Request sent to destroyMeetingChapter', {
		chapterID,
		meetingID,
	});
	return axiosInstance.delete(`v1/meeting/${meetingID}/chapter/${chapterID}`, {
		headers: { 'x-organization-id': organizationID },
	});
};

export const createBot = ({
	meetingUrl,
	organizationID,
	joinAt = null,
	meetingTitle = '(No title)',
}: {
	meetingUrl: string;
	organizationID: string;
	joinAt?: string | null;
	meetingTitle?: string;
}) => {
	logger('info', 'Request sent to createBot', {
		meetingUrl,
		organizationID,
		joinAt,
		meetingTitle,
	});
	return axiosInstance.post(
		'v1/recall-ai/bot/create',
		{
			meetingUrl,
			botName: 'Reelay Notetaker',
			joinAt,
			isMeetingExist: false,
			meetingTitle,
		},
		{
			params: { organizationID },
		}
	);
};

export const updateBot = ({
	botID,
	data,
	organizationID,
}: {
	botID: string;
	data: {
		meetingLink?: string;
		joinAt: string;
	};
	organizationID: string;
}) => {
	logger('info', 'Request sent to updateBot', {
		botID,
		data,
		organizationID,
	});
	return axiosInstance.patch(`v1/recall-ai/bot/${botID}`, data, {
		params: { organizationID },
	});
};

export const removeBotFromCall = ({ botID }: { botID: string }) => {
	logger('info', 'Request sent to removeBotFromCall', {
		botID,
	});
	return axiosInstance.patch(`v1/recall-ai/bot/remove-from-call`, {
		botID,
	});
};

// old payload
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// return window.API.createBot({
//   data: {
//     meetingUrl: meetingURL,
//     recordingMode: 'gallery_view_v2',
//     joinAt: meetingTime,
//     transcription_options: {
//       provider: 'assembly_ai',
//     },
//     intelligence: {
//       assembly_ai: {
//         auto_chapters: true,
//         speaker_labels: true,
//       },
//     },
//   },
// });

/** Getting Presented, External, and Invited Users **/

export const getMeetingInvitedUsers = (
	meetingID: string | undefined,
	organizationID: string | undefined,
	status?: string,
	sharingToken?: string
) => {
	logger('info', 'Request sent to getMeetingInvitedUsers', {
		meetingID,
		organizationID,
	});
	return axiosInstance.get(`v1/meeting/${meetingID}/invited-users`, {
		headers: {
			'x-organization-id': organizationID,
			'x-sharing-token': sharingToken,
		},
		params: { status },
	});
};

export const getMeetingExternalUsers = (
	meetingID: string | undefined,
	organizationID: string | undefined,
	sharingToken?: string
) => {
	logger('info', 'Request sent to getMeetingExternalUsers', {
		meetingID,
		organizationID,
	});
	return axiosInstance.get(`v1/meeting/${meetingID}/external-users`, {
		headers: {
			'x-organization-id': organizationID,
			'x-sharing-token': sharingToken,
		},
	});
};

export const getMeetingPresentedUsers = (
	meetingID: string | undefined,
	organizationID: string | undefined,
	sharingToken?: string
) => {
	logger('info', 'Request sent to getMeetingPresentedUsers', {
		meetingID,
		organizationID,
	});
	return axiosInstance.get(`v1/meeting/${meetingID}/presented-users`, {
		headers: {
			'x-organization-id': organizationID,
			'x-sharing-token': sharingToken,
		},
	});
};

export const signUp = (payload: {
	registerData: RegisterData;
	organizationData: OrganizationData;
	infoCollectionData: InfoCollectionData;
	inviteTeamData: InviteTeamData;
	preferencesData: PreferencesData;
	timezone: string;
}) => {
	logger('info', 'Request sent to signup', payload);
	return axiosInstance.post(`v1/auth/sign-up`, payload);
};

export const signUpUser = (payload: {
	registerData: RegisterData;
	organizationID: string;
	preferencesData: PreferencesData;
	timezone: string;
}) => {
	logger('info', 'Request sent to signup', payload);
	return axiosInstance.post(`v1/auth/sign-up-user`, payload);
};

export const createMeetingShareableLink = (
	meetingID: string | undefined,
	organizationID: string | undefined,
	title = ''
) => {
	logger('info', 'Request sent to generateShareableLink', {
		meetingID,
		organizationID,
	});
	return axiosInstance.put(
		`v1/shareable-links/${meetingID}`,
		{
			organizationID,
			expirationDate: DateTime.now().toISOTime(),
			title,
		},
		{
			params: { organizationID },
			headers: {
				'x-organization-id': organizationID,
			},
		}
	);
};

export const createMeetingUserLink = (slug: string | undefined) => {
	logger('info', 'Request sent to createMeetingUserLink', {
		slug,
	});
	return axiosInstance.put(`v1/meeting-userlinks`, {
		slug,
	});
};

export const createMeetingUserLinkWithoutShareableLink = (
	friendlyID: string
) => {
	logger('info', 'Request sent to createMeetingUserLinkWithoutShareableLink', {
		friendlyID,
	});
	return axiosInstance.put(`v1/meeting-userlinks-without-shareable-link`, {
		friendlyID,
	});
};

export const getMeetingUserLink = ({ meetingID = '', friendlyID = '' }) => {
	logger('info', 'Request sent to getMeetingUserLink', {
		meetingID,
		friendlyID,
	});
	return axiosInstance.get(`v1/meeting-userlinks`, {
		params: {
			meetingID,
			friendlyID,
		},
	});
};

export const updateMeetingUserLink = (
	linkID: string | undefined,
	status: string,
	organizationID: string | undefined
) => {
	logger('info', 'Request sent to updateMeetingUserLink', {
		linkID,
		status,
		organizationID,
	});
	return axiosInstance.patch(
		`v1/meeting-userlinks/${linkID}`,
		{ status },
		{
			params: { organizationID },
			headers: {
				'x-organization-id': organizationID,
			},
		}
	);
};

export const getMeetingUserLinks = (meetingID: string) => {
	logger('info', 'Request sent to getMeetingUserLinks', {
		meetingID,
	});
	return axiosInstance.get(`v1/meeting-userlinks`, {
		params: {
			meetingID,
		},
	});
};

export const getDetailsByShareableLinkSlugID = (slug: string | undefined) => {
	logger('info', 'Request sent to getDetailsByShareableLinkSlugID', {
		slug,
	});
	return axiosInstance.get(`v1/shareable-links/slug/${slug}`);
};

export const updateMeetingShareableLink = (
	payload,
	linkID: string | undefined,
	meetingID: string | undefined,
	organizationID: string | undefined
) => {
	logger('info', 'Request sent to updateMeetingShareableLink', {
		linkID,
		meetingID,
		organizationID,
	});
	return axiosInstance.patch(
		`v1/shareable-links/${meetingID}/${linkID}`,
		payload,
		{
			params: { organizationID },
			headers: {
				'x-organization-id': organizationID,
			},
		}
	);
};

export const deleteMeetingShareableLink = (
	linkID: string | undefined,
	meetingID: string | undefined,
	organizationID: string | undefined
) => {
	logger('info', 'Request sent to deleteMeetingShareableLink', {
		linkID,
		meetingID,
		organizationID,
	});
	return axiosInstance.delete(`v1/shareable-links/${meetingID}/${linkID}`, {
		params: { organizationID },
		headers: {
			'x-organization-id': organizationID,
		},
	});
};

export const listMeetingShareableLinks = (
	meetingID: string | undefined,
	organizationID: string | undefined
) => {
	logger('info', 'Request sent to listMeetingShareableLinks', {
		meetingID,
		organizationID,
	});
	return axiosInstance.get(`v1/shareable-links/${meetingID}`, {
		params: { organizationID },
		headers: {
			'x-organization-id': organizationID,
		},
	});
};

export const getUserCalendars = (organizationID: string | undefined) => {
	logger('info', 'Request sent to getUserCalendars', {
		organizationID,
	});
	return axiosInstance.get(`v1/calendars/`, {
		headers: { 'x-organization-id': organizationID },
	});
};

export const buildGoogleCalendarOAuthUrl = (
	organizationID: string | undefined,
	redirectToMeetings = false,
	autoRecord = null
) => {
	logger('info', 'Request sent to buildGoogleCalendarOAuthUrl', {
		organizationID,
	});
	return axiosInstance.post(
		`v1/calendars/connect-google`,
		{ redirectToMeetings, autoRecord },
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const buildMicrosoftOutlookOAuthUrl = (
	organizationID: string | undefined,
	redirectToMeetings = false,
	autoRecord = null
) => {
	logger('info', 'Request sent to buildMicrosoftOutlookOAuthUrl', {
		organizationID,
	});
	return axiosInstance.post(
		`v1/calendars/connect-microsoft`,
		{ redirectToMeetings, autoRecord },
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const removeCalendar = (calendarID: string, organizationID: string) => {
	logger('info', 'Request sent to removeCalendar', {
		calendarID,
		organizationID,
	});
	return axiosInstance.delete(`v1/calendar/${calendarID}`, {
		headers: { 'x-organization-id': organizationID },
	});
};

export const updateCalendar = (
	calendarID: string,
	payload,
	organizationID: string
) => {
	logger('info', 'Request sent to updateCalendar', {
		calendarID,
		organizationID,
	});
	return axiosInstance.patch(`v1/calendar/${calendarID}`, payload, {
		headers: { 'x-organization-id': organizationID },
	});
};

export const getUserSettings = (userID: string) => {
	return axiosInstance
		.get(`v1/user/${userID}/settings`)
		.then((res) => {
			logger('info', 'Response received from getUserSettings', res);
			return res.data.data;
		})
		.catch((err) => {
			logger('error', 'Error getting user settings', err);
			return err;
		});
};

/**
 * for creating or updating user settings
 * @param userID
 */
export const saveUserSettings = (userID: string, payload) => {
	return axiosInstance
		.post(`v1/user/${userID}/settings`, payload)
		.then((res) => {
			logger('info', 'Response received from getUserSettings', res);
			return destructureSettingsResponse(res.data.data);
		})
		.catch((err) => {
			logger('error', 'Error getting user settings', err);

			throw new Error(err?.response?.data);
		});
};

export const getUserStats = (userID: string) => {
	logger('info', 'Request sent to get user stats from /pre-check', { userID });
	return axiosInstance
		.get(`v1/user/${userID}/pre-check`)
		.then(({ data: { data } }) => data);
};

export const getMicrosoftOAuthURL = () => {
	logger('info', 'Request sent to getMicrosoftOAuthURL', {});
	return axiosInstance.get('v1/auth/microsoft-oauth-url');
};

export const authenticateWithMicrosoft = (code: string) => {
	logger('info', 'Request sent to authenticateWithMicrosoft', { code });
	return axiosInstance.post('v1/auth/authenticate-microsoft', { code });
};

export const getUpcomingMeetings = (
	startDate: string,
	endDate: string,
	organizationID: string
) => {
	logger('info', 'Request sent to getUpcomingMeetings', {
		startDate,
		endDate,
		organizationID,
	});
	return axiosInstance.post(
		`v1/upcoming-meetings`,
		{ startDate, endDate },
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const sendMinutesEmail = ({
	emails,
	meetingID,
	organizationID,
	attachmentID,
}: {
	emails: string[];
	meetingID: string | undefined;
	organizationID: string | undefined;
	attachmentID: string;
}) => {
	logger('info', 'Request sent to sendMinutesEmail', {
		emails,
		meetingID,
		attachmentID,
		organizationID,
	});
	return axiosInstance.post(
		`v1/meeting/${meetingID}/send-minutes-email`,
		{ emails, attachmentID },
		{
			headers: { 'x-organization-id': organizationID },
		}
	);
};

export const saveSpeakerLabels = (meetingID, speakerLabelChanges) => {
	logger('info', 'Request sent to rename-transcript-speakers', {
		meetingID,
		speakerLabelChanges,
	});
	return axiosInstance.post(
		`/v1/meeting/${meetingID}/rename-transcript-speakers`,
		{ speakerEdits: speakerLabelChanges }
	);
};

export const convertMeetingToFullAsset = (meetingID: string) => {
	logger(
		'info',
		'Request sent to convertMeetingToFullAsset meetingID',
		meetingID
	);
	return axiosInstance.post(
		`/v1/meeting/${meetingID}/convert-to-full-asset`
		// { speakerEdits: speakerLabelChanges }
	);
};
