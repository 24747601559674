import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { IAction } from '../interfaces/action';
import { IHighlight } from '../interfaces/highlight';
import { IMeeting, defaultMeeting } from '../interfaces/meeting';
import { ShareableLink } from '../helpers/links';
import { InvitedUser } from '../interfaces/user';
import { Chapter } from '../helpers/meetings';
import { Chat } from '../interfaces/chat';
import { IAttachment } from './userAtoms';
import { Utterance } from '../helpers/data';

const { persistAtom } = recoilPersist();

// @TODO: Create a new File in ./Atoms called Auth.ts for us to store all auth related Atoms including the ones below and some additional ones like the JWT Tokens.

export const currentMeeting = atom<IMeeting>({
	key: 'currentMeeting',
	default: defaultMeeting,
});

export const myMeetings = atom<IMeeting[]>({
	key: 'myMeetings',
	default: [],
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const meetingsSharedWithMe = atom<IMeeting[]>({
	key: 'meetingsSharedWithMe',
	default: [],
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const Highlight = atom({
	key: 'highlight',
	default: [
		{
			content: '',
			createdAt: '',
			friendlyID: '',
			id: '',
			meetingID: '',
			timeEndMS: '',
			timeStartMS: '',
			updatedAt: '',
		},
	],
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const currentMeetingHighlights = atom<(IHighlight | IAction)[]>({
	key: 'meetingHighlights',
	default: [],
	// eslint-disable-next-line camelcase
});

export const Action = atom({
	key: 'action',
	default: [
		{
			id: '',
			createdAt: '',
			description: '',
			friendlyID: '',
			meetingID: '',
			owningUserID: '',
			status: '',
			updatedAt: '',
		},
	],
	// eslint-disable-next-line camelcase
	effects_UNSTABLE: [persistAtom],
});

export const currentMeetingActions = atom<IAction[]>({
	key: 'meetingActions',
	default: [],
});

export const currentMeetingDistributionList = atom<InvitedUser[]>({
	key: 'currentMeetingDistributionList',
	default: [],
});

export const videoPlayerCurrentTime = atom({
	key: 'videoPlayerCurrentTime',
	default: 0,
});

export const videoPlayerElapsedTime = atom({
	key: 'videoPlayerElapsedTime',
	default: '',
});

// export const meetingTableStatusFilter = atom({
//   key:     'meetingTableStatusFilter',
//   default: ['created', 'pending', 'draft', 'review', 'published'],
// });

export const timestamp = atom({
	key: 'timestamp',
	default: { time: 0 },
});

/**
 * This is an alternating boolean used to fire the useEffect is the same
 * play button is clicked in a row. useEffect does not fire because
 * timestamp value does not change. So this was made to allow users
 * to cick the same play button as many times in a row and allow the video
 * player time to change to that timestamp.
 */
export const playButtonClicked = atom({
	key: 'playButtonClicked',
	default: false,
});

export const meetingVideoUploadProgressBars = atom({
	key: 'meetingVideoUploadProgressBars',
	default: {} as any,
});

export const uploadingVideoAtom = atom({
	key: 'uploadingVideoAtom',
	default: false,
});

export const mobileVideoPlayerCurrentTime = atom({
	key: 'mobileVideoPlayerCurrentTime',
	default: 0,
});

export const mobileTimestamp = atom({
	key: 'mobileTimestamp',
	default: { time: 0 },
});

export const totalDurationAtom = atom<string>({
	key: 'totalDurationAtom',
	default: '',
});

export const elapsedTimeAtom = atom<string>({
	key: 'elapsedTimeAtom',
	default: '',
});

export const componentLoadedAtom = atom<boolean>({
	key: 'componentLoadedAtom',
	default: false,
});

export const timeDisplayFormatAtom = atom<string>({
	key: 'timeDisplayFormatAtom',
	default: 'normal',
});

export const watchedEventSentAtom = atom<boolean>({
	key: 'watchedEventSentAtom',
	default: false,
});

export const showThumbnailAtom = atom<boolean>({
	key: 'showThumbnailAtom',
	default: false,
});

export const videoPlayerStateAtom = atom({
	key: 'videoPlayerStateAtom',
	default: {
		playing: false,
		muted: true,
		volume: 0.5,
		playbackRate: 1.0,
		played: 0,
		seeking: false,
	},
});

export const meetingsView = atom({
	key: 'meetingsView',
	default: 'card',
});

export const activeHighlightOrChapterIndex = atom({
	key: 'activeHighlightOrChapterIndex',
	default: null,
});

export const transcriptSearchQuery = atom({
	key: 'transcriptSearchQuery',
	default: '',
});

export const editScheduledMeetingModalOpen = atom({
	key: 'editScheduledMeetingModalOpen',
	default: false,
});

export const editScheduledMeetingInitialValues = atom({
	key: 'editScheduledMeetingInitialValues',
	default: {
		name: '',
		meetingLink: '',
		joinAt: '',
		meetingID: '',
		botID: '',
	},
});

export const showLinkRow = atom({
	key: 'showLinkRow',
	default: false,
});

export const sharePopoverOpen = atom({
	key: 'sharePopoverOpen',
	default: false,
});

export const meetingShareableLinks = atom<ShareableLink[]>({
	key: 'meetingShareableLinks',
	default: [],
});

export const meetingSegmentedControlValue = atom<string>({
	key: 'meetingSegmentedControlValue',
	default: 'published',
});

export const currentMeetingChapters = atom<Chapter[]>({
	key: 'currentMeetingChapters',
	default: [],
});

export const previousPageURL = atom({
	key: 'previousPageURL',
	default: '',
	effects_UNSTABLE: [persistAtom],
});

export const transcriptID = atom({
	key: 'transcriptID',
	default: '',
});

export const attendeesList = atom<InvitedUser[]>({
	key: 'attendeesList',
	default: [],
});

export const showAllAttendeesAtom = atom<boolean>({
	key: 'showAllAttendeesAtom',
	default: false,
});

export const showExpandedTranscript = atom<boolean>({
	key: 'showExpandedTranscript',
	default: false,
});

export const showExpandedChat = atom<boolean>({
	key: 'showExpandedChat',
	default: false,
});

export const attendeesCount = selector({
	key: 'attendeesCount',
	get: ({ get }) => {
		return get(attendeesList).length;
	},
});

export const timelineBarScrollType = atom({
	key: 'timelineBarScrollType',
	default: '',
});

export const chatAtom = atom<Chat[]>({
	key: 'chatAtom',
	default: [],
});

export const assignActionModalOpenState = atom({
	key: 'assignActionModalOpenState',
	default: false,
});

export const assigningActionAtom = atom<IAction | IHighlight | null>({
	key: 'assigningActionAtom',
	default: null,
});

export const meetingAttachments = atom<IAttachment[]>({
	key: 'meetingAttachments',
	default: [],
});

export const momentDialogOpened = atom<boolean>({
	key: 'momentDialogOpened',
	default: false,
});

export const currentUtterances = atom<Utterance[]>({
	key: 'currentUtterances',
	default: [],
});

export const viewingMeetingAtom = atom<boolean>({
	key: 'viewingMeetingAtom',
	default: false,
});

export const selectedSpeakersAtom = atom<string[]>({
	key: 'selectedSpeakersAtom',
	default: [],
});

export const reloadMeetingsAtom = atom<boolean>({
	key: 'reloadMeetingsAtom',
	default: false,
});
