import { Modal } from '@mantine/core';
import ModalTitle from '../../Titles/ModalTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessages } from '@fortawesome/pro-light-svg-icons';
import { IMeeting } from '../../../interfaces/meeting';
import { useRecoilValue } from 'recoil';
import { notifications } from '../../../Atoms/notifications';
import { NotificationList } from '../../NotificationList';
import { currentMeeting } from '../../../Atoms/meetingAtom';

interface Props {
	opened: boolean;
	open: () => void;
	close: () => void;
}

export default function RequestsModal({ open, close, opened }: Props) {
	const meeting = useRecoilValue(currentMeeting);
	const userNotifications = useRecoilValue(notifications);
	const requestUsers = userNotifications.filter(
		(notification) => notification.meetingID === meeting.id
	);

	return (
		<Modal
			opened={opened}
			onClose={close}
			title={
				<ModalTitle
					text='Requests'
					icon={<FontAwesomeIcon icon={faMessages} />}
				/>
			}
			size={'lg'}
			zIndex={10000}
			radius={'lg'}
			withinPortal={false}
			styles={(theme) => ({
				header: {
					borderRadius: theme.spacing.lg,
				},
			})}
		>
			<NotificationList notifications={requestUsers} />
		</Modal>
	);
}
