import {
	faCompress,
	faFolderArrowDown,
} from '@fortawesome/pro-regular-svg-icons';
import { Transition } from 'react-transition-group';
import {
	Dispatch,
	SetStateAction,
	createRef,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentMeeting,
	currentUtterances,
	meetingAttachments,
	selectedSpeakersAtom,
	showExpandedTranscript,
	timestamp,
	videoPlayerCurrentTime,
} from '../../../../../Atoms/meetingAtom';
import {
	Divider,
	Group,
	ScrollArea,
	Stack,
	Text,
	createStyles,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GeneralSearchBar from '../../GeneralSearchBar';
import { useDebouncedValue } from '@mantine/hooks';
import TranscriptRow from '../../MeetingDetailsTabSection/tabcontents/components/TranscriptRow';
import TranscriptPlayer from './TranscriptPlayer';
import { applyTranscriptSearchFilter } from '../../../../../pages/meeting/helper';
import { MILLISECONDS_PER_SECOND } from '../../../../../components/constants';
import { downloadAttachment } from '../../../../../helpers/attachments';
import { showFailureNotification } from '../../../../../helpers/notifications';
import { useParams } from 'react-router';
import {
	getInitialsFromSpeakerName,
	getSpeakersFromTranscriptWithAssignedColors,
} from '../../../../../helpers/transcript';
import { Utterance } from '../../../../../helpers/data';
import SpeakerInitialsAvatar from '../../../../../components/aside/TabPanels/Transcript/SpeakerInitialsAvatar';

const duration = 400;

const defaultStyle = {
	transition: `height ${duration}ms ease-out`,
	height: '0%',
};

const transitionStyles = {
	entering: { height: '0%' },
	entered: { height: '100%' },
	exiting: { height: '100%' },
	exited: { height: '0%' },
};

const useStyles = createStyles((theme) => ({
	actionButton: {
		cursor: 'pointer',
	},
	speakerText: {
		color: 'black',
		fontWeight: 400,
		fontSize: 14,
		lineHeight: '20px',
	},
}));

interface Props {
	searchValue: string;
	setSearchValue: Dispatch<SetStateAction<string>>;
	inviteUser?: ({
		email,
		firstName,
		lastName,
	}: {
		email: string;
		firstName: string;
		lastName: string;
	}) => void;
	playerRef: any;
}
export default function ExpandedTranscriptView({
	searchValue,
	setSearchValue,
	inviteUser,
	playerRef,
}: Props) {
	const { classes, theme } = useStyles();
	const nodeRef = useRef(null);
	const viewport = useRef<HTMLDivElement>(null);
	const { tabValue } = useParams();
	const [expanded, setExpanded] = useRecoilState(showExpandedTranscript);
	const utterances = useRecoilValue(currentUtterances);
	const meeting = useRecoilValue(currentMeeting);
	const [transcript, setTranscript] = useState(utterances);
	const [selectedSpeakers, setSelectedSpeakers] =
		useRecoilState(selectedSpeakersAtom);
	const setWebTimestamp = useSetRecoilState(timestamp);
	const currentTime = useRecoilValue(videoPlayerCurrentTime);
	const [debounced] = useDebouncedValue(searchValue, 300);
	const [initialLoad, setInitialLoad] = useState(true);
	const [activeChapterIndex, setActiveChapterIndex] = useState<number>(0);
	const attachments = useRecoilValue(meetingAttachments);
	const meetingTranscript = attachments.find(
		(attachment) => attachment.type === 'transcript'
	);

	const speakers = getSpeakersFromTranscriptWithAssignedColors(utterances);

	useEffect(() => {
		if (initialLoad) setInitialLoad(false);

		if (!initialLoad) {
			setTranscript(applyTranscriptSearchFilter(utterances, debounced));
		}
	}, [debounced]);

	useEffect(() => {
		return () => {
			setExpanded(false);
		};
	}, []);

	useEffect(() => {
		setTranscript(
			selectedSpeakers.length === 0
				? utterances
				: utterances.filter((utterance: Utterance) =>
						selectedSpeakers.includes(utterance.speaker)
				  )
		);
		setSearchValue('');
	}, [utterances, selectedSpeakers]);

	const handleTranscriptClick = (index: number, timeStartMS: number) => {
		setActiveChapterIndex(index);
		setWebTimestamp({
			time: Math.floor(timeStartMS / MILLISECONDS_PER_SECOND),
		});
	};

	const momentRefs = useRef([]);
	momentRefs.current = utterances.map(
		(_, i) => momentRefs.current[i] ?? createRef()
	);

	// Effect to calculate active chapter index and scroll to it
	useEffect(() => {
		let newActiveIndex = activeChapterIndex;
		// Find the index of the moment for the current time
		for (let i = 0; i < transcript.length; i++) {
			const { start } = transcript[i];
			if (currentTime < Math.floor(start / MILLISECONDS_PER_SECOND)) {
				newActiveIndex = i === 0 ? i : i - 1;
				break;
			} else if (
				currentTime >= Math.floor(start / MILLISECONDS_PER_SECOND) &&
				i === transcript.length - 1
			) {
				newActiveIndex = i;
				break;
			}
		}
		if (newActiveIndex !== activeChapterIndex) {
			setActiveChapterIndex(newActiveIndex);
			// calculate the height of all the previous moments and subtract it from the scrollHeight
			let height = 0;

			for (let i = 0; i < newActiveIndex; i++) {
				height += momentRefs.current[i].current.clientHeight;
			}
			viewport.current.scrollTo({
				top: height,
				behavior: 'smooth',
			});
		}
	}, [activeChapterIndex]);

	useEffect(() => {
		let newActiveIndex = 0; // Start with an assumption that the first index is the default

		// Loop through items to find the highest index <= currentTime
		for (let i = 0; i < transcript.length; i++) {
			const { start } = transcript[i];
			const timeStartSeconds = Math.floor(start / MILLISECONDS_PER_SECOND);
			if (currentTime >= timeStartSeconds) {
				newActiveIndex = i;
			} else {
				break; // Exit the loop early if we find a chapter that starts after currentTime
			}
		}

		// Check if the newly found index is different from the current activeChapterIndex
		if (newActiveIndex !== activeChapterIndex) {
			setActiveChapterIndex(newActiveIndex);

			// Optionally scroll the active chapter card into view
			if (
				momentRefs.current[newActiveIndex] &&
				momentRefs.current[newActiveIndex].current
			) {
				// calculate the height of all the previous moments and subtract it from the scrollHeight
				let height = 0;

				for (let i = 0; i < newActiveIndex; i++) {
					height += momentRefs.current[i].current.clientHeight;
				}
				viewport.current.scrollTo({
					top: height,
					behavior: 'smooth',
				});
			}
		}
	}, [tabValue, currentTime, transcript]); // Dependency array now only includes tab, isolating this effect from other changes

	return (
		<Transition nodeRef={nodeRef} in={expanded} timeout={duration}>
			{(state) => (
				<Stack
					bg={'background-gray-2'}
					p={'md'}
					style={{
						...defaultStyle,
						...transitionStyles[state],
						position: 'absolute',
						bottom: expanded ? 0 : '-15px',
						left: 0,
						right: 0,
						zIndex: 100,
					}}
					sx={(theme) => ({
						borderTopLeftRadius: theme.spacing.lg,
						borderTopRightRadius: theme.spacing.lg,
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: 0,
					})}
				>
					<Group position='apart' noWrap>
						<Text color='primary-text' size={'xl'} fw={700}>
							Transcript
						</Text>
						<Group
							spacing={'xs'}
							noWrap
							onClick={(e) => {
								e.stopPropagation();
								setExpanded(false);
							}}
							className={classes.actionButton}
						>
							<Text size={12} color='primary-cta'>
								Collapse
							</Text>
							<FontAwesomeIcon
								icon={faCompress}
								size={'xl'}
								color={theme.colors['primary-cta'][0]}
								onClick={(e) => {
									e.stopPropagation();
									setExpanded(false);
								}}
							/>
						</Group>
					</Group>
					<TranscriptPlayer playerRef={playerRef} />
					<Divider color='#8C919B' />
					<Group noWrap position='apart'>
						<Group
							noWrap
							spacing={'xs'}
							align='center'
							className={classes.actionButton}
							onClick={(e) => {
								e.stopPropagation();
								meetingTranscript
									? downloadAttachment(
											meetingTranscript,
											meeting.organizationID
									  )
									: showFailureNotification({
											message: 'Sorry, no transcript available for download.',
									  });
							}}
						>
							<FontAwesomeIcon
								icon={faFolderArrowDown}
								size='lg'
								color={theme.colors['primary-cta'][0]}
							/>
							<Text size={14} color='primary-cta'>
								Download this Transcript
							</Text>
						</Group>
						<GeneralSearchBar
							value={searchValue}
							setValue={setSearchValue}
							width={'400px'}
							placeholder='Search Transcript'
							flex={2}
						/>
						<div></div>
					</Group>
					<Group spacing={'xs'} mih={50} h={'auto'} noWrap>
						<Text className={classes.speakerText}>Speakers:</Text>
						<Group spacing={5}>
							{speakers.map(
								(speaker: { name: string; color: string }, index) => (
									<SpeakerInitialsAvatar
										clickable={true}
										selectedSpeakers={selectedSpeakers}
										setSelectedSpeakers={setSelectedSpeakers}
										key={index}
										index={index}
										size={32}
										speakerName={speaker.name}
										speakerColor={speaker.color}
										initials={getInitialsFromSpeakerName(speaker.name)}
									/>
								)
							)}
						</Group>
					</Group>
					<ScrollArea
						viewportRef={viewport}
						lh={'25px'}
						type='always'
						offsetScrollbars
						scrollbarSize={10}
						styles={(theme) => ({
							root: {
								flex: 2,
								marginBottom: 16,
							},
							viewport: {
								overflowX: 'hidden',
								width: '100%',
								maxWidth: '100%',
								fontSize: 12,
								flex: 2,
							},
						})}
					>
						{transcript.map((utterance, index) => {
							return (
								<TranscriptRow
									key={utterance.start}
									item={utterance}
									index={index}
									handleTranscriptClick={handleTranscriptClick}
									scrollRef={momentRefs.current[index]}
									activeIndex={activeChapterIndex}
									searchValue={debounced}
								/>
							);
						})}
					</ScrollArea>
				</Stack>
			)}
		</Transition>
	);
}
