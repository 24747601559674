import React, { SetStateAction } from 'react';
import { Button, Group } from '@mantine/core';

interface Props {
  setStep: React.Dispatch<SetStateAction<number>>;
}

export default function CompletedStep({ setStep }: Props) {
  return (
    <Group position={'center'} mt={'md'} style={{ flex: 1 }}>
      <Button onClick={() => setStep(0)}>Go to beginning</Button>
    </Group>
  );
}
