import { Dispatch, SetStateAction, useState } from 'react';
import {
	createStyles,
	Table,
	ScrollArea,
	Text,
	Switch,
	Badge,
	Anchor,
	Stack,
	ActionIcon,
	Group,
} from '@mantine/core';
import {
	modifyLinkDataForTable,
	ShareableLink,
	sortLinksByStatus,
} from '../../helpers/links';
import { DisabledTextLink } from '../Popovers/ShareMeetingPopover/components/DisabledTextLink';
import {
	deleteMeetingShareableLink,
	updateMeetingShareableLink,
	updateMeetingUserLink,
} from '../../api/api';
import { compareByTimestamp } from '../../helpers/timestamp';
import { showNotification } from '@mantine/notifications';
import {
	defaultFailureNotificationProps,
	defaultSuccessNotificationProps,
} from '../constants';
import { logger } from '../../helpers/logger';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentMeeting,
	currentMeetingDistributionList,
	meetingShareableLinks,
} from '../../Atoms/meetingAtom';
import { currentUser, userOrganizationMembers } from '../../Atoms/userAtoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft } from '@fortawesome/pro-solid-svg-icons';
import { DeleteIcon } from '../Popovers/ShareMeetingPopover/components/DeleteIcon';
import { InvitedUser } from '../../interfaces/user';
import { DateTime } from 'luxon';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';

const useStyles = createStyles((theme) => ({
	header: {
		position: 'sticky',
		zIndex: 1000,
		top: 0,
		backgroundColor:
			theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
		transition: 'box-shadow 150ms ease',

		'&::after': {
			content: '""',
			position: 'absolute',
			left: 0,
			right: 0,
			bottom: 0,
			borderBottom: `1px solid ${
				theme.colorScheme === 'dark'
					? theme.colors.dark[3]
					: theme.colors.gray[2]
			}`,
		},
	},

	scrolled: {
		boxShadow: theme.shadows.sm,
	},
}));

interface TableScrollAreaProps {
	data: ShareableLink[];
	setShowAdvanced?: Dispatch<SetStateAction<boolean>>;
}

export function ShareableLinkTable({
	data,
	setShowAdvanced,
}: TableScrollAreaProps) {
	const users = useRecoilValue(userOrganizationMembers);
	const linksSortedByStatus = sortLinksByStatus(data);
	const formattedData = modifyLinkDataForTable(linksSortedByStatus, users);
	const { classes, cx } = useStyles();
	const [scrolled, setScrolled] = useState(false);
	const setLinkList = useSetRecoilState(meetingShareableLinks);
	const [viewingUserList, setViewingUserList] = useState(false);
	const [linkUserList, setLinkUserList] = useState<InvitedUser[]>([]);
	const [viewers, setViewers] = useRecoilState(currentMeetingDistributionList);
	const user = useRecoilValue(currentUser);

	const toggleUserLinkStatus = async (linkID: string, status: string) => {
		try {
			// update user meeting link status
			const response = await updateMeetingUserLink(
				linkID,
				status,
				user.currentOrganizationID
			);
			// update meeting user links
			const newViewers = [...viewers].map((viewer) => {
				return viewer.id === response?.data?.data?.userID
					? {
							...viewer,
							userlink: {
								...response?.data?.data,
								updatedAt: viewer.userlink.updatedAt,
								createdAt: viewer.userlink.createdAt,
							},
					  }
					: viewer;
			});
			setViewers(newViewers);
			setLinkUserList(
				newViewers.filter(
					(viewer) =>
						viewer.userlink.shareableLinkID ===
						response?.data?.data?.shareableLinkID
				)
			);
			logger(
				'info',
				'User meeting link status was successfully updated',
				response
			);
		} catch (error) {
			logger('error', 'Link status failed to update', error);
		}
	};

	const updateShareableLink = async (payload, index) => {
		const linkToUpdate = formattedData[index];
		const { id, meetingID, organizationID } = linksSortedByStatus[index];
		try {
			// update shareable link
			const updatedLink = await updateMeetingShareableLink(
				payload,
				id,
				meetingID,
				organizationID
			);
			// update links list in state
			setLinkList((list) =>
				sortLinksByStatus(
					list
						.map((link) =>
							link.id === updatedLink?.data?.data?.id
								? updatedLink?.data?.data
								: link
						)
						.slice()
						.sort((a, b) => compareByTimestamp(a, b, 'createdAt', 'asc'))
				)
			);
			logger('info', 'Link status was successfully updated', updatedLink);
		} catch (error) {
			logger('error', 'Link status failed to update', error);
		}
	};

	const deleteShareableLink = async (link: ShareableLink) => {
		const { id, meetingID, organizationID } = link;
		try {
			// delete shareable link
			const deletedLink = await deleteMeetingShareableLink(
				id,
				meetingID,
				organizationID
			);
			const deletedLinkList = deletedLink?.data?.data?.linkIDs;
			// update links list in state
			setLinkList((list) =>
				list.filter((link) => !deletedLinkList.includes(link.id))
			);
			// setOpened(false);
			showSuccessNotification({
				message: 'Link was successfully deleted. Great work!',
			});
		} catch (error) {
			const errorMessage =
				error?.response?.data === 'Active shareable link can not be removed'
					? 'Active shareable link can not be removed. Please turn off link and try again.'
					: error?.response?.data;
			showFailureNotification({
				message: errorMessage,
			});
		}
	};

	const viewUserList = (row) => {
		setViewingUserList(true);
		setLinkUserList(
			viewers.filter(
				(viewer) => viewer.userlink.shareableLinkID === row.link.id
			)
		);
	};

	const rows = formattedData.map((row, index) => {
		return (
			<tr key={row.url}>
				<td>
					<Badge
						variant={'dot'}
						color={row.status === 'active' ? 'green' : 'red'}
						size='md'
						radius='lg'
					>
						{row.status === 'active' ? 'Live' : 'Inactive'}
					</Badge>
				</td>
				<td>{row.createdAt}</td>
				<td>
					<Anchor
						component='button'
						type='button'
						onClick={() => viewUserList(row)}
					>
						{row.title}
					</Anchor>
				</td>
				<td>{row.owner}</td>
				<td>
					<DisabledTextLink url={row.url} />
				</td>
				<td>
					<Switch
						styles={{ trackLabel: { fontSize: 10 } }}
						size={'md'}
						onLabel={'ON'}
						offLabel={'OFF'}
						checked={row.status === 'active'}
						onChange={(event) => {
							// setChecked(event.currentTarget.checked);
							updateShareableLink(
								{ status: event.currentTarget.checked ? 'active' : 'inactive' },
								index
							);
						}}
					/>
				</td>
				<td>{row.expirationDate}</td>
				<td>
					<DeleteIcon
						deleteShareableLink={deleteShareableLink}
						link={row.link}
					/>
				</td>
			</tr>
		);
	});

	const userRows = linkUserList.map((user, index) => {
		return (
			<tr key={user.id}>
				<td>
					<Badge
						variant={'dot'}
						color={user.userlink.status === 'active' ? 'green' : 'red'}
						size='md'
						radius='lg'
					>
						{user.userlink.status === 'active' ? 'Live' : 'Inactive'}
					</Badge>
				</td>
				<td>{`${user.firstName} ${user.lastName}`}</td>
				<td>{user.email}</td>
				<td>{`${DateTime.fromISO(user.userlink.createdAt).toLocaleString(
					DateTime.DATE_MED
				)}`}</td>
				<td>
					<Switch
						styles={{ trackLabel: { fontSize: 10 } }}
						size={'md'}
						onLabel={'ON'}
						offLabel={'OFF'}
						checked={user.userlink.status === 'active'}
						onChange={(event) =>
							toggleUserLinkStatus(
								user.userlink.id,
								event.currentTarget.checked ? 'active' : 'denied'
							)
						}
					/>
				</td>
			</tr>
		);
	});

	return (
		<>
			{viewingUserList ? (
				<>
					{linkUserList.length ? (
						<Stack>
							<Group position={'left'} align={'center'}>
								<ActionIcon
									m={10}
									variant='transparent'
									onClick={() => setViewingUserList(false)}
								>
									<FontAwesomeIcon icon={faArrowCircleLeft} size={'xl'} />
								</ActionIcon>
								<Text>Users invited through this link:</Text>
							</Group>
							<ScrollArea
								h={800}
								onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
							>
								<Table miw={700} fontSize={'sm'}>
									<thead
										className={cx(classes.header, {
											[classes.scrolled]: scrolled,
										})}
									>
										<tr>
											<th />
											<th>Name</th>
											<th>Email</th>
											<th>Invited On</th>
											<th>Status</th>
										</tr>
									</thead>
									<tbody>{userRows}</tbody>
								</Table>
							</ScrollArea>
						</Stack>
					) : (
						<Group position={'left'} align={'center'}>
							<ActionIcon
								m={10}
								variant='transparent'
								onClick={() => setViewingUserList(false)}
							>
								<FontAwesomeIcon icon={faArrowCircleLeft} size={'xl'} />
							</ActionIcon>
							<Text>No users have been invited through this link.</Text>
						</Group>
					)}
				</>
			) : formattedData.length ? (
				<Stack spacing={0}>
					<Group position={'left'} align={'center'}>
						<ActionIcon
							m={10}
							variant='transparent'
							onClick={() => setShowAdvanced(false)}
						>
							<FontAwesomeIcon icon={faArrowCircleLeft} size={'xl'} />
						</ActionIcon>
					</Group>
					<ScrollArea
						h={800}
						onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
						offsetScrollbars
						scrollbarSize={10}
					>
						<Table miw={700} fontSize={'sm'}>
							<thead
								className={cx(classes.header, { [classes.scrolled]: scrolled })}
							>
								<tr>
									<th />
									<th>Created</th>
									<th>Title</th>
									<th>Shared By</th>
									<th>Link</th>
									<th>Status</th>
									<th>Expires</th>
									<th />
								</tr>
							</thead>
							<tbody>{rows}</tbody>
						</Table>
					</ScrollArea>
				</Stack>
			) : (
				<Text>No links made.</Text>
			)}
		</>
	);
}
