import React from 'react';
import { Group, Paper, Stack, Text } from '@mantine/core';
import { UserAvatar } from '../../../../../components/avatars/UserAvatar';
import { styles } from '../../styles';
import { useRecoilValue } from 'recoil';
import { user } from '../../../../../Atoms/userAtoms';
import { userSettings } from '../../../../../Atoms/settings';
import { swapBackgroundColor } from '../../../../../_utils/handy-functions';

export const UserCard = () => {
	const { classes, theme } = styles();
	const userInfo = useRecoilValue(user);
	const userSettingsData = useRecoilValue(userSettings);

	const { firstName, lastName, updatedAt } = userInfo;
	const { job_title, address } = userSettingsData;

	let avatarImageURL =
		'urls' in userInfo
			? userInfo?.urls[2]
			: `${userInfo.userAvatarURL}-512.png`;
	avatarImageURL = swapBackgroundColor(avatarImageURL, theme.fn.primaryColor());

	// Add the updatedAt timestamp as a query parameter to prevent caching issues
	const timestamp = Date.now();
	const avatarImageURLWithTimestamp = `${avatarImageURL}?t=${timestamp}`;

	return (
		<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
			<Group position={'apart'}>
				<Group>
					<UserAvatar
						firstName={firstName}
						lastName={lastName}
						avatarURL={avatarImageURLWithTimestamp}
						size={100}
					/>
					<Stack justify={'center'} spacing={0}>
						<Text
							color={'primary-text'}
							size={'lg'}
							weight={500}
						>{`${firstName} ${lastName}`}</Text>
						<Text weight={500}>{job_title || 'Job Title'}</Text>
						<Text size={'sm'}>
							{address?.city && address?.state
								? `${address?.city}, ${address?.state}`
								: 'City, State'}
						</Text>
					</Stack>
				</Group>
			</Group>
		</Paper>
	);
};
