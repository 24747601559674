import React from 'react';
import { Anchor, Checkbox } from '@mantine/core';
import { termsLink } from '../constants';

const TermsOfServiceLink = (
	<span>
		<Anchor href={termsLink} target='_blank' td={'underline'}>
			terms of use
		</Anchor>{' '}
		and{' '}
		<Anchor
			href='https://www.reelay.com/ai-disclosure-statement'
			target='_blank'
			td={'underline'}
		>
			AI disclosure statement.
		</Anchor>
	</span>
);

interface Props {
	checked?: boolean;
	setChecked?: (checked: boolean) => void;
	form?: any;
	width?: number | string;
}

export default function TermsOfUseCheckbox({
	checked,
	setChecked,
	form,
	width = '80%',
}: Props) {
	if (form) {
		return (
			<Checkbox
				{...form.getInputProps('agreed_with_terms')}
				label={<span>by selecting, you agree to the {TermsOfServiceLink}</span>}
				autoFocus={false}
			/>
		);
	}

	return (
		<Checkbox
			w={width}
			checked={checked}
			onChange={(event) => setChecked(event.currentTarget.checked)}
			label={<span>By selecting, you agree to the {TermsOfServiceLink}</span>}
			autoFocus={false}
		/>
	);
}
