import React, { useState } from 'react';
import { TextInput, Button, Stack, Text, ScrollArea } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt } from '@fortawesome/pro-regular-svg-icons';
import { OrganizationData } from '../types';
import { checkHubSpotCompanyExists } from '../../../api/hubspot';
import { showFailureNotification } from '../../../helpers/notifications';
import { createStyles } from '@mantine/core';
import { isValidDomain } from '../../../_utils/validators';

const useStyles = createStyles((theme) => ({
	title: {
		fontSize: 32,
		fontWeight: 500,
		marginBottom: '1.5rem',
		[`@media (max-width: ${theme.breakpoints.sm})`]: {
			fontSize: 24,
		},
	},
	subtitle: {
		fontSize: 20,
		fontWeight: 500,
		marginBottom: '1rem',
	},
	input: {
		backgroundColor: '#F5F5F5',
		borderRadius: '4px',
	},
	addDomainText: {
		fontSize: 11,
		color: '#7F7F7F',
		fontWeight: 400,
		textAlign: 'right',
		marginTop: '0.5rem',
		cursor: 'pointer',
		fontStyle: 'italic',
	},
}));

interface OrganizationCreationProps {
	onNext: () => void;
	data: OrganizationData;
	setData: (data: OrganizationData) => void;
}

const OrganizationCreation: React.FC<OrganizationCreationProps> = ({
	onNext,
	data,
	setData,
}) => {
	const { classes } = useStyles();
	const [domains, setDomains] = useState<string[]>(
		data.additionalDomains || []
	);
	const [loading, setLoading] = useState(false);

	const form = useForm({
		initialValues: {
			name: data.name || '',
			domain: data.domain || '',
		},
		validate: {
			name: (value) =>
				value.trim().length === 0 ? 'Organization name is required' : null,
			domain: (value) =>
				value.trim().length === 0
					? 'Domain is required'
					: !isValidDomain(value)
					? 'Invalid domain format'
					: null,
		},
	});

	const handleSubmit = async (values: typeof form.values) => {
		try {
			setLoading(true);

			const invalidDomains = domains.filter(
				(domain) => domain.trim().length > 0 && !isValidDomain(domain)
			);

			if (invalidDomains.length > 0) {
				showFailureNotification({
					message: `Invalid domains detected: ${invalidDomains.join(', ')}`,
					title: 'Invalid domains',
				});
				return;
			}

			const companyExists = await checkHubSpotCompanyExists(
				values.name,
				values.domain
			);

			if (companyExists?.data?.domainExists) {
				showFailureNotification({
					message: `A company with the domain "${values.domain}" already exists.`,
					title: 'Company domain already exists.',
				});
				return;
			}

			if (companyExists?.data?.nameExists) {
				showFailureNotification({
					message: `A company with the name "${values.name}" already exists.`,
					title: 'Company name already exists.',
				});
				return;
			}

			// redirect them to stripe checkout page

			setData({ ...values, additionalDomains: domains });
			onNext();
		} catch (error) {
			console.error('Failed to create organization:', error);
			showFailureNotification({
				title: 'Failed to create organization',
				message: error?.response?.data,
			});
		} finally {
			setLoading(false);
		}
	};

	const addDomain = () => {
		setDomains([...domains, '']);
	};

	const handleDomainChange = (index: number, value: string) => {
		const newDomains = [...domains];
		newDomains[index] = value;
		setDomains(newDomains);
	};

	return (
		<Stack spacing='md' p='xl' py={'md'}>
			<Text className={classes.title}>You’re in good company with Reelay.</Text>
			<Text className={classes.subtitle}>Let’s create your organization:</Text>

			<form onSubmit={form.onSubmit(handleSubmit)}>
				<TextInput
					placeholder='Organization name'
					required
					size='md'
					{...form.getInputProps('name')}
					className={classes.input}
				/>

				<ScrollArea
					h={'auto'}
					styles={{
						viewport: {
							maxHeight: 150,
						},
					}}
				>
					<TextInput
						placeholder='Email domain'
						required
						size='md'
						{...form.getInputProps('domain')}
						icon={<FontAwesomeIcon icon={faAt} color='#1C7ED6' />}
						className={classes.input}
						style={{ marginTop: '1rem' }}
					/>

					{domains.map((domain, index) => (
						<TextInput
							key={index}
							placeholder={`Enter another domain`}
							size='md'
							value={domain}
							onChange={(e) => handleDomainChange(index, e.currentTarget.value)}
							icon={<FontAwesomeIcon icon={faAt} color='#1C7ED6' />}
							className={classes.input}
							style={{ marginTop: '1rem' }}
						/>
					))}
				</ScrollArea>

				<Text className={classes.addDomainText} onClick={addDomain}>
					+ Add another domain
				</Text>

				<Button
					type='submit'
					fullWidth
					mt='lg'
					size='md'
					radius={'md'}
					loading={loading}
				>
					Up next, invite your team
				</Button>
			</form>
		</Stack>
	);
};

export default OrganizationCreation;
