import React, { useEffect } from 'react';
import {
	Button,
	Checkbox,
	Group,
	ScrollArea,
	Stack,
	Text,
	Box,
} from '@mantine/core';
import { RequestNotificationCard } from '../Popovers/ShareMeetingPopover/components/RequestNotificationCard';
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil';
import { user as userAtom } from '../../Atoms/userAtoms';
import {
	MeetingRequestNotification,
	notifications as notificationsAtom,
} from '../../Atoms/notifications';
import { useListState } from '@mantine/hooks';
import { updateMeetingUserLink } from '../../api/api';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { currentMeetingDistributionList } from '../../Atoms/meetingAtom';

interface Props {
	notifications: MeetingRequestNotification[];
}

export const NotificationList = ({ notifications }: Props) => {
	const currentUser = useRecoilValue(userAtom);
	const setNotifications = useSetRecoilState(notificationsAtom);
	const setViewers = useSetRecoilState(currentMeetingDistributionList);

	const [checkboxValues, handlers] = useListState(
		notifications.map((notification, index) => ({
			checked: false,
			value: { ...notification, index },
			key: notification?.id,
		}))
	);

	const allChecked = checkboxValues.every((value) => value.checked);
	const indeterminate =
		checkboxValues.some((value) => value.checked) && !allChecked;
	const amountChecked = checkboxValues.filter((value) => value.checked).length;

	useEffect(() => {
		handlers.setState(
			notifications.map((notification, index) => ({
				checked: false,
				value: { ...notification, index },
				key: notification?.id,
			}))
		);
	}, [notifications]);

	const updateRequest = async (user: MeetingRequestNotification, status) => {
		try {
			const res = await updateMeetingUserLink(
				user?.id,
				status,
				currentUser.currentOrganizationID
			);
			setNotifications((prev) =>
				prev.filter(
					(viewer) =>
						viewer.id !== user.id || viewer.meetingID !== user.meetingID
				)
			);
			setViewers((prev) =>
				prev.map((viewer) =>
					viewer.id === user.id && viewer.userlink.meetingID === user.meetingID
						? { ...viewer, userlink: res?.data?.data }
						: viewer
				)
			);
			showSuccessNotification({ message: 'Request updated successfully!' });
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we were unable to update your request. Please try again later or contact support.',
			});
		}
	};

	const bulkUpdateUserRequests = async (checkboxValues, status) => {
		try {
			const response = await Promise.allSettled(
				checkboxValues.map((value) => updateRequest(value.value, status))
			);
			const successful = response.filter(
				(res) => res.status === 'fulfilled'
			).length;
			showSuccessNotification({
				message: `Bulk action: ${successful} of ${checkboxValues.length} requests were successfully updated.`,
			});
		} catch (error) {
			showFailureNotification({
				message:
					'Sorry, we were unable to process your bulk request. Please try again later or contact support.',
			});
		}
	};

	return (
		<Box>
			{notifications.length ? (
				<Group position='apart' mb='md' spacing='xs' pl='sm'>
					<Checkbox
						size='sm'
						checked={allChecked}
						indeterminate={indeterminate}
						transitionDuration={0}
						onChange={() =>
							handlers.setState((current) =>
								current.map((value) => ({ ...value, checked: !allChecked }))
							)
						}
					/>
					<Group>
						<Button
							disabled={!amountChecked}
							size='xs'
							variant='filled'
							color='blue'
							onClick={() =>
								bulkUpdateUserRequests(
									checkboxValues.filter((item) => item.checked),
									'active'
								)
							}
						>
							{amountChecked ? `Approve (${amountChecked})` : 'Approve'}
						</Button>
						<Button
							disabled={!amountChecked}
							size='xs'
							variant='filled'
							color='red'
							onClick={() =>
								bulkUpdateUserRequests(
									checkboxValues.filter((item) => item.checked),
									'denied'
								)
							}
						>
							{amountChecked ? `Deny (${amountChecked})` : 'Deny'}
						</Button>
					</Group>
				</Group>
			) : null}

			<ScrollArea
				h={notifications.length ? 500 : 100}
				offsetScrollbars
				scrollbarSize={10}
				type='always'
				styles={(theme) => ({
					scrollbar: {
						'&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
							backgroundColor: theme.colors.primary[0],
						},
						right: 10,
					},
				})}
			>
				<Stack h='100%' spacing={0}>
					{notifications.length ? (
						notifications.map((user, index) => (
							<RequestNotificationCard
								key={user.id}
								checkboxValue={checkboxValues[index]}
								index={index}
								viewingMeeting={false}
								requestingUser={user}
								handlers={handlers}
								updateRequest={updateRequest}
							/>
						))
					) : (
						<Text align='center' color='dimmed'>
							No new notifications.
						</Text>
					)}
				</Stack>
			</ScrollArea>
		</Box>
	);
};
