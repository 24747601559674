import React, { useState } from 'react';
import {
	TextInput,
	Button,
	Stack,
	Text,
	Select,
	Group,
	createStyles,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { InfoCollectionData, hubspotIndustries } from '../types';

import { showFailureNotification } from '../../../helpers/notifications';
import useMediaQueries from '../../../customHooks/useMediaQueries';

const useStyles = createStyles((theme) => ({
	headerText: {
		fontSize: 32,
		fontWeight: 500,
		marginBottom: '1.5rem',
		[`@media (max-width: ${theme.breakpoints.sm})`]: {
			fontSize: 24,
		},
	},
	input: {
		backgroundColor: '#F5F5F5',
		borderRadius: theme.radius.sm,
	},
	label: {
		fontSize: 16,
		fontWeight: 500,
		color: theme.fn.primaryColor(),
		marginBottom: theme.spacing.xs,
	},
	optionButton: {
		backgroundColor: '#F5F5F5',
		border: `1px solid ${theme.colors.gray[5]}`,
		padding: '10px 20px',
		borderRadius: theme.radius.sm,
		cursor: 'pointer',
		fontSize: 16,
		fontWeight: 500,
		textAlign: 'center',
		color: theme.black,
		'&:hover': {
			backgroundColor: '#E0E0E0',
		},
		'&:focus': {
			backgroundColor: '#D9D9D9',
			boxShadow: 'none',
		},
		'&:active': {
			backgroundColor: '#D9D9D9',
			boxShadow: 'none',
		},
	},
	selectedOptionButton: {
		backgroundColor: '#D9D9D9',
		color: theme.black,
		'&:focus': {
			boxShadow: 'none',
		},
	},
}));

// Map button text to HubSpot-compatible numeric values
const numberOfEmployeesOptions = {
	'Less than 10': 10,
	'10 - 50': 50,
	'51 - 199': 199,
	'200+': 200,
};

interface InfoCollectionProps {
	onNext: () => void;
	data: InfoCollectionData;
	setData: (data: InfoCollectionData) => void;
}

const InfoCollection: React.FC<InfoCollectionProps> = ({
	onNext,
	data,
	setData,
}) => {
	const { classes } = useStyles();
	const [numberOfEmployees, setNumberOfEmployees] = useState<number | null>(
		numberOfEmployeesOptions[data.numberOfEmployees] || null
	);
	const [loading, setLoading] = useState(false);
	const { maxMediumBreakpoints } = useMediaQueries();

	const form = useForm({
		initialValues: {
			industry: data.industry || '',
			// goal: data.goal || '',
		},
		validate: {
			industry: (value) =>
				value.trim().length === 0 ? 'Team type is required' : null,
			// goal: (value) => (value.trim().length === 0 ? 'Goal is required' : null),
		},
	});

	const handleSubmit = async (values: typeof form.values) => {
		try {
			setLoading(true);
			if (!numberOfEmployees) {
				alert('Please select your company size.');
				return;
			}
			setData({ ...values, numberOfEmployees });
			onNext();
		} catch (error) {
			showFailureNotification({
				title: 'Failed to create company',
				message: error?.response?.data,
			});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Stack spacing='md' px='xl'>
			<Text className={classes.headerText}>How will you use Reelay?</Text>

			<form onSubmit={form.onSubmit(handleSubmit)}>
				<Select
					label='What type of industry do you work in?'
					placeholder='Select your team type'
					data={hubspotIndustries}
					required
					{...form.getInputProps('industry')}
					classNames={{ input: classes.input, label: classes.label }}
				/>

				{/* <Select
					label='What do you hope to achieve using Reelay?'
					placeholder='Select your goal'
					data={[
						'Better action tracking across my meetings',
						'Improve task management',
						'Enhanced collaboration',
					]}
					required
					{...form.getInputProps('goal')}
					classNames={{ input: classes.input, label: classes.label }}
					mt='md'
				/> */}

				<Text className={classes.label} mt='lg' mb='xs'>
					How big is your company?
				</Text>
				{maxMediumBreakpoints ? (
					<Stack>
						{Object.keys(numberOfEmployeesOptions).map((sizeLabel) => (
							<Button
								key={sizeLabel}
								className={`${classes.optionButton} ${
									numberOfEmployees === numberOfEmployeesOptions[sizeLabel] &&
									classes.selectedOptionButton
								}`}
								onClick={() =>
									setNumberOfEmployees(numberOfEmployeesOptions[sizeLabel])
								}
							>
								{sizeLabel}
							</Button>
						))}
					</Stack>
				) : (
					<Group grow>
						{Object.keys(numberOfEmployeesOptions).map((sizeLabel) => (
							<Button
								key={sizeLabel}
								className={`${classes.optionButton} ${
									numberOfEmployees === numberOfEmployeesOptions[sizeLabel] &&
									classes.selectedOptionButton
								}`}
								onClick={() =>
									setNumberOfEmployees(numberOfEmployeesOptions[sizeLabel])
								}
							>
								{sizeLabel}
							</Button>
						))}
					</Group>
				)}

				<Button
					type='submit'
					fullWidth
					mt='lg'
					size='md'
					radius={'md'}
					loading={loading}
				>
					Next, set your preferences
				</Button>
			</form>
		</Stack>
	);
};

export default InfoCollection;
