import { Chapter } from './meetings';

export const sortChaptersByTime = (chapters: Chapter[]) => {
	if (!Array.isArray(chapters)) {
		throw new Error(`topics is not an array`);
	}
	return chapters.sort((a, b) => {
		return a.time - b.time;
	});
};
