import React, { MouseEventHandler } from 'react';
import { ReelayActionCardData } from '../constants';
import {
	Grid,
	Image,
	Paper,
	Stack,
	Title,
	Text,
	createStyles,
	FileButton,
} from '@mantine/core';

const useStyles = createStyles((theme) => ({
	card: {
		cursor: 'pointer',
		borderColor: theme.colors['primary'][0],
		'&:hover': {
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[6]
					: theme.colors.gray[0],
		},
	},
}));
interface Props {
	data: ReelayActionCardData;
	onClickHandler: any;
}
export const ReelayActionCard = ({ data, onClickHandler }: Props) => {
	const { classes } = useStyles();
	const { imageURL, title, content, action } = data;

	const CardImg = ({ action }: { action: string }) => {
		return (
			<Image
				width={100}
				style={{
					transform: `translateY(${
						action === 'past' ? 3 : action === 'live' ? -5 : 0
					}px)`,
				}}
				src={imageURL}
			/>
		);
	};

	const TitleAndContent = () => {
		return (
			<Stack h={'35%'} spacing={'xs'} w={'100%'}>
				<Title order={5} color={'primary-text'}>
					{title}
				</Title>
				<Text size={14} color={'reelay-secondary-dark'}>
					{content}
				</Text>
			</Stack>
		);
	};

	return (
		<Grid.Col span={6} h={'90%'}>
			{action === 'past' ? (
				// Upload Card
				<FileButton
					onChange={onClickHandler}
					accept={'mp4,avi,ogg,wav,wma,wmv,mov'}
				>
					{(props) => (
						<Paper
							{...props}
							className={classes.card}
							h={'100%'}
							shadow='xs'
							p='md'
							withBorder
						>
							<Stack h={'100%'} justify={'space-around'} align={'center'}>
								<CardImg action={action} />
								<TitleAndContent />
							</Stack>
						</Paper>
					)}
				</FileButton>
			) : (
				// Nav Card (live, future)
				<Paper
					className={classes.card}
					h={'100%'}
					shadow='xs'
					p='md'
					withBorder
					onClick={() => onClickHandler(action)}
				>
					<Stack h={'100%'} justify={'space-around'} align={'center'}>
						<CardImg action={action} />
						<TitleAndContent />
					</Stack>
				</Paper>
			)}
		</Grid.Col>
	);
};

export default ReelayActionCard;
