import React, { useEffect, useState } from 'react';
import { Center, Container, Group, Stack, Text, Title } from '@mantine/core';
import TeamsTable from '../../components/teams_table';
import CreateTeamButton from '../../components/Buttons/create_team_button';
import { getTeams } from '../../api/api';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
	currentUserFormattedTeams,
	userOrganization,
} from '../../Atoms/userAtoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPeopleGroup } from '@fortawesome/pro-solid-svg-icons';
import { logger } from '../../helpers/logger';
import { ReelayLoader } from '../../components/ReelayLoader';

export default function Teams() {
	const [teamsAvail, setTeamsAvail] = useState<boolean>(false);
	const [teams, setTeams] = useRecoilState(currentUserFormattedTeams);
	const [loading, setLoading] = useState<boolean>(false);
	const organization = useRecoilValue(userOrganization);
	const organizationID = organization.id;

	useEffect(() => {
		getUserTeams();
	}, [teamsAvail]);

	const getUserTeams = async () => {
		try {
			setLoading(true);

			const teamsResponse = await getTeams(organizationID);
			logger('info', 'Teams for organization', teamsResponse);
			setTeams(teamsResponse.data.data);
			setTeamsAvail(true);
			// setTeams(formattedTeams.body.data);
		} catch (error) {
			logger('error', 'Error getting teams', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<>
			<Group grow position='apart' h={'100%'}>
				{loading ? (
					<ReelayLoader />
				) : teams.length ? (
					<TeamsTable />
				) : (
					<Stack p={0} h={'100%'}>
						<Title order={3}>Teams</Title>
						<Center sx={() => ({ height: 500 })}>
							<Container>
								<Stack align='center' justify='center'>
									<FontAwesomeIcon icon={faPeopleGroup} size={'4x'} />
									<Text color={'reelay-secondary-dark'}>
										Teams you create will show up here.
									</Text>
									<Text color={'reelay-secondary-dark'}>
										Reelay Teams are used to ensure your meeting distribution is
										faster and easier than ever before.{' '}
									</Text>
									<CreateTeamButton type={'bottom'} />
								</Stack>
							</Container>
						</Center>
					</Stack>
				)}
			</Group>
		</>
	);
}
