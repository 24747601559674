import React from 'react';
import {
	Modal,
	Stack,
	Space,
	Text,
	Center,
	Button,
	Group,
	Title,
} from '@mantine/core';
import ReactPlayer from 'react-player';

interface TutorialModalProps {
	opened: boolean;
	onClose: () => void;
	onConfirm: () => void;
}

const TutorialModal: React.FC<TutorialModalProps> = ({
	opened,
	onClose,
	onConfirm,
}) => {
	return (
		<Modal
			opened={opened}
			onClose={onClose}
			size={'xl'}
			title={
				<Title order={6} size={'h3'} fw={400}>
					Congratulations!
				</Title>
			}
		>
			<Stack w={'100%'}>
				<Text size={14} weight={400}>
					Now that you’ve signed up, check your email for account verification.
					You’re ready to start using Reelay, but if you have any questions you
					can always reach us at support@reelay.com
				</Text>
				<Center>
					<ReactPlayer
						url='https://www.youtube.com/watch?v=I5I7rHfjCG4'
						width={'100%'}
						config={{
							youtube: {
								playerVars: {
									showinfo: 0,
									modestbranding: 1,
									controls: 0,
								},
							},
						}}
					/>
				</Center>
				<Group position='right'>
					<Button onClick={onConfirm}>Close</Button>
				</Group>
			</Stack>
		</Modal>
	);
};

export default TutorialModal;
