import axios from 'axios';

const axiosInstance = axios.create({
	baseURL: process.env.REACT_APP_BASE_API_URL, // Set your base URL
	// baseURL: 'api/',
	// baseURL: 'local.reelay.ai', // Set your base URL
	timeout: 60000, // Set the timeout for requests
	// You can add more default configurations as needed
});

export default axiosInstance;
