// styles.js
import { createStyles } from '@mantine/core';

export const styles = createStyles((theme) => ({
	card: {
		border: `1px solid ${theme.colors['reelay-secondary-light'][0]}`,
		color: theme.colors['secondary-text'][0],
	},
	outerContainer: {
		overflowY: 'auto',
	},
}));
