import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReelayLogoPageLoader from '../../components/loaders/ReelayLogoPageLoader';
import {
	Button,
	Container,
	createStyles,
	Group,
	Text,
	Title,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldKeyhole } from '@fortawesome/pro-solid-svg-icons';
import {
	createMeetingUserLink,
	createMeetingUserLinkWithoutShareableLink,
	getDetailsByShareableLinkSlugID,
	getMeetingUserLink,
} from '../../api/api';
import { useNavigate, useParams } from 'react-router';
import { IMeeting } from '../../interfaces/meeting';
import { convertRemToPx } from '../../_utils/handy-functions';
import MobileHeader from '../../components/mobileHeader';
import { useRecoilValue } from 'recoil';
import { user } from '../../Atoms/userAtoms';
import { useMediaQuery } from 'react-responsive';

const pendingCopy = {
	title: 'Awaiting Approval',
	description:
		'A request to view this meeting has been sent to its host. You will be notified via email once they respond.',
	buttonText: 'Request Sent',
};

const requestAccessCopy = {
	title: 'Unauthorized Access',
	description:
		'It looks like you do not currently have access to view this meeting. If you would like to request access to the meeting then you can do so down below.',
	buttonText: 'Request Access',
};

const useStyles = createStyles((theme) => ({
	root: {
		paddingTop: 80,
		paddingBottom: 80,
	},

	label: {
		textAlign: 'center',
		fontWeight: 900,
		fontSize: 220,
		lineHeight: 1,
		marginBottom: convertRemToPx(theme.spacing.xl) * 1.5,
		color:
			theme.colorScheme === 'dark'
				? theme.colors.dark[4]
				: theme.colors.gray[2],

		[theme.fn.smallerThan('sm')]: {
			fontSize: 120,
		},
	},

	title: {
		fontFamily: `Greycliff CF, ${theme.fontFamily}`,
		textAlign: 'center',
		fontWeight: 900,
		fontSize: 38,

		[theme.fn.smallerThan('sm')]: {
			fontSize: 32,
		},
	},

	description: {
		maxWidth: 500,
		margin: 'auto',
		marginTop: theme.spacing.xl,
		marginBottom: convertRemToPx(theme.spacing.xl) * 1.5,
	},
}));

/**
 * This component is for users visiting a meeting through a shareable meeting link
 * example = https://local.reelay.ai/share/?id=xEntrQjaOL8y
 * id = slug of shareable meeting link
 * @constructor
 */
const ShareableMeetingLink = () => {
	const { classes, theme } = useStyles();
	const navigate = useNavigate();
	const location = useLocation();
	const { meetingID } = useParams();
	const searchParams = new URLSearchParams(location.search);
	const currentUser = useRecoilValue(user);
	const slugID = searchParams.get('id');
	const [pageLoading, setPageLoading] = useState(true);
	const [requestSent, setRequestSent] = useState(false);
	const [isPending, setIsPending] = useState(false);
	const [details, setDetails] = useState<{ title; description; buttonText }>(
		requestAccessCopy
	);
	const isMobile = useMediaQuery({
		query: `(max-width: ${theme.breakpoints.xs})`,
	});
	const [meetingDetails, setMeetingDetails] = useState<IMeeting | null>(null);

	useEffect(() => {
		// use slug id to fetch organization, meeting, and link creator details.
		// set state with response data.
		const fetchMeetingDetailsBySlugID = async () => {
			try {
				if (slugID) {
					const details = await getDetailsByShareableLinkSlugID(slugID);
					// check if user has permission to view meeting
					// if user has permission to view meeting, redirect to meeting page
					// if user does not have permission to view meeting, show request access page
					const permission = await getMeetingUserLink({
						meetingID: details?.data?.data?.meetingID,
					});
					const activePermission = permission?.data?.data.find(
						(link) => link.userID === currentUser.id
					);
					const meetingName = details?.data?.data?.meeting?.name;
					setMeetingDetails(details?.data?.data?.meeting);
					const status = activePermission?.status;
					// if meeting-user-link status is 'active' then redirect to meeting page.
					if (status === 'active') {
						navigate(`/meetings/${details?.data?.data?.meeting?.friendlyID}`);
					} else if (status === 'pending') {
						setDetails({
							title: 'Awaiting Approval',
							description: `Your access to view ${meetingName} is still pending. You will receive an email once the host has granted access to the meeting with a link to view the meeting.`,
							buttonText: 'Request Sent',
						});
						setIsPending(true);
						setRequestSent(true);
					} else {
						setDetails({
							title: 'Unauthorized Access',
							description: `It looks like you do not currently have access to view ${meetingName}. If you would like to request access to the meeting then you can do so down below.`,
							buttonText: 'Request Access',
						});
					}
				} else if (meetingID) {
					const permission = await getMeetingUserLink({
						friendlyID: meetingID,
					});
					const activePermission = permission?.data?.data.find(
						(link) => link.userID === currentUser.id
					);
					const status = activePermission?.status;
					// if meeting-user-link status is 'active' then redirect to meeting page.
					if (status === 'active') {
						navigate(`/meetings/${activePermission?.meetingID}`);
					} else if (status === 'pending') {
						setDetails({
							title: 'Awaiting Approval',
							description: `Your access to view this meeting is still pending. You will receive an email once the host has granted access to the meeting with a link to view the meeting.`,
							buttonText: 'Request Sent',
						});
						setIsPending(true);
						setRequestSent(true);
					} else {
						setDetails({
							title: 'Unauthorized Access',
							description: `It looks like you do not currently have access to view this meeting. If you would like to request access to the meeting then you can do so down below.`,
							buttonText: 'Request Access',
						});
					}
				}
			} catch (error) {
				// if error is 404, show unauthorized access page
				if (error?.response?.status === 404) {
					navigate('/page-not-found');
				}
			} finally {
				setPageLoading(false);
			}
		};
		fetchMeetingDetailsBySlugID();
	}, [slugID, meetingID]);

	const handleRequestAccess = async () => {
		try {
			if (requestSent) return;
			const res = slugID
				? await createMeetingUserLink(slugID)
				: await createMeetingUserLinkWithoutShareableLink(meetingID);
			setRequestSent(true);
			setIsPending(true);
			setDetails({
				title: 'Awaiting Approval',
				description: `Your access to view ${
					meetingDetails?.name || 'this meeting'
				} is still pending. You will receive an email once the host has granted access to the meeting with a link to view the meeting.`,
				buttonText: 'Request Sent',
			});
		} catch (error) {}
	};

	return (
		<>
			{pageLoading ? (
				<ReelayLogoPageLoader />
			) : (
				<>
					<Container className={classes.root}>
						<div className={classes.label}>
							<FontAwesomeIcon icon={faShieldKeyhole} />
						</div>
						<Title className={classes.title}>{details.title}</Title>
						<Text
							color='dimmed'
							size='lg'
							align='center'
							className={classes.description}
						>
							{details.description}
						</Text>
						<Group position='center'>
							<Button
								size='md'
								onClick={handleRequestAccess}
								color={requestSent ? 'teal' : theme.fn.primaryColor()}
							>
								{details.buttonText}
							</Button>
						</Group>
					</Container>
				</>
			)}
		</>
		// show reelay loading screen
		// show request access page if user link is not found.
		// show pending page if user access is pending
		// if user has access to meeting they will be redirected to meeting page
	);
};

export default ShareableMeetingLink;
