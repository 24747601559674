import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
	Container,
	Title,
	Text,
	Grid,
	Paper,
	Loader,
	Table,
	Badge,
	ScrollArea,
	TextInput,
	Button,
	Group,
	NumberInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import {
	formatCurrency,
	getFullDateFromEpochMS,
} from '../../../../../helpers/date';
import { getOrganization } from '../../../../../api/api';
import {
	getSubscription,
	getReceipts,
	getRenewalInfo,
	connectStripeSubscriptionToOrg,
	createManualSubscription,
	updateManualSubscription,
	connectStripeCustomerToOrg,
} from '../../../../../api/billing';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../../helpers/notifications';
import { useRecoilState } from 'recoil';
import {
	StripeSubscription,
	stripeSubscriptionAtom,
} from '../../../../../Atoms/billing';

interface StripeReceipt {
	id: string;
	amount: number;
	created: number;
	currency: string;
	receiptUrl: string;
	status: string;
}

const AdminBillingPage: React.FC = () => {
	const { organizationID } = useParams<{ organizationID: string }>();
	const [isLoading, setIsLoading] = useState(true);
	const [subscription, setSubscription] = useRecoilState(
		stripeSubscriptionAtom
	);
	const [organization, setOrganization] = useState<any>(null);

	const form = useForm({
		initialValues: {
			stripeSubscriptionID: '',
			stripeCustomerID: '',
			seats: 1,
		},
		validate: {
			stripeSubscriptionID: (value) =>
				value ? null : 'Stripe Subscription ID is required',
			stripeCustomerID: (value) =>
				value ? null : 'Stripe Customer ID is required',
		},
	});

	const manualSubscriptionForm = useForm({
		initialValues: {
			seats: 1,
		},
		validate: {
			seats: (value) =>
				value > 0 ? null : 'Number of seats must be greater than 0',
		},
	});

	const updateManualSubscriptionForm = useForm({
		initialValues: {
			seats: 1,
		},
		validate: {
			seats: (value) =>
				value > 0 ? null : 'Number of seats must be greater than 0',
		},
	});

	const fetchSubscriptionDetails = useCallback(async () => {
		if (!organizationID) return;

		setIsLoading(true);
		try {
			const org = await getOrganization(organizationID);
			setOrganization(org);

			const subscriptionResult = await getSubscription(org.subscriptionId);

			if (subscriptionResult) {
				const subscriptionData = subscriptionResult;
				const newSubscription: StripeSubscription = {
					...subscriptionData,
					receipts: [],
					hasPaymentMethod: false,
				};

				if (subscriptionData.type === 'stripe') {
					const [receiptsResult, renewalResult] = await Promise.allSettled([
						getReceipts(org.subscriptionId),
						getRenewalInfo(org.subscriptionId),
					]);

					if (receiptsResult.status === 'fulfilled') {
						newSubscription.receipts = receiptsResult.value.receipts;
					}

					if (renewalResult.status === 'fulfilled') {
						newSubscription.renewal = renewalResult.value;
						newSubscription.hasPaymentMethod = true;
					}
				}

				setSubscription(newSubscription);
				form.setFieldValue(
					'stripeSubscriptionID',
					newSubscription.stripeSubscriptionID
				);
				form.setFieldValue('stripeCustomerID', org.stripeCustomerId);
				updateManualSubscriptionForm.setFieldValue(
					'seats',
					newSubscription.seatsPurchased
				);
			} else {
				throw new Error('Failed to fetch subscription data');
			}
		} catch (error: any) {
			console.error('Failed to get subscription details:', error);
		} finally {
			setIsLoading(false);
		}
	}, [organizationID]);

	useEffect(() => {
		fetchSubscriptionDetails();
	}, [fetchSubscriptionDetails]);

	const handleUpdateSubscription = async (values: {
		stripeSubscriptionID: string;
		stripeCustomerID: string;
	}) => {
		try {
			const updatedOrganization = await connectStripeSubscriptionToOrg({
				organizationId: organizationID,
				subscriptionId: values.stripeSubscriptionID,
			});
			const updatedOrgWithCustomerID = await connectStripeCustomerToOrg(
				organizationID,
				values.stripeCustomerID
			);

			// update organization
			setOrganization({
				...updatedOrganization,
				...updatedOrgWithCustomerID,
			});
			// fetch subscription.
			fetchSubscriptionDetails();
			showSuccessNotification({
				title: 'Subscription Updated',
				message: 'The Stripe Subscription ID has been successfully updated.',
			});
		} catch (error: any) {
			console.error('Failed to update subscription:', error);
			showFailureNotification({
				title: 'Failed to update subscription',
				message:
					error.message || 'An error occurred while updating the subscription.',
			});
		}
	};

	const handleCreateManualSubscription = async (values: { seats: number }) => {
		try {
			const result = await createManualSubscription({
				organizationId: organizationID,
				numberOfSeats: values.seats,
			});
			showSuccessNotification({
				title: 'Manual Subscription Created',
				message: `A manual subscription with ${values.seats} seats has been created.`,
			});
			fetchSubscriptionDetails();
		} catch (error: any) {
			console.error('Failed to create manual subscription:', error);
			showFailureNotification({
				title: 'Failed to create manual subscription',
				message:
					error.message ||
					'An error occurred while creating the manual subscription.',
			});
		}
	};

	const handleUpdateManualSubscription = async (values: { seats: number }) => {
		try {
			const result = await updateManualSubscription({
				subscriptionId: subscription?.id,
				seats: values.seats,
			});
			showSuccessNotification({
				title: 'Manual Subscription Updated',
				message: `The manual subscription has been updated to ${values.seats} seats.`,
			});
			fetchSubscriptionDetails();
		} catch (error: any) {
			console.error('Failed to update manual subscription:', error);
			showFailureNotification({
				title: 'Failed to update manual subscription',
				message:
					error.message ||
					'An error occurred while updating the manual subscription.',
			});
		}
	};

	const openConfirmModal = (values: {
		stripeSubscriptionID: string;
		stripeCustomerID: string;
		seats: number;
	}) => {
		modals.openConfirmModal({
			title: 'Confirm Update',
			children: (
				<Text size='sm'>
					Are you sure you want to update the Stripe Subscription ID to{' '}
					<strong>{values.stripeSubscriptionID}</strong> and set the Stripe
					Customer ID to <strong>{values.stripeCustomerID}</strong>?
				</Text>
			),
			labels: { confirm: 'Update', cancel: 'Cancel' },
			onConfirm: () => handleUpdateSubscription(values),
		});
	};

	const openConfirmManualSubscriptionModal = (values: { seats: number }) => {
		modals.openConfirmModal({
			title: 'Confirm Manual Subscription',
			children: (
				<Text size='sm'>
					Are you sure you want to create a manual subscription with{' '}
					<strong>{values.seats}</strong> seats?
				</Text>
			),
			labels: { confirm: 'Create', cancel: 'Cancel' },
			onConfirm: () => handleCreateManualSubscription(values),
		});
	};

	const openConfirmUpdateManualSubscriptionModal = (values: {
		seats: number;
	}) => {
		modals.openConfirmModal({
			title: 'Confirm Update',
			children: (
				<Text size='sm'>
					Are you sure you want to update the manual subscription to{' '}
					<strong>{values.seats}</strong> seats?
				</Text>
			),
			labels: { confirm: 'Update', cancel: 'Cancel' },
			onConfirm: () => handleUpdateManualSubscription(values),
		});
	};

	if (isLoading) {
		return (
			<Container
				size='lg'
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '100vh',
				}}
			>
				<Loader size='xl' />
			</Container>
		);
	}

	return (
		<Container size='lg'>
			{!subscription?.id && (
				<>
					<Title order={2} mb={'lg'}>
						No subscription found for this organization.
					</Title>
					<Grid gutter='md'>
						<Grid.Col span={12}>
							<Paper p='md' withBorder>
								<Title order={4} mb='sm'>
									Create Manual Subscription
								</Title>
								<form
									onSubmit={manualSubscriptionForm.onSubmit(
										openConfirmManualSubscriptionModal
									)}
								>
									<NumberInput
										required
										label='Number of Seats'
										placeholder='Enter number of seats'
										min={1}
										description='This will create a manual subscription for this organization.'
										{...manualSubscriptionForm.getInputProps('seats')}
									/>
									<Group position='right' mt='md'>
										<Button type='submit'>Create Manual Subscription</Button>
									</Group>
								</form>
							</Paper>
						</Grid.Col>
						<Grid.Col span={12}>
							<Paper p='md' withBorder>
								<Title order={4} mb='sm'>
									Update Stripe Subscription
								</Title>
								<form onSubmit={form.onSubmit(openConfirmModal)}>
									<TextInput
										required
										label='Stripe Subscription ID'
										placeholder='Enter new Stripe Subscription ID'
										description='This will update the Stripe Subscription ID for this organization.'
										{...form.getInputProps('stripeSubscriptionID')}
									/>
									<TextInput
										required
										label='Stripe Customer ID'
										placeholder='Enter new Stripe Customer ID'
										description='This will update the Stripe Customer ID for this organization.'
										{...form.getInputProps('stripeCustomerID')}
									/>
									<Group position='right' mt='md'>
										<Button type='submit'>Update Subscription</Button>
									</Group>
								</form>
							</Paper>
						</Grid.Col>
					</Grid>
				</>
			)}

			{subscription?.id && (
				<Grid gutter='md'>
					<Grid.Col span={6}>
						<Paper p='md' withBorder>
							<Title order={4} mb='sm'>
								Subscription Details
							</Title>
							<Text>
								<strong>Seats Purchased:</strong> {subscription.seatsPurchased}
							</Text>
							<Text>
								<strong>Seats Assigned:</strong> {subscription.seatsAssigned}
							</Text>
							<Text>
								<strong>Created At:</strong>{' '}
								{getFullDateFromEpochMS(subscription.createdAt)}
							</Text>
							<Text>
								<strong>Updated At:</strong>{' '}
								{getFullDateFromEpochMS(subscription.updatedAt)}
							</Text>
							<Text>
								<strong>Current Period:</strong>{' '}
								{getFullDateFromEpochMS(subscription.currentPeriodStart)} -{' '}
								{getFullDateFromEpochMS(subscription.currentPeriodEnd)}
							</Text>
							<Text>
								<strong>Status:</strong>{' '}
								{subscription.isTrialing ? 'Trial' : 'Active'}
							</Text>
							<Text>
								<strong>Type:</strong>{' '}
								{subscription.type.charAt(0).toUpperCase() +
									subscription.type.slice(1)}
							</Text>
						</Paper>
					</Grid.Col>

					<Grid.Col span={6}>
						<Paper p='md' withBorder>
							<Title order={4} mb='sm'>
								Trial Information
							</Title>
							<Text>
								<strong>Trial Start:</strong>{' '}
								{getFullDateFromEpochMS(subscription.trialStart) || 'N/A'}
							</Text>
							<Text>
								<strong>Trial End:</strong>{' '}
								{getFullDateFromEpochMS(subscription.trialEnd) || 'N/A'}
							</Text>
						</Paper>
					</Grid.Col>

					<Grid.Col span={12}>
						<Paper p='md' withBorder>
							<Title order={4} mb='sm'>
								Renewal Information
							</Title>
							<Text>
								<strong>Renewal Date:</strong>{' '}
								{subscription.renewal?.renewalDate
									? getFullDateFromEpochMS(subscription.renewal.renewalDate)
									: 'N/A'}
							</Text>
							<Text>
								<strong>Renewal Amount:</strong>{' '}
								{formatCurrency(subscription?.renewal?.amount) || 'N/A'}
							</Text>
						</Paper>
					</Grid.Col>

					<Grid.Col span={12}>
						<Paper p='md' withBorder>
							<Title order={4} mb='sm'>
								Receipts
							</Title>
							<ScrollArea style={{ height: 300 }}>
								{subscription.receipts && subscription.receipts.length > 0 ? (
									<Table>
										<thead>
											<tr>
												<th>Date</th>
												<th>Amount</th>
												<th>Status</th>
												<th>Receipt</th>
											</tr>
										</thead>
										<tbody>
											{subscription.receipts.map((receipt: StripeReceipt) => (
												<tr key={receipt.id}>
													<td>{getFullDateFromEpochMS(receipt.created)}</td>
													<td>{formatCurrency(receipt.amount)}</td>
													<td>
														<Badge
															color={
																receipt.status === 'paid' ? 'green' : 'yellow'
															}
														>
															{receipt.status}
														</Badge>
													</td>
													<td>
														<a
															href={receipt.receiptUrl}
															target='_blank'
															rel='noopener noreferrer'
														>
															View Receipt
														</a>
													</td>
												</tr>
											))}
										</tbody>
									</Table>
								) : (
									<Text>No receipts available</Text>
								)}
							</ScrollArea>
						</Paper>
					</Grid.Col>

					{subscription.type === 'manual' && (
						<Grid.Col span={12}>
							<Paper p='md' withBorder>
								<Title order={4} mb='sm'>
									Update Manual Subscription
								</Title>
								<form
									onSubmit={updateManualSubscriptionForm.onSubmit(
										openConfirmUpdateManualSubscriptionModal
									)}
								>
									<NumberInput
										required
										label='Number of Seats'
										placeholder='Enter number of seats'
										min={1}
										description='This will update the number of seats for this manual subscription.'
										{...updateManualSubscriptionForm.getInputProps('seats')}
									/>
									<Group position='right' mt='md'>
										<Button type='submit'>Update Manual Subscription</Button>
									</Group>
								</form>
							</Paper>
						</Grid.Col>
					)}

					<Grid.Col span={12}>
						<Paper p='md' withBorder>
							<Title order={4} mb='sm'>
								{subscription?.stripeSubscriptionID
									? 'Update Stripe Subscription'
									: 'Add Stripe Subscription'}
							</Title>
							<form onSubmit={form.onSubmit(openConfirmModal)}>
								<TextInput
									required
									label='Stripe Subscription ID'
									placeholder='Enter new Stripe Subscription ID'
									description='This will update the Stripe Subscription ID for this organization.'
									{...form.getInputProps('stripeSubscriptionID')}
								/>
								<TextInput
									required
									label='Stripe Customer ID'
									placeholder='Enter new Stripe Customer ID'
									description='This will update the Stripe Customer ID for this organization.'
									{...form.getInputProps('stripeCustomerID')}
								/>

								<Group position='right' mt='md'>
									<Button type='submit'>Update Subscription</Button>
								</Group>
							</form>
						</Paper>
					</Grid.Col>
				</Grid>
			)}
		</Container>
	);
};

export default AdminBillingPage;
