import { Outlet } from 'react-router-dom';
import { Container, createStyles, Paper, ScrollArea } from '@mantine/core';
import { convertRemToPx } from '../../_utils/handy-functions';

const useStyles = createStyles((theme) => ({
	wrapper: {
		paddingBottom: convertRemToPx(theme.spacing.xl) * 2,
		height: '100%',
		boxSizing: 'border-box',
		width: '100%',
		maxWidth: '1200px',
		borderTop: '#FFFFFF',
	},
	paper: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'transparent',
	},
}));

export default function Settings() {
	const { classes } = useStyles();

	return (
		<Container size='lg' className={classes.wrapper}>
			<ScrollArea
				styles={() => ({
					root: {
						height: '100%',
					},
					viewport: {
						height: '100%',
					},
				})}
			>
				<Paper p='lg' className={classes.paper}>
					<Outlet />
				</Paper>
			</ScrollArea>
		</Container>
	);
}
