import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const assignSupporterToOrganization = (
	userID: string,
	organizationID: string
) => {
	return axiosInstance
		.post(
			`v1/organization/${organizationID}/assign-supporter-to-organization`,
			{ userID }
		)
		.then((res) => {
			logger('info', 'Response received from getOrganizationSettings', res);
			return res.data.data;
		})
		.catch((err) => {
			logger('error', 'Error getting user settings', err);
			return err;
		});
};

export const unassignSupporterFromOrganization = (
	userID: string,
	organizationID: string
) => {
	return axiosInstance
		.post(
			`v1/organization/${organizationID}/unassign-supporter-from-organization`,
			{ userID }
		)
		.then((res) => {
			logger('info', 'Response received from getOrganizationSettings', res);
			return res.data.data;
		})
		.catch((err) => {
			logger('error', 'Error getting user settings', err);
			throw new Error(err);
		});
};

export const removeOrganization = (organizationID: string) => {
	return axiosInstance
		.delete(`v1/organization/${organizationID}`)
		.then((res) => {
			logger('info', 'Response received from removeOrganization', res);
			return res.data.data;
		})
		.catch((err) => {
			logger('error', 'Error removing organization', err);
			throw new Error(err);
		});
};

export const getOrganizationStats = (organizationID: string) => {
	return axiosInstance
		.get(`v1/organization/${organizationID}/pre-check`)
		.then((res) => {
			logger('info', 'Response received from getOrganizationStats', res);
			return res.data.data;
		});
};

export const sendSlackNeedMoreTimeNotification = async (notificationData: {
	requestor_name: string;
	requestor_email: string;
	organization: string;
}) => {
	try {
		const response = await axiosInstance.post(
			'v1/organization/send-slack-need-more-time-notification',
			notificationData,
			{
				headers: {
					'Content-Type': 'application/json',
				},
			}
		);
		return response.data;
	} catch (error) {
		console.error('Error sending Slack notification:', error);
		throw error;
	}
};
