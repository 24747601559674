import { MILLISECONDS_PER_SECOND } from '../../../../../components/constants';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Divider,
	Group,
	Paper,
	Stack,
	Text,
	createStyles,
} from '@mantine/core';
import { formatSecondsToMinutesAndSeconds } from '../../../../../_utils/time';
import { Dispatch, SetStateAction, useRef } from 'react';
import { useClickOutside } from '@mantine/hooks';
import { Chapter } from '../../../../../helpers/meetings';

const iCON_WIDTH = 14.98;

interface Props {
	topic: Chapter;
	setSeeMoreOpened: Dispatch<SetStateAction<boolean>>;
}

const useStyles = createStyles(() => ({
	header: {
		fontSize: 16,
	},
}));

export default function SeeMoreTopic({ topic, setSeeMoreOpened }: Props) {
	const { classes } = useStyles();
	const iconRef = useRef(null);
	const ref = useClickOutside(() => setSeeMoreOpened(false));
	const { time, headline, gist, summary, content } = topic;

	return (
		<Paper
			ref={ref}
			w={450}
			shadow='xl'
			p={'1rem'}
			px={0}
			style={{
				position: 'absolute',
				top: '59%',
				right: 15,
				zIndex: 1000000000000,
			}}
		>
			<Stack spacing='md'>
				<Group
					position='apart'
					noWrap
					className={classes.header}
					align='center'
					pl={'1rem'}
					pr={'2rem'}
				>
					<Group noWrap>
						<FontAwesomeIcon
							ref={iconRef}
							icon={faChevronLeft}
							size='xl'
							onClick={(e) => {
								e.stopPropagation();
								setSeeMoreOpened(false);
							}}
							style={{
								cursor: 'pointer',
							}}
						/>
						<Text fw={500}>
							Topic:{' '}
							<Text span fw={700}>
								{content || gist}
							</Text>
						</Text>
					</Group>
					<Text fw={700}>
						{formatSecondsToMinutesAndSeconds(time / MILLISECONDS_PER_SECOND)}
					</Text>
				</Group>
				<Divider color='gray' />
				<Text
					lh={'25px'}
					pl={`calc(32px + ${iconRef?.current?.clientHeight || iCON_WIDTH}px)`}
					pr={32}
				>
					{typeof headline === 'string' && headline.length ? (
						<Text span lh={'25px'} fw={700}>
							{headline || ''}.{' '}
						</Text>
					) : null}
					{summary}
				</Text>
			</Stack>
		</Paper>
	);
}
