import React, { useState } from 'react';
import cx from 'clsx';
import {
	List,
	ScrollArea,
	Stack,
	Table,
	Title,
	TransferListData,
} from '@mantine/core';
import { MicrosoftActiveDirectoryGroup } from '../../../../../../Atoms/sso';
import { RowData } from '../UserRoleStep';
import classes from './ConfirmStep.module.css';

interface Props {
	data: TransferListData;
	activeDirectoryGroups: { [key: string]: MicrosoftActiveDirectoryGroup };
	userListTableData: RowData[];
	setStep: React.Dispatch<React.SetStateAction<number>>;
	rolesState: { [key: string]: string };
	setRolesState: React.Dispatch<
		React.SetStateAction<{ [key: string]: string }>
	>;
}

export default function ConfirmStep({
	data,
	activeDirectoryGroups,
	userListTableData,
	rolesState,
}: Props) {
	const [scrolled, setScrolled] = useState(false);

	const selectedGroups = data[1]
		.map((item) => item.value)
		.map((id) => activeDirectoryGroups[id]);

	const roleDisplayNames = {
		SUPERADMINS: 'Superadmin',
		ADMINS: 'Admin',
		MEMBERS: 'Member',
	};

	return (
		<Stack h={'100%'} style={{ flex: 9, overflowY: 'auto' }}>
			<Title order={4}>Directories Added</Title>
			<List withPadding style={{ flex: 3, overflowY: 'auto' }}>
				{selectedGroups.map((group) => (
					<List.Item>{group.displayName}</List.Item>
				))}
			</List>
			<Title order={4}>Users</Title>
			<ScrollArea
				styles={(theme) => ({
					root: {
						overflowY: 'auto',
						flex: 3,
					},
					scrollbar: {
						'&[data-orientation="vertical"] .mantine-ScrollArea-thumb': {
							backgroundColor: theme.fn.primaryColor(),
						},
					},
				})}
				onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
			>
				<Table miw={700}>
					<thead
						className={cx(classes.header, { [classes.scrolled]: scrolled })}
					>
						<tr>
							<th>Name</th>
							<th>Email</th>
							<th>Directory</th>
							<th>Role</th>
						</tr>
					</thead>
					<tbody>
						{userListTableData.map((row) => (
							<tr key={row.name}>
								<td>{row.name}</td>
								<td>{row.email}</td>
								<td>{row.directory}</td>
								<td>{roleDisplayNames[rolesState[row.id]?.split(' ')[1]]}</td>
							</tr>
						))}
					</tbody>
				</Table>
			</ScrollArea>
		</Stack>
	);
}
