/* eslint-disable comma-dangle */
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './app';
import { initTrackers, reportWebVitals } from './_utils/trackers';
import { MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import theme from './_utils/theme';
import { RecoilRoot } from 'recoil';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { ModalsProvider } from '@mantine/modals';
import { Logtail } from '@logtail/browser';

initTrackers();

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<BrowserRouter>
		<RecoilRoot>
			<MantineProvider theme={theme} withNormalizeCSS withGlobalStyles>
				<FlagsmithProvider
					options={{
						environmentID: process.env.REACT_APP_FLAGSMITH_ENV_ID,
					}}
					flagsmith={flagsmith}
				>
					<ModalsProvider>
						<CookiesProvider>
							<Notifications
								position='top-right'
								autoClose={3000}
								zIndex={1000000000}
							/>
							<App />
						</CookiesProvider>
					</ModalsProvider>
				</FlagsmithProvider>
			</MantineProvider>
		</RecoilRoot>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
