import React from 'react';
import { Container, createStyles } from '@mantine/core';
import CollectionTable from './CollectionTable';

const useStyles = createStyles(() => ({
	outerContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
	},
}));

interface Props {
	setOpened: React.Dispatch<React.SetStateAction<boolean>>;
	myCollections: boolean;
}

export default function CollectionsOverview({
	setOpened,
	myCollections,
}: Props) {
	const { classes } = useStyles();

	return (
		<Container className={classes.outerContainer} size={'xl'} pb={'xl'}>
			<CollectionTable setOpened={setOpened} myCollections={myCollections} />
		</Container>
	);
}
