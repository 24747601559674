// styles.js
import { createStyles } from '@mantine/core';

export const styles = createStyles((theme) => ({
  outerStack: {
    overflowY: 'hidden',
    overflowX: 'hidden',
    height: '100%',
    maxWidth: '100%',
    alignItems: 'stretch',
  },
  speakerText: {
    color: theme.colors['secondary-text'][0],
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '20px',
  },
  paper: {
    backgroundColor: theme.colors?.['background-gray'],
    borderColor: theme.colors?.['primary'][0],
    color: theme.colors?.['primary-text'],
    borderRadius: '8px',
    border: '1px',
  },
  cardStack: {
    overflowY: 'auto',
    maxHeight: '100%',
  },
  highlightContainer: {
    height: '100%',
    alignItems: 'stretch',
  },
}));
