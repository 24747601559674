import { createStyles } from '@mantine/core';

export const styles = createStyles((theme) => ({
	header: {
		marginBottom: theme.spacing.md,
		color: theme.colors['primary-text'][0],
		fontWeight: 600,
	},
	content: {},
	grid: {
		display: 'grid',
		gridTemplateColumns: '1fr',
		gap: theme.spacing.lg,
		[`@media (min-width: ${theme.breakpoints.md})`]: {
			gridTemplateColumns: '1fr 1fr',
		},
	},
	section: {
		marginBottom: theme.spacing.lg,
		height: '100%',
	},
	sectionTitle: {
		fontWeight: 700,
		fontSize: theme.fontSizes.md,
		marginBottom: theme.spacing.sm,
		color: theme.colors['primary-text'][0],
	},
	chartContainer: {
		height: 200,
		position: 'relative',
		display: 'flex',
		justifyContent: 'center',
	},
	seatControls: {
		marginTop: theme.spacing.md,
	},
	input: {
		width: '4rem',
	},
	billingInfo: {
		display: 'flex',
		flexDirection: 'column',
		gap: theme.spacing.sm,
		color: theme.colors['primary-text'][0],
		fontWeight: 500,
	},
	pricing: {
		marginTop: theme.spacing.lg,
	},
	pricingDetails: {
		display: 'flex',
		minHeight: '125px',
		justifyContent: 'space-between',
		[`@media (max-width: ${theme.breakpoints.md})`]: {
			flexDirection: 'column',
			gap: theme.spacing.md,
		},
		color: theme.colors['secondary-text'][0],
		marginBottom: theme.spacing.md,
	},
	priceBox: {
		height: '100%',
	},
	primaryText: {
		color: theme.colors['primary-text'][0],
		fontWeight: 700,
	},
	secondaryText: {
		color: theme.colors['primary-text'][0],
		fontSize: '14px',
		fontWeight: 500,
	},
	updateButton: {
		fontWeight: 500,
		padding: theme.spacing.sm,
		width: '100%',
		textAlign: 'center',
		borderRadius: theme.radius.sm,
	},
}));
