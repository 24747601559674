export function compareByTimestamp(a, b, field, sortOrder) {
  const timestampA = new Date(a[field]).getTime();
  const timestampB = new Date(b[field]).getTime();

  if (sortOrder === 'asc') {
    return timestampA - timestampB;
  } else if (sortOrder === 'desc') {
    return timestampB - timestampA;
  } else {
    throw new Error('Invalid sortOrder: Use "asc" or "desc"');
  }
}
