import React from 'react';
import { googleMeetIcon, outlookTeamsIcon, zoomIcon } from '../constants';
import { Image, Text } from '@mantine/core';

export default function PlatformIcon({
	platform,
	marginTop,
	size = 20,
}: {
	platform: string | null;
	marginTop?: number;
	size?: number;
}) {
	const icons = {
		meet: googleMeetIcon,
		teams: outlookTeamsIcon,
		zoom: zoomIcon,
		uploaded: '/favicon-32x32.png',
	};

	const url = icons[platform];

	if (!url) return <Text>N/A</Text>;

	return (
		<Image mt={marginTop} maw={size} src={url} alt='Platform icon' mb={3} />
	);
}
