import React, { useEffect, useState } from 'react';
import {
  Table,
  ScrollArea,
  UnstyledButton,
  Group,
  Text,
  Center,
  TextInput,
  rem,
  Chip,
  useMantineTheme,
} from '@mantine/core';
import { keys } from '@mantine/utils';
import {
  IconSelector,
  IconChevronDown,
  IconChevronUp,
  IconSearch,
} from '@tabler/icons-react';
import classes from './UserRoleStep.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';

export interface RowData {
  id: string;
  name: string;
  email: string;
  directory: string;
  userType: string;
}

export const activeDirectoryRoles = {
  superadmin: 'REELAY SUPERADMINS',
  admin: 'REELAY ADMINS',
  member: 'REELAY MEMBERS',
};

const NUMBER_OF_COLS = 4;

interface ThProps {
  children: React.ReactNode;
  reversed: boolean;
  sorted: boolean;
  onSort(): void;
}

function Th({ children, reversed, sorted, onSort }: ThProps) {
  const Icon = sorted
    ? reversed
      ? IconChevronUp
      : IconChevronDown
    : IconSelector;
  return (
    <th className={classes.th}>
      <UnstyledButton onClick={onSort} className={classes.control}>
        <Group position={'apart'}>
          <Text fw={500} fz='sm'>
            {children}
          </Text>
          <Center className={classes.icon}>
            <Icon style={{ width: rem(16), height: rem(16) }} stroke={1.5} />
          </Center>
        </Group>
      </UnstyledButton>
    </th>
  );
}

function filterData(data: RowData[], search: string) {
  const query = search.toLowerCase().trim();
  return data.filter((item) =>
    keys(data[0]).some((key) => {
      if (typeof item[key] === 'string') {
        return item[key].toLowerCase().includes(query);
      }
      return false;
    })
  );
}

function sortData(
  data: RowData[],
  payload: { sortBy: keyof RowData | null; reversed: boolean; search: string }
) {
  const { sortBy } = payload;

  if (!sortBy) {
    return filterData(data, payload.search);
  }

  return filterData(
    [...data].sort((a, b) => {
      if (payload.reversed) {
        return b[sortBy].localeCompare(a[sortBy]);
      }

      return a[sortBy].localeCompare(b[sortBy]);
    }),
    payload.search
  );
}

interface Props {
  data: RowData[];
  rolesState: { [key: string]: string };
  setRolesState: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
}

export default function UserRoleStep({
  data,
  rolesState,
  setRolesState,
}: Props) {
  const theme = useMantineTheme();
  const [search, setSearch] = useState('');
  const [sortBy, setSortBy] = useState<keyof RowData | null>(null);
  const [reverseSortDirection, setReverseSortDirection] = useState(false);
  const [sortedData, setSortedData] = useState(data);

  useEffect(() => {
    setSortedData(data);
  }, [data]);

  const setSorting = (field: keyof RowData) => {
    const reversed = field === sortBy ? !reverseSortDirection : false;
    setReverseSortDirection(reversed);
    setSortBy(field);
    setSortedData(sortData(data, { sortBy: field, reversed, search }));
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget;
    setSearch(value);
    setSortedData(
      sortData(data, { sortBy, reversed: reverseSortDirection, search: value })
    );
  };

  const rows = sortedData.map((row) => (
    <tr key={row.id}>
      <td>
        {row.name === 'No name in AD.' ? (
          <Group noWrap spacing={'xs'}>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              color={theme.colors.yellow[5]}
            />
            No name in AD
          </Group>
        ) : (
          row.name
        )}
      </td>
      <td>{row.email}</td>
      <td>{row.directory}</td>
      <td>
        <Chip.Group
          value={rolesState[row.id] || activeDirectoryRoles.member}
          onChange={(value: string) => {
            setRolesState((prev) => ({
              ...prev,
              [row.id]: value,
            }));
          }}
        >
          <Group noWrap>
            <Chip size={'xs'} value={activeDirectoryRoles.member}>
              Member
            </Chip>
            <Chip size={'xs'} value={activeDirectoryRoles.admin}>
              Admin
            </Chip>
            <Chip size={'xs'} value={activeDirectoryRoles.superadmin}>
              Superadmin
            </Chip>
          </Group>
        </Chip.Group>
      </td>
    </tr>
  ));

  return (
    <ScrollArea
      styles={{
        root: {
          flex: 2,
          overflowY: 'auto',
        },
      }}
    >
      <TextInput
        placeholder='Search by any field'
        mb='md'
        icon={
          <IconSearch
            style={{ width: rem(16), height: rem(16) }}
            stroke={1.5}
          />
        }
        value={search}
        onChange={handleSearchChange}
      />
      <Table horizontalSpacing='md' verticalSpacing='xs' miw={700}>
        <tbody>
          <tr>
            <Th
              sorted={sortBy === 'name'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('name')}
            >
              Name
            </Th>
            <Th
              sorted={sortBy === 'email'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('email')}
            >
              Email
            </Th>
            <Th
              sorted={sortBy === 'directory'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('directory')}
            >
              Directory
            </Th>
            <Th
              sorted={sortBy === 'userType'}
              reversed={reverseSortDirection}
              onSort={() => setSorting('userType')}
            >
              Role
            </Th>
          </tr>
        </tbody>
        <tbody>
          {rows.length > 0 ? (
            rows
          ) : (
            <tr>
              <td colSpan={NUMBER_OF_COLS}>
                {/*<td colSpan={Object.keys(data[0]).length}>*/}
                <Text fw={500} ta='center'>
                  Nothing found
                </Text>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </ScrollArea>
  );
}
