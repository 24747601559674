import React from 'react';
import { Center, Container, Image, Stack, Text } from '@mantine/core';
import CreateButton from '../../../components/Buttons/CreateButton';
import { FOLDERS_IMAGE } from './CollectionTable';

interface Props {
  setOpened: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function EmptyCollectionsPage({ setOpened }: Props) {
  return (
    <Center sx={() => ({ height: 500 })}>
      <Container>
        <Stack align='center' justify='center'>
          <Image maw={200} src={FOLDERS_IMAGE} alt={'A folder'} />
          <Text color={'reelay-secondary-dark'}>
            Collections you create will show up here.
          </Text>
          <Text color={'reelay-secondary-dark'}>
            Reelay collections are used to organize meetings together.
          </Text>
          <CreateButton setOpened={setOpened} text={'Create a Collection'} />
        </Stack>
      </Container>
    </Center>
  );
}
