import { Group, Title } from '@mantine/core';

interface Props {
	text: string;
	icon?: React.ReactNode;
}

export default function ModalTitle({ text, icon }: Props) {
	if (icon) {
		return (
			<Group spacing={'sm'} noWrap>
				{icon}
				<Title order={6} size={'h3'} fw={400}>
					{text}
				</Title>
			</Group>
		);
	}

	return (
		<Title order={6} size={'h3'} fw={400}>
			{text}
		</Title>
	);
}
