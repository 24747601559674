import { IAction } from '../interfaces/action';
import { sortActionsByTimeStartMS } from './highlights';
import { User } from '../Atoms/userAtoms';

/**
 * sorts actions in a column
 * 1. claimed by you in chronological order
 * 2. not claimed by you in chronological order.
 * @param actions
 * @param userID
 */
export const sortActions = (actions: IAction[], userID: string) => {
	const yourActions = [];
	const otherActions = [];

	for (const action of actions) {
		(action?.assignees?.some((assignee) => assignee?.id === userID)
			? yourActions
			: otherActions
		).push(action);
	}

	const yourActionsInChronologicalOrder = sortActionsByTimeStartMS(yourActions);
	const otherActionsInChronologicalOrder =
		sortActionsByTimeStartMS(otherActions);

	return [
		...yourActionsInChronologicalOrder,
		...otherActionsInChronologicalOrder,
	];
};

export const isAssignedToUser = (assignees: User[], userID: string) => {
	if (!assignees) return false;
	return assignees.some((assignee) => assignee.id === userID);
};
