import { Stack, Title } from '@mantine/core';
import OrganizationMembersTable from '../../../../components/organization_members_table';
import { currentUser } from '../../../../Atoms/userAtoms';
import { useRecoilValue } from 'recoil';

const Members = () => {
	const user = useRecoilValue(currentUser);
	return (
		<Stack p={0} h={'100%'}>
			<OrganizationMembersTable
				organizationID={user.currentOrganizationID}
				showSkipAudit={false}
				auditSide={false}
				title={<Title order={3}>User Management</Title>}
			/>
		</Stack>
	);
};

export default Members;
