import React from 'react';
import {
  Title,
  Text,
  Button,
  createStyles,
  Container,
  Group,
} from '@mantine/core';
import { useNavigate } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldKeyhole } from '@fortawesome/pro-solid-svg-icons';
import { AnyFunction } from '../../types/types';

const useStyles = createStyles((theme) => ({
  root: {
    paddingTop: 80,
    paddingBottom: 80,
  },

  label: {
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 220,
    lineHeight: 1,
    marginBottom: theme.spacing.xl,
    color:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[4]
        : theme.colors.gray[2],

    [theme.fn.smallerThan('sm')]: {
      fontSize: 120,
    },
  },

  title: {
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    textAlign: 'center',
    fontWeight: 900,
    fontSize: 38,

    [theme.fn.smallerThan('sm')]: {
      fontSize: 32,
    },
  },

  description: {
    maxWidth: 500,
    margin: 'auto',
    marginTop: theme.spacing.xl,
    marginBottom: theme.spacing.xl,
  },
}));

interface Props {
  title?: string;
  description?: string;
  buttonText?: string;
  onClick?: AnyFunction | null;
}

export const UnauthorizedAccess = ({
  title = 'Unauthorized Access',
  description = 'Unfortunately, you do not have permission to view this page.',
  buttonText = 'Take me back to home page',
  onClick = null,
}: Props) => {
  const { classes } = useStyles();
  const navigate = useNavigate();

  return (
    <Container className={classes.root}>
      <div className={classes.label}>
        <FontAwesomeIcon icon={faShieldKeyhole} />
      </div>
      <Title className={classes.title}>{title}</Title>
      <Text
        color='dimmed'
        size='lg'
        align='center'
        className={classes.description}
      >
        {description}
      </Text>
      <Group position='center'>
        <Button
          variant='subtle'
          size='md'
          onClick={() => (onClick ? onClick() : navigate('/meetings'))}
        >
          {buttonText}
        </Button>
      </Group>
    </Container>
  );
};
