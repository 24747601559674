import { string } from 'prop-types';
import { atom, selector } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const integrations = atom<any[]>({
	key: 'integrations',
	default: [],
});

export const hasCalendar = selector({
	key: 'hasCalendar',
	get: ({ get }) => {
		const integrationsArray = get(integrations);
		return integrationsArray.length > 0;
	},
});

export const calendarSyncingData = atom({
	key: 'calendarSyncingData',
	default: {},
});

export const calendarStatusData = atom({
	key: 'calendarStatusData',
	default: {},
});
