import { atom } from 'recoil';
import { IAction } from '../interfaces/action';

export const currentActions = atom<IAction[]>({
	key: 'currentActions',
	default: [],
});

export const actionItemsSearchValue = atom({
	key: 'actionItemsSearchValue',
	default: '',
});

export const actionTableSelection = atom({
	key: 'actionTableSelection',
	default: [],
});
