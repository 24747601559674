import React from 'react';
import { Box, Group, Image, Text } from '@mantine/core';
import { useNavigate } from 'react-router';

import { useRecoilValue } from 'recoil';
import { hasAdminAccess } from '../../Atoms/userAtoms';
import { organizationBrand } from '../../Atoms/settings';
import { isAuthenticated } from '../../Atoms/auth';
import { reelay_logo, reelay_woodmark_logo } from '../constants';

interface Props {
	width?: number;
	height?: number;
	showReelayLogo?: boolean;
	addPoweredByCaption?: boolean;
	showWoodmark?: boolean;
	pl?: string | number;
	pr?: string | number;
}
export function Logo({
	width = 150,
	height = 70,
	showReelayLogo = false,
	addPoweredByCaption = false,
	showWoodmark = false,
	pl = 'xs',
	pr = 'xs',
}: Props) {
	const brand = useRecoilValue(organizationBrand);
	const navigate = useNavigate();
	const adminAccess = useRecoilValue(hasAdminAccess);
	const userAuthenticated = useRecoilValue(isAuthenticated);
	// 150 / 70 equals this number.
	const widthToHeightRatio = 2.14285714286;

	const handleLogoClick = () => {
		if (!userAuthenticated) {
			navigate('/login');
			return;
		} else if (adminAccess) {
			navigate('/admin/meetings');
		} else {
			navigate('/meetings');
		}
	};

	return (
		<>
			{addPoweredByCaption && brand?.logo_url ? (
				<Group noWrap pl={pl} pr={pr}>
					<Image
						sx={() => ({
							cursor: 'pointer',
						})}
						onClick={handleLogoClick}
						width={width}
						height={width ? width / widthToHeightRatio : height}
						fit='contain'
						src={brand.logo_url}
					/>
					<Text color={'secondary-text'} size={'sm'} align={'center'}>
						powered by{' '}
						<Text size={'md'} weight={500} span>
							reelay
						</Text>
					</Text>
				</Group>
			) : (
				<Box
					sx={(theme) => ({
						paddingLeft: pl,
						paddingRight: pr,
						cursor: 'pointer',
					})}
					onClick={handleLogoClick}
				>
					<Image
						width={width}
						height={width ? width / widthToHeightRatio : height}
						fit='contain'
						src={
							showWoodmark
								? reelay_woodmark_logo
								: showReelayLogo || !brand?.logo_url
								? reelay_logo
								: brand.logo_url
						}
					/>
				</Box>
			)}
		</>
	);
}
