import React, { useEffect, useState } from 'react';
import { Group, List, Button, Modal, Text, createStyles } from '@mantine/core';
import { IMeeting } from '../../interfaces/meeting';
import { getAttachmentsForMeeting, sendAnalyticEvent } from '../../api/api';
import { useRecoilState } from 'recoil';
import { IAttachment } from '../../Atoms/userAtoms';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faDownload } from '@fortawesome/pro-light-svg-icons';
import theme from '../../_utils/theme';
import { AttachmentPreviewModal } from '../AttachmentPreviewModal';
import { FilePreviewModal } from '../create_reelay_modal/FilePreviewModal';
import { downloadAttachment } from '../../helpers/attachments';

interface Props {
	viewingAttachments: boolean;
	setViewingAttachments: any;
	meeting: IMeeting | null;
}

const useStyles = createStyles(() => ({
	previewLink: {
		cursor: 'pointer',
	},
	trashIcon: {
		cursor: 'pointer',
	},
}));

export const AttachmentsModal = ({
	viewingAttachments,
	setViewingAttachments,
	meeting,
}: Props) => {
	const { classes } = useStyles();
	const [currentAttachments, setCurrentAttachments] = useState<IAttachment[]>(
		[]
	);
	const [currentFilePreview, setCurrentFilePreview] = useState<number>(0);
	const [viewingPreview, setViewingPreview] = useState<boolean>(false);
	const [filePreviews, setFilePreviews] = useState<any>({});

	useEffect(() => {
		const getAttachments = async () => {
			if (meeting) {
				const attachments = await getAttachmentsForMeeting(
					meeting?.id,
					meeting?.organizationID
				);
				setCurrentAttachments(attachments.data.data);
				createFilePreviews(attachments.data.data);
			}
		};
		getAttachments();
	}, [meeting]);

	const previewAttachment = (index: number) => {
		setCurrentFilePreview(index);
		setViewingPreview(true);
	};

	const createFilePreviews = (attachments: IAttachment[]) => {
		setFilePreviews(
			attachments.reduce((prev, cur) => {
				return {
					...prev,
					[cur.id]: { uri: cur.attachmentURL, name: cur.fileName },
				};
			}, {})
		);
	};

	return (
		<>
			<Modal
				centered
				radius={'md'}
				padding={25}
				style={{ height: 'auto', maxHeight: '50%' }}
				size={'70%'}
				opened={viewingAttachments}
				onClose={() => setViewingAttachments(false)}
				title='Attachments'
			>
				{currentAttachments.map((attachment: IAttachment, index: number) => (
					<Group
						my={10}
						key={attachment.id}
						position={'apart'}
						align={'center'}
						noWrap
						w={'100%'}
						style={{ maxWidth: '100%', minWidth: '90%' }}
					>
						<Text>{attachment.fileName}</Text>
						<Group>
							{/*{ (attachment.mimeType === 'image/png' || attachment.mimeType === 'image/jpeg') &&*/}
							{/* <Button
                onClick={() => previewAttachment(index)}
                variant={'outline'}
                size={'xs'}
              >
                Preview
              </Button> */}
							<FontAwesomeIcon
								icon={faDownload}
								className={classes.previewLink}
								onClick={() =>
									downloadAttachment(attachment, meeting.organizationID)
								}
							/>
						</Group>
					</Group>
				))}
				{/*<AttachmentPreviewModal*/}
				{/*  viewingPreview={viewingPreview}*/}
				{/*  setViewingPreview={setViewingPreview}*/}
				{/*  currentFilePreview={currentFilePreview}*/}
				{/*  documents={filePreviews}*/}
				{/*/>*/}
				{viewingPreview && (
					<FilePreviewModal
						files={currentAttachments}
						viewingPreview={viewingPreview}
						setViewingPreview={setViewingPreview}
						currentFilePreview={currentFilePreview}
						filePreviews={filePreviews}
					/>
				)}
			</Modal>
		</>
	);
};
