import React from 'react';
import { User } from '../../Atoms/userAtoms';
import {
	Avatar,
	Center,
	createStyles,
	Group,
	Stack,
	Text,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAt, faPhoneVolume } from '@fortawesome/pro-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import { swapBackgroundColor } from '../../_utils/handy-functions';

interface Props {
	meetingHost: User;
}

const useStyles = createStyles((theme) => ({
	card: {
		padding: theme.spacing.lg,
		paddingLeft: 0,
		paddingBottom: 0,
	},
	icon: {
		size: '12px',
		color: theme.colors['secondary-gray'][0],
	},
	hostName: {
		fontSize: 14,
		fontWeight: 400,
		lineHeight: '20px',
		letterSpacing: 0,
	},
	hostInfo: {
		fontSize: 12,
		fontWeight: 400,
		lineHeight: '22px',
		letterSpacing: 0,
		color: theme.colors['secondary-gray'][0],
	},
}));

const MeetingHostCard = ({ meetingHost }: Props) => {
	const { classes, theme } = useStyles();
	const { firstName, lastName, userAvatarURL, avatarSizes, phone, email } =
		meetingHost;
	const isSmall = useMediaQuery({
		query: `(max-height: ${theme.breakpoints.sm}px)`,
	});
	let avatarURL =
		typeof userAvatarURL === 'string' &&
			userAvatarURL.length &&
			Array.isArray(avatarSizes) &&
			avatarSizes[1]
			? `${userAvatarURL}-${avatarSizes[1]}.png`
			: '';
	avatarURL = swapBackgroundColor(avatarURL, theme.fn.primaryColor());

	const avatarInitials =
		meetingHost.firstName && meetingHost.lastName
			? `${meetingHost?.firstName?.substring(
				0,
				1
			)}${meetingHost?.lastName?.substring(0, 1)}`.toUpperCase()
			: '?';

	return (
		<div className={classes.card}>
			<Group position={'left'}>
				<Avatar
					src={avatarURL}
					alt={`Avatar photo of ${firstName} ${lastName}`}
					radius={'md'}
					color={'primary'}
					variant={'filled'}
					size={isSmall ? 'md' : 'xl'}
					children={
						<Center mb={1} mr={3}>
							{avatarInitials}
						</Center>
					}
				/>
				<Stack h={'100%'} spacing={1} justify={'center'}>
					<Text className={classes.hostName}>{`${firstName} ${lastName}`}</Text>
					<Group position={'left'} spacing={12}>
						<FontAwesomeIcon icon={faAt} className={classes.icon} />
						<Text className={classes.hostInfo}>{email}</Text>
					</Group>
					<Group position={'left'} spacing={12}>
						<FontAwesomeIcon icon={faPhoneVolume} className={classes.icon} />
						<Text className={classes.hostInfo}>
							{phone ? phone : 'Not Available'}
						</Text>
					</Group>
				</Stack>
			</Group>
		</div>
	);
};

export default MeetingHostCard;
