import {
	Group,
	Tooltip,
	Popover,
	UnstyledButton,
	Button,
	Text,
	createStyles,
	Loader,
} from '@mantine/core';
import {
	faTrash,
	faBoxArchive,
	faFolderPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRecoilValue } from 'recoil';
import { currentUser } from '../../Atoms/userAtoms';
import { flagsmithFeatureFlags } from '../constants';
import { useFlags } from 'flagsmith/react';
import { archiveBulkButtonLoadingAtom } from '../../Atoms/collections';
const useStyles = createStyles(() => ({
	bulkActionIcon: {
		cursor: 'pointer',
	},
	disabledIcon: {
		opacity: 0.5 /* Decrease opacity to visually mute the icon */,
		pointerEvents: 'none' /* Disable pointer events to prevent interaction */,
	},
}));

interface Props {
	selection: any[];
	bulkActionDeleteMeetings: () => void;
	handleBulkAddToCollection?: (meetingIDs: string[]) => void;
	handleBulkArchive?: (meetingIDs: string[]) => void;
	// handleBulkAction: (action: string) => void;
	setOpened: (o: any) => void;
	opened: boolean;
}

export default function MeetingBulkActionRow({
	selection = [],
	bulkActionDeleteMeetings,
	handleBulkAddToCollection,
	handleBulkArchive,
	setOpened,
	opened,
}: Props) {
	const { classes } = useStyles();
	const user = useRecoilValue(currentUser);
	const bulkArchiveButtonLoading = useRecoilValue(archiveBulkButtonLoadingAtom);
	const isSupportUser = user.roles.includes('support');
	const { meetingCollections } = flagsmithFeatureFlags;
	const flags = useFlags([meetingCollections]);
	const collectionsEnabled = flags.meeting_collections.enabled;

	return (
		<Group noWrap>
			{!isSupportUser && collectionsEnabled && (
				<Tooltip label={'Archive'} position={'bottom'}>
					{
						bulkArchiveButtonLoading ? <Loader size={16} /> :
						<UnstyledButton onClick={() => handleBulkArchive(selection)}>
							<FontAwesomeIcon
								icon={faBoxArchive}
								className={classes.bulkActionIcon}
							/>
						</UnstyledButton>
					}
				</Tooltip>
			)}
			{!isSupportUser && (
				<Tooltip label={'Add to Collection'} position={'bottom'}>
					<UnstyledButton onClick={() => handleBulkAddToCollection(selection)}>
						<FontAwesomeIcon
							icon={faFolderPlus}
							className={classes.bulkActionIcon}
						/>
					</UnstyledButton>
				</Tooltip>
			)}

			<Popover
				withArrow
				shadow='md'
				width={200}
				opened={opened}
				onChange={setOpened}
			>
				<Popover.Target>
					<Tooltip label={'Delete'} position={'bottom'}>
						<FontAwesomeIcon
							onClick={() => setOpened((o) => !o)}
							icon={faTrash}
							className={classes.bulkActionIcon}
						/>
					</Tooltip>
				</Popover.Target>
				<Popover.Dropdown>
					<Text size='sm'>
						Are you sure you want to permanently delete these meetings?
					</Text>
					<Button
						fullWidth
						onClick={bulkActionDeleteMeetings}
						color='red'
						uppercase
						mt={'md'}
					>
						Delete
					</Button>
				</Popover.Dropdown>
			</Popover>
		</Group>
	);
}
