import React from 'react';
import { Button, Group } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFloppyDisk, faPencil } from '@fortawesome/pro-light-svg-icons';

interface Props {
  isEditing: boolean;
  handleEdit: () => void;
}
export const EditSaveCancelButton = ({ isEditing, handleEdit }: Props) => {
  return (
    <>
      {isEditing ? (
        <Group spacing={'xs'}>
          <Button
            type={'submit'}
            variant={'filled'}
            radius={'xl'}
            rightIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
            color={'primary'}
          >
            Save
          </Button>
          <Button
            variant={'outline'}
            radius={'xl'}
            color={'primary'}
            onClick={handleEdit}
          >
            Cancel
          </Button>
        </Group>
      ) : (
        <Button
          variant={'outline'}
          radius={'xl'}
          rightIcon={<FontAwesomeIcon icon={faPencil} />}
          color={'primary'}
          onClick={handleEdit}
        >
          Edit
        </Button>
      )}
    </>
  );
};
