import React, { useEffect, useState } from 'react';
import {
	Checkbox,
	Group,
	Modal,
	Stack,
	Text,
	Title,
	TransferList,
	TransferListData,
	TransferListItemComponent,
	TransferListItemComponentProps,
} from '@mantine/core';
import { PrimaryButton } from '../../../components/Buttons/PrimaryButton';
import { userOrganizationMembers } from '../../../Atoms/userAtoms';
import { useRecoilValue } from 'recoil';
import { Collection } from '../../../Atoms/collections';
import ModalTitle from '../../../components/Titles/ModalTitle';

interface Props {
	opened: boolean;
	setOpened: React.Dispatch<React.SetStateAction<boolean>>;
	// eslint-disable-next-line no-unused-vars
	handleUpdate: (data) => void;
	collection: Collection;
}
export default function CollectionUsersModal({
	opened,
	setOpened,
	handleUpdate,
	collection,
}: Props) {
	const organizationMembers = useRecoilValue(userOrganizationMembers);
	const [data, setData] = useState<TransferListData>([[], []]);
	const [loading, setLoading] = useState(false);
	const owner = collection?.users.find(
		(user) => user.id === collection?.owningUserID
	);
	const currentUsers = collection?.users;

	useEffect(() => {
		const nonMembers = organizationMembers
			.filter((member) => {
				return !currentUsers.some((user) => user.id === member.id);
			})
			.map((user) => ({
				value: user.id,
				label: `${user.firstName} ${user.lastName} ${user.email}`,
			}));
		const currentMembersExcludingOwner = currentUsers
			.map((user) => ({
				value: user.id,
				label: `${user.firstName} ${user.lastName} ${user.email}`,
			}))
			.filter((user) => user.value !== owner?.id);
		const initialValues: TransferListData = [
			nonMembers,
			currentMembersExcludingOwner,
		];

		setData(initialValues);
	}, [currentUsers]);

	const ItemComponent: TransferListItemComponent = ({
		data,
		selected,
	}: TransferListItemComponentProps) => {
		const [firstName, lastName, email] = data.label.split(' ');
		const name =
			firstName === 'null' && lastName === 'null'
				? 'N/A'
				: `${firstName === 'null' ? '' : firstName} ${lastName === 'null' ? '' : lastName
					}`.trim();
		return (
			<Group noWrap>
				<Checkbox
					checked={selected}
					onChange={() => null}
					tabIndex={-1}
					sx={{ pointerEvents: 'none' }}
				/>
				<div style={{ flex: 1 }}>
					<Text size='sm' weight={500}>
						{name}
					</Text>
					<Text size='xs' color='dimmed' weight={400}>
						{email}
					</Text>
				</div>
			</Group>
		);
	};

	return (
		<Modal
			size={'xl'}
			title={<ModalTitle text={'Manage Users'} />}
			opened={opened}
			onClose={() => setOpened(false)}
		>
			<Stack spacing={'xs'}>
				<Group position={'left'}>
					{/*<Text>*/}
					{/*{`This belongs to ${owner?.firstName} ${owner?.lastName} `}*/}
					<Text size='sm' color='dimmed' weight={400} span>
						{`Collection owner: ${owner?.firstName} ${owner?.lastName} - ${owner?.email}`}
					</Text>
					{/*</Text>*/}
				</Group>

				<Text size={'sm'}>
					Add users to the right list and remove users to the left.
				</Text>
				<TransferList
					value={data}
					onChange={setData}
					searchPlaceholder='Search users...'
					titles={['Users in your organization', 'Current/New Users']}
					breakpoint='sm'
					itemComponent={ItemComponent}
					nothingFound='Nothing found'
					placeholder='No users left'
					listHeight={400}
					radius={'lg'}
				/>
				<Group position={'right'}>
					<PrimaryButton
						loading={loading}
						text={'Save'}
						clickFunc={async () => {
							setLoading(true);
							await handleUpdate(data);
							setLoading(false);
						}}
					/>
				</Group>
			</Stack>
		</Modal>
	);
}
