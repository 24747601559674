import React, { useEffect, useRef, useState } from 'react';
import {
	Button,
	Container,
	createStyles,
	Group,
	Loader,
	Modal,
	Pagination,
	ScrollArea,
	Stack,
	Text,
	TextInput,
	Title,
} from '@mantine/core';
import { IMeeting } from '../../../../interfaces/meeting';
import { NavigateParams } from '../../../../components/Meetings/Meetings';
import { encode } from 'js-base64';
import { useParams, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	addMeetingToCollectionModalOpenedAtom,
	meetingBeingAddedToCollection,
} from '../../../../Atoms/collections';
import { updateCollectionContent } from '../../../../api/collections';
import { logger } from '../../../../helpers/logger';
import { user } from '../../../../Atoms/userAtoms';
import { searchMeetings } from '../../../../api/api';
import ReelayLogoPageLoader from '../../../../components/loaders/ReelayLogoPageLoader';
import { useDebouncedValue } from '@mantine/hooks';
import AddMeetingToCollectionTable from './components/AddMeetingToCollectionTable';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import { useCollections } from '../../../../customHooks/useCollections';
import axios from 'axios';
import ModalTitle from '../../../../components/Titles/ModalTitle';
import { addViewsToMeetings } from '../../../../helpers/meetings';
import { useMediaQuery } from 'react-responsive';

interface Props {
	opened: boolean;
	setOpened: React.Dispatch<React.SetStateAction<boolean>>;
	rowsPerPage: number;
	meetingCount: number;
	fetchingMeetings: boolean;
	setCollectionMeetings: React.Dispatch<React.SetStateAction<IMeeting[]>>;
}

const useStyles = createStyles(() => ({
	outerContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	topContainer: {
		// flex: 1,
	},
	bottomContainer: {
		height: 'auto',
	},
}));

export default function AddMeetingToCollectionModal({
	setCollectionMeetings,
}: Props) {
	const { classes } = useStyles();
	const { collectionID } = useParams();
	const smallHeight = useMediaQuery({
		query: `(max-height: 850px)`,
	});
	const { collection, setCollection } = useCollections(false);
	const { content } = collection;
	const [searchParams] = useSearchParams();
	const skipParam = searchParams.get('skip');
	const [skip, setSkip] = useState(
		isNaN(skipParam as unknown as number) ? 0 : Number(skipParam)
	);
	const [buttonLoading, setButtonLoading] = useState(false);
	const [
		addMeetingToCollectionModalOpened,
		setAddMeetingToCollectionModalOpened,
	] = useRecoilState(addMeetingToCollectionModalOpenedAtom);
	const [meetingToAddToCollection, setMeetingToAddToCollection] =
		useRecoilState(meetingBeingAddedToCollection);
	const [selectedCollection, setSelectedCollection] = useState('');

	const [search, setSearch] = useState('');
	const [debounced] = useDebouncedValue(search, 400);
	const [initialLoad, setInitialLoad] = useState(true);
	const currentUser = useRecoilValue(user);

	// const setSegmentValue = useSetRecoilState(meetingSegmentedControlValue);
	const viewport = useRef<HTMLDivElement>(null);

	const changePage = (value: number) => {
		navigateTo({
			segment: 'all',
			skip: (value > 0 ? value - 1 : 0) * limit,
			limit,
			search: encode(search),
		});
	};

	const handleSearch = (value: string) => {
		return navigateTo({
			// segment: currentSegmentValue,
			segment: 'all',
			search: encode(value),
		});
	};

	const navigateTo = (params: NavigateParams) => {
		const { skip, limit, order, search } = params;
		getMeetings({
			search,
			skip,
			limit,
			order,
		});
	};

	const [isInitialized, setInitialized] = useState(false);

	useEffect(() => {
		// Create a CancelToken source for each API call
		const source = axios.CancelToken.source();
		const loadPage = async () => {
			try {
				await refresh(source);
				setInitialized(true);
			} catch (error) {
				logger('error', 'Error getting meetings', error);
			}
		};
		loadPage();

		return () => {
			source.cancel('Component unmounted');
			setSelection([]);
		};
	}, [content]);

	const refresh = async (source: any) => {
		const currentSkip = (page - 1) * limit;
		await getMeetings(
			{
				skip,
				limit,
				order,
				search,
			},
			source
		);
		scrollToTop();
	};

	const scrollToTop = () => {
		if (viewport?.current) {
			viewport.current.scrollTo({ top: 0, behavior: 'smooth' });
		}
	};

	const [total, setTotal] = useState(0);
	const [data, setData] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(20);
	const [selection, setSelection] = useState([]);
	const order = '-publishedAt';

	useEffect(() => {
		setLimit(smallHeight ? 10 : 20);
	}, [smallHeight]);

	const getMeetings = async (params: any, source?: any) => {
		setLoading(true);
		const { searchValue = '', skip = 0 } = params || {};

		setPage(Math.floor(skip / limit) + 1);

		const filter = {
			status: undefined,
			isExpired: undefined,
		};

		const query = {
			organizationID: currentUser.currentOrganizationID,
			...filter,
			search,
			limit: params.limit || 20,
			offset: isNaN(params.skip) ? 0 : Number(params.skip),
			order,
			collectionID,
		};
		const response = await searchMeetings(query, source);
		const { count, data } = response?.data || {};

		// filter meetings that are already in the collection
		const meetingsNotInCollection = data.filter((meeting: IMeeting) => {
			const meetingAlreadyInCollection = content.find(
				(item) => item.meetingID === meeting.id
			);
			return !meetingAlreadyInCollection;
		});
		const meetingsWithViews = await addViewsToMeetings(meetingsNotInCollection);
		// update actual count
		const actualCount = meetingsNotInCollection.length;
		setSkip(skip);
		setTotal(count);
		if (meetingsNotInCollection !== undefined) setData(meetingsWithViews);
		setLoading(false);
	};

	const renderContent = () => {
		if (!isInitialized) {
			<ReelayLogoPageLoader />;
		} else {
			return (
				<AddMeetingToCollectionTable
					meetings={data}
					searchValue={search}
					meetingCount={total}
					// activePage={page}
					// setActivePage={setActivePage}
					rowsPerPage={limit}
					fetchingMeetings={false}
					selection={selection}
					setSelection={setSelection}
					loading={isLoading}
				/>
			);
		}
	};

	const closeModalAndClearSelect = () => {
		setAddMeetingToCollectionModalOpened(false);
		setSelectedCollection('');
	};

	useEffect(() => {
		if (initialLoad) setInitialLoad(false);

		if (!initialLoad) debouncedHandleInputChange(debounced);
	}, [debounced]);

	// Create a debounced version of handleInputChange with a 300ms delay
	const debouncedHandleInputChange = async (value: string) => {
		if (typeof handleSearch === 'function') {
			handleSearch(value);
		}
	};

	const handleAddMeetingsToCollection = async () => {
		try {
			setButtonLoading(true);
			const res = await updateCollectionContent(
				collection.id,
				selection.map((meetingID) => ({ meetingID }))
			);
			const content = res?.data?.data;
			setCollection((current) => ({
				...current,
				content: [
					...current.content,
					...(Array.isArray(content) ? content : []),
				],
			}));
			const newMeetingsAdded = data.filter((meeting) =>
				selection.includes(meeting.id)
			);
			setCollectionMeetings((current) => [...current, ...newMeetingsAdded]);
			showSuccessNotification({
				title: 'Success!',
				message: 'Meetings added to collection.',
			});
			setAddMeetingToCollectionModalOpened(false);
		} catch (error) {
			logger('error', 'Error adding meeting to collection', error);
			showFailureNotification({
				message:
					'Failed to add meetings to collection: ' + error?.response?.data ||
					'',
			});
		} finally {
			setButtonLoading(false);
		}
	};

	return (
		<Modal
			// h={600}
			size={'80%'}
			title={<ModalTitle text='Add Meeting' />}
			opened={addMeetingToCollectionModalOpened}
			onClose={closeModalAndClearSelect}
		>
			<Container className={classes.outerContainer} size={'xl'} pb={'lg'}>
				<Stack className={classes.topContainer} spacing={'sm'}>
					<Group position={'apart'} align={'center'} spacing={'md'} noWrap>
						<Group align={'center'} style={{ flex: 2 }}>
							<TextInput
								style={{ flex: 2 }}
								placeholder={'Search by meeting name...'}
								onChange={(e) => {
									setSearch(e.target.value);
								}}
								w={'100%'}
								value={search}
								rightSection={<></>}
								onBlur={(e) => {
									e.preventDefault();
								}}
							/>
						</Group>
					</Group>
				</Stack>

				<div
					style={{
						flex: 2,
						width: '100%',
						margin: 0,
						padding: 0,
						paddingTop: '16px',
					}}
				>
					<ScrollArea
						type='always'
						viewportRef={viewport}
						h={smallHeight ? 300 : 600}
						pb={'sm'}
						styles={() => ({
							viewport: {
								height: '100%',
								position: 'relative',
							},
							scrollbar: {
								right: 0,
							},
							thumb: {
								right: 0,
							},
						})}
					>
						{renderContent()}
					</ScrollArea>
				</div>

				<Group position={'apart'} noWrap>
					<Group position='right'>
						<Pagination
							withEdges
							value={page}
							onChange={changePage}
							total={Math.ceil(total / limit)}
						/>
						{total === 0 ? null : (
							<Text mt={0} color='secondary-text' align={'center'} size={12}>
								{`${skip}-${
									skip + limit > total ? total : skip + limit
								} of ${total}`}
							</Text>
						)}
					</Group>
					<Button
						onClick={handleAddMeetingsToCollection}
						loading={buttonLoading}
						disabled={!selection.length}
					>
						Add
					</Button>
				</Group>
			</Container>
		</Modal>
	);
}
