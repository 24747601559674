import { useEffect, useCallback, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentUser, userOrganizationMembers } from '../Atoms/userAtoms';
import { getOrganization } from '../api/api';
import {
	getAllSeats,
	getReceipts,
	getRenewalInfo,
	getSubscription,
} from '../api/billing';
import { organizationAtom } from '../Atoms/organizationAtom';
import { stripeSubscriptionAtom } from '../Atoms/billing';
import { showFailureNotification } from '../helpers/notifications';
import { mapSeatsToMembers } from '../helpers/seats';
import { isUserAdmin } from '../helpers/auth';
import { useLocation } from 'react-router';

const useStripe = (fetchDetails: boolean) => {
	const location = useLocation();
	const isAdmin = location.pathname.includes('/admin');
	const user = useRecoilValue(currentUser);
	const [organizationState, setOrganization] = useRecoilState(organizationAtom);
	const [subscription, setSubscription] = useRecoilState(
		stripeSubscriptionAtom
	);
	const [members, setUserOrganizationMembers] = useRecoilState(
		userOrganizationMembers
	);
	const [isLoading, setIsLoading] = useState(false);
	const isAdminRole = isUserAdmin(user.roles);

	const fetchSubscriptionDetails = useCallback(
		async (signal: AbortSignal) => {
			if (!user.currentOrganizationID || !fetchDetails || isAdmin) return;

			setIsLoading(true);
			try {
				let organization = organizationState;

				organization = await getOrganization(user.currentOrganizationID);
				setOrganization(organization);

				const promises = [getSubscription(organization.subscriptionId, signal)];

				if (isAdminRole) {
					promises.push(getReceipts(organization.subscriptionId, signal));
					promises.push(getRenewalInfo(organization.subscriptionId, signal));
				}

				const results = await Promise.allSettled(promises);

				const subscriptionData =
					results[0].status === 'fulfilled' ? results[0].value : null;
				const receipts =
					results.length > 1 && results[1].status === 'fulfilled'
						? results[1].value
						: { receipts: [] };
				const renewal =
					results.length > 2 && results[2].status === 'fulfilled'
						? results[2].value
						: subscription.renewal;

				if (subscriptionData) {
					const flattenedRenewal = {
						...subscriptionData,
						receipts: receipts.receipts,
						renewal,
					};

					setSubscription(flattenedRenewal);

					const seats = await getAllSeats(subscriptionData.id);

					// Update userOrganizationMembers with seats status using the seats library
					setUserOrganizationMembers((prevMembers) =>
						mapSeatsToMembers(prevMembers, seats)
					);
				}
			} catch (error: any) {
				if (error.name === 'AbortError') {
					console.info('Fetch subscription details aborted');
				} else {
					console.error('Failed to get subscription details:', error);
					showFailureNotification({
						title: 'Failed to get subscription details',
						message:
							error.message ||
							'An error occurred while fetching subscription details.',
					});
				}
			} finally {
				setIsLoading(false);
			}
		},
		[user.currentOrganizationID, setUserOrganizationMembers]
	);

	useEffect(() => {
		const abortController = new AbortController();
		fetchSubscriptionDetails(abortController.signal);

		return () => {
			abortController.abort();
		};
	}, [fetchSubscriptionDetails]);

	const renewal = subscription?.renewal;
	const hasPaymentMethod = !!(renewal && renewal.renewalDate && renewal.amount);

	return {
		subscription,
		setSubscription,
		isLoading,
		hasPaymentMethod,
		members,
	};
};

export default useStripe;
