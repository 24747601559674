// filter highlights list by the chip group selected.
// only when chip is selected, highlights of that type will display.
import { IHighlight } from '../interfaces/highlight';
import { IAction } from '../interfaces/action';

export const initialHighlightChipGroupValue = [
	'highlight',
	'action',
	'question',
	// 'decision',
	// 'shoutout',
	// 'note',
];

export interface HighlightType {
	value: string;
	label: string;
	color: string;
	hexColor: string;
}

// export const highlightTypeChipSet: HighlightType[] = [
// 	{
// 		value: 'highlight',
// 		label: 'Highlight',
// 		color: 'indigo',
// 		hexColor: '#4c6ef5',
// 	},
// 	{
// 		value: 'action',
// 		label: 'Action',
// 		color: 'teal',
// 		hexColor: '#12b886',
// 	},
// 	{
// 		value: 'decision',
// 		label: 'Decision',
// 		color: 'lime',
// 		hexColor: '#82c91e',
// 	},
// 	{
// 		value: 'shoutout',
// 		label: 'Shoutout',
// 		color: '',
// 		hexColor: '#3b368f',
// 	},
// ];

export const momentTypeChipSet: HighlightType[] = [
	{
		value: 'highlight',
		label: 'Highlight',
		color: 'indigo',
		hexColor: '#4c6ef5',
	},
	{
		value: 'action',
		label: 'Action',
		color: 'teal',
		hexColor: '#12b886',
	},
	{
		value: 'question',
		label: 'Question',
		color: 'lime',
		hexColor: '#82c91e',
	},
];

export const momentChipSet: HighlightType[] = [
	{
		value: 'highlight',
		label: 'Highlight',
		color: 'indigo',
		hexColor: '#4c6ef5',
	},
	{
		value: 'question',
		label: 'Question',
		color: 'lime',
		hexColor: '#82c91e',
	},
];

export const actionChipSet: HighlightType[] = [
	{
		value: 'action',
		label: 'Action',
		color: 'teal',
		hexColor: '#12b886',
	},
];

export const highlightTypeLabels = {
	highlight: 'Highlight',
	action: 'Action Item',
	question: 'Question',
	decision: 'Decision',
	shoutout: 'Shoutout',
	note: 'Note',
};

export const visibilityLabels = {
	personal: 'Personal',
	group: 'Public',
	private: 'Private',
};

export const filterHighlightsByChipGroup = (
	highlights: (IHighlight | IAction)[],
	highlightsSelected
) => {
	return highlights.filter((highlight) =>
		highlightsSelected.includes(highlight.type)
	);
};

export const sortHighlightsByTimeStartMS = (arr: (IHighlight | IAction)[]) =>
	arr.sort((a, b) => a.timeStartMS - b.timeStartMS);

export const sortActionsByTimeStartMS = (arr: IAction[]) =>
	arr.sort((a, b) => a.timeStartMS - b.timeStartMS);
