import { Collection } from '../Atoms/collections';
import { CollectionTableProps } from '../pages/collections/components/CollectionTable';

export const transformCollectionsToTableProps = (
	collections: Collection[]
): CollectionTableProps[] => {
	return collections.map((collection) => ({
		id: collection.id,
		name: collection.name,
		owner: collection.owner,
		lastModified: collection.updatedAt,
	}));
};
