import { DateTime } from 'luxon';

// Define a list of American and European time zones with standard time labels
const commonTimeZones = [
	// American Time Zones
	{ tz: 'America/New_York', label: 'Eastern Standard Time' }, // Representing EST
	{ tz: 'America/Chicago', label: 'Central Standard Time' }, // Representing CST
	{ tz: 'America/Denver', label: 'Mountain Standard Time' }, // Representing MST
	{ tz: 'America/Phoenix', label: 'Mountain Standard Time (Arizona)' }, // No DST
	{ tz: 'America/Los_Angeles', label: 'Pacific Standard Time' }, // Representing PST
	{ tz: 'America/Anchorage', label: 'Alaska Standard Time' }, // Representing AKST
	{ tz: 'Pacific/Honolulu', label: 'Hawaii-Aleutian Standard Time' }, // Representing HAST
	// European Time Zones (Choosing central or significant locations for each time zone)
	{ tz: 'Europe/London', label: 'Greenwich Mean Time' }, // UK and Ireland
	{ tz: 'Europe/Lisbon', label: 'Western European Time' }, // Portugal, western Spain
	{ tz: 'Europe/Berlin', label: 'Central European Time' }, // Central for CET
	{ tz: 'Europe/Athens', label: 'Eastern European Time' }, // Central for EET
	{ tz: 'Europe/Istanbul', label: 'Turkey Time' }, // Turkey
	{ tz: 'Europe/Moscow', label: 'Moscow Standard Time' }, // Russia (Moscow Time Zone)
	// Asian Time Zones
	{ tz: 'Asia/Tokyo', label: 'Japan Standard Time' },
	{ tz: 'Asia/Shanghai', label: 'China Standard Time' },
	{ tz: 'Asia/Kolkata', label: 'India Standard Time' },
	{ tz: 'Asia/Dubai', label: 'Gulf Standard Time' },
	{ tz: 'Asia/Bangkok', label: 'Indochina Time' },
	// African Time Zones
	{ tz: 'Africa/Johannesburg', label: 'South Africa Standard Time' },
	{ tz: 'Africa/Lagos', label: 'West Africa Time' },
	{ tz: 'Africa/Cairo', label: 'Eastern European Time (Africa)' }, // Egypt, represents EET in Africa
	// Australian Time Zones
	{ tz: 'Australia/Sydney', label: 'Australian Eastern Standard Time' },
	{ tz: 'Australia/Perth', label: 'Australian Western Standard Time' },
	{ tz: 'Australia/Adelaide', label: 'Australian Central Standard Time' },
	// South American Time Zones
	{ tz: 'America/Sao_Paulo', label: 'Brasilia Standard Time' },
	{ tz: 'America/Buenos_Aires', label: 'Argentina Standard Time' },
	{ tz: 'America/Santiago', label: 'Chile Standard Time' },
	// Additional Zones
	{ tz: 'Pacific/Auckland', label: 'New Zealand Standard Time' },
	{ tz: 'Atlantic/Reykjavik', label: 'Greenwich Mean Time (Iceland)' }, // Iceland, no DST
];

// Function to format the offset
function formatOffset(offset) {
	const sign = offset < 0 ? '-' : '+'; // Determine the sign based on the offset value
	const absOffset = Math.abs(offset);
	const hours = Math.floor(absOffset / 60);
	const minutes = absOffset % 60;
	return `(${sign}${hours}:${String(minutes).padStart(2, '0')})`;
}

// Map to include formatted offsets
const timeZonesWithOffsets = commonTimeZones.map(({ tz, label }) => {
	const now = DateTime.now().setZone(tz);
	const offset = formatOffset(now.offset); // Use the custom formatOffset function
	return {
		value: tz,
		label: `${label} ${offset}`,
		group: 'Common Time Zones',
	};
});

const timeZones = (Intl as any)
	.supportedValuesOf('timeZone')
	.filter((tz: string) => !commonTimeZones.some((t) => t.tz === tz))
	.map((tz: string) => ({
		value: tz,
		label: tz.replace(/_/g, ' '),
		group: 'All Time Zones',
	}));

export const timezones = [...timeZonesWithOffsets, ...timeZones];
