import React, { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { mobileTimestamp } from '../../Atoms/meetingAtom';
import { sendAnalyticEvent } from '../../api/api';
import { MILLISECONDS_PER_SECOND, WATCH_DURATION } from '../constants';
import { IMeeting } from '../../interfaces/meeting';
import { currentUser } from '../../Atoms/userAtoms';
import { useMantineTheme } from '@mantine/core';
import Hls from 'hls.js';
import { useMediaQuery } from 'react-responsive';
import { useSearchParams } from 'react-router-dom';

export interface Props {
	playbackID: string;
	meeting: IMeeting;
}

export default function ReelayMobilePlayer({ playbackID, meeting }: Props) {
	const theme = useMantineTheme();
	const { videoMetadata } = meeting;
	const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });
	const playerRef = useRef<HTMLVideoElement | null>(null);
	const user = useRecoilValue(currentUser);
	const timestampValue = useRecoilValue(mobileTimestamp);
	const [sentWatchedEvent, setSentWatchedEvent] = useState(false);
	const [pageLoaded, setPageLoaded] = useState(false);
	const [searchParams] = useSearchParams();
	const sharingToken = searchParams.get('vt');

	useEffect(() => {
		if (Hls.isSupported()) {
			const hls = new Hls();
			hls.loadSource(videoMetadata.playbackURL);
			hls.attachMedia(playerRef.current);
		}
	}, [meeting]);

	useEffect(() => {
		const listenToTimestampValueChanges = async () => {
			if (playerRef && playerRef.current) {
				setPageLoaded(true);
				if (pageLoaded) playerRef.current.play();
				playerRef.current.currentTime = timestampValue.time;
			}
		};
		listenToTimestampValueChanges();
	}, [timestampValue]);

	useEffect(() => {
		if (Object.keys(meeting).length) {
			playerRef.current.addEventListener('loadedmetadata', function () {
				playerRef?.current?.addEventListener('play', fireOnVideoStart);
				// Code to run when the video starts playing
			});
		}

		return playerRef.current.removeEventListener('play', fireOnVideoStart);
	}, [meeting]);

	useEffect(() => {
		if (Object.keys(meeting).length) {
			playerRef.current.addEventListener('loadedmetadata', function () {
				playerRef?.current?.addEventListener('pause', fireOnVideoPause);
				// Code to run when the video starts playing
			});
		}

		return playerRef.current.removeEventListener('pause', fireOnVideoPause);
	}, [meeting]);

	useEffect(() => {
		if (Object.keys(meeting).length) {
			playerRef.current.addEventListener('loadedmetadata', function () {
				playerRef?.current?.addEventListener('timeupdate', handleTimeUpdate);
			});
		}

		return playerRef.current.removeEventListener(
			'timeupdate',
			handleTimeUpdate
		);
	}, [meeting]);

	function handleTimeUpdate(event) {
		if (event.target.currentTime > WATCH_DURATION && !sentWatchedEvent) {
			sendAnalyticEvent(
				{
					name: 'reelay.video',
					sourceID: meeting?.id,
					targetID: meeting?.videoMetadata?.id,
					data: {
						type: 'watched',
						startTime: 0,
						endTime: event.target.currentTime * MILLISECONDS_PER_SECOND,
					},
				},
				meeting?.organizationID,
				sharingToken
			);
			playerRef.current.removeEventListener('timeupdate', handleTimeUpdate);
			setSentWatchedEvent(true);
		}
	}

	const fireOnVideoStart = () => {
		if (playerRef?.current?.currentTime) {
			// Send play event analytic when the video starts/resumes playing
			sendAnalyticEvent(
				{
					name: 'reelay.video',
					sourceID: meeting?.id,
					targetID: meeting?.videoMetadata?.id,
					data: {
						type: 'play',
						startTime:
							playerRef?.current?.currentTime * MILLISECONDS_PER_SECOND,
					},
				},
				meeting?.organizationID,
				sharingToken
			);
		}
	};

	const fireOnVideoPause = () => {
		if (playerRef?.current?.currentTime) {
			// send pause event when the video pauses
			sendAnalyticEvent(
				{
					name: 'reelay.video',
					sourceID: meeting?.id,
					targetID: meeting?.videoMetadata?.id,
					data: {
						type: 'pause',
						startTime:
							playerRef?.current?.currentTime * MILLISECONDS_PER_SECOND,
					},
				},
				meeting?.organizationID,
				sharingToken
			);
		}
	};

	return (
		<video
			autoPlay
			controls
			ref={playerRef}
			style={{
				borderRadius: isLandscape ? 16 : 0,
				borderTopRightRadius: isLandscape ? 16 : 0,
				borderTopLeftRadius: isLandscape ? 16 : 0,
				height: isLandscape ? 300 : 'auto',
				maxHeight: isLandscape ? 300 : 'auto',
				width: '100%',
			}}
			playsInline
		></video>
	);
}
