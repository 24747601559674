import { atom } from 'recoil';

export const filestackModalOpenedAtom = atom<boolean>({
	key: 'filestackModalOpenedAtom',
	default: false,
});

export const usingUploadMeetingButtonAtom = atom<boolean>({
	key: 'usingUploadMeetingButtonAtom',
	default: false,
});

export const filestackUploadingAtom = atom<boolean>({
	key: 'filestackUploadingAtom',
	default: false,
});
