import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();

export const navbarOpenedAtom = atom<boolean>({
	key: 'navbarOpenedAtom',
	default: false,
});

// export const webOrMobileChapters = atom<boolean>({
//   key: 'webOrMobileChapters',
//   default: true,
//   // eslint-disable-next-line camelcase
//   effects_UNSTABLE: [persistAtom],
// });
