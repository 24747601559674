import { Box, Text, Avatar, Group, createStyles, Stack } from '@mantine/core';
import svg from '../../../assets/images/testimonial-background.svg';

const useStyles = createStyles((theme) => ({
	testimonialBox: {
		backgroundImage: `url(${svg})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		borderTopRightRadius: theme.radius.md,
		borderBottomRightRadius: theme.radius.md,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		width: '100%',
		padding: 0,
		margin: 0,
	},
	whiteCard: {
		backgroundColor: theme.white,
		padding: theme.spacing.xl,
		borderRadius: theme.radius.md,
		boxShadow: theme.shadows.md,
		width: '80%',
		height: '80%',
	},
	stars: {
		fontSize: 24,
	},
	avatarGroup: {
		marginTop: theme.spacing.lg,
	},
	nameText: {
		fontSize: '16px',
		fontWeight: 500,
		textAlign: 'left',
	},
	titleText: {
		fontSize: '11px',
		fontStyle: 'italic',
		fontWeight: 400,
		textAlign: 'left',
		color: '#7F7F7F',
	},
	quoteText: {
		fontSize: '16px',
		fontStyle: 'italic',
		fontWeight: 500,
		textAlign: 'center',

		// Use Mantine's 'md' breakpoint for responsive text resizing
		[`@media (max-width: ${theme.breakpoints.lg})`]: {
			fontSize: '16px', // Set the font size to 16px on smaller screens
		},
	},
}));

interface Props {
	testimonial: any;
}

const Testimonial = ({ testimonial }: Props) => {
	const { classes } = useStyles();

	return (
		<Box className={classes.testimonialBox}>
			{testimonial && (
				<Stack
					spacing={0}
					align='center'
					className={classes.whiteCard}
					justify='space-between'
				>
					<Text className={classes.stars}>
						{'⭐'.repeat(testimonial.stars)}
					</Text>
					<Text
						className={classes.quoteText}
						style={{
							fontSize: testimonial.testimonial.length > 275 ? '14px' : '16px',
						}}
					>
						"{testimonial.testimonial}"
					</Text>
					<Group
						position='center'
						spacing='xs'
						className={classes.avatarGroup}
						noWrap
					>
						<Avatar
							src={testimonial.avatarUrl || 'https://via.placeholder.com/150'}
							alt={testimonial.name}
							size='lg'
							radius='xl'
						/>
						<Stack spacing={0}>
							<Text className={classes.nameText}>{testimonial.name}</Text>
							<Text className={classes.titleText}>
								{testimonial.role}, {testimonial.organization}
							</Text>
						</Stack>
					</Group>
				</Stack>
			)}
		</Box>
	);
};

export default Testimonial;
