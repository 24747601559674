import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay } from '@fortawesome/pro-solid-svg-icons';
import { createStyles, Group, Text } from '@mantine/core';
import { calculateTimeStamp } from '../../../../_utils/time';
import { IHighlight } from '../../../../interfaces/highlight';
import { IAction } from '../../../../interfaces/action';

const useStyles = createStyles((theme) => ({
	cardText: {
		fontSize: 12,
		color: theme.colors['secondary-text'][0],
	},
	playButton: {
		cursor: 'pointer',
	},
}));

interface Props {
	highlight: IHighlight | IAction;
	handlePlayButton: (highlight: { ms: number; highlightID: string }) => void;
}
export default function PlayButtonWithTimestamp({
	handlePlayButton,
	highlight,
}: Props) {
	const { classes, theme } = useStyles();
	return (
		<Group position={'apart'} spacing={4}>
			<FontAwesomeIcon
				icon={faPlay}
				size={'xs'}
				color={'secondary-text'}
				className={classes.playButton}
				onClick={() =>
					handlePlayButton({
						ms: highlight.timeStartMS,
						highlightID: highlight.id,
					})
				}
			/>
			<Text className={classes.cardText}>
				{calculateTimeStamp(Number(highlight.timeStartMS))}
			</Text>
		</Group>
	);
}
