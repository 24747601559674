import { Button, Group, Modal, Select, Stack } from '@mantine/core';
import { useState } from 'react';
import { User } from '../../../../Atoms/userAtoms';
import { useRecoilValue } from 'recoil';
import { transferMeetingsOwner } from '../../../../api/support';
import { currentMeeting } from '../../../../Atoms/meetingAtom';
import { showSuccessNotification } from '../../../../helpers/notifications';

interface Props {
	opened: boolean;
	setOpened: (opened: boolean) => void;
	orgUsers: User[];
	setRefetchData: (o: any) => void;
}

export default function TransferMeetingOwnerModal({
	opened,
	setOpened,
	orgUsers,
	setRefetchData,
}: Props) {
	const [value, setValue] = useState('');
	const [buttonLoading, setButtonLoading] = useState(false);
	const meeting = useRecoilValue(currentMeeting);

	const data = orgUsers.map((user) => ({
		value: user.id,
		label: `${user.firstName} ${user.lastName} (${user.email})`,
	}));

	const transferOwner = async () => {
		try {
			setButtonLoading(true);
			const user = orgUsers.find(
				(user) => `${user.firstName} ${user.lastName} (${user.email})` === value
			);
			const res = await transferMeetingsOwner(
				[meeting.id],
				user.id,
				user.currentOrganizationID
			);
			showSuccessNotification({
				message: `Reelay owner transferred successfully to ${user.firstName} ${user.lastName} (${user.email})`,
			});
			setRefetchData((o) => !o);
			setOpened(false);
		} catch (error) {
		} finally {
			setButtonLoading(false);
		}
	};

	return (
		<Modal
			opened={opened}
			onClose={() => setOpened(false)}
			title='Transfer Reelay Owner'
		>
			<form onSubmit={transferOwner}>
				<Stack spacing={24}>
					<Select
						label='Select new reelay owner'
						searchable
						placeholder='Pick user'
						data={data}
						searchValue={value}
						onSearchChange={(val) => {
							setValue(val);
						}}
					/>
					<Group noWrap position='right'>
						<Button
							radius={'md'}
							// disabled={value}
							onClick={transferOwner}
							loading={buttonLoading}
						>
							Send
						</Button>
					</Group>
				</Stack>
			</form>{' '}
		</Modal>
	);
}
