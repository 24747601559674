import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import {
	getAttachmentsForMeeting,
	getChaptersByMeetingID,
	getHighlights,
	getMeeting,
	getMeetingAnalytics,
	getMeetingExternalUsers,
	getMeetingInvitedUsers,
	getTranscriptionForMeeting,
	sendAnalyticEvent,
	updateMeeting,
} from '../../api/api';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	currentUser,
	currentUserReelays,
	editingHighlightInitialValuesAtom,
	isProjectManagerAtom,
	user,
	userOrganizationMembers,
} from '../../Atoms/userAtoms';
import {
	createStyles,
	Group,
	Stack,
	Text,
	Paper,
	UnstyledButton,
} from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from '@fortawesome/pro-solid-svg-icons';
import { useDisclosure, useInputState } from '@mantine/hooks';
import {
	meetingShareableLinks,
	meetingsSharedWithMe as SharedMeetingAtom,
	myMeetings as MyMeetingAtom,
	previousPageURL,
	meetingAttachments,
	timestamp,
	currentMeetingDistributionList,
	attendeesList,
	currentMeetingChapters,
	currentMeetingHighlights,
	currentMeeting,
	currentUtterances,
	chatAtom,
	viewingMeetingAtom,
} from '../../Atoms/meetingAtom';

import ReelayLogoPageLoader from '../../components/loaders/ReelayLogoPageLoader';
import { useNavigate } from 'react-router';
import { replaceApostropheHTMLEntityWithSingleQuote } from '../../_utils/handy-functions';
import { logger } from '../../helpers/logger';
import { MobileViewingPage } from '../mobileViewing';
import SharingModal from '../../components/Modals/SharingModal';
import RequestsModal from '../../components/Modals/RequestsModal';
import MyLinksModal from '../../components/Modals/MyLinksModal';
import ManageAccessModal from '../../components/Modals/ManageAccessModal';
import ShareableMeetingLink from '../ShareableMeetingLink';
import AssignActionModal from '../../components/Modals/AssignActionModal';
import MeetingToAddToCollectionModal from '../../components/Modals/MeetingAddToCollectionModal';
import MeetingLeftCard from './components/MeetingLeftCard';
import MeetingRightCard from './components/MeetingRightCard';
import { IHighlight } from '../../interfaces/highlight';
import { IAction } from '../../interfaces/action';
import { sortHighlightsByTimeStartMS } from '../../helpers/highlights';
import { getChatByMeetingID } from '../../api/chat';
import useMediaQueries from '../../customHooks/useMediaQueries';
import { isAuthenticated } from '../../Atoms/auth';

const useStyles = createStyles((theme) => ({
	enabled: {
		cursor: 'pointer',
	},
	disabled: {
		cursor: 'none',
		pointerEvents: 'none',
		color: 'gray',
	},
	publishReelayButton: {
		backgroundColor: theme.colors?.['primary'][1],
		color: theme.colors?.['primary-text'],
	},
}));

export default function Meeting() {
	const { classes, theme } = useStyles();
	const { meetingID, tabValue } = useParams();
	const outerContainerRef = useRef(null);
	const [searchParams, setSearchParams] = useSearchParams();
	const navigate = useNavigate();
	const { widthOrHeightLessThanLG, isTabletOrSmaller } = useMediaQueries();
	const user = useRecoilValue(currentUser);
	const setEditingHighlightInitialValues = useSetRecoilState(
		editingHighlightInitialValuesAtom
	);
	const orgMembers = useRecoilValue(userOrganizationMembers);
	const setDistributionList = useSetRecoilState(currentMeetingDistributionList);
	const setAttendees = useSetRecoilState(attendeesList);
	const [chapters, setChapters] = useRecoilState(currentMeetingChapters);
	const [currentHighlights, setCurrentHighlights] = useRecoilState(
		currentMeetingHighlights
	);
	const setChat = useSetRecoilState(chatAtom);
	// react state
	const [meeting, setMeeting] = useRecoilState(currentMeeting);
	const [viewingMeeting, setViewingMeeting] =
		useRecoilState(viewingMeetingAtom);
	const [name, setName] = useInputState('');
	const [fetchingMeeting, setFetchingMeeting] = useState(true);
	const isAuthorized = useRecoilValue(isAuthenticated);
	const [isUnauthorized, setIsUnauthorized] = useState(false);
	const [isGuestViewing, setIsGuestViewing] = useState(false);

	const [
		sharingModalOpened,
		{ open: openSharingModal, close: closeSharingModal },
	] = useDisclosure(false);
	const [
		requestsModalOpened,
		{ open: openRequestsModal, close: closeRequestsModal },
	] = useDisclosure(false);
	const [
		myLinksModalOpened,
		{ open: openMyLinksModal, close: closeMyLinksModal },
	] = useDisclosure(false);
	const [
		manageAccessModalOpened,
		{ open: openManageAccessModal, close: closeManageAccessModal },
	] = useDisclosure(false);

	const linkList = useRecoilValue(meetingShareableLinks);
	const [previousURL, setPreviousURL] = useRecoilState(previousPageURL);
	const setCurrentAttachments = useSetRecoilState(meetingAttachments);
	const setTimestamp = useSetRecoilState(timestamp);
	const [utterances, setUtterances] = useRecoilState(currentUtterances);
	const [searchValue, setSearchValue] = useState('');
	const sharingToken = searchParams.get('vt');
	const COL_WIDTH = 12;

	useEffect(() => {
		const handleGetMeetingData = async () => {
			try {
				setViewingMeeting(true);
				const meetingRes = await getMeeting(
					meetingID,
					user.currentOrganizationID,
					sharingToken
				);
				const meeting = meetingRes?.data?.data;
				const { id, organizationID, summary, agenda, objective, name } =
					meeting;
				setIsGuestViewing(organizationID !== user.currentOrganizationID);
				const [
					meetingAnalytics,
					attachments,
					transcription,
					meetingHighlights,
					chapters,
					chat,
				] = await Promise.all([
					getMeetingAnalytics(id, user.currentOrganizationID, sharingToken),
					getAttachmentsForMeeting(
						id,
						user.currentOrganizationID,
						sharingToken
					),
					getTranscriptionForMeeting(
						id,
						user.currentOrganizationID,
						sharingToken
					),
					getHighlights(id, user.currentOrganizationID, sharingToken),
					getChaptersByMeetingID(id, user.currentOrganizationID, sharingToken),
					getChatByMeetingID(id, user.currentOrganizationID, sharingToken),
					fetchUsersLists(id, user.currentOrganizationID, sharingToken),
				]);

				setChat(chat?.data?.data || []);

				const utterances = transcription?.data?.data?.transcription?.utterances;
				setUtterances(
					(utterances || []).map((utterance) => ({
						...utterance,
						speaker:
							utterance.speaker === null
								? 'Unknown Speaker'
								: utterance.speaker,
					}))
				);

				setCurrentHighlights(sortHighlightsByTimeStartMS(meetingHighlights));
				setChapters(chapters?.data?.data?.chapters || []);
				goToTimestamp(meetingHighlights);
				setCurrentAttachments(attachments.data.data);

				const views = meetingAnalytics?.data?.data?.users?.length
					? meetingAnalytics?.data?.data?.users?.reduce((prev, cur) => {
							if (cur.viewed) return prev + Number(cur.viewed);
							return prev;
					  }, 0)
					: 0;

				setMeeting({
					...meeting,
					objective: replaceApostropheHTMLEntityWithSingleQuote(objective),
					agenda: replaceApostropheHTMLEntityWithSingleQuote(agenda),
					summary: replaceApostropheHTMLEntityWithSingleQuote(summary),
					name: replaceApostropheHTMLEntityWithSingleQuote(name),
					views,
				});
				setName(replaceApostropheHTMLEntityWithSingleQuote(meeting.name));
			} catch (err) {
				logger('error', 'Error loading meetings for the table', err);
				if (
					err?.response?.status === 403 ||
					err?.response?.data === 'Forbidden'
				)
					setIsUnauthorized(true);
			} finally {
				setFetchingMeeting(false);
			}
		};

		handleGetMeetingData().then(() =>
			logger('info', 'meeting data fetched', { meetingID })
		);
		return () => {
			setPreviousURL('');
			setViewingMeeting(false);
		};
	}, [meetingID]);

	useEffect(() => {
		setEditingHighlightInitialValues({
			content: '',
			type: '',
			visibility: '',
			timeStartMS: 0,
			isTopQuestion: null,
		});
	}, [tabValue]);

	// get audience tab info
	const fetchUsersLists = async (
		meetingID: string,
		organizationID: string,
		sharingToken: string | undefined
	) => {
		logger('info', 'fetching users lists', {
			meetingID,
			organizationID,
		});
		try {
			const users = await Promise.allSettled([
				getMeetingInvitedUsers(
					meetingID,
					organizationID,
					undefined,
					sharingToken
				),
				getMeetingExternalUsers(meetingID, organizationID, sharingToken),
			]);

			const invitedUsers =
				users[0].status === 'fulfilled'
					? users[0].value.data.data.invitedUsers
					: [];
			const externalUsers =
				users[1].status === 'fulfilled'
					? users[1].value.data.data.externalUsers
					: [];
			const existingExternalUsers = externalUsers.map((externalUser) => {
				const existingUser = orgMembers.find(
					(user) =>
						user.firstName === externalUser[1] &&
						user.lastName === externalUser[2]
				);
				if (existingUser) {
					return existingUser;
				}
				return externalUser;
			});
			const attendees = [
				...invitedUsers.filter((user) => user?.userlink?.presented),
				...existingExternalUsers,
			];

			setDistributionList(invitedUsers);
			setAttendees(attendees);
		} catch (error) {
			logger('error', 'error fetching users lists', {
				meetingID: meeting.id,
				organizationID: meeting.organizationID,
				error,
			});
		}
	};

	const goToTimestamp = (highlights: IHighlight[], actions?: IAction[]) => {
		const possibleHighlightParam = searchParams.get('highlight');
		const possibleActionParam = searchParams.get('action');

		if (possibleHighlightParam) {
			const highlight: IHighlight = highlights.find(
				(item) => item.id === possibleHighlightParam
			);
			navigate(`/meetings/${meetingID}/moments`);
			setTimestamp({ time: Math.round(+(highlight.timeStartMS / 1000)) });
		}

		if (possibleActionParam) {
			const action: any = actions.find(
				(item) => item.id === possibleHighlightParam
			);
			navigate(`/meetings/${meetingID}/moments`);
			setTimestamp({ time: Math.round(+(action.timeStartMS / 1000)) });
		}
	};

	return (
		<>
			{isUnauthorized ? (
				<ShareableMeetingLink />
			) : isTabletOrSmaller ? (
				<MobileViewingPage setIsUnauthorized={setIsUnauthorized} />
			) : (
				<div
					style={{
						minHeight: '100%',
						height: '100%',
					}}
				>
					{fetchingMeeting ? (
						<ReelayLogoPageLoader />
					) : (
						<Stack w={'100%'} h={'100%'} spacing={0}>
							{!isAuthorized || isGuestViewing ? null : (
								<div id='sharing-modals'>
									<SharingModal
										opened={sharingModalOpened}
										close={closeSharingModal}
										organizationID={meeting.organizationID}
									/>
									{/* request modal */}
									<RequestsModal
										open={openRequestsModal}
										opened={requestsModalOpened}
										close={closeRequestsModal}
									/>
									{/* My links modal   */}
									<MyLinksModal
										opened={myLinksModalOpened}
										close={closeMyLinksModal}
									/>
									{/* My links modal   */}
									<ManageAccessModal
										opened={manageAccessModalOpened}
										close={closeManageAccessModal}
									/>
									<AssignActionModal />
									<MeetingToAddToCollectionModal />
								</div>
							)}
							<Group
								ref={outerContainerRef}
								mah={'100%'}
								w={'100%'}
								noWrap
								style={{
									flex: 2,
								}}
							>
								<MeetingLeftCard
									isGuestViewing={isGuestViewing}
									outerContainerRef={outerContainerRef}
									name={name}
									setName={setName}
									openManageAccessModal={openManageAccessModal}
									openSharingModal={openSharingModal}
									openMyLinksModal={openMyLinksModal}
									openRequestsModal={openRequestsModal}
								/>
								<MeetingRightCard
									searchValue={searchValue}
									setSearchValue={setSearchValue}
								/>
							</Group>
						</Stack>
					)}
				</div>
			)}
		</>
	);
}
