import axiosInstance from '../axios/axios';
import { logger } from '../helpers/logger';

export const fetchTestimonials = async () => {
	const env = process.env.REACT_APP_ENVIRONMENT;
	const isProduction = env === 'PRODUCTION';
	const spaceId = process.env.REACT_APP_CONTENTFUL_SPACE_ID;
	const environmentId = 'master';
	const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

	const url = `https://cdn.contentful.com/spaces/${spaceId}/environments/${environmentId}/entries`;

	try {
		const response = await axiosInstance.get(url, {
			headers: {
				Authorization: `Bearer ${accessToken}`,
			},
			params: {
				content_type: 'testimonial',
			},
		});

		// Extract testimonials and associated assets
		const testimonials = response.data.items
			.map((item, index) => {
				const assetID = item?.fields?.avatar?.sys?.id;
				const asset = response.data.includes.Asset.find(
					(asset) => asset.sys.id === assetID
				);

				const avatarUrl = asset?.fields.file.url;

				return {
					name: item.fields.customerName,
					role: item.fields.customerRole,
					organization: item.fields.organization,
					testimonial: item.fields.content,
					stars: item.fields.stars,
					avatarUrl: avatarUrl ? `https:${avatarUrl}` : null,
					production: item.fields.production,
				};
			})
			.filter((item) => !isProduction || item.production);

		return testimonials;
	} catch (error) {
		logger('error', 'Failed to fetch testimonials', error);
		return [];
	}
};
