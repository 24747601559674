export const SEAT_LIMIT_TIER_1 = 50;
export const SEAT_LIMIT_TIER_2 = 199;
export const MILLISECONDS_IN_A_SECOND = 1000;
export const SECONDS_IN_A_MINUTE = 60;
export const MINUTES_IN_AN_HOUR = 60;
export const HOURS_IN_A_DAY = 24;
export const PERCENTAGE_MULTIPLIER = 100;
const priceId = process.env.REACT_APP_STRIPE_PRICE_ID;

export const pricingTiers = [
	{
		min: 1,
		max: SEAT_LIMIT_TIER_1,
		price: 10,
		priceId,
	},
	{
		min: SEAT_LIMIT_TIER_1 + 1,
		max: SEAT_LIMIT_TIER_2,
		price: 8,
		priceId,
	},
	{
		min: SEAT_LIMIT_TIER_2 + 1,
		max: Infinity,
		price: 5,
		priceId,
	},
];
