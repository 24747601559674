import React, { useEffect, useState } from 'react';
import { styles } from '../../../settings/tabs/styles';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { userSettings } from '../../../../Atoms/settings';
import { Group, Paper, Stack, Text } from '@mantine/core';
import { UserAvatar } from '../../../../components/avatars/UserAvatar';
import { useParams } from 'react-router';
import {
	SupportLink,
	organizationAtom,
	organizations,
} from '../../../../Atoms/organizationAtom';
import { DangerNoButton } from '../../../../components/Buttons/DangerNoButton';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { unassignSupporterFromOrganization } from '../../../../api/organizations';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../../helpers/notifications';
import { logger } from '../../../../helpers/logger';

interface Props {
	supportUser: SupportLink;
	index: number;
}

export default function SupportUserCard({ supportUser, index }: Props) {
	const { classes } = styles();
	const { organizationID } = useParams();
	const [orgs, setOrgs] = useRecoilState(organizations);
	const setOrg = useSetRecoilState(organizationAtom);
	const currentOrg = orgs.find((org) => org.id === organizationID);
	const [isEditing, setIsEditing] = useState(false);
	const userSettingsData = useRecoilValue(userSettings);
	const [buttonLoading, setButtonLoading] = useState(false);

	const { userAvatarURL, firstName, lastName, urls, email } = supportUser.user;
	const { job_title, address } = userSettingsData;

	const avatarURL =
		'urls' in supportUser
			? `${urls[2]}&` + new Date().getTime()
			: userAvatarURL
			? `${userAvatarURL}-512.png?` + new Date().getTime()
			: '';

	const removeSupportUser = async () => {
		try {
			setButtonLoading(true);
			const res = await unassignSupporterFromOrganization(
				supportUser.userID,
				organizationID
			);
			if (res?.status === 'removed') {
				const updatedOrg = {
					...currentOrg,
					supportLinks: currentOrg?.supportLinks.filter(
						(link) => link.userID !== res.userID
					),
				};
				setOrg(updatedOrg);
				setOrgs((prev) =>
					prev.map((org) => (org.id === organizationID ? updatedOrg : org))
				);
				showSuccessNotification({
					message: `Successfully unassigned ${firstName} ${lastName} from ${currentOrg?.name}.`,
				});
			}
		} catch (error) {
			logger('error', 'Error unassigning support user', error);
			showFailureNotification({
				message: `Sorry, there was an error unassigning ${firstName} ${lastName} from ${currentOrg?.name}. Please try again.`,
			});
		} finally {
			setButtonLoading(false);
		}
	};

	return (
		<Paper className={classes.card} shadow='xs' p='md' radius={'md'}>
			<Group position={'apart'} align='center'>
				<Group>
					<UserAvatar
						firstName={firstName}
						lastName={lastName}
						avatarURL={avatarURL}
						size={50}
					/>
					<Stack justify={'center'} spacing={0}>
						<Text
							color={'primary-text'}
							size={'md'}
							weight={500}
						>{`${firstName} ${lastName}`}</Text>
						<Text size={'sm'} weight={500}>
							{email || 'N/A'}
						</Text>
						<Text size={'sm'}>
							{`Assigned since ${new Date().toLocaleDateString()}`}
						</Text>
					</Stack>
				</Group>
				<Stack justify='center' h='100%'>
					<DangerNoButton
						clickFunc={removeSupportUser}
						text={'Remove'}
						leftIcon={<FontAwesomeIcon icon={faTrash} />}
						radius={'lg'}
						loading={buttonLoading}
					/>
				</Stack>
			</Group>
		</Paper>
	);
}
