import { ActionIcon, Indicator, Popover, useMantineTheme } from '@mantine/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-regular-svg-icons';
import { useRecoilValue } from 'recoil';
import { notifications as notificationsAtom } from '../../../Atoms/notifications';
import { NotificationList } from '../../NotificationList';
import { useMediaQuery } from 'react-responsive';

export const NotificationsPopover = () => {
	const theme = useMantineTheme();
	const notifications = useRecoilValue(notificationsAtom);
	const isMobile = useMediaQuery({
		query: `(max-width: ${theme.breakpoints.xs})`,
	});

	return (
		<>
			<Popover
				width={isMobile ? '100%' : 500}
				withArrow
				shadow='md'
				position='bottom-end'
			>
				<Popover.Target>
					<ActionIcon variant='outline' size={'36px'}>
						{notifications.length > 0 ? (
							<Indicator
								styles={{ indicator: { padding: 2 } }}
								color='red'
								size={15}
								label={notifications.length}
							>
								<FontAwesomeIcon icon={faBell} color='black' />
							</Indicator>
						) : (
							<FontAwesomeIcon icon={faBell} color='black' />
						)}
					</ActionIcon>
				</Popover.Target>
				<Popover.Dropdown>
					<NotificationList notifications={notifications} />
				</Popover.Dropdown>
			</Popover>
		</>
	);
};
