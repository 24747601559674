import axios, { AxiosRequestConfig } from 'axios';
import {
	AssignSeatInput,
	CreateSubscriptionInput,
	UpdateTrialInput,
	ActivateSeatInput,
	DeactivateSeatInput,
	CheckSeatStatusInput,
	GetAllSeatsInput,
} from '../Atoms/billing';
import { Organization } from '../Atoms/organizationAtom';

const billingApi = axios.create({
	baseURL: process.env.REACT_APP_BILLING_API_BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

export const createSubscription = async (
	subscriptionData: CreateSubscriptionInput,
	signal?: AbortSignal
) => {
	try {
		const config: AxiosRequestConfig = { signal };
		const response = await billingApi.post(
			'/subscriptions',
			subscriptionData,
			config
		);
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
			console.log('Request cancelled:', error);
		} else {
			console.error('Error creating subscription:', error);
		}
		throw error;
	}
};

export const getCustomer = async (customerId: string, signal?: AbortSignal) => {
	try {
		const config: AxiosRequestConfig = { signal };
		const response = await billingApi.get(`/customers/${customerId}`, config);
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error('Error fetching customer:', error);
		}
		throw error;
	}
};

export const getSubscription = async (
	subscriptionId: string,
	signal?: AbortSignal
) => {
	try {
		const config: AxiosRequestConfig = { signal };
		const response = await billingApi.get(
			`/subscriptions?subscriptionId=${subscriptionId}`,
			config
		);
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error('Error fetching subscription:', error);
		}
		throw error;
	}
};

export const getRenewalInfo = async (
	subscriptionId: string,
	signal?: AbortSignal
) => {
	try {
		const config: AxiosRequestConfig = { signal };
		const response = await billingApi.get(
			`/subscriptions/renewal?subscriptionId=${subscriptionId}`,
			config
		);

		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error('Error fetching renewal info:', error);
		}
		throw error;
	}
};

export const getReceipts = async (
	subscriptionId: string,
	signal?: AbortSignal,
	limit?: number
) => {
	try {
		const config: AxiosRequestConfig = {
			params: { subscriptionId, limit },
			signal,
		};
		const response = await billingApi.get(`/subscriptions/receipts`, config);

		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error('Error fetching receipts:', error);
		}
		throw error;
	}
};

export const assignSeat = async (assignSeatData: AssignSeatInput) => {
	try {
		const response = await billingApi.post('/seats/assign', assignSeatData);
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error('Error assigning seat:', error);
		}
		throw error;
	}
};

export const updateTrial = async (trialData: UpdateTrialInput) => {
	try {
		const config: AxiosRequestConfig = {};
		const response = await billingApi.put(
			'/subscriptions/trial',
			trialData,
			config
		);
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error('Error updating trial:', error);
		}
		throw error;
	}
};

export const updateSeatsNumber = async (updateSeatsNumberData: {
	subscriptionId: string;
	seats: number;
}) => {
	const response = await billingApi.put(
		'/subscriptions/updateSeatsNumber',
		updateSeatsNumberData
	);
	return response.data;
};

/**
 * Retrieve all seats for a given subscription.
 * @param subscriptionId - The ID of the subscription.
 * @returns A list of seats.
 */
export const getAllSeats = async (subscriptionId: string) => {
	try {
		const config: AxiosRequestConfig = {
			params: { subscriptionId },
		};
		const response = await billingApi.get<GetAllSeatsInput, { data: any }>(
			'/seats',
			config
		);
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error('Error fetching all seats:', error);
		}
		throw error;
	}
};

/**
 * Activate a specific seat.
 * @param activateSeatData - Data required to activate a seat.
 * @param signal - Optional AbortSignal for request cancellation.
 * @returns The updated seat information.
 */
export const activateSeat = async (activateSeatData: ActivateSeatInput) => {
	try {
		const response = await billingApi.put<ActivateSeatInput>(
			'/seats/activate',
			activateSeatData
		);
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error('Error activating seat:', error);
		}
		throw error;
	}
};

/**
 * Deactivate a specific seat.
 * @param deactivateSeatData - Data required to deactivate a seat.
 * @returns The updated seat information.
 */
export const deactivateSeat = async (
	deactivateSeatData: DeactivateSeatInput
) => {
	try {
		const response = await billingApi.put<DeactivateSeatInput>(
			'/seats/deactivate',
			deactivateSeatData
		);
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error('Error deactivating seat:', error);
		}
		throw error;
	}
};

/**
 * Check the status of a specific seat.
 * @param checkSeatStatusData - Data required to check seat status.
 * @returns The status of the seat.
 */
export const checkSeatStatus = async (
	checkSeatStatusData: CheckSeatStatusInput
): Promise<{ status: string }> => {
	try {
		const config: AxiosRequestConfig = {
			params: {
				subscriptionId: checkSeatStatusData.subscriptionId,
				userId: checkSeatStatusData.userId,
			},
		};
		const response = await billingApi.get<
			CheckSeatStatusInput,
			{ data: { status: string } }
		>('/seats/status', config);
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error('Error checking seat status:', error);
		}
		throw error;
	}
};

/**
 * Connect a Stripe subscription to an organization.
 * @param connectData - Data required to connect the subscription.
 * @returns The updated organization.
 */
export const connectStripeSubscriptionToOrg = async (connectData: {
	organizationId: string;
	subscriptionId: string;
}): Promise<{ organization: Organization }> => {
	try {
		const response = await billingApi.post(
			'/subscriptions/connectStripeSubscriptionToOrg',
			connectData
		);
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error(
				'Error connecting Stripe subscription to organization:',
				error
			);
		}
		throw error;
	}
};

/**
 * Connect a Stripe customer to an organization.
 * @param organizationId - The ID of the organization.
 * @param customerId - The ID of the Stripe customer.
 * @returns The updated organization.
 */
export const connectStripeCustomerToOrg = async (
	organizationId: string,
	customerId: string
): Promise<Organization> => {
	try {
		const response = await billingApi.post(
			'/subscriptions/connectStripeCustomerToOrg',
			{
				organizationId,
				customerId,
			}
		);
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error('Error connecting Stripe customer to organization:', error);
		}
		throw error;
	}
};

export const createManualSubscription = async (payload: {
	organizationId: string;
	numberOfSeats: number;
}) => {
	try {
		const response = await billingApi.post('/subscriptions/manual', payload);
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error('Error creating manual subscription:', error);
		}
		throw error;
	}
};

export const updateManualSubscription = async (payload: {
	subscriptionId: string;
	seats: number;
}) => {
	try {
		const response = await billingApi.put(
			'/subscriptions/manual/seats',
			payload
		);
		return response.data;
	} catch (error) {
		if (axios.isCancel(error)) {
		} else {
			console.error('Error creating manual subscription:', error);
		}
		throw error;
	}
};
