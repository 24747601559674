import React from 'react';
import { User } from '../../../Atoms/userAtoms';
import { Avatar, Group, Text } from '@mantine/core';

interface Props {
	owner: User | 'Unknown';
}

export default function OwnerRow({ owner }: Props) {
	const ownerName =
		owner === 'Unknown' ? 'Unknown' : `${owner.firstName} ${owner.lastName}`;

	if (owner === 'Unknown') {
		return (
			<Group noWrap spacing={'sm'}>
				<Avatar radius='xl' size={'sm'} />
				<Text>Unknown</Text>
			</Group>
		);
	} else {
		const avatarURL =
			'urls' in owner
				? `${owner.urls[2]}?` + new Date().getTime()
				: owner?.userAvatarURL
					? `${owner?.userAvatarURL}-48.png?` + new Date().getTime()
					: '';

		return (
			<Group noWrap spacing={'sm'}>
				{avatarURL ? (
					<Avatar
						src={avatarURL}
						radius='xl'
						alt={'picture of user'}
						size={'sm'}
					/>
				) : (
					<Avatar src={owner?.userAvatarURL} radius='xl' size={'sm'}>
						AH
					</Avatar>
				)}

				<Text>{ownerName}</Text>
			</Group>
		);
	}
}
