import { Group, Paper, Stack, Text, createStyles } from '@mantine/core';
import GeneralSearchBar from '../GeneralSearchBar';
import { Dispatch, SetStateAction, useRef } from 'react';
import MomentsTabs from '../MomentsTabs';
import { IMeeting } from '../../../../interfaces/meeting';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentMeeting } from '../../../../Atoms/meetingAtom';
import { useMediaQuery } from 'react-responsive';
import useMediaQueries from '../../../../customHooks/useMediaQueries';

const HEADER_FONT_SIZE = 20;

const useStyles = createStyles((theme) => ({
	enabled: {
		cursor: 'pointer',
	},
	disabled: {
		cursor: 'none',
		pointerEvents: 'none',
		color: 'gray',
	},
	publishReelayButton: {
		backgroundColor: theme.colors?.['primary'][1],
		color: theme.colors?.['primary-text'],
	},
}));

interface Props {
	searchValue: string;
	setSearchValue: Dispatch<SetStateAction<string>>;
	// handleSearch: (value: string) => void;
}

export default function MeetingRightCard({
	searchValue,
	setSearchValue,
}: // handleSearch,
Props) {
	const cardRef = useRef(null);
	const { widthOrHeightLessThanLG, widthLessThanXL, isLessThan2000 } =
		useMediaQueries();

	const responsiveWidth = widthLessThanXL ? 600 : isLessThan2000 ? 700 : 1000;

	return (
		<Paper
			ref={cardRef}
			h={'100%'}
			w={responsiveWidth}
			miw={responsiveWidth}
			maw={responsiveWidth}
			sx={(theme) => ({
				borderTopLeftRadius: theme.spacing.lg,
				borderTopRightRadius: theme.spacing.lg,
				borderBottomLeftRadius: 0,
				borderBottomRightRadius: 0,
			})}
			p={'xl'}
			style={{
				boxShadow: 'none',
			}}
			// bg={theme.colors['background-gray-2'][0]}
			bg={'#F2F7F8'}
		>
			<Stack w={'100%'} h={'100%'}>
				<Group position={'apart'}>
					<Text size={HEADER_FONT_SIZE} fw={700}>
						Meeting Moments:
					</Text>
				</Group>
				<GeneralSearchBar
					value={searchValue}
					setValue={setSearchValue}
					placeholder='Search this meeting'
					maxWidth={'100%'}
					width={'100%'}
				/>
				<MomentsTabs cardRef={cardRef} searchValue={searchValue} />
			</Stack>
		</Paper>
	);
}
