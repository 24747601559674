import React, { useEffect } from 'react';
import ReelayLogoPageLoader from '../../components/loaders/ReelayLogoPageLoader';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { updateMeetingUserLink } from '../../api/api';
import { logger } from '../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../helpers/notifications';
import { useRecoilValue } from 'recoil';
import { user } from '../../Atoms/userAtoms';

/**
 * This component is to handle the granted access (approve/deny) from the email link
 * example url - https://local.reelay.ai/access-request/?id=MUL_cm5khfd6cgwjm3gfz1xg&status=allow
 * It approves/deny access and redirects to the meetings page. Shows success notification.
 */

export default function DecideAccessRedirect() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const currentUser = useRecoilValue(user);
	const meetingUserLinkID = searchParams.get('id');
	const statusParam = searchParams.get('status');
	// can be 'allow' or 'deny'

	useEffect(() => {
		const updateRequest = async () => {
			try {
				const status = statusParam === 'allow' ? 'active' : 'denied';
				// approve request by setting user meeting link status to active
				const res = await updateMeetingUserLink(
					meetingUserLinkID,
					status,
					currentUser.currentOrganizationID
				);
				logger('info', 'request updated', res);

				showSuccessNotification({
					title:
						status === 'active'
							? 'User granted access!'
							: 'User denied access!',
					message:
						status === 'active'
							? 'Access successfully granted. The user can now view the meeting. Great work!'
							: 'Access successfully denied. The user does not have permission to view the meeting.',
				});
			} catch (error) {
				logger('error', 'failed to update request', error);
				showFailureNotification({
					message:
						"Sorry, we were unable to approve or deny the user's request. Please try again.",
				});
			} finally {
				navigate('/meetings');
			}
		};

		updateRequest();
	}, []);

	return <ReelayLogoPageLoader />;
}
