import {ActionIcon, Group, Paper, ScrollArea, Stack, Text} from '@mantine/core';
import { IMeeting } from '../../../../interfaces/meeting';
import { replaceApostropheHTMLEntityWithSingleQuote } from '../../../../_utils/handy-functions';
import { useFlags } from 'flagsmith/react';
import {
	getInitialsFromSpeakerName,
	getSpeakersFromTranscriptWithAssignedColors,
} from '../../../../helpers/transcript';
import { Utterance } from '../../../../helpers/data';
import TranscriptText from './TranscriptText';
import SpeakerInitialsAvatar from './SpeakerInitialsAvatar';
import TranscriptTextSearch from './TranscriptTextSearch';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
	timestamp,
	transcriptSearchQuery,
	videoPlayerCurrentTime,
} from '../../../../Atoms/meetingAtom';
import { styles } from '../styles';
import { MILLISECONDS_PER_SECOND } from '../../../constants';
import { createRef, useEffect, useRef, useState } from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/pro-solid-svg-icons";
import {useDisclosure} from "@mantine/hooks";
import EditSpeakersModal from './EditSpeakersModal';
import {saveSpeakerLabels} from "../../../../api/api";

interface Props {
	utterances: Utterance[];
	meeting: IMeeting;
	showTitle?: boolean;
	isAuditPage: boolean;
	refreshTranscript: (meetingID: string) => Promise<void>;
}
export const TranscriptPanel = ({
	utterances,
	meeting,
	showTitle = true,
	isAuditPage,
	refreshTranscript,
}: Props) => {
	const flags = useFlags(['audit_options']);
	const auditOptionsEnabled = flags?.audit_options?.enabled;
	const auditOptionsParsed = JSON.parse(flags?.audit_options?.value as any);
	const { transcript_readable, transcript_viewable } = !auditOptionsEnabled
		? {
			transcript_readable: false,
			transcript_viewable: true,
		}
		: auditOptionsParsed;
	const { classes } = styles();
	const viewportRef = useRef(null);
	const [selectedSpeakers, setSelectedSpeakers] = useState<string[]>([]);
	const setSearchQuery = useSetRecoilState(transcriptSearchQuery);
	const [showGradient, setShowGradient] = useState(true);
	const speakers = getSpeakersFromTranscriptWithAssignedColors(utterances);
	const speakerColorHashTable = speakers.reduce((acc: any, speaker: any) => {
		return {
			...acc,
			[speaker.name]: speaker.color,
		};
	}, {});
	const filteredUtterances =
		selectedSpeakers.length === 0
			? utterances
			: utterances.filter((utterance: Utterance) =>
				selectedSpeakers.includes(utterance.speaker)
			);
	// The gradient should be hidden when the bottom of the scroll area is within
	// `gradientHeight` pixels from the bottom of the content.
	const gradientHeight = 300; // The height of your gradient
	const handleScroll = ({ y }) => {
		// Assuming you have a ref to your ScrollArea, let's call it `scrollAreaRef`
		const scrollHeight = viewportRef.current.scrollHeight;
		const clientHeight = viewportRef.current.clientHeight;
		// Calculate the maximum Y position for scrolling
		const maxScrollY = scrollHeight - clientHeight;
		// The threshold to hide the gradient is the maximum Y minus the gradient height
		// i divided by 2 because i felt it was turning off too early.
		const gradientHideThreshold = maxScrollY - gradientHeight / 2;
		// If the current scroll position is greater than or equal to the threshold, hide the gradient
		setShowGradient(y < gradientHideThreshold);
	};
	useEffect(() => {
		return () => {
			setSearchQuery('');
		};
	}, []);
	// Create a ref for each transcriptCard
	const [activeTranscriptIndex, setActiveTranscriptIndex] = useState<number>(0);
	const currentTime = useRecoilValue(videoPlayerCurrentTime);
	const transcriptRefs = useRef([]);
	transcriptRefs.current = filteredUtterances.map(
		(_, i) => transcriptRefs.current[i] ?? createRef()
	);
	const [
		isEditSpeakerLabelsModalOpened,
		{ open: openEditSpeakerLabelsModal, close: closeEditSpeakerLabelsModal }
	] = useDisclosure(false);
	const handleSpeakersEdit = async (speakerLabelChanges: { [oldSpeakerLabel: string]: string }) => {
		await saveSpeakerLabels(meeting.id, speakerLabelChanges);
		await refreshTranscript(meeting.id);
		closeEditSpeakerLabelsModal();
	};

	// Effect to calculate active chapter index and scroll to it
	useEffect(() => {
		if (!isAuditPage) {
			let newActiveIndex = activeTranscriptIndex;
			// Find the index of the moment for the current time
			for (let i = 0; i < filteredUtterances.length; i++) {
				const { start } = filteredUtterances[i];
				if (currentTime < Math.floor(start / MILLISECONDS_PER_SECOND)) {
					newActiveIndex = i === 0 ? i : i - 1;
					break;
				} else if (
					currentTime >= Math.floor(start / MILLISECONDS_PER_SECOND) &&
					i === filteredUtterances.length - 1
				) {
					newActiveIndex = i;
					break;
				}
			}
			if (newActiveIndex !== activeTranscriptIndex) {
				setActiveTranscriptIndex(newActiveIndex);
				// Scroll the active moment card into view
				if (transcriptRefs.current[newActiveIndex]) {
					transcriptRefs.current[newActiveIndex].current.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				}
			}
		}
	}, [currentTime, filteredUtterances]);
	const transcriptIsOk = !!utterances.length;
	const transcriptIsMissing = !transcriptIsOk;
	if (transcript_viewable || !isAuditPage) {
		return (
			<>
				<Stack mx={'sm'} h={'100%'}>
					{showTitle ? (
						<Text className={'sub-header'} color={'primary-text'}>
							{replaceApostropheHTMLEntityWithSingleQuote(meeting.name)}
						</Text>
					) : null}
					<TranscriptTextSearch utterances={filteredUtterances} />
					<Group spacing={'xs'} mih={50} h={'auto'}>
						<Text className={classes.speakerText}>Speakers:</Text>
						<Group spacing={5}>
							{speakers.map((speaker: { name: string; color: string }, index) => (
								<SpeakerInitialsAvatar
									clickable={true}
									selectedSpeakers={selectedSpeakers}
									setSelectedSpeakers={setSelectedSpeakers}
									key={index}
									index={index}
									size={32}
									speakerName={speaker.name}
									speakerColor={speaker.color}
									initials={getInitialsFromSpeakerName(speaker.name)}
								/>
							))}
						</Group>
						<ActionIcon onClick={() => openEditSpeakerLabelsModal()}>
							<FontAwesomeIcon icon={faPencil} />
						</ActionIcon>
					</Group>
					<ScrollArea
						viewportRef={viewportRef}
						type={'always'}
						offsetScrollbars
						scrollbarSize={10}
						className={'transcript-scroll-area'}
						onScrollPositionChange={handleScroll}
						styles={{
							root: {
								flex: 2,
							},
							viewport: {
								'::after': {
									display: showGradient && !isAuditPage ? 'block' : 'none', // Control the display based on the state
									content: '""',
									position: 'absolute',
									left: 0,
									right: 0,
									bottom: 0,
									height: `${gradientHeight}px` /* Adjust the height to control how long the fade effect is */,
									pointerEvents:
										'none' /* This makes sure the fade doesn't interfere with scrolling or clicking */,
									background:
										'linear-gradient(to bottom, rgba(255, 255, 255, 0), #FFFFFF)' /* Adjust the color to match your background */,
								},
							},
						}}
					>
						{filteredUtterances.length ? (
							<Paper className={classes.paper} p='md' shadow={'xs'} h={'100%'}>
								<Stack spacing={'md'} h={'100%'}>
									{filteredUtterances.map(
										(utterance: Utterance, index: number) => (
											<TranscriptText
												ref={transcriptRefs.current[index]}
												utterance={utterance}
												key={index}
												index={index}
												speakerColorHashTable={speakerColorHashTable}
												isAuditPage={isAuditPage}
												activeIndex={activeTranscriptIndex}
												setActiveIndex={setActiveTranscriptIndex}
											/>
										)
									)}
								</Stack>
							</Paper>
						) : (
							<Text>No Transcript available..</Text>
						)}
					</ScrollArea>
				</Stack>
				<EditSpeakersModal
					opened={isEditSpeakerLabelsModalOpened}
					speakerLabels={speakers}
					onSave={handleSpeakersEdit}
					onClose={closeEditSpeakerLabelsModal}
				/>
			</>
		);
	}
	return (
		<Stack mx={'sm'} h={'100%'}>
			{showTitle ? (
				<Text className={'sub-header'} color={'primary-text'}>
					{replaceApostropheHTMLEntityWithSingleQuote(meeting.name)}
				</Text>
			) : null}
			<Group spacing={'xs'} mih={50} h={'auto'}>
				{transcriptIsOk && (
					<Text className={'sub-header'} color={'primary-text'}>
						Transcript is OK{' '}
						<span style={{ color: 'green', fontSize: '24px' }}>✓</span>
					</Text>
				)}
				{transcriptIsMissing && (
					<Text className={'sub-header'} color={'primary-text'}>
						Transcript is missing, please reach out to the Developer on call
					</Text>
				)}
			</Group>
		</Stack>
	);
};

export default TranscriptPanel;
