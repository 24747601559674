// src/libs/seats.ts

import { ISeat, User } from '../Atoms/userAtoms';

/**
 * Maps seats to organization members.
 * @param members - Array of organization members.
 * @param seats - Array of seats fetched from the backend.
 * @returns Updated array of members with seat statuses.
 */
export const mapSeatsToMembers = (members: User[], seats: ISeat[]): User[] => {
	// Create a map for O(1) lookup
	const seatMap: Record<string, ISeat> = seats.reduce((acc, seat) => {
		acc[seat.userID] = seat;
		return acc;
	}, {} as Record<string, ISeat>);

	return members.map((member) => ({
		...member,
		seat: seatMap[member.id] || {
			userID: member.id,
			status: 'inactive',
			id: '',
			subscriptionID: '',
		},
	}));
};

/**
 * Updates a single member's seat status.
 * @param member - The member to update.
 * @param seat - The seat information.
 * @returns Updated member with seat status.
 */
export const updateMemberSeatStatus = (member: User, seat: ISeat): User => ({
	...member,
	seat,
});

/**
 * Finds a seat by user ID.
 * @param seats - Array of seats.
 * @param userId - The user ID to search for.
 * @returns The seat if found, otherwise undefined.
 */
export const findSeatByUserId = (
	seats: ISeat[],
	userId: string
): ISeat | undefined => seats.find((seat) => seat.userID === userId);
