import {
	Avatar,
	Divider,
	Group,
	ScrollArea,
	Stack,
	Text,
	Tooltip,
	createStyles,
} from '@mantine/core';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
	attendeesList,
	currentMeeting,
	currentUtterances,
	meetingAttachments,
	selectedSpeakersAtom,
	showAllAttendeesAtom,
	showExpandedTranscript,
	timestamp,
	videoPlayerCurrentTime,
} from '../../../../../Atoms/meetingAtom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronDown,
	faExpand,
	faFolderArrowDown,
} from '@fortawesome/pro-regular-svg-icons';
import {
	Dispatch,
	SetStateAction,
	createRef,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import GeneralSearchBar from '../../GeneralSearchBar';
import TranscriptRow from './components/TranscriptRow';
import { MILLISECONDS_PER_SECOND } from '../../../../../components/constants';
import { applyTranscriptSearchFilter } from '../../../../../pages/meeting/helper';
import { downloadAttachment } from '../../../../../helpers/attachments';
import { showFailureNotification } from '../../../../../helpers/notifications';
import useMediaQueries from '../../../../../customHooks/useMediaQueries';
import { useParams } from 'react-router';
import SpeakerInitialsAvatar from '../../../../../components/aside/TabPanels/Transcript/SpeakerInitialsAvatar';
import {
	getInitialsFromSpeakerName,
	getSpeakersFromTranscriptWithAssignedColors,
} from '../../../../../helpers/transcript';
import { Utterance } from '../../../../../helpers/data';

const useStyles = createStyles((theme) => ({
	actionButton: {
		cursor: 'pointer',
	},
	speakerText: {
		color: 'black',
		fontWeight: 400,
		fontSize: 14,
		lineHeight: '20px',
	},
}));

interface Props {
	searchValue: string;
	setSearchValue: Dispatch<SetStateAction<string>>;
}

export default function TranscriptTabContent({
	searchValue,
	setSearchValue,
}: Props) {
	const { classes, theme } = useStyles();
	const viewport = useRef<HTMLDivElement>(null);
	const { tabValue } = useParams();
	const utterances = useRecoilValue(currentUtterances);
	const meeting = useRecoilValue(currentMeeting);
	const setShowExpandedTranscript = useSetRecoilState(showExpandedTranscript);
	const [webTimestamp, setWebTimestamp] = useRecoilState(timestamp);
	const [activeChapterIndex, setActiveChapterIndex] = useState<number>(0);
	const [debounced] = useDebouncedValue(searchValue, 300);
	const [initialLoad, setInitialLoad] = useState(true);
	const [transcriptList, setTranscriptList] = useState(utterances || []);
	const [selectedSpeakers, setSelectedSpeakers] =
		useRecoilState(selectedSpeakersAtom);
	const currentTime = useRecoilValue(videoPlayerCurrentTime);
	const attachments = useRecoilValue(meetingAttachments);
	const meetingTranscript = attachments.find(
		(attachment) => attachment.type === 'transcript'
	);
	const { widthOrHeightLessThanLG } = useMediaQueries();

	const speakers = getSpeakersFromTranscriptWithAssignedColors(utterances);

	useEffect(() => {
		if (initialLoad) setInitialLoad(false);

		if (!initialLoad) {
			setTranscriptList(applyTranscriptSearchFilter(utterances, debounced));
		}
	}, [debounced]);

	useEffect(() => {
		setTranscriptList(
			selectedSpeakers.length === 0
				? utterances
				: utterances.filter((utterance: Utterance) =>
						selectedSpeakers.includes(utterance.speaker)
				  )
		);
		setSearchValue('');
	}, [utterances, selectedSpeakers]);

	const momentRefs = useRef([]);
	momentRefs.current = utterances.map(
		(_, i) => momentRefs.current[i] ?? createRef()
	);

	// Effect to calculate active chapter index and scroll to it
	useEffect(() => {
		let newActiveIndex = activeChapterIndex;
		// Find the index of the moment for the current time
		for (let i = 0; i < transcriptList.length; i++) {
			const { start } = transcriptList[i];
			if (currentTime < Math.floor(start / MILLISECONDS_PER_SECOND)) {
				newActiveIndex = i === 0 ? i : i - 1;
				break;
			} else if (
				currentTime >= Math.floor(start / MILLISECONDS_PER_SECOND) &&
				i === transcriptList.length - 1
			) {
				newActiveIndex = i;
				break;
			}
		}
		if (newActiveIndex !== activeChapterIndex) {
			setActiveChapterIndex(newActiveIndex);
			// calculate the height of all the previous moments and subtract it from the scrollHeight
			let height = 0;

			for (let i = 0; i < newActiveIndex; i++) {
				height += momentRefs.current[i].current.clientHeight;
			}
			viewport.current.scrollTo({
				top: height,
				behavior: 'smooth',
			});
		}
	}, [activeChapterIndex]);

	useEffect(() => {
		let newActiveIndex = 0; // Start with an assumption that the first index is the default

		// Loop through items to find the highest index <= currentTime
		for (let i = 0; i < transcriptList.length; i++) {
			const { start } = transcriptList[i];
			const timeStartSeconds = Math.floor(start / MILLISECONDS_PER_SECOND);
			if (currentTime >= timeStartSeconds) {
				newActiveIndex = i;
			} else {
				break; // Exit the loop early if we find a chapter that starts after currentTime
			}
		}

		// Check if the newly found index is different from the current activeChapterIndex
		if (newActiveIndex !== activeChapterIndex) {
			setActiveChapterIndex(newActiveIndex);

			// Optionally scroll the active chapter card into view
			if (
				momentRefs.current[newActiveIndex] &&
				momentRefs.current[newActiveIndex].current
			) {
				// calculate the height of all the previous moments and subtract it from the scrollHeight
				let height = 0;

				for (let i = 0; i < newActiveIndex; i++) {
					height += momentRefs.current[i].current.clientHeight;
				}
				viewport.current.scrollTo({
					top: height,
					behavior: 'smooth',
				});
			}
		}
	}, [tabValue, currentTime, transcriptList]); // Dependency array now only includes tab, isolating this effect from other changes

	const handleTranscriptClick = (index: number, timeStartMS: number) => {
		setActiveChapterIndex(index);
		setWebTimestamp({
			time: Math.floor(timeStartMS / MILLISECONDS_PER_SECOND),
		});
	};

	const SPEAKER_LIMIT = 5;

	return (
		<Stack h={'100%'} mt={'md'}>
			<Group
				noWrap
				position='apart'
				spacing={widthOrHeightLessThanLG ? 'xs' : 'md'}
			>
				<Group
					noWrap
					spacing={'xs'}
					align='center'
					className={classes.actionButton}
					onClick={(e) => {
						e.stopPropagation();
						meetingTranscript
							? downloadAttachment(meetingTranscript, meeting.organizationID)
							: showFailureNotification({
									message: 'Sorry, no transcript available for download.',
							  });
					}}
				>
					<FontAwesomeIcon
						icon={faFolderArrowDown}
						size='lg'
						color={theme.colors['primary-cta'][0]}
					/>
					<Text size={14} color='primary-cta'>
						Download this Transcript
					</Text>
				</Group>
				<GeneralSearchBar
					value={searchValue}
					setValue={setSearchValue}
					width={'30%'}
					placeholder='Search Transcript'
					flex={2}
				/>
				<Group
					noWrap
					spacing={'xs'}
					align='center'
					className={classes.actionButton}
					onClick={(e) => {
						e.stopPropagation();
						setShowExpandedTranscript(true);
					}}
				>
					<Text size={14} color='primary-cta'>
						Expand
					</Text>
					<FontAwesomeIcon
						icon={faExpand}
						size='lg'
						color={theme.colors['primary-cta'][0]}
					/>
				</Group>
			</Group>
			<Group spacing={'xs'} mih={50} h={'auto'} noWrap>
				<Text className={classes.speakerText}>Speakers:</Text>
				<Group spacing={5}>
					{(speakers.length <= SPEAKER_LIMIT
						? speakers
						: speakers.slice(0, SPEAKER_LIMIT)
					).map((speaker: { name: string; color: string }, index) => (
						<SpeakerInitialsAvatar
							clickable={true}
							selectedSpeakers={selectedSpeakers}
							setSelectedSpeakers={setSelectedSpeakers}
							key={index}
							index={index}
							size={32}
							speakerName={speaker.name}
							speakerColor={speaker.color}
							initials={getInitialsFromSpeakerName(speaker.name)}
						/>
					))}
					{speakers.length > SPEAKER_LIMIT ? (
						<Tooltip
							position={'bottom'}
							withArrow
							label={
								<>
									{speakers.slice(SPEAKER_LIMIT).map((speaker, index) => (
										<div key={speaker.name}>{speaker.name}</div>
									))}
								</>
							}
						>
							<Avatar
								styles={{
									root: {
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										height: '40px',
										width: '40px',
										border: `1px solid transparent`,
									},
								}}
								variant={'filled'}
								radius={'xl'}
							>
								{`+${speakers.length - 5}`}
							</Avatar>
						</Tooltip>
					) : null}
				</Group>
			</Group>
			<ScrollArea
				viewportRef={viewport}
				lh={'25px'}
				type='always'
				offsetScrollbars
				scrollbarSize={10}
				styles={(theme) => ({
					root: {
						flex: 2,
						marginBottom: 16,
					},
					viewport: {
						overflowX: 'hidden',
						width: '100%',
						maxWidth: '100%',
						fontSize: 12,
						flex: 2,
					},
				})}
			>
				{transcriptList.map((utterance, index) => {
					return (
						<TranscriptRow
							key={utterance.start}
							item={utterance}
							index={index}
							handleTranscriptClick={handleTranscriptClick}
							scrollRef={momentRefs.current[index]}
							activeIndex={activeChapterIndex}
							searchValue={debounced}
						/>
					);
				})}
			</ScrollArea>
		</Stack>
	);
}
