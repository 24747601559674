import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Accordion,
	Group,
	Stack,
	Text,
	UnstyledButton,
	createStyles,
} from '@mantine/core';
import { activeLink, currentUser, hasAdminAccess } from '../../Atoms/userAtoms';
import { navbarOpenedAtom } from '../../Atoms/layout';
import {
	adminNavLinks,
	flagsmithFeatureFlags,
	navigationLinks,
} from '../constants';
import { useFlags } from 'flagsmith/react';
import { isSuperadmin, isSupportUser } from '../../helpers/auth';

interface MainLinkProps {
	icon?: any;
	label: string;
	path: string;
	nestedLinks?: { icon?: any; label: string; path: string }[];
}

const useStyles = createStyles((theme) => ({
	accordionControl: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '10px 0',
		cursor: 'pointer',
	},
	accordionPanel: {
		padding: '10px 0',
	},
	nestedLink: {
		width: '100%',
		padding: '10px 0',
		display: 'flex',
		alignItems: 'center',
		'&:hover': {
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[6]
					: theme.colors.gray[1],
		},
	},
	activeNestedLink: {
		backgroundColor:
			theme.colorScheme === 'dark'
				? theme.colors.dark[5]
				: theme.colors.blue[0],
	},
	iconSpacing: {
		marginRight: theme.spacing.sm,
	},
	clickableLink: {
		'&:hover': {
			backgroundColor:
				theme.colorScheme === 'dark'
					? theme.colors.dark[6]
					: theme.colors.gray[0],
		},
	},
}));

function MainLink({ icon, label, path, nestedLinks }: MainLinkProps) {
	const { classes, cx } = useStyles();
	const navigate = useNavigate();
	const [active, setActive] = useRecoilState(activeLink);
	const setNavbarOpened = useSetRecoilState(navbarOpenedAtom);
	const [nestedActive, setNestedActive] = useState('');
	const hasLinks = Boolean(nestedLinks && nestedLinks.length > 0);
	const location = useLocation();

	const isActive = (linkPath: string) => {
		const pathSegments = location.pathname.split('/');
		const furthestSubroute = pathSegments[pathSegments.length - 1];
		const lastSegmentOfLinkPath = linkPath.split('/').pop();
		return lastSegmentOfLinkPath?.includes(furthestSubroute);
	};

	const handleClick = () => {
		if (!hasLinks) {
			setActive(label);
			navigate(path);
			setNavbarOpened(false);
		}
	};

	const items = (hasLinks ? nestedLinks : []).map((link) => (
		<UnstyledButton
			key={link.label}
			className={cx(classes.nestedLink, {
				[classes.activeNestedLink]: isActive(link.path),
			})}
			onClick={() => {
				setNestedActive(link.label);
				navigate(link.path);
				setNavbarOpened(false);
			}}
		>
			{link.icon && (
				<FontAwesomeIcon
					icon={link.icon as any}
					className={classes.iconSpacing}
				/>
			)}
			<Text>{link.label}</Text>
		</UnstyledButton>
	));

	const content = (
		<Group position='apart' spacing={0}>
			<Group noWrap spacing={0}>
				{icon && (
					<FontAwesomeIcon icon={icon} className={classes.iconSpacing} />
				)}
				<Text fw={500} fz='md'>
					{label}
				</Text>
			</Group>
		</Group>
	);

	return hasLinks ? (
		<Accordion.Item value={path.substring(1)}>
			<Accordion.Control className={classes.accordionControl}>
				{content}
			</Accordion.Control>
			<Accordion.Panel className={classes.accordionPanel}>
				<Stack spacing={0}>{items}</Stack>
			</Accordion.Panel>
		</Accordion.Item>
	) : (
		<UnstyledButton
			className={cx(classes.accordionControl, classes.clickableLink)}
			p={'md'}
			onClick={handleClick}
		>
			{content}
		</UnstyledButton>
	);
}

interface MainLinksProps {
	openAccordionValue: string;
	setOpenAccordionValue: React.Dispatch<React.SetStateAction<string>>;
}

export default function AdminLinks({
	openAccordionValue,
	setOpenAccordionValue,
}: MainLinksProps) {
	const user = useRecoilValue(currentUser);
	const adminAccess = useRecoilValue(hasAdminAccess);
	const { meetingCollections, actionItems } = flagsmithFeatureFlags;
	const flags = useFlags([meetingCollections, actionItems]);
	const collectionsEnabled = flags.meeting_collections.enabled;
	const actionItemsEnabled = flags.action_items.enabled;
	const superAdmin = isSuperadmin(user.roles);
	const isSupport = isSupportUser(user.roles);

	const links = (adminAccess ? adminNavLinks : navigationLinks)
		.filter((link) => {
			if (link.label === 'Actions') {
				return actionItemsEnabled;
			}
			if (link.label === 'Collections') {
				return collectionsEnabled;
			}
			return true;
		})
		.map((link) => {
			const { icon, label, path, nestedLinks: allNestedLinks } = link;

			let nestedLinks = allNestedLinks;
			if (label === 'Settings' && !superAdmin) {
				nestedLinks = allNestedLinks.filter(
					(nestedLink) =>
						nestedLink.label !== 'Security' && nestedLink.label !== 'Billing'
				);
			}

			return (
				<MainLink
					icon={icon}
					path={path}
					label={label}
					key={link.label}
					nestedLinks={nestedLinks}
				/>
			);
		});

	return (
		<Accordion
			value={openAccordionValue}
			onChange={setOpenAccordionValue}
			multiple={false}
			styles={(theme) => ({
				content: {
					paddingLeft: 0,
					paddingTop: 0,
				},
			})}
		>
			{links}
		</Accordion>
	);
}
