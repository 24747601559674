import React from 'react';
import { IconSearch } from '@tabler/icons-react';
import { MantineSize, TextInput } from '@mantine/core';

interface Props {
	searchValue: string;
	handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	placeholder: string;
	style: React.CSSProperties;
	width: number;
	radius: MantineSize | number;
}

export default function MeetingSearchBar({
	searchValue,
	handleSearchChange,
	placeholder,
	style,
	width,
	radius,
}: Props) {
	return (
		<TextInput
			placeholder={placeholder}
			icon={<IconSearch size='0.9rem' stroke={1.5} />}
			value={searchValue}
			onChange={handleSearchChange}
			style={style}
			w={width}
			radius={radius}
		/>
	);
}
