import React, { useRef, useState } from 'react';
import { Button, Group, Modal, Stack, TextInput, Title } from '@mantine/core';
import { useFocusTrap } from '@mantine/hooks';
import { logger } from '../../../helpers/logger';
import {
	showFailureNotification,
	showSuccessNotification,
} from '../../../helpers/notifications';
import { user } from '../../../Atoms/userAtoms';
import { updateMeeting } from '../../../api/api';
import { IMeeting } from '../../../interfaces/meeting';
import { useRecoilState, useRecoilValue } from 'recoil';
import { currentMeeting } from '../../../Atoms/meetingAtom';

interface Props {
	setOpened: React.Dispatch<React.SetStateAction<boolean>>;
	opened: boolean;
}

export default function EditMeetingNameModal({ opened, setOpened }: Props) {
	const focusTrapRef = useFocusTrap();
	const buttonRef = useRef(null);
	const currentUser = useRecoilValue(user);
	const [meeting, setMeeting] = useRecoilState(currentMeeting);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>(null);
	const [value, setValue] = useState(meeting.name);

	const handleEditName = async () => {
		try {
			if (!value.length) {
				setError('Name is required');
				return;
			}

			setLoading(true);
			const res = await updateMeeting(
				{ name: value },
				meeting.id,
				meeting.organizationID
			);

			const name = res?.data?.data?.name;
			setMeeting({
				...meeting,
				name,
			});
			logger('info', `Meeting ${meeting.id} name updated to ${name}`, name);
			setOpened(false);
			setValue(name);
			setError(null);
			showSuccessNotification({
				message: 'Meeting name successfully changed!',
			});
		} catch (error) {
			logger('error', 'Error editing meeting name', error);
			showFailureNotification({
				message: `Sorry, there was an error editing the meeting name. Reason: ${
					error?.response?.data || error?.message
				}`,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleKeyPress = (e) => {
		if (e.key === 'Enter' && buttonRef.current) {
			buttonRef.current.click();
		}
	};

	return (
		<Modal
			size={'sm'}
			title={
				<Title order={6} size={'h3'} fw={400}>
					Edit Meeting Name
				</Title>
			}
			opened={opened}
			onClose={() => setOpened(false)}
		>
			<Stack ref={focusTrapRef}>
				<TextInput
					data-autofocus
					placeholder={'Enter meeting name'}
					value={value}
					onChange={(event) => setValue(event.currentTarget.value)}
					disabled={loading}
					error={error}
					onKeyDown={handleKeyPress}
				/>
				<Group noWrap position={'right'} spacing={'xs'}>
					<Button
						variant='subtle'
						radius='md'
						onClick={() => setOpened(false)}
						px={'sm'}
						disabled={loading}
					>
						Cancel
					</Button>
					<Button
						ref={buttonRef}
						onClick={handleEditName}
						radius='md'
						px={'sm'}
						loading={loading}
					>
						Save
					</Button>
				</Group>
			</Stack>
		</Modal>
	);
}
