import { DateTime } from 'luxon';
import { User } from '../Atoms/userAtoms';

export interface ShareableLink {
	createdAt: string;
	expirationDate?: string;
	id: string;
	meetingID: string;
	organizationID: string;
	slug: string;
	status: string;
	title?: string;
	updatedAt: string;
	url: string;
	userID: string;
}

export interface ShareableLinkTableData {
	createdAt: string;
	expirationDate: string;
	status: string;
	title: string;
	url: string;
	owner: string;
	link: ShareableLink;
}

export interface MeetingUserLink {
	createdAt: string;
	id: string;
	meetingID: string;
	presented: boolean;
	priority: number;
	shareableLinkID: string | null;
	updatedAt: string;
	userID: string;
	status: string;
	user: User;
}

export const modifyLinkDataForTable = (
	links: ShareableLink[],
	users: User[]
): ShareableLinkTableData[] => {
	return links.map((link) => {
		const user = users.find((u) => u.id === link.userID);
		const owner = user
			? user?.firstName && user?.lastName
				? `${user?.firstName} ${user?.lastName}`
				: user?.email
			: 'Unknown';
		return {
			createdAt: `${DateTime.fromISO(link.createdAt).toLocaleString(
				DateTime.DATE_MED
			)}`,
			expirationDate: link.expirationDate
				? `${DateTime.fromISO(link.expirationDate).toLocaleString(
						DateTime.DATE_MED
				  )}`
				: 'Never',
			status: link.status,
			title: link.title || 'No title',
			url: link.url,
			owner,
			link,
		};
	});
};

export function sortLinksByStatus(links: ShareableLink[]) {
	const sortedLinks = [...links];

	sortedLinks.sort((linkA, linkB) => {
		if (linkA.status === 'active' && linkB.status !== 'active') {
			return -1; // linkA should come before linkB
		} else if (linkA.status !== 'active' && linkB.status === 'active') {
			return 1; // linkA should come after linkB
		} else {
			return 0; // Keep the order unchanged
		}
	});

	return sortedLinks;
}
