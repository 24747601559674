import React from 'react';
import { Stack, Text, useMantineTheme } from '@mantine/core';
import { ReelayLoader } from '../../ReelayLoader';

const ReelayLogoPageLoader = ({
  caption = '',
  height = '80vh',
}: {
  caption?: string;
  height?: string;
}) => {
  const theme = useMantineTheme();

  return (
    <Stack justify={'center'} h={height}>
      <Stack justify={'center'} align={'center'}>
        <ReelayLoader />
        <Text color={theme.colors['primary-text'][0]}>{caption}</Text>
      </Stack>
    </Stack>
  );
};

export default ReelayLogoPageLoader;
